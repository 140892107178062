import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect, useLape, useLapeEffect } from 'lape'
import { useEffect } from 'react'

export interface FormLocalstorageProps {
  isExistingData: boolean
  url: string
  disabled?: boolean
  followUrlTransitions?: boolean
  removeDataAfterTransitionFromUrls?: (string | RegExp)[]
}

const FormLocalstorageLape = ({
  isExistingData,
  url,
  disabled,
  followUrlTransitions,
  removeDataAfterTransitionFromUrls,
}: FormLocalstorageProps) => {
  const { values } = useLapeContext()
  // useLapeEffect cashes the callback, so it always uses the initial url
  // sometimes it's not a desired behavior
  const state = useLape<{ url: string }>({ url })

  const updateLocalStorage = (forcedUrl?: string) => {
    if (!isExistingData && !disabled) {
      try {
        localStorage.setItem(forcedUrl || state.url, JSON.stringify(values))
      } catch (e) {
        console.warn(e)
      }
    }
  }

  useLapeEffect(updateLocalStorage)

  useEffect(() => {
    if (!followUrlTransitions) {
      return
    }
    if (state.url === url) {
      return
    }
    if (removeDataAfterTransitionFromUrls?.some(str => state.url.match(str))) {
      localStorage.removeItem(state.url)
    }
    // to avoid async behaviour url is passed directly
    updateLocalStorage(url)
    state.url = url
  }, [url])

  return null
}

export default connect(FormLocalstorageLape)
