import Form from '@src/features/Form/Form'
import { tableauIntegrationPreferences } from '@src/api/integrations'
import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import {
  ActionButton,
  Box,
  Flex,
  Icon,
  Item,
  Token,
  Text,
  Spacer,
  Avatar,
  HStack,
  VStack,
} from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TableauIntegrationInterface } from '@src/interfaces/integrations'

import { getStringMessageFromError } from '@src/store/notifications/actions'
import { IntegrationEnablePopup } from '../components/IntegrationEnablePopup'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const TableauIntegration = () => {
  return (
    <Form api={tableauIntegrationPreferences} forceParams={{ id: '1' }}>
      <TableauForm />
    </Form>
  )
}

const TableauForm = () => {
  const queryClient = useQueryClient()
  const { values, reset, submit, isSubmitting, loading } =
    useLapeContext<TableauIntegrationInterface>()
  const confirmationPopup = useConfirmationDialog()
  const [enableIntegrationPopupOpen, setEnableIntegrationPopupOpen] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  useEffect(() => {
    const integrationConnected = workspaceLocalStorage.getItem(
      LocalStorageKeys.CONNECTED_INTEGRATION,
    )
    if (integrationConnected) {
      showStatusPopup({
        status: 'success',
        title: `${integrationConnected} integration successful`,
      })
      workspaceLocalStorage.removeItem(LocalStorageKeys.CONNECTED_INTEGRATION)
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="Tableau"
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
        subtitle={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }} pb="s-4">
            <HStack align="end">
              <VStack>
                {values.enabled && (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  Collaboration
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image={{
                  default: 'https://assets.revolut.com/assets/apps/Tableau.webp',
                  '2x': 'https://assets.revolut.com/assets/apps/Tableau@2x.webp',
                  '3x': 'https://assets.revolut.com/assets/apps/Tableau@3x.webp',
                }}
                size={56}
              >
                {values.enabled ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
      />

      {!values.enabled && (
        <Box pt="s-16">
          <ActionButton
            useIcon="Link"
            pending={loading}
            disabled={loading || isSubmitting}
            variant="accent"
            onClick={async evnt => {
              evnt.preventDefault()
              setEnableIntegrationPopupOpen(true)
            }}
          >
            Enable
          </ActionButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        {/* TODO: CREATE PAGE CONTENT https://revolut.atlassian.net/browse/REVC-6918 */}
        {values.enabled && (
          <Item
            color={Token.color.danger}
            use="button"
            onClick={async evnt => {
              evnt.preventDefault()
              confirmationPopup.show({
                label: 'Are you sure?',
                body: 'After disabling this integration you won’t be able to use Tableau as a tracking tool',
                onConfirm: async () => {
                  values.enabled = false
                  try {
                    const response = await submit()
                    showStatusPopup({ title: 'Integration disabled' })
                    reset(response)
                  } catch (error) {
                    showStatusPopup({
                      title: 'Cannot disable integration',
                      description: getStringMessageFromError(error),
                      status: 'error',
                    })
                    values.enabled = true
                  }
                },
              })
            }}
          >
            <Item.Content>
              <Flex gap="s-16">
                <Icon name="Unlink" />
                <Item.Title>Disconnect Tableau</Item.Title>
              </Flex>
            </Item.Content>
          </Item>
        )}
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.TABLEAU_INTEGRATION_PREFERENCES)
          }}
          useValidator
        />
      </PageActions>
      <IntegrationEnablePopup
        isOpen={enableIntegrationPopupOpen}
        type={'tableau'}
        onClose={() => {
          setEnableIntegrationPopupOpen(false)
        }}
      />
    </PageWrapper>
  )
}
