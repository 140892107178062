import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { BenchmarkInterface } from '@src/interfaces/benchmarks'
import { selectPermissions } from '@src/store/auth/selectors'
import { getBenchmarks } from '@src/api/benchmarks'
import {
  benchmarkCreatedByColumn,
  benchmarkCreatedOnColumn,
  benchmarkLocationColumn,
  benchmarkLowerBandColumn,
  benchmarkSeniorityColumn,
  benchmarkSeniorityLevelColumn,
  benchmarkSpecialisationColumn,
  benchmarkStatusColumn,
  benchmarkTagsColumn,
  benchmarkTypeColumn,
  benchmarkUpperBandColumn,
} from '@src/constants/columns/benchmarks'
import { PermissionTypes } from '@src/store/auth/types'
import Stat from '@src/components/Stat/Stat'
import { CurrencySelect } from '@src/components/CurrencySelect/CurrencySelect'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable, useTable } from '@components/TableV2/hooks'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'

const Benchmarks = () => {
  const permissions = useSelector(selectPermissions)

  const isCommercial = useIsCommercial()
  const isNewTable = useIsNewTable()

  const [currency, setCurrency] = useState<string>()

  const table = useTable<BenchmarkInterface>({ getItems: getBenchmarks })

  const { data: settings } = useGetOrganisationSettings()

  const ROW = useMemo((): RowInterface<BenchmarkInterface> => {
    return {
      linkToForm: ({ id, is_hidden }) => {
        if (is_hidden) {
          return
        }
        navigateTo(pathToUrl(ROUTES.FORMS.BENCHMARK.PREVIEW, { id }))
      },
      disabled: ({ is_hidden }) => is_hidden,
      cells: [
        {
          ...benchmarkSpecialisationColumn,
          width: 160,
        },
        {
          ...benchmarkSeniorityColumn,
          width: 100,
        },
        settings?.enable_multiple_levels_per_seniority
          ? {
              ...benchmarkSeniorityLevelColumn,
              width: 100,
            }
          : null,
        {
          ...benchmarkLocationColumn,
          width: 100,
        },
        {
          ...benchmarkTypeColumn,
          width: 100,
        },
        {
          ...benchmarkTagsColumn,
          width: 120,
        },
        {
          ...benchmarkLowerBandColumn,
          width: 100,
        },
        {
          ...benchmarkUpperBandColumn,
          width: 100,
        },
        {
          ...benchmarkCreatedOnColumn,
          width: 100,
        },
        {
          ...benchmarkCreatedByColumn,
          width: 160,
        },
        {
          ...benchmarkStatusColumn,
          width: 120,
        },
      ].filter(Boolean),
    }
  }, [settings])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="Compensation bands"
          val={table?.loading ? undefined : table?.count}
          mr="s-16"
        />

        <CurrencySelect
          onCurrencyChange={({ iso_code }) => {
            setCurrency(iso_code)
            table.onFilterChange({
              columnName: 'target_currency',
              filters: [{ id: iso_code, name: iso_code }],
            })
          }}
          value={currency ?? 'Select'}
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={4}>
          {permissions.includes(PermissionTypes.AddBenchmarks) && (
            <PrimaryAction
              use={InternalLink}
              useIcon="Plus"
              to={pathToUrl(ROUTES.FORMS.BENCHMARK.GENERAL)}
            >
              Add compensation band
            </PrimaryAction>
          )}
          {permissions.includes(PermissionTypes.AddBenchmarksBulkUploadSession) && (
            <MoreBar.Action
              use={InternalLink}
              useIcon="ShareIOs"
              to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.UPLOAD_FILE)}
            >
              Bulk update bands
            </MoreBar.Action>
          )}

          <MoreBar.Action
            use={InternalLink}
            useIcon="Pencil"
            to={pathToUrl(ROUTES.FORMS.BULK_EDIT_COMPENSATION_BANDS)}
          >
            Bulk edit
          </MoreBar.Action>
          <SettingsButton
            canView={[PermissionTypes.ViewTotalCompensationPreferences]}
            path={ROUTES.SETTINGS.COMPENSATION.GENERAL}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          hiddenCells={{
            [benchmarkTagsColumn.idPoint]: isCommercial,
          }}
          name={TableNames.CompensationBenchmarks}
          useWindowScroll
          row={ROW}
          {...table}
          noDataMessage="Benchmarks will appear here."
          tableSettings={
            isNewTable
              ? {
                  visible: [],
                  hidden: [benchmarkTagsColumn.title, benchmarkCreatedByColumn.title],
                }
              : undefined
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Benchmarks
