import {
  ExportRequest,
  GetRequestData,
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
  Statuses,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  EligibilityCheckOptionsInterface,
  EligibilityCheckResult,
  PromotionNominationInterface,
  PromotionNominationProgression,
  PromotionNominationTimelineInterface,
  PromotionStage,
  PromotionStageInterface,
  PromotionStageReviewData,
} from '@src/interfaces/promotions'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { BenchmarkInterface } from '@src/interfaces/benchmarks'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import { RewardsSalaryStats } from '@src/interfaces/rewards'
import isNil from 'lodash/isNil'
import { FeatureFlags } from '@src/store/auth/types'
import { getCommentsAPI } from '@src/api/comments'
import { ChatMessageInterface } from '@src/interfaces/chat'

const getEmployeePromotionBaseUrl = (employeeId: number | string) => {
  return `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}${API.PROMOTION_NOMINATIONS}`
}

const promotionsBaseUrl = `${API.PERFORMANCE}${API.PROMOTION_NOMINATIONS}`

export const promotionNominationRequests: RequestInterface<PromotionNominationInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(
        `${promotionsBaseUrl}`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        'v2',
      ),
    getItem: async id => api.get(`${promotionsBaseUrl}/${id}`, undefined, 'v2'),
    patchItem: async (data, id) =>
      api.patch(`${promotionsBaseUrl}/${id}`, data, undefined, 'v2'),
    putItem: async (data, id) =>
      api.put(`${promotionsBaseUrl}/${id}`, data, undefined, 'v2'),
    deleteItem: async id => api.delete(`${promotionsBaseUrl}/${id}`, undefined, 'v2'),
    postItem: async data => api.post(promotionsBaseUrl, data, undefined, 'v2'),
  }

export const getPromotionNominationRequestsNew = (
  employeeId: number | string,
): RequestInterfaceNew<PromotionNominationInterface> => ({
  get: async ({ id }) =>
    api.get(`${getEmployeePromotionBaseUrl(employeeId)}/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.put(
      `${getEmployeePromotionBaseUrl(employeeId)}/${id}`,
      data,
      undefined,
      'v2',
    ),
  delete: async ({ id }) =>
    api.delete(`${getEmployeePromotionBaseUrl(employeeId)}/${id}`, undefined, 'v2'),
  submit: async data =>
    apiWithoutHandling.post(
      getEmployeePromotionBaseUrl(employeeId),
      data,
      undefined,
      'v2',
    ),
})

export const useGetEmployeePendingPromotions = (employeeId?: number | string) => {
  return useFetch<GetRequestData<PromotionNominationInterface>>(
    employeeId ? getEmployeePromotionBaseUrl(employeeId) : null,
    'v2',
    { params: { status: Statuses.in_review, employee__id: employeeId } },
  )
}

export const useGetEmployeePromotions = ({
  employeeId,
  staleTime,
  cycleId,
}: {
  employeeId?: number | string
  staleTime?: number
  cycleId?: number | string
}) => {
  return useFetch<GetRequestData<PromotionNominationInterface>>(
    employeeId ? getEmployeePromotionBaseUrl(employeeId) : null,
    'v2',
    {
      params: { employee__id: employeeId, review_cycle__id: cycleId },
    },
    undefined,
    staleTime ? { staleTime } : undefined,
  )
}

export const useGetPromotionProgression = (
  employeeId?: number | string,
  cycleId?: number | string,
) => {
  return useFetch<PromotionNominationTimelineInterface>(
    employeeId ? `${API.PROMOTION_PROGRESSION}/${employeeId}/timeline` : null,
    'v2',
    {
      params: {
        cycle__id: cycleId,
      },
    },
  )
}

export const useGetPromotionProgressionTrack = (employeeId?: number | string) => {
  return useFetch<PromotionNominationProgression>(
    employeeId ? `${API.PROMOTION_PROGRESSION}/${employeeId}/progressionTrack` : null,
    'v2',
  )
}

export const getNomineePerformanceHistory =
  (nominationId: string): RequestInterface<TalentPerformanceInterface>['getItems'] =>
  ({ sortBy, filters, page }) => {
    return api.get<GetRequestData<TalentPerformanceInterface>>(
      `${API.PERFORMANCE}/employeesCalibratedGrades/promotion`,
      {
        params: {
          ...filterSortPageIntoQuery(sortBy, filters, page),
          promotion_nomination_id: nominationId,
        },
      },
    )
  }

export const useGetNomineeCurrentSalary = (
  nominationId: number,
  employeeId?: number | string,
  currencyIso?: string,
) => {
  return useFetch<RewardsSalaryStats>(
    employeeId && !isNil(currencyIso)
      ? `${API.EMPLOYEES}/${employeeId}/salarySummary/promotionSalary`
      : null,
    'v1',
    {
      params: {
        target_currency: currencyIso || '',
        promotion_nomination_id: nominationId,
      },
    },
  )
}

export const checkPromotionEligibility = (
  employeeId: number | string,
  options: EligibilityCheckOptionsInterface,
  nominationId?: string,
) => {
  return api.post<EligibilityCheckResult>(
    nominationId
      ? `${getEmployeePromotionBaseUrl(employeeId)}/${nominationId}/check`
      : `${getEmployeePromotionBaseUrl(employeeId)}/check`,
    options,
    undefined,
    'v2',
  )
}

export const exportPromotionNominees: ExportRequest = () => {
  return api.get(`${promotionsBaseUrl}/csv`, undefined, 'v2')
}

export const useGetPromotionNomination = (
  employeeId: number | string,
  id?: number | string,
) => {
  return useFetch<PromotionNominationInterface>(
    id ? `${getEmployeePromotionBaseUrl(employeeId)}/${id}` : null,
    'v2',
  )
}

export const getPromotionStagesRequest =
  (nominationId: number | string) =>
  (): AxiosPromise<GetRequestInterface<PromotionStageInterface>> => {
    return api.get<GetRequestInterface<PromotionStageInterface>>(
      `${promotionsBaseUrl}/${nominationId}/stages`,
      undefined,
      'v2',
    )
  }

export const useGetPromotionStages = (nominationId?: number) => {
  return useFetch<GetRequestInterface<PromotionStageInterface>>(
    nominationId ? `${promotionsBaseUrl}/${nominationId}/stages` : null,
    'v2',
  )
}

export const useGetPromotionStage = (
  nominationId: number | string,
  stageId: number | string,
) => {
  return useFetch<PromotionStageInterface>(
    `${promotionsBaseUrl}/${nominationId}/stages/${stageId}`,
    'v2',
  )
}

export const changePromotionStage = (
  nominationId: number | string,
  currentStageId: number,
  newStage: PromotionStage,
) => {
  return api.patch(
    `${promotionsBaseUrl}/${nominationId}/stages/${currentStageId}/skip`,
    {
      stage_type: { id: newStage },
    },
    undefined,
    'v2',
  )
}

export const delegatePromotionStage = (
  nominationId: number | string,
  stageId: number,
  newReviewerId: number | string,
) => {
  return api.patch(
    `${promotionsBaseUrl}/${nominationId}/stages/${stageId}/delegate`,
    {
      reviewer: { id: newReviewerId },
    },
    undefined,
    'v2',
  )
}

export const cancelPromotionNomination = (
  employeeId: number | string,
  nominationId: number | string,
) => {
  return api.patch(
    `${getEmployeePromotionBaseUrl(employeeId)}/${nominationId}/cancel`,
    undefined,
    undefined,
    'v2',
  )
}

export const useGetPromotionSalaryBand = (options?: {
  nominationId: number | string
  seniorityId: number | string
  specialisationId: number | string
  locationId: number | string
}) => {
  const params = options
    ? {
        seniority__id: options.seniorityId,
        specialisation__id: options.specialisationId,
        location__id: options.locationId,
        benchmark_type: 'salary',
        source: 'internal',
        status: Statuses.approved,
        promotion_nomination_id: options.nominationId,
      }
    : {}

  const result = useFetch<BenchmarkInterface[]>(
    options ? `${API.BENCHMARKS}/promotionSalaryBands` : null,
    'v1',
    {
      params,
    },
  )
  const noBenchmark = result.data && result.data.length === 0
  const data = result.data && (noBenchmark ? ('none' as const) : result.data[0])

  return {
    ...result,
    data,
  }
}

export const updatePromotionStage = (data: {
  nominationId: number | string
  stageId: number | string
  stageData: PromotionStageReviewData
}) => {
  return apiWithoutHandling.put<PromotionStageInterface>(
    `${promotionsBaseUrl}/${data.nominationId}/stages/${data.stageId}`,
    data.stageData,
    undefined,
    'v2',
  )
}

export const enablePromotionFeature = (enable?: boolean) => {
  return api.put(`${API.EMPLOYEES}/features/featureSettings/switch`, {
    codename: FeatureFlags.PromotionNomination,
    is_enabled: enable,
  })
}

export const reopenPromotion = ({
  nominationId,
  employeeId,
}: {
  nominationId: string
  employeeId: string
}) => {
  return api.patch(
    `${API.EMPLOYEES}/${employeeId}/${promotionsBaseUrl}/${nominationId}/reopen`,
    undefined,
    undefined,
    'v2',
  )
}

export const resetPromotionStage = ({
  stageId,
  employeeId,
}: {
  stageId: string
  employeeId: string
}) => {
  return apiWithoutHandling.patch<PromotionStageInterface>(
    `${promotionsBaseUrl}/${employeeId}/stages/${stageId}/reset`,
    undefined,
    undefined,
    'v2',
  )
}

export const getPromotionComments = (nomination_id: string | number) =>
  getCommentsAPI({
    baseUrl: `${API.PROMOTION}/${nomination_id}/comments`,
    apiVersion: 'v2',
  })

export const addPromotionComment = (nomination_id: string | number, comment: string) =>
  api.post<ChatMessageInterface>(
    `${API.PROMOTION}/${nomination_id}/comments`,
    {
      body: comment,
      create_task: false,
    },
    undefined,
    'v2',
  )
