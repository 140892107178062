import React from 'react'
import { useSelector } from 'react-redux'
import { Box, HStack, MoreBar, VStack } from '@revolut/ui-kit'

import { selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PermissionTypes } from '@src/store/auth/types'
import SettingsButtons from '@src/features/SettingsButtons'
import { useGlobalSettings } from '@src/api/settings'
import { useResignationPermissions } from '@src/pages/Forms/EmployeeResignation/hooks'
import { ProfileSectionsData } from '../../hooks'
import { ChangePassword } from './Actions/ChangePassword'
import { GetActivationLink } from './Actions/GetActivationLink'
import { GetMagicLink } from './Actions/GetMagicLink'
import { ResendActivationEmail } from './Actions/ResendActivationEmail'
import { SubmitResignation } from './Actions/SubmitResignation'
import { CreateTicket } from './Actions/CreateTicket'
import { AnonymiseProfile } from './Actions/AnonymiseProfile'
import { CandidateProfile } from './Links/CandidateProfile'
import { Email } from './Links/Email'
import { Jira } from './Links/Jira'
import { AssignPermissions } from './Permissions'
import { UseEmployeeStatusTransitionsReturnType } from './ChangeStatusDropdown/StatusChange/hooks'
import { UseEmployeeInactivityDataReturnType } from './ChangeStatusDropdown/SetInactive/hooks'
import { StatusChangeButtonsGroup } from './ChangeStatusDropdown/StatusChange'
import { ChangeStatusDropdown } from './ChangeStatusDropdown'
import { ActionsGroupDropdown } from './Actions'
import { AddFeedback } from './Actions/AddFeedback'
import { LinksGroupDropdown } from './Links'
import { EditDropdown } from './EditDropdown'

type Props = {
  data: EmployeeInterface
  refreshData: () => void
  statusTransitions: UseEmployeeStatusTransitionsReturnType
  employeeInactivity: UseEmployeeInactivityDataReturnType
}
export const ActionButtonsGroup = ({
  data,
  refreshData,
  statusTransitions,
  employeeInactivity,
}: Props) => {
  const user = useSelector(selectUser)
  const isThisUser = data?.id && data.id === user?.id

  const {
    settings: { employee_onboarding_v2_enabled, resignations_enabled },
  } = useGlobalSettings()
  const { canAdd } = useResignationPermissions()
  const canCreateResignation = resignations_enabled && canAdd

  const { id, field_options } = data

  const canChangeEmployeeStatus = field_options?.permissions?.includes(
    PermissionTypes.ChangeEmployeeStatus,
  )
  const shouldRender = id && (canChangeEmployeeStatus || canCreateResignation)

  if (shouldRender && statusTransitions.data != null) {
    return (
      <VStack space="s-16">
        <Box>
          <SettingsButtons mb="s-16">
            <ChangePassword data={data} />
            <GetActivationLink data={data} />
            <GetMagicLink data={data} />
            <StatusChangeButtonsGroup
              employee={data}
              statusTransitions={statusTransitions}
              employeeInactivity={employeeInactivity}
              isEmployeeOnboardingV2={!!employee_onboarding_v2_enabled}
            />
            <ResendActivationEmail data={data} />
            <CandidateProfile data={data} />
            <Jira data={data} />
            <Email data={data} />
            <SubmitResignation data={data} />
            <AssignPermissions data={data} />
            <CreateTicket data={data} />
            <AnonymiseProfile data={data} refreshData={refreshData} />
          </SettingsButtons>
        </Box>
      </VStack>
    )
  }
  if (canCreateResignation || isThisUser) {
    return (
      <MoreBar>
        <ChangePassword data={data} />
        <SubmitResignation data={data} />
      </MoreBar>
    )
  }
  return null
}

type DropdownActionButtonsGroupProps = {
  data: EmployeeInterface
  refreshData: () => void
  statusTransitions: UseEmployeeStatusTransitionsReturnType
  employeeInactivity: UseEmployeeInactivityDataReturnType
  sectionsData: ProfileSectionsData
}
export const DropdownActionButtonsGroup = ({
  data,
  refreshData,
  statusTransitions,
  employeeInactivity,
  sectionsData,
}: DropdownActionButtonsGroupProps) => {
  const user = useSelector(selectUser)
  const isCurrentUser = user.id === data.id

  const {
    settings: { employee_onboarding_v2_enabled },
  } = useGlobalSettings()

  return (
    <>
      <HStack space="s-8">
        <EditDropdown data={data} sectionsData={sectionsData} />
        <ChangeStatusDropdown
          data={data}
          isCurrentUser={isCurrentUser}
          statusTransitions={statusTransitions}
          employeeInactivity={employeeInactivity}
          isEmployeeOnboardingV2={!!employee_onboarding_v2_enabled}
        />
        <AddFeedback data={data} />
        <AssignPermissions data={data} />
        <MoreBar maxCount={0}>
          <ActionsGroupDropdown data={data} refreshData={refreshData} />
          <LinksGroupDropdown data={data} />
        </MoreBar>
      </HStack>
    </>
  )
}
