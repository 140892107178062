import React, { useEffect, useMemo, useRef, useState } from 'react'
import lowerCase from 'lodash/lowerCase'
import { ChatMessageInterface } from '@src/interfaces/chat'
import {
  Flex,
  Spinner,
  Text,
  useIntersectViewport,
  DetailsCellSkeleton,
  VStack,
} from '@revolut/ui-kit'
import ChatMessage, { ChatMessageProps } from '@components/Chat/ChatMessage'
import { Grid } from '@components/CommonSC/Grid'
import { FileInterface } from '@src/interfaces/files'

export interface ChatMessagesListProps extends Omit<ChatMessageProps, 'message'> {
  isLoading: boolean
  data: ChatMessageInterface[] | null
  scrollToComment?: number
  onAfterScrollToComment?: () => void
  maxShown?: number
  reversedOrder?: boolean
  showNoDataMessage?: boolean
  canEdit?: boolean
  withInfiniteLoading?: boolean
  loadNextPage?: () => void
  onPreviewAttachment?: (file: FileInterface) => void
}

const ChatMessagesList = ({
  isLoading,
  data,
  maxShown,
  reversedOrder,
  showNoDataMessage,
  canEdit = true,
  scrollToComment,
  onAfterScrollToComment,
  withInfiniteLoading,
  loadNextPage,
  ...messageProps
}: ChatMessagesListProps) => {
  // If you use useRef here, component won't be re-rendered when ref changes, useState ensures it's re-rendered
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const messages = useMemo(() => {
    if (!data) {
      return data
    }
    const ordered = reversedOrder ? [...data].reverse() : data

    if (maxShown && !withInfiniteLoading) {
      return ordered.slice(0, maxShown)
    }
    return ordered
  }, [data, maxShown, reversedOrder, withInfiniteLoading])

  useEffect(() => {
    if (scrollToComment && ref) {
      const comment = ref.querySelector(`[data-commentid="${scrollToComment}"]`)
      comment?.scrollIntoView(true)
      onAfterScrollToComment?.()
    }
  }, [scrollToComment, ref])

  const infiniteLoaderIntersectionRef = useRef<HTMLDivElement>(null)
  useIntersectViewport(
    isLoading ? undefined : infiniteLoaderIntersectionRef,
    isIntersecting => {
      if (!withInfiniteLoading) {
        return
      }
      if (isIntersecting) {
        loadNextPage?.()
      }
    },
  )

  if (!withInfiniteLoading && (isLoading || !messages)) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spinner color="grey-tone-50" />
      </Flex>
    )
  }

  const hasNoMessages = messages?.length && messages.length < 1

  if (hasNoMessages && showNoDataMessage) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Text color="grey-tone-50" use="p" padding="1em">
          No {lowerCase(messageProps.type)}s yet
        </Text>
      </Flex>
    )
  }

  return (
    <div ref={setRef}>
      <Grid flow="row" gap={16}>
        {messages?.map(message => (
          <ChatMessage
            key={message.id}
            message={message}
            canEdit={canEdit}
            highlight={scrollToComment === message.id}
            {...messageProps}
          />
        ))}
        <VStack ref={infiniteLoaderIntersectionRef} space="s-16">
          {withInfiniteLoading && isLoading && (
            <>
              <DetailsCellSkeleton />
              <DetailsCellSkeleton />
              <DetailsCellSkeleton />
            </>
          )}
        </VStack>
      </Grid>
    </div>
  )
}

export default ChatMessagesList
