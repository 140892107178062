import React from 'react'

import { chain, Ellipsis, Flex, HStack, Text, Token, VStack } from '@revolut/ui-kit'
import {
  EmployeeTimeOffRequestCalendar,
  EmployeeTimeOffRequestsCalendarInterface,
  PublicHolidayCalendarItem,
} from '@src/interfaces/timeOff'
import { newTabTo, pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  approvalStatusIdToIconColor,
  getCellBoxProps,
  getRequestDayTextPeriod,
  getTimeOffCalendarInfoByDay,
  requestToIcon,
} from './helpers'
import {
  BORDER_WIDTH,
  WEEK_CELL_HEIGHT,
  WEEK_CELL_WIDTH,
  TooltipContainer,
} from './constants'

const getPolicyCardTooltipBody = ({
  request,
  publicHoliday,
  canViewPolicy,
}: {
  request: EmployeeTimeOffRequestCalendar | undefined
  publicHoliday: PublicHolidayCalendarItem | undefined
  canViewPolicy: boolean
}) => {
  const hasApprovalStatus = !!request?.approval_status.name

  if (publicHoliday) {
    return (
      <TooltipContainer>
        <Text whiteSpace="pre">{publicHoliday.name}</Text>
      </TooltipContainer>
    )
  }

  if (request) {
    return (
      <TooltipContainer>
        {chain(
          <Text fontWeight="600" whiteSpace="pre">
            {canViewPolicy ? request.category.name : 'Off'}
          </Text>,
          <Text color={Token.color.greyTone50} whiteSpace="pre">
            {getRequestDayTextPeriod(request)}
          </Text>,
          hasApprovalStatus && (
            <Text
              color={approvalStatusIdToIconColor(request.approval_status.id)}
              whiteSpace="pre"
            >
              {request.approval_status.name}
            </Text>
          ),
        )}
      </TooltipContainer>
    )
  }

  return null
}

const getPolicyCardTitle = ({
  request,
  publicHoliday,
  canViewPolicy,
}: {
  request: EmployeeTimeOffRequestCalendar | undefined
  publicHoliday: PublicHolidayCalendarItem | undefined
  canViewPolicy: boolean
}) => {
  if (publicHoliday) {
    return (
      <Text variant="h6" color={Token.color.greyTone50}>
        Bank holiday
      </Text>
    )
  }
  if (request) {
    return canViewPolicy ? (
      <Ellipsis>
        <Text variant="h6">{request.category.name}</Text>
      </Ellipsis>
    ) : (
      <Text variant="h6">Off</Text>
    )
  }
  return null
}

type WeekCellContentProps = {
  request: EmployeeTimeOffRequestCalendar | undefined
  publicHoliday: PublicHolidayCalendarItem | undefined
  isNonWorking: boolean
  canViewPolicy: boolean
}
const WeekCellContent = ({
  request,
  publicHoliday,
  isNonWorking,
  canViewPolicy,
}: WeekCellContentProps) => {
  if (isNonWorking) {
    return <Text color={Token.color.greyTone50}>Non working</Text>
  }
  return (
    <Tooltip
      placement="bottom"
      body={getPolicyCardTooltipBody({ request, publicHoliday, canViewPolicy })}
    >
      <VStack width={WEEK_CELL_WIDTH - BORDER_WIDTH * 2} px="s-8">
        <HStack space="s-8" align="center">
          {requestToIcon({
            request,
            isNonWorking,
            publicHoliday,
            canViewPolicy,
          })}
          {getPolicyCardTitle({ request, publicHoliday, canViewPolicy })}
        </HStack>
        {request && (
          <Text variant="caption" color={Token.color.greyTone50}>
            {getRequestDayTextPeriod(request)}
          </Text>
        )}
      </VStack>
    </Tooltip>
  )
}

type Props = {
  day: Date
  requestsCalendar: EmployeeTimeOffRequestsCalendarInterface
  canViewPolicy?: boolean
}
export const TimeOffTableWeekCell = ({ day, requestsCalendar, canViewPolicy }: Props) => {
  const { request, isNonWorking, publicHoliday } = getTimeOffCalendarInfoByDay(
    requestsCalendar,
    day,
  )
  const boxProps = getCellBoxProps({
    request,
    isNonWorking,
    publicHoliday,
    canViewPolicy,
  })
  const employeeId = requestsCalendar.employee.id

  return (
    <Flex
      {...boxProps}
      height={WEEK_CELL_HEIGHT}
      width={WEEK_CELL_WIDTH}
      justifyContent={isNonWorking ? 'center' : 'flex-start'}
      borderRadius="16px"
      onClick={() => {
        if (request?.id) {
          newTabTo(
            pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
              id: request.id,
              employeeId,
            }),
          )
        }
      }}
    >
      <WeekCellContent
        request={request}
        isNonWorking={isNonWorking}
        publicHoliday={publicHoliday}
        canViewPolicy={!!canViewPolicy}
      />
    </Flex>
  )
}
