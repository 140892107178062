import { useMemo } from 'react'
import { AvailableCandidateSlots } from '@src/interfaces/interviewTool'

export const useGetChosenSlot = (
  slots?: AvailableCandidateSlots[],
  chosenEventId?: string,
) => {
  return useMemo(() => {
    if (chosenEventId && slots?.length) {
      return slots.find(item => item.id === chosenEventId)
    }
    return null
  }, [slots, chosenEventId])
}
