import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { RootProvider, useMatchMedia } from '@revolut/ui-kit'
import '@revolut/ui-kit/styles.css'

import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { FeatureFlags, LocalStorageKeys } from '@src/store/auth/types'
import { useRouteMatch } from 'react-router-dom'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { BannerProvider } from './BannerProvider'
import { useTopNav } from '@src/features/TopNav/useTopNav'
import { Environments, env } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'

type ThemeMode = 'light' | 'dark'

export type ThemeSetting = ThemeMode | 'auto'

type ThemeContextValue = {
  themeSettingValue: ThemeSetting
  theme: ThemeMode
  setTheme: (value: ThemeSetting) => void
  loginTheme: ThemeMode
  setLoginTheme: (value: ThemeMode) => void
  setTopNavEnabled: (value: boolean) => void
  /** @deprecated Only for development */
  setTransparentModeEnabled: (value: boolean) => void
  /** @deprecated Only for development */
  transparentModeEnabled: boolean
}

const ThemeContext = createContext<ThemeContextValue | null>(null)

export const useAppTheme = () => {
  const context = useContext(ThemeContext)
  if (context === null) {
    throw new Error('useAppTheme must be used within an UIKitWithThemeProvider')
  }
  return context
}

export const UIKitWithThemeProvider: React.FC = ({ children }) => {
  const { topNav, setEnabled } = useTopNav()
  const featureFlags = useSelector(selectFeatureFlags)

  const [transparentModeEnabled, setTransparentModeEnabled] = useState(
    () =>
      !!(
        env &&
        [
          Environments.local,
          Environments.development,
          Environments.developmentCommercial,
          Environments.developmentCommercialRoot,
          Environments.developmentSubdomains,
        ].includes(env)
      ),
  )

  useEffect(() => {
    if (featureFlags?.includes(FeatureFlags.TransparentMode)) {
      setTransparentModeEnabled(true)
    }
  }, [featureFlags])

  const [themeSettingValue, setThemeSettingValue] = useLocalStorage<ThemeSetting>(
    LocalStorageKeys.THEME,
    'light',
    false,
  )
  const [loginTheme, setLoginTheme] = useLocalStorage<'light' | 'dark'>(
    LocalStorageKeys.LOGIN_THEME,
    'dark',
  )

  const prefersDarkTheme = useMatchMedia(
    themeSettingValue === 'auto' ? '(prefers-color-scheme: dark)' : null,
  )

  const isLoginThemeRoute = !!useRouteMatch({
    path: [
      ROUTES.LOGIN.MAIN,
      ROUTES.SIGNUP.MAIN,
      WORKSPACES.ANY,
      ROUTES.TWO_FACTOR_LOGIN,
    ],
  })

  const contextValue = useMemo<ThemeContextValue>(() => {
    return {
      themeSettingValue,
      theme:
        themeSettingValue === 'auto'
          ? prefersDarkTheme
            ? 'dark'
            : 'light'
          : themeSettingValue,
      setTheme: (mode: ThemeSetting) => {
        setThemeSettingValue(mode)
      },
      loginTheme,
      setLoginTheme: (mode: ThemeMode) => {
        setLoginTheme(mode)
      },
      setTopNavEnabled: setEnabled,
      transparentModeEnabled,
      setTransparentModeEnabled,
    }
  }, [
    themeSettingValue,
    setThemeSettingValue,
    setLoginTheme,
    loginTheme,
    prefersDarkTheme,
    setEnabled,
    transparentModeEnabled,
    setTransparentModeEnabled,
  ])

  const background = useMemo(() => {
    return transparentModeEnabled && contextValue.theme === 'dark'
      ? { hue: 231, saturation: 100 }
      : undefined
  }, [transparentModeEnabled, contextValue.theme])

  return (
    <ThemeContext.Provider value={contextValue}>
      <RootProvider
        topNav={topNav}
        mode={isLoginThemeRoute ? loginTheme : contextValue.themeSettingValue}
        background={background}
      >
        <BannerProvider>{children}</BannerProvider>
      </RootProvider>
    </ThemeContext.Provider>
  )
}
