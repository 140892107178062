import React from 'react'
import get from 'lodash/get'

import { IdAndName } from '@src/interfaces'
import {
  PayCycleInterface,
  PayCycleStatus,
  PayrollTimelineChange,
  PayrollTimelineChangeInterface,
  PayrollTimelineEventInterface,
} from '@src/interfaces/payrollV2'
import { formatDate, formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import { useTimelineFilters } from '../TabChanges/common'
import { domainNameToFieldsConfig } from './domainToGenericField'

export type RouteParams = { id: string }

export type CycleSelectorOptionsData = {
  options: CycleOption[]
  isLoading: boolean
}

export type CycleOption = IdAndName & {
  statusId: PayCycleStatus
}

export type CommonTabProps = {
  data: PayCycleInterface
  selectedCycle: CycleOption | undefined
  setSelectedCycle: (newCycle: CycleOption) => void
  cycleSelectorOptions: CycleOption[]
  isCycleSelectorLoading: boolean
  timelineFilters: ReturnType<typeof useTimelineFilters>
}

export type FieldConfigType = 'date' | 'dateTime' | 'snakeCase' | 'idAndName'

export type FieldConfig = {
  path: string
  label?: string
  type?: FieldConfigType
  renderField?: (data: PayrollTimelineChange | null) => React.ReactNode
}

const typeToFormatter: Record<
  FieldConfigType,
  (data: IdAndName | string | number | null) => React.ReactNode
> = {
  date: d => (d ? formatDate(String(d)) : ''),
  dateTime: d => (d ? formatWithoutTimezone(String(d), true) : ''),
  snakeCase: id => (id ? formatSnakeCase(String(id)) : ''),
  idAndName: d => {
    if (!d) {
      return ''
    }
    return typeof d === 'object' && 'name' in d ? d.name : ''
  },
}

const isConfig = (field: FieldConfig | string): field is FieldConfig =>
  typeof field === 'object' && 'path' in field

const parseNum = (value: string | number) => {
  if (typeof value === 'number') {
    return value
  }
  if (!isNaN(Number(value))) {
    return Number(value)
  }
  return undefined
}

export type ChangeType = 'create' | 'increase' | 'decrease' | 'none' | 'unknown'
export type ParsedDomainFieldChanges = {
  from: React.ReactNode
  to: React.ReactNode
  prerenderedValue?: React.ReactNode
  label: string
  changeType: ChangeType
}
export const parseDomainFieldChanges = (
  data: PayrollTimelineChangeInterface,
  fieldConf: FieldConfig | string,
): ParsedDomainFieldChanges => {
  const changesFrom = data.from_value
  const changesTo = data.to_value

  const fieldPath = isConfig(fieldConf) ? fieldConf.path : fieldConf
  const fieldLabel =
    isConfig(fieldConf) && fieldConf.label
      ? fieldConf.label
      : formatSnakeCase(fieldPath.split('.')[0])
  const type = isConfig(fieldConf) ? fieldConf.type : undefined
  const renderField = isConfig(fieldConf) ? fieldConf.renderField : undefined

  let from
  let to
  let changeType: ChangeType = 'none'
  let prerenderedValue: React.ReactNode

  const nothingChanged = { from, to, label: fieldLabel, changeType: 'none' as const }

  if (!get(changesTo, fieldPath)) {
    return nothingChanged
  }
  const fromValue = get(changesFrom, fieldPath)
  const toValue = get(changesTo, fieldPath)

  from = parseNum(fromValue) || fromValue
  to = parseNum(toValue) || toValue

  if (from === to) {
    return nothingChanged
  }
  if (type) {
    const format = typeToFormatter[type]
    from = format?.(from)
    to = format?.(to)
  } else if (renderField) {
    from = renderField(changesFrom)
    to = renderField(changesTo)
  }
  if (typeof from === 'number' && typeof to === 'number') {
    changeType =
      from < to ? ('increase' as const) : from > to ? ('decrease' as const) : 'none'
  } else if (!from && to) {
    changeType = 'create'
  } else if (from || to) {
    changeType = 'unknown' as const
  }

  return {
    from,
    to,
    prerenderedValue,
    label: fieldLabel,
    changeType,
  }
}

export const getNonEmptyFieldsChanges = (data: PayrollTimelineEventInterface) => {
  const fields = domainNameToFieldsConfig[data.content.domain_name]?.fields || []
  let res = []

  for (let i = 0; i < fields.length; i++) {
    const { from, to, prerenderedValue, label, changeType } = parseDomainFieldChanges(
      data.content,
      fields[i],
    )
    const hasValueToShow = Boolean(to || prerenderedValue)

    if (hasValueToShow && changeType !== 'none') {
      res.push({ label, from, to, changeType, prerenderedValue })
    }
  }
  return res
}
