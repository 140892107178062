import React from 'react'
import { AppSwitcher } from '@revolut-internal/backoffice-ui-provider'
import { useSelector } from 'react-redux'

import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const TopNavAppSwitcher = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  return isCommercial ? null : <AppSwitcher />
}
