import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { companyRoadmapsRequests } from '@src/api/roadmaps'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import {
  roadmapCompanyOrgUnitColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
  getDeleteRoadmapColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { getLocalDateTime } from '@src/utils/roadmap'
import { VStack, H5, Text, Token, HStack, chain } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { useShowRoadmapDetails, useRoadmapContext } from '@src/features/Roadmaps'
import Table from '@src/components/TableV2/Table'
import { roadmapsExtraRequests } from '@src/api/roadmaps'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { DeleteActionProps } from '@src/components/ColumnInserts/DeleteAction/DeleteAction'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'

interface FilterOption {
  name: string
  id: number | string
}

const getRow = (
  handleDelete: DeleteActionProps['handleDelete'],
): RowInterface<RoadmapInterface> => {
  return {
    cells: [
      {
        ...roadmapGenericNameColumn,
        width: 300,
      },
      {
        ...roadmapCompanyOrgUnitColumn,
        width: 170,
      },
      {
        ...roadmapProgressColumn,
        width: 170,
      },
      {
        ...roadmapStatusColumn,
        width: 100,
      },
      {
        ...roadmapStartDate,
        width: 130,
      },
      {
        ...roadmapDueDate,
        width: 130,
      },
      {
        ...roadmapResolutionAtColumn,
        width: 150,
      },
      {
        ...ownerNameColumn,
        width: 160,
      },
      {
        ...roadmapEpicUrlColumn,
        width: 100,
      },
      {
        ...getDeleteRoadmapColumn({ handleDelete }),
        width: 70,
      },
    ],
  }
}

export const Roadmap = () => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const permissions = useSelector(selectPermissions)
  const isNewTable = useIsNewTable()

  const ctx = useRoadmapContext()

  const [value, setValue] = useState<FilterOption[]>([])
  const confirmationPopup = useConfirmationDialog()

  const [expandAllChildren, setExpandAllChildren] = useState(false)
  const initialFilter = [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
    {
      filters: [{ name: `Department Roadmap`, id: `department_roadmap` }],
      columnName: 'type',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'department__name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(
    companyRoadmapsRequests,
    initialFilter,
    initialSortBy,
  )

  const canAddRoadmaps = permissions.includes(PermissionTypes.CanAddCompanyRoadmap)

  const getFilters = (filterOptions: FilterOption[]) => {
    const additionalFilters = filterOptions.map(filter => ({
      id: filter.id,
      name: filter.name,
    }))

    return additionalFilters.length
      ? [
          {
            filters: additionalFilters,
            columnName: 'status',
            nonResettable: true,
          },
        ]
      : []
  }

  const refetchFilterChildren = (filterOptions: FilterOption[]) => {
    const filterBy = getFilters(filterOptions)
    table.data.forEach((parent, ind) => {
      table.fetchChildren([ind], parent.id, filterBy)
    })
  }

  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
  })

  const onDeleteRoadMap = async (itemId: string | number) => {
    confirmationPopup.show({
      showHeader: false,
      body: (
        <H5 color={Token.color.foreground}>
          Are you sure you want to delete this roadmap?
        </H5>
      ),
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: async () => {
        await roadmapsExtraRequests.deleteItem(itemId)
        table.refresh()
      },
    })
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          {isNewTable ? (
            <CompanyNavigation roadmapFilters={table.filterBy} />
          ) : (
            <VStack>
              <HStack>
                <Stat
                  label={
                    isNewTable ? (
                      <Text>
                        {chain(
                          'Progress',
                          !!table?.stats?.refresh_date_time && (
                            <>
                              last updated:{' '}
                              <Text color={Token.color.greyTone50}>
                                {getLocalDateTime(table?.stats?.refresh_date_time)}
                              </Text>
                            </>
                          ),
                        )}
                      </Text>
                    ) : (
                      'Progress'
                    )
                  }
                  val={
                    table?.stats?.avg_done !== undefined ? (
                      <Text color={getPercentColor((table?.stats?.avg_done || 0) * 100)}>
                        {formatPercentage(table?.stats?.avg_done)}
                      </Text>
                    ) : (
                      'N/A'
                    )
                  }
                  mr="s-32"
                />
                {!isNewTable && (
                  <CycleFilter
                    onFilterChange={table.onFilterChange}
                    columnName="review_cycle__offset"
                    filter={table.filterBy}
                    type={CycleFilterType.NewUI}
                  />
                )}
              </HStack>
              {!isNewTable && !!table?.stats?.refresh_date_time && (
                <Text mb="s-12" mt="s-8">
                  Progress last updated:{' '}
                  <Text color={Token.color.greyTone50}>
                    {getLocalDateTime(table?.stats?.refresh_date_time)}
                  </Text>
                </Text>
              )}
            </VStack>
          )}
        </Table.Widget.Info>
        <Table.Widget.Actions>
          {roadmapSettings?.jira_epics_enabled && (
            <Table.Widget.MoreBar>
              <MoreInfoButton href={FETCH_JIRA_ROADMAP} />
              <SwitchButton
                checked={expandAllChildren}
                onClick={() => {
                  setExpandAllChildren(!expandAllChildren)
                }}
              >
                Show all sub-tasks
              </SwitchButton>
            </Table.Widget.MoreBar>
          )}
        </Table.Widget.Actions>
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by name"
            onFilter={table.onFilterChange}
            variant="compact"
          />
          {isNewTable && (
            <CycleFilterButton
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__offset"
              filter={table.filterBy}
            />
          )}
          <ButtonFilter
            title="Sub-task status"
            onChange={selection => {
              setValue(selection)
              refetchFilterChildren(selection)
            }}
            selector={selectorKeys.roadmap_child_status}
            value={value.map(el => ({ ...el, value: el }))}
            type="MultiSelect"
            useIcon="Filter"
          />
        </Table.Widget.Filters>

        <Table.Widget.Table>
          <AdjustableTable<RoadmapInterface>
            name={TableNames.CompanyRoadmap}
            useWindowScroll
            row={getRow(onDeleteRoadMap)}
            onRowClick={showRoadmapDetails}
            {...table}
            hideCount
            dataType={ctx.dataType}
            pendingDataType={ctx.isLoading}
            noDataMessage={ctx.noDataMessage}
            expandableType="chevron"
            fetchChildren={(parentIndexes, id) =>
              table.fetchChildren(parentIndexes, id, getFilters(value))
            }
            childrenAlwaysOpen={expandAllChildren}
            hiddenCells={{
              action: !canAddRoadmaps,
            }}
          />
        </Table.Widget.Table>
      </Table.Widget>

      {renderSidebar({ canEdit: false })}
    </>
  )
}

export default Roadmap
