import React, { useState } from 'react'
import {
  Button,
  Caption,
  Flex,
  Header,
  Input,
  InputGroup,
  Layout,
  Token,
} from '@revolut/ui-kit'
import { GetRevolutPeopleCaption, validateWorkspace } from './common'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'

export const Workspace = () => {
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false)
  const [workspace, setWorkspace] = useState('')
  const [workspaceError, setWorkspaceError] = useState<string>()

  const workspaceContext = useWorkspaceContext()

  const onValidateWorkspace = (value: string) => {
    const validation = validateWorkspace(value)

    if (validation.success) {
      setWorkspaceError(undefined)
      return { valid: true }
    }

    setWorkspaceError(validation.error.issues[0].message)
    return { valid: false }
  }

  const onSubmit = () => {
    setHasAttemptedSubmit(true)

    const validation = onValidateWorkspace(workspace)

    if (validation.valid) {
      window.location.href = `${workspace}${ROUTES.LOGIN.MAIN}`
      workspaceContext?.saveWorkspace(workspace)
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      style={{ display: 'contents' }}
    >
      <Flex flexDirection="column" mt={{ all: '80px', lg: 'auto' }} mb="auto" gap="s-16">
        <Header variant="item">
          <Header.BackButton onClick={() => window.history.back()} aria-label="Back" />
          <Header.Title>Log in to your workspace</Header.Title>
          <Header.Description>Provide your company's workspace</Header.Description>
        </Header>

        <InputGroup>
          <Input
            label="Your workspace name"
            value={workspace}
            onChange={e => {
              setWorkspace(e.currentTarget.value)
              if (hasAttemptedSubmit) {
                onValidateWorkspace(e.currentTarget.value)
              }
            }}
            aria-invalid={!!workspaceError}
            message={workspaceError}
          />

          <Caption color={Token.color.greyTone50} textAlign="center">
            Don't know your workspace URL?{' '}
            <InternalUIKitLink
              // @ts-expect-error
              to={WORKSPACES.FIND}
            >
              Find your workspace
            </InternalUIKitLink>
          </Caption>
        </InputGroup>
      </Flex>

      <Layout.ActionsFill>
        <Button onClick={onSubmit} type="submit" elevated>
          Continue
        </Button>
        <GetRevolutPeopleCaption />
      </Layout.ActionsFill>
    </form>
  )
}
