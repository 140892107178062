import { Flex, Text, Avatar, Token, Icon } from '@revolut/ui-kit'
import { FinalGrade, ReviewCategory } from '@src/interfaces/performance'
import React from 'react'
import { css } from 'styled-components'
import { gradeSettings } from '../utils'

const CircleStrokeWidthCss = css`
  svg circle {
    stroke-width: 10px;
  }
`

interface Props {
  grade: FinalGrade
  periodName: string | undefined
  subTitle: string
  category?: ReviewCategory
}

export const GradeSection = ({ grade, periodName, subTitle, category }: Props) => {
  const avatarColor =
    periodName || gradeSettings?.[grade]?.title === 'Pending'
      ? Token.color.foreground
      : gradeSettings?.[grade]?.color

  const calculatedPeriodName = () => {
    if (category === ReviewCategory.Probation) {
      return 'P'
    }

    if (category === ReviewCategory.PIP) {
      return 'PIP'
    }
    return periodName?.split(' ')[0]
  }

  const progressColor =
    gradeSettings?.[grade]?.title === 'Pending'
      ? Token.color.greyTone8
      : gradeSettings?.[grade]?.color
  return (
    <Flex ml="s-4" gap="s-16" alignItems="flex-end">
      <Avatar
        css={CircleStrokeWidthCss}
        size={76}
        progress={1}
        progressColor={progressColor}
        color={avatarColor}
        textStyle="heading2"
        useIcon={periodName ? undefined : 'Trophy'}
      >
        {periodName && calculatedPeriodName()}
        {gradeSettings?.[grade]?.icon && (
          <Avatar.Badge
            bg={gradeSettings?.[grade]?.color}
            position="top-right"
            useIcon={
              gradeSettings?.[grade]?.icon ? (
                <Icon name={gradeSettings?.[grade]?.icon} size={20} />
              ) : null
            }
            size={24}
          />
        )}
      </Avatar>
      <Flex minWidth={110} gap="s-2" mb="s-6" flexDirection="column">
        <Text
          variant="heading3"
          whiteSpace="nowrap"
          color={gradeSettings?.[grade]?.color}
        >
          {gradeSettings?.[grade]?.title}
        </Text>
        <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="caption">
          {subTitle}
        </Text>
      </Flex>
    </Flex>
  )
}
