import { Group, VStack, Cell, Item, Token } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  TimelineError,
  TimelinesSettingsWidget,
} from './components/TimelinesSettingsWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { useTimelineMappings } from './useTimelinesMapping'
import { EligibleEmployeesWidget } from '@src/pages/Forms/ReviewCycle/EligibleEmployeesForm'
import {
  getGlobalEligibilityMutator,
  globalEligiblityGroups,
} from '@src/api/performanceSettings'
import styled from 'styled-components'
import { ScorecardQuestionsWidget } from '@src/pages/Performance/Scorecard/CustomQuestions/ScorecardQuestions'
import { PerformanceGradeLogic } from './components/PerforamnceGradeLogic'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

const FitCell = styled(Cell)`
  padding: 0 0 ${Token.size.s16} 0;
`

export const ReviewCyclesMasterSettings = () => {
  const form = useLapeContext<PerformanceSettingsInterface>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangePerformancePreferences)
  const [timelinesDirty, setTimelinesDirty] = useState<boolean>(false)
  const hasFunctionalManagementEnabled = form.values.enable_functional_management

  const timelineMapping = useTimelineMappings({
    timelines: form.values.timelines,
    onAfterChanged: () => {
      if (!timelinesDirty) {
        setTimelinesDirty(true)
      }
    },
  })

  return (
    <VStack>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply in general for all assessment cycles"
          />
          <VStack space="s-16">
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable functional management',
                description:
                  'A functional manager(FM) is a person with the same skills but a higher seniority compared to the person who is being mentored. When enabled the FM will have to review the mentee during all types of performance cycles.',
              }}
              name="enable_functional_management"
              disabled={disableEdit}
            />
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable upwards reviews',
                  description:
                    'Direct and functional reports are allowed to submit anonymous reviews of their managers',
                }}
                name="enable_upwards_reviews"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable peer reviews',
                  description: 'Allow peers to review each other',
                }}
                name="enable_peer_reviews"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable self reviews',
                  description:
                    'Allows the employee to submit a review of their own performance and behaviors',
                }}
                name="enable_self_reviews"
                disabled={disableEdit}
              />
              {hasFunctionalManagementEnabled && (
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable functional manager reviews',
                    description:
                      'A functional manager(FM) is a person with the same skills but a higher seniority compared to the person who is being mentored. When enabled the FM will have to review the mentee during all types of performance cycles',
                  }}
                  name="enable_functional_manager_reviews"
                  disabled={disableEdit}
                />
              )}
            </Group>
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable skills assessment',
                  description:
                    'Adds a section in the scorecard to ask for the skills to be assessed during any assessment cycle',
                }}
                name="enable_skill_assessment"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable values assessment',
                  description:
                    'Adds a section in the scorecard to ask for the values to be assessed. Values are the building blocks of the company culture, so values assessment helps with culture fit assessment.',
                }}
                name="enable_values"
                disabled={disableEdit}
              />
            </Group>
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable dynamic scorecard deliverables section',
                  description:
                    'Allows the deliverables section in performance review scorecards to include goals or not, based on whether the user has employee goals assigned to them.',
                }}
                name="enable_auto_reviewed_employee_type"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable segmented deliverables assessment',
                  description:
                    'Allows the deliverables section in performance review scorecards to have segmented assessment',
                }}
                name="enable_segmented_deliverables_assessment"
                disabled={disableEdit}
              />
            </Group>
            {/* ToDo: confirm enabled_scorecards_generations field name */}
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable scorecard auto-generation',
                description:
                  'Enabling this setting will allow the system to automatically process the generation of performance review scorecards prior to the start of the feedback period. This includes a sync task for employee data, as well as employee timeline items.',
              }}
              name="enabled_scorecards_generations"
              disabled={disableEdit}
            />
            {/* Should we add also default_reviewed_employee_type field somewhere???? */}
          </VStack>
          <NewStepperTitle
            title="Timelines"
            subtitle="Settings that define the steps and timelines for all future review cycles"
          />
          <TimelinesSettingsWidget
            disabled={disableEdit}
            timeline={timelineMapping}
            errors={form.errors?.timelines as unknown as TimelineError[]}
          />
          <NewStepperTitle
            title="Employee eligibility"
            subtitle="Settings that define the employee eligibility for all future review cycles"
          />
          <VStack space="s-16">
            <FitCell>
              <VStack width="100%">
                <Item style={transparentThemeBackgroundOverrides}>
                  <Item.Content>
                    <Item.Title>
                      Define employee groups included in the performance review
                    </Item.Title>
                    <Item.Description>
                      Select the criteria that define employees as eligible
                    </Item.Description>
                  </Item.Content>
                </Item>
                <EligibleEmployeesWidget
                  variant="item"
                  allowDeleteAll
                  disabled={disableEdit}
                  api={globalEligiblityGroups}
                  configureFormMutator={getGlobalEligibilityMutator}
                />
              </VStack>
            </FitCell>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable employee data sync',
                description:
                  'Keeps the employee data used in the performance review synchronised with their profile data until scorecards are created. Disabling this will freeze the data for the performance review only',
              }}
              name="enabled_eligible_updates"
              disabled={disableEdit}
            />
          </VStack>

          <NewStepperTitle
            title="Custom scorecard questions"
            subtitle="Settings that define the additional custom questions in scorecards for all future review cycles"
          />
          <ScorecardQuestionsWidget />

          <NewStepperTitle
            title="Performance grade logic"
            subtitle="Settings that define the grade calculation logic for all future review cycles"
          />
          <PerformanceGradeLogic searchable={false} />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          onClick={() => {
            form.values.timelines = timelineMapping.getTimelines()
            return form.submit()
          }}
          confirmationDialogue={{
            body: 'New settings will be cascaded to all existing future cycles.',
          }}
          useValidator
          successText="Settings saved"
          hideWhenNoChanges={!timelinesDirty}
        />
      </PageActions>
    </VStack>
  )
}
