import cronstrue from 'cronstrue'
import { useSelector } from 'react-redux'

import {
  NotificationTemplateInterface,
  NotificationAction,
  NotificationType,
} from '@src/interfaces/notificationTemplate'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CDN_BUCKET } from '@src/constants/externalLinks'

export const getScheduleMessage = (values: NotificationTemplateInterface) => {
  if (!values.schedule) {
    return null
  }

  let message = ''

  try {
    message = cronstrue.toString(values.schedule)
  } catch {
    message = 'Invalid cron value'
  }

  return message
}

export const actionOptions: RadioSelectOption<{
  id: NotificationAction
  name: string
}>[] = [
  {
    label: 'Navigate to URL',
    value: { id: NotificationAction.url, name: 'Navigate to URL' },
  },
  {
    label: 'Show full text',
    value: { id: NotificationAction.text, name: 'Show full text' },
  },
]

export const useCanSendToTenants = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()
  const featureFlags = useSelector(selectFeatureFlags)

  const isCoreInstance = featureFlags?.includes(FeatureFlags.TenantAccounts)

  return (
    isCoreInstance &&
    (values.type?.id === NotificationType.system ||
      values.type?.id === NotificationType.announcement ||
      values.type?.id === NotificationType.email) &&
    values.audience_selection_type?.id === 'filtered_table'
  )
}

export const announcementBackgroundIcons = [
  'Bank',
  'Calendar',
  'Chat',
  'Check',
  'Coin',
  'Globe',
  'Heart',
  'Logo',
  'Medal',
  'Present',
  'Revolut-Insider',
  'Tile-pattern',
  'Warn',
] as const

export type AnnouncementBackgroundIcon = typeof announcementBackgroundIcons[number]

export const getAnnouncementBackgroundIconUrl = (icon: AnnouncementBackgroundIcon) => {
  return `${CDN_BUCKET}/announcements/${icon}.png`
}
