import React from 'react'
import { Text, TextProps, Checkbox, CheckboxProps } from '@revolut/ui-kit'

export interface NewCheckboxProps extends Omit<CheckboxProps, 'label'> {
  label?: React.ReactNode
  labelProps?: TextProps
  description?: string
}

const NewCheckbox = (props: NewCheckboxProps) => {
  const { label, labelProps, description, ...checkboxProps } = props

  return (
    <Checkbox {...checkboxProps}>
      <Checkbox.Label>
        <Text {...labelProps}>{label}</Text>
      </Checkbox.Label>
      {description && <Checkbox.Description>{description}</Checkbox.Description>}
    </Checkbox>
  )
}

export default NewCheckbox
