import React from 'react'
import { Widget, Banner, Avatar, ExpandableText, Flex } from '@revolut/ui-kit'

export const ReviewCycleBanner = () => {
  return (
    <Widget display="flex" p="s-16">
      <Flex gap="s-16" width="100%">
        <Avatar
          variant="brand"
          image="https://assets.revolut.com/assets/categories/Pie-chart.png"
          imageSet={{
            '2x': 'https://assets.revolut.com/assets/categories/Pie-chart@2x.png',
            '3x': 'https://assets.revolut.com/assets/categories/Pie-chart@3x.png',
          }}
          size={56}
        />
        <Banner.Content>
          <Banner.Title mb="s-4">The performance process</Banner.Title>
          <Banner.Description>
            <ExpandableText
              prefersShowMore
              labelShowLess="View less"
              labelShowMore="View more"
            >
              Below are the settings for this performance cycle including the timeline for
              each step.{'\n'}
              The output of the cycle is a final grade for all eligible employees included
              in the review, ranging from Unsatisfactory (1) through to Exceptional (5).
              {'\n'}
              This grade can be the product of the review scorecards, or combined with
              manager recommendations as well as calibration inputs from Heads of
              Department and Function.
            </ExpandableText>
          </Banner.Description>
        </Banner.Content>
      </Flex>
    </Widget>
  )
}
