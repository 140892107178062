import { BaseColor } from '@src/pages/Forms/QueryForm/components/Charts/types'

export const CHART_COLORS: BaseColor[] = [
  'blue',
  'green',
  'lime',
  'yellow',
  'orange',
  'pink',
  'purple',
  'red',
]

export const LINE_STROKE_WIDTH = 2.5
export const DOT_RADIUS = 8
export const LINE_ANIMATION_DURATION = 800
