import SideBar from '@components/SideBar/SideBar'
import React from 'react'
import { Group, Token, Flex } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { FormPreview } from '@components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { useGetRoadmapSettings } from '@src/api/settings'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { SectionOptions } from '@src/interfaces/customFields'

interface DetailsSidebarProps {
  open: boolean
  onClose: () => void
  department: DepartmentInterface
  approvalFlow: React.ReactNode | null
}

export const DetailsSidebar = ({
  open,
  onClose,
  department,
  approvalFlow,
}: DetailsSidebarProps) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()

  return (
    <SideBar
      variant="wide"
      isOpen={open}
      onClose={onClose}
      title="Department details"
      useIcon="Bank"
      avatarProps={{ color: Token.color.greyTone20 }}
    >
      {approvalFlow}
      <FormPreview<DepartmentInterface>
        data={department}
        title="General details"
        onEdit={() =>
          navigateTo(pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, { id: department.id }))
        }
      >
        <Group>
          <FormPreview.Item<DepartmentInterface> field="name" title="Department name" />
          <FormPreview.Item<DepartmentInterface>
            field="owner"
            title="Owner"
            type="employee"
          />
          <FormPreview.Item<DepartmentInterface>
            field="kpi_committee"
            title="KPI committee"
            type="employee"
          />
          <FormPreview.Item<DepartmentInterface>
            field="performance_dashboard_link"
            title="Performance dashboard link"
            type="link"
          />
          <FormPreview.Details<DepartmentInterface>
            title="KPI champions"
            description="These people have increased permissions to help when editing and setting KPIs"
            insert={d =>
              d.kpi_champions ? (
                <Flex flexWrap="wrap">
                  {d.kpi_champions.map(kpiChamp => (
                    <UserWithAvatar
                      height="s-32"
                      mr="s-12"
                      key={kpiChamp.id}
                      {...kpiChamp}
                    />
                  ))}
                </Flex>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Details<DepartmentInterface> field="mission" title="Mission" />
          <CommunicationDetails
            jiraProjects={department.jira_projects}
            group={department.communication_group}
            linkedGroups={department.linked_communication_groups}
            jiraEnabled={roadmapSettings?.jira_epics_enabled}
          />
          <FormPreview.CustomFields
            sectionId={SectionOptions.Departments}
            departmentId={department.id}
          />
        </Group>
      </FormPreview>
    </SideBar>
  )
}
