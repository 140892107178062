import React from 'react'
import { Color, Text, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  NotificationLogsInterface,
  NotificationSendingHistoryInterface,
  NotificationTemplateInterface,
} from '@src/interfaces/notificationTemplate'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getScheduleMessage } from '@src/pages/Forms/NotificationTemplate/common'
import { format } from 'date-fns'
import BarChart from '@components/Charts/BarChart/BarChart'
import Table from '@components/TableV2/Table'

export const notificationName: ColumnInterface<NotificationTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.templated_notifications,
  title: 'Notification name',
}

export const notificationType: ColumnInterface<NotificationTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'type.id',
  dataPoint: 'type.name',
  sortKey: 'type',
  filterKey: 'type',
  selectorsKey: selectorKeys.templated_notification_types,
  title: 'Notification Type',
}

export const notificationDeliveryType: ColumnInterface<NotificationTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'delivery_type.id',
  dataPoint: 'delivery_type.name',
  sortKey: 'delivery_type',
  filterKey: 'delivery_type',
  selectorsKey: selectorKeys.templated_notification_delivery_types,
  title: 'Delivery Type',
}

export const notificationDeliverySchedule: ColumnInterface<NotificationTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: '',
    filterKey: '',
    selectorsKey: selectorKeys.none,
    title: 'Delivery schedule',
    insert: ({ data }) => (data.schedule ? getScheduleMessage(data) : '-'),
  }

export const notificationCreationDate: ColumnInterface<NotificationTemplateInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Creation Date',
}

export const notificationLastSent: ColumnInterface<NotificationTemplateInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'last_sent_date_time',
  dataPoint: 'last_sent_date_time',
  sortKey: 'last_sent_date_time',
  filterKey: 'last_sent_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Last Sent',
}

export const notificationStatus: ColumnInterface<NotificationTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.templated_notification_statuses,
  title: 'Status',
}

export const notificationSendingHistorySentOn: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'sent_date',
    dataPoint: 'sent_date',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Sent on',
  }

export const notificationSendingHistorySentBy: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'sent_by',
    dataPoint: 'sent_by',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Sent by',
    insert: ({ data }) => <Table.EmployeeCell employee={data.sent_by} />,
  }

export const notificationSendingHistoryAudience: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.text,
    idPoint: 'audience_count',
    dataPoint: 'audience_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Audience',
  }

export const notificationSendingHistorySuccess: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.text,
    idPoint: 'success_count',
    dataPoint: 'success_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Success',
  }

export const notificationSendingHistoryError: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.text,
    idPoint: 'fail_count',
    dataPoint: 'fail_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Errors',
    colors: data => (data.fail_count ? Token.color.red : Token.color.foreground),
  }

export const notificationEngagementColumn: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'engagement',
    dataPoint: 'engagement',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Engagement',
    insert: ({ data }) => (
      <BarChart
        label="Engagement"
        data={[
          { value: data.read_count, color: Color.GREEN, label: 'Read' },
          {
            value: data.audience_count - data.read_count - data.dismissed_count,
            color: Color.GREY_TONE_20,
            label: 'Unread',
          },
          { value: data.dismissed_count, color: Color.RED, label: 'Dismissed' },
        ]}
      />
    ),
  }

export const notificationSendingHistoryStatus: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.text,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    colors: data => {
      switch (data.status.id) {
        case 'scheduled':
        case 'sending':
        case 'canceling':
          return Token.color.orange
        case 'sent':
          return Token.color.green
        case 'sent_with_errors':
        case 'failed':
        case 'canceled':
        case 'cancellation_failed':
          return Token.color.red
        default:
          return Token.color.foreground
      }
    },
  }

export const notificationSendingHistoryActions: ColumnInterface<NotificationSendingHistoryInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'actions',
    dataPoint: 'actions',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }

export const notificationLogsSentOn: ColumnInterface<NotificationLogsInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'request.sent_date',
  dataPoint: 'request.sent_date',
  sortKey: 'sent_on',
  filterKey: 'sent_on',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Sent on',
}

export const notificationLogsSentBy: ColumnInterface<NotificationLogsInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: 'sent_by__id',
  filterKey: 'sent_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Sent by',
  insert: ({ data }) => <UserWithAvatar {...data.request.sent_by} />,
}

export const notificationLogsSentTo: ColumnInterface<NotificationLogsInterface> = {
  type: CellTypes.insert,
  idPoint: 'recipient_employee',
  dataPoint: 'recipient_employee',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Sent to',
  insert: ({ data }) =>
    data.recipient_employee[0] ? <UserWithAvatar {...data.recipient_employee[0]} /> : '-',
}

export const notificationLogsStatus: ColumnInterface<NotificationLogsInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.templated_notification_request_message_log_statuses,
  title: 'Status',
  colors: data => {
    switch (data.status.id) {
      case 'created':
      case 'scheduled':
        return Token.color.orange
      case 'sent':
      case 'read':
      case 'dismissed':
        return Token.color.green
      case 'canceled':
      case 'failed':
        return Token.color.red
      default:
        return Token.color.foreground
    }
  },
}

export const notificationLogsCompletedOn: ColumnInterface<NotificationLogsInterface> = {
  type: CellTypes.insert,
  idPoint: 'read_date',
  dataPoint: 'read_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Completed on',
  insert: ({ data }) =>
    data.read_date || data.dismissed_date ? (
      <>
        {format(new Date(data.read_date || data.dismissed_date), 'd MMM yyyy')}{' '}
        <Text color={Token.color.greyTone50}>
          {format(new Date(data.read_date || data.dismissed_date), 'HH:mm OOO')}
        </Text>
      </>
    ) : (
      '-'
    ),
}
