import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import Slack from '@src/pages/Hub/Integrations/Slack/Slack'
import SlackBot from '@src/pages/Hub/Integrations/Slack/SlackBot'

const SlackIntegration = () => {
  return (
    <Switch>
      <Route
        exact
        path={[
          ROUTES.FEATURES.INTEGRATION.SLACK.ALL,
          ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.ALL,
        ]}
        component={Slack}
      />
      <Route
        path={[
          ROUTES.FEATURES.INTEGRATION.SLACK.BOT,
          ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.BOT,
        ]}
        component={SlackBot}
      />
    </Switch>
  )
}

export default SlackIntegration
