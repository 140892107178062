import React, { ReactNode } from 'react'

import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { ApprovalSteps } from '../common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { FormattedMessage } from 'react-intl'

type Variant = 'goal' | 'KPI'

interface ApprovalProcessesFieldsProps {
  disableEdit: boolean
  variant: Variant
}
const variantsPlural: Record<Variant, ReactNode> = {
  goal: <FormattedMessage id="goalsSettings.goalsLabel" defaultMessage="goals" />,
  KPI: <FormattedMessage id="goalsSettings.kpisLabel" defaultMessage="KPIs" />,
}

const variantsSingular: Record<Variant, ReactNode> = {
  goal: <FormattedMessage id="goalsSettings.goalLabel" defaultMessage="goal" />,
  KPI: <FormattedMessage id="goalsSettings.kpiLabel" defaultMessage="KPI" />,
}

const ApprovalTitle = ({ entity, variant }: { entity: string; variant: Variant }) => {
  return (
    <FormattedMessage
      values={{ entity, variant: variantsPlural[variant] }}
      id="goalsSettings.approvalProcess.step.title"
      defaultMessage="Enable {entity} {variant} approval"
    />
  )
}
const ApprovalDescription = ({
  entity,
  variant,
}: {
  entity: string
  variant: Variant
}) => {
  return (
    <FormattedMessage
      values={{ entity, variant: variantsSingular[variant] }}
      id="goalsSettings.approvalProcess.step.title"
      defaultMessage="Whenever a new {entity} {variant} is created or an existing one is changed it asks for approval"
    />
  )
}

const StepperTitle = ({ entity, variant }: { entity: string; variant: Variant }) => {
  return (
    <FormattedMessage
      values={{ entity, variant: variantsPlural[variant] }}
      id="goalsSettings.approvalProcess.step.subtitle"
      defaultMessage="{entity} {variant}"
    />
  )
}

const StepperSubtitle = ({ entity, variant }: { entity: string; variant: Variant }) => {
  return (
    <FormattedMessage
      values={{ entity, variant: variantsPlural[variant] }}
      id="goalsSettings.approvalProcess.step.subtitle"
      defaultMessage="Approval settings for {entity} {variant}"
    />
  )
}

export const ApprovalProcessesFields = ({
  disableEdit,
  variant,
}: ApprovalProcessesFieldsProps) => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()

  return (
    <>
      <NewStepperTitle
        title={<StepperTitle variant={variant} entity="Employee" />}
        subtitle={<StepperSubtitle variant={variant} entity="Employee" />}
      />
      <ApprovalSteps
        title={<ApprovalTitle entity="Employee" variant={variant} />}
        description={<ApprovalDescription entity="Employee" variant={variant} />}
        switchField="enable_employee_kpi_approvals"
        approvalProcess="employee_kpis_approvals"
        entity={{ goal: 'employee goal', KPI: 'employee kpi' }[variant]}
        disabled={disableEdit}
        formRef={values}
      />

      <NewStepperTitle
        title={<StepperTitle variant={variant} entity="Team" />}
        subtitle={<StepperSubtitle variant={variant} entity="Team" />}
      />
      <ApprovalSteps
        title={<ApprovalTitle entity="Team" variant={variant} />}
        description={<ApprovalDescription entity="Team" variant={variant} />}
        switchField="enable_team_kpi_approvals"
        approvalProcess="team_kpis_approvals"
        entity={{ goal: 'team goal', KPI: 'team kpi' }[variant]}
        disabled={disableEdit}
        formRef={values}
      />

      <NewStepperTitle
        title={<StepperTitle variant={variant} entity="Department" />}
        subtitle={<StepperSubtitle variant={variant} entity="Department" />}
      />
      <ApprovalSteps
        title={<ApprovalTitle entity="Department" variant={variant} />}
        description={<ApprovalDescription entity="Department" variant={variant} />}
        switchField="enable_department_kpi_approvals"
        approvalProcess="department_kpis_approvals"
        entity={`department ${variant}`}
        disabled={disableEdit}
        formRef={values}
      />

      <NewStepperTitle
        title={<StepperTitle variant={variant} entity="Company" />}
        subtitle={<StepperSubtitle variant={variant} entity="Company" />}
      />
      <ApprovalSteps
        title={<ApprovalTitle entity="Company" variant={variant} />}
        description={<ApprovalDescription entity="Company" variant={variant} />}
        switchField="enable_company_kpi_approvals"
        approvalProcess="company_kpis_approvals"
        entity={{ goal: 'company goal', KPI: 'company kpi' }[variant]}
        disabled={disableEdit}
        formRef={values}
      />

      {values.enable_function_role_specialisation_kpis ? (
        <>
          <NewStepperTitle
            title={<StepperTitle variant={variant} entity="Function" />}
            subtitle={<StepperSubtitle variant={variant} entity="Function" />}
          />
          <ApprovalSteps
            title={<ApprovalTitle entity="Function" variant={variant} />}
            description={<ApprovalDescription entity="Function" variant={variant} />}
            switchField="enable_function_kpi_approvals"
            approvalProcess="function_kpis_approvals"
            entity={{ goal: 'function goal', KPI: 'function kpi' }[variant]}
            disabled={disableEdit}
            formRef={values}
          />

          <NewStepperTitle
            title={<StepperTitle variant={variant} entity="Role" />}
            subtitle={<StepperSubtitle variant={variant} entity="Role" />}
          />
          <ApprovalSteps
            title={<ApprovalTitle entity="Role" variant={variant} />}
            description={<ApprovalDescription entity="Role" variant={variant} />}
            switchField={`enable_role_kpi_approvals`}
            approvalProcess="role_kpis_approvals"
            entity={{ goal: 'role goal', KPI: 'role kpi' }[variant]}
            disabled={disableEdit}
            formRef={values}
          />
        </>
      ) : null}
    </>
  )
}
