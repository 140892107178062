import React from 'react'
import {
  Color,
  createChain,
  Ellipsis,
  HStack,
  Icon,
  IconName,
  Text,
  Token,
} from '@revolut/ui-kit'

import { notReachable } from '@src/utils/notReachable'
import { ChangeType } from '.'

export const changeTypeToIconProps = (
  changeType: ChangeType | undefined,
): { name: IconName | undefined; color: Color | undefined } => {
  if (!changeType) {
    return { name: undefined, color: undefined }
  }
  switch (changeType) {
    case 'create':
      return { name: 'PlusCircle', color: Token.color.blue }
    case 'increase':
      return { name: 'ArrowUp', color: Token.color.success }
    case 'decrease':
      return { name: 'ArrowDown', color: Token.color.warning }
    case 'unknown':
      return { name: 'ArrowExchange', color: Token.color.greyTone50 }
    case 'none':
      return { name: undefined, color: undefined }
    default:
      return notReachable(changeType)
  }
}

type Props = {
  from: React.ReactNode
  to: React.ReactNode
  type?: ChangeType
}
export const ValuesDiff = ({ from, to, type }: Props) => {
  const arrowChain = createChain(<Icon name="ArrowThinRight" size={12} />)
  const maxPreviewWidth = 96

  return (
    <HStack align="center" space="s-4">
      <Icon {...changeTypeToIconProps(type)} size={15} />
      {arrowChain(
        from ? (
          <Ellipsis maxWidth={maxPreviewWidth}>
            <Text color={Token.color.greyTone50} textDecoration="line-through">
              {from}
            </Text>
          </Ellipsis>
        ) : undefined,
        <Ellipsis maxWidth={from ? maxPreviewWidth : maxPreviewWidth * 2}>
          <Text>{to}</Text>
        </Ellipsis>,
      )}
    </HStack>
  )
}
