import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { timeOffPoliciesRequests } from '@src/api/timeOff'

import Preview from './Preview'
import { EditTimeOffPolicyTabs } from './EditTabs'

const TimeOffPolicy = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW}>
          <Form api={timeOffPoliciesRequests} disableLocalStorageCaching>
            <Preview />
          </Form>
        </Route>
        <Route
          exact
          path={ROUTES.FORMS.TIME_OFF_POLICY.EDIT.ANY}
          component={EditTimeOffPolicyTabs}
        />
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => <TimeOffPolicy />)
