import React, { useEffect } from 'react'

import AdjustableTable from '@src/components/Table/AdjustableTable'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/Table/hooks'
import {
  departmentGenericNameColumn,
  departmentHeadcountColumn,
  departmentRequisitionsColumn,
} from '@src/constants/columns/department'
import { TableNames } from '@src/constants/table'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { departmentRequests } from '@src/api/department'
import { TableProps } from '@src/pages/Forms/CustomFieldsForm/FilterTable/types'
import { filterChangeHandler } from '@src/pages/Forms/CustomFieldsForm/FilterTable/common'

const Row: RowInterface<DepartmentInterface> = {
  cells: [
    {
      ...departmentGenericNameColumn,
      width: 200,
    },
    {
      ...ownerNameColumn,
      width: 200,
    },
    {
      ...departmentHeadcountColumn,
      width: 100,
    },
    {
      ...departmentRequisitionsColumn,
      width: 100,
    },
  ],
}

interface DepartmentsFilterTableProps extends TableProps {
  filter?: FilterByInterface[]
}

export const DepartmentsFilterTable = ({
  isPreview,
  filter,
  onFilterChange,
}: DepartmentsFilterTableProps) => {
  const tableOptions = {
    disableQuery: true,
  }

  const table = useTable<DepartmentInterface>(
    departmentRequests,
    filter,
    [],
    tableOptions,
  )

  useEffect(() => {
    onFilterChange?.(filterChangeHandler(table.filterBy))
  }, [table.filterBy])

  return (
    <AdjustableTable
      name={TableNames.DepartmentsFilter}
      row={Row}
      {...table}
      noReset={isPreview}
      disabledFiltering={isPreview}
      useWindowScroll
      onFilterChange={f => table?.onFilterChange(f, false)}
    />
  )
}
