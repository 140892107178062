import React from 'react'
import { Text } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  DatePickerInputCell,
  EditableTableRenderMode,
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  MultiSelectInputCell,
  MultiSelectInputCellProps,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { IdAndName } from '@src/interfaces'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { getStatusColor } from '@components/CommonSC/General'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { CustomFieldTypes } from '@src/constants/customFields'
import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'

type EditableEmployeeColumn<T = EmployeesSimpleInterface> = GenericEditableTableColumn<T>
type EditableEmployeeWithCreateNewColumn<T = EmployeesSimpleInterface> = (
  onChange: GenericEditableTableOnChange,
  onCreate: (onChangeAction: (newEntity: IdAndName) => void) => void,
  mode: EditableTableRenderMode,
) => ColumnInterface<ImportInterface<T>>

export const employeesFirstNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="first_name" />,
})

export const employeesMiddleNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'middle_name',
  dataPoint: 'middle_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Middle name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="middle_name" />,
})

export const employeesLastNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="last_name" />,
})

export const employeesPreferredNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'preferred_name',
  dataPoint: 'preferred_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Preferred name',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="preferred_name" />
  ),
})

export const employeesEmailColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: 'email',
  filterKey: 'id',
  selectorsKey: selectorKeys.employee,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

const AccessGroupMultiSelect = ({
  data,
  onChange,
}: Pick<MultiSelectInputCellProps<EmployeesSimpleInterface>, 'data' | 'onChange'>) => {
  const accessGroups = data.data.access_groups || []
  const label = accessGroups.map(group => group.group_name).join(', ')
  const value = accessGroups.map(group => ({
    id: group.group_id,
    name: group.group_name,
  }))
  /** When value changes in the table, without triggering `onValueChange`, the multi select does not register that, need to rerender */
  const renderKey = value.map(i => i.id).join(',')

  const onValueChange: GenericEditableTableOnChange = (rowId, val, field) => {
    if (Array.isArray(val)) {
      onChange(
        rowId,
        val.map(group => ({ group_id: group.id, group_name: group.name })),
        field,
      )
    }
  }

  return (
    <MultiSelectInputCell
      data={data}
      value={value}
      onChange={onValueChange}
      field="access_groups"
      fieldName="access groups"
      selector={selectorKeys.groups}
      label={label}
      key={renderKey}
    />
  )
}

export const employeesAccessGroupColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_groups',
  dataPoint: 'access_groups',
  sortKey: 'access_groups',
  filterKey: 'access_groups__id',
  selectorsKey: selectorKeys.groups,
  title: 'Access groups',
  insert: ({ data }) => <AccessGroupMultiSelect data={data} onChange={onChange} />,
})

export const employeesAccessLevelColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_level',
  dataPoint: 'access_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Access group',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="access_level"
      fieldName="access group"
      selector={selectorKeys.groups}
      selectorField="name"
    />
  ),
})

export const employeesNameColumn: EditableEmployeeColumn = () => ({
  type: CellTypes.insert,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'full_name',
  selectorsKey: selectorKeys.employee,
  title: 'Name',
  insert: ({ data }) => (
    <UserWithAvatar
      id={data.data.id}
      full_name={`${data.data.first_name} ${data.data.last_name}`}
    />
  ),
})

export const employeesTeamColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{data.data.team?.name}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="team"
        selector={selectorKeys.team}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
        onCreateNewClick={onCreate}
      />
    ),
})

export const employeesDepartmentColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'department',
  dataPoint: 'department',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.department,
  title: 'Department',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="department"
        selector={selectorKeys.department}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
        onCreateNewClick={onCreate}
      />
    ),
})

export const employeesEntityColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'entity',
  dataPoint: 'entity',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.entity,
  title: 'Entity',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="entity"
        selector={selectorKeys.entity}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
        onCreateNewClick={onCreate}
      />
    ),
})

export const employeesRoleColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{data.data.specialisation?.name || '-'}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="specialisation"
        selector={selectorKeys.specialisations}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
        fieldName="role"
        onCreateNewClick={onCreate}
      />
    ),
})

export const employeesSeniorityColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{data.data.seniority?.name}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="seniority"
        selector={selectorKeys.seniority}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
      />
    ),
})

export const employeesLocationColumn: EditableEmployeeWithCreateNewColumn = (
  onChange,
  onCreate,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <Text>{data.data.location?.name || '-'}</Text>
    ) : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="location"
        selector={selectorKeys.location}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
        onCreateNewClick={onCreate}
      />
    ),
})

export const employeesCountryColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'country',
  dataPoint: 'country',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.countries,
  title: 'Country',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="country"
        selector={selectorKeys.countries}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
      />
    ),
})

export const employeesLanguagesColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'languages',
  dataPoint: 'languages',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.language,
  title: 'Languages',
  insert: ({ data }) => {
    if (mode === 'preview') {
      return null
    }
    const languages = data.data.languages

    return (
      <MultiSelectInputCell
        data={data}
        value={languages.split(',').map(lang => ({ id: lang, name: lang }))}
        onChange={(rowId, val, field) => {
          if (Array.isArray(val)) {
            const strValue = val.map(lang => lang.name).join('; ')
            onChange(rowId, strValue, field)
          }
        }}
        field="languages"
        fieldName="languages"
        selector={selectorKeys.language}
        label={languages}
        key={languages}
      />
    )
  },
})

export const employeesNationalitiesColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'nationalities',
  dataPoint: 'nationalities',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.countries,
  title: 'Nationalities',
  insert: ({ data }) => {
    const nationalitiesStr = data.data.nationalities

    if (mode === 'preview') {
      return null
    }
    return (
      <MultiSelectInputCell
        data={data}
        value={nationalitiesStr
          .split(',')
          .map(nationality => ({ id: nationality, name: nationality }))}
        onChange={(rowId, val, field) => {
          if (Array.isArray(val)) {
            const strValue = val.map(nationality => nationality.name).join('; ')
            onChange(rowId, strValue, field)
          }
        }}
        field="nationalities"
        fieldName="nationalities"
        selector={selectorKeys.countries}
        label={nationalitiesStr}
        key={nationalitiesStr}
      />
    )
  },
})

export const employeesLineManagerColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'line_manager',
  dataPoint: 'line_manager',
  sortKey: null,
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Line manager',
  insert: ({ data }) =>
    mode === 'preview' ? (
      <UserWithAvatar {...data.data.line_manager} />
    ) : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="line_manager"
        fieldName="manager"
        selector={selectorKeys.all_employees_avatar_email}
        selectorField={mode === 'bulkSession' ? 'email' : undefined}
      />
    ),
})

export const employeesFunctionalManagerColumn: EditableEmployeeColumn = (
  onChange,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'functional_manager',
  dataPoint: 'functional_manager',
  sortKey: null,
  filterKey: 'functional_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Functional manager',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="functional_manager"
        fieldName="functional manager"
        selector={selectorKeys.all_employees_avatar_email}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
      />
    ),
})

export const employeesJobTitleColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'job_title',
  dataPoint: 'job_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job title',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="job_title" />,
})

export const employeesStartDateColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
  insert: ({ data }) => (
    <DatePickerInputCell field="start_date" data={data} onChange={onChange} />
  ),
})

export const employeesStatusColumn: EditableEmployeeColumn = () => ({
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => (
    <Text color={getStatusColor(data.data.status?.id)}>
      {data.data.status?.name || '-'}
    </Text>
  ),
})

export const employeesContractTypeColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'contract_type',
  dataPoint: 'contract_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.contract_types,
  title: 'Contract type',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="contract_type"
        selector={selectorKeys.contract_types}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
      />
    ),
})

export const employeesLegalSexColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'legal_sex',
  dataPoint: 'legal_sex',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.legal_sexes,
  title: 'Legal sex',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="legal_sex"
        selector={selectorKeys.legal_sexes}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
      />
    ),
})

export const employeesMaritalStatusColumn: EditableEmployeeColumn = (onChange, mode) => ({
  type: CellTypes.insert,
  idPoint: 'marital_status',
  dataPoint: 'marital_status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.marital_statuses,
  title: 'Marital status',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="marital_status"
        selector={selectorKeys.marital_statuses}
        selectorField={mode === 'bulkSession' ? 'name' : undefined}
      />
    ),
})

export const employeesBirthDateColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'birth_date',
  dataPoint: 'birth_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Birth date',
  insert: ({ data }) => (
    <DatePickerInputCell field="birth_date" data={data} onChange={onChange} />
  ),
})

export const employeesPersonalEmailColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'personal_email',
  dataPoint: 'personal_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Personal email',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="personal_email" />
  ),
})

export const employeesPhoneCountryCodeColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'phone_country_code',
  dataPoint: 'phone_country_code',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Phone country code',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="phone_country_code" />
  ),
})

export const employeesPhoneNumberShortColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'phone_number_short',
  dataPoint: 'phone_number_short',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Phone number short',
  insert: ({ data }) => (
    <TextCell
      data={data}
      onChange={onChange}
      field="phone_number_short"
      type={TableCellInputType.int}
    />
  ),
})

export const employeesAddressLine1Column: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'address_line_1',
  dataPoint: 'address_line_1',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Address line 1',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="address_line_1" />
  ),
})

export const employeesAddressLine2Column: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'address_line_2',
  dataPoint: 'address_line_2',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Address line 2',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="address_line_2" />
  ),
})

export const employeesAddressLine3Column: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'address_line_3',
  dataPoint: 'address_line_3',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Address line 3',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="address_line_3" />
  ),
})

export const employeesBankNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bank_name',
  dataPoint: 'bank_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bank name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="bank_name" />,
})

export const employeesBankAccountNameColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'account_name',
  dataPoint: 'account_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bank account name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="account_name" />,
})

export const employeesBankAccountCurrencyColumn: EditableEmployeeColumn = (
  onChange,
  mode,
) => ({
  type: CellTypes.insert,
  idPoint: 'bank_currency',
  dataPoint: 'bank_currency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.currencies,
  title: 'Bank account currency',
  insert: ({ data }) =>
    mode === 'preview' ? null : (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="bank_currency"
        selector={selectorKeys.currencies}
        selectorField={mode === 'bulkSession' ? 'iso_code' : undefined}
      />
    ),
})

export const employeesEmergencyContactEmailColumn: EditableEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'emergency_contact_email',
  dataPoint: 'emergency_contact_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Emergency contact email',
  insert: ({ data }) => (
    <TextCell data={data} onChange={onChange} field="emergency_contact_email" />
  ),
})

export const employeesEmergencyContactFullNameColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_full_name',
    dataPoint: 'emergency_contact_full_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact full name',
    insert: ({ data }) => (
      <TextCell data={data} onChange={onChange} field="emergency_contact_full_name" />
    ),
  })

export const employeesEmergencyContactRelationshipColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_relationship',
    dataPoint: 'emergency_contact_relationship',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact relationship',
    insert: ({ data }) => (
      <TextCell data={data} onChange={onChange} field="emergency_contact_relationship" />
    ),
  })

export const employeesEmergencyContactMobilePhoneColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_mobile_phone',
    dataPoint: 'emergency_contact_mobile_phone',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact mobile phone',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        field="emergency_contact_mobile_phone"
        type={TableCellInputType.int}
      />
    ),
  })

export const employeesEmergencyContactPhoneCountryCodeColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_phone_country_code',
    dataPoint: 'emergency_contact_phone_country_code',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact phone country code',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        field="emergency_contact_phone_country_code"
      />
    ),
  })

export const employeesEmergencyContactPhoneNumberColumn: EditableEmployeeColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'emergency_contact_phone_number',
    dataPoint: 'emergency_contact_phone_number',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Emergency contact phone number',
    insert: ({ data }) => (
      <TextCell
        data={data}
        onChange={onChange}
        field="emergency_contact_phone_number"
        type={TableCellInputType.int}
      />
    ),
  })

export const createCustomFieldColumn = (
  customField: CustomFieldsForSectionInterface,
): EditableEmployeeColumn => {
  return onChange => ({
    type: CellTypes.insert,
    idPoint: customField.field_name,
    dataPoint: customField.field_name,
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: customField.name,
    insert: ({ data }) => {
      const inputTypeId = customField.input_type.id

      if ([CustomFieldTypes.Text, CustomFieldTypes.Number].includes(inputTypeId)) {
        return (
          <TextCell
            data={data}
            onChange={onChange}
            field={customField.field_name}
            type={
              inputTypeId === CustomFieldTypes.Number ? TableCellInputType.int : undefined
            }
          />
        )
      }
      if (inputTypeId === CustomFieldTypes.Date) {
        return (
          <DatePickerInputCell
            data={data}
            onChange={onChange}
            field={customField.field_name}
          />
        )
      }
      if (
        [CustomFieldTypes.Dropdown, CustomFieldTypes.MultipleSelector].includes(
          inputTypeId,
        )
      ) {
        const fieldOptions = customField.validation_checks?.validation_choices || []
        const formattedOptions = fieldOptions.map(option => ({
          id: option.id,
          name: option.name,
          value: option,
        }))
        const commonProps = {
          data,
          onChange,
          selector: selectorKeys.none,
          options: formattedOptions,
          field: customField.field_name,
          fieldName: customField.name,
        }
        return inputTypeId === CustomFieldTypes.Dropdown ? (
          <RadioSelectInputCell selectorField="name" {...commonProps} />
        ) : inputTypeId === CustomFieldTypes.MultipleSelector ? (
          <MultiSelectInputCell {...commonProps} />
        ) : null
      }
      return null
    },
  })
}
