import { VStack } from '@revolut/ui-kit'
import React from 'react'
import SectionTitle from '../components/SectionTitle'
import { LapeBooleanRadioSwitch } from '../components/RadioSwitch'
import { EligibleEmployeesWidget } from '@src/pages/Forms/ReviewCycle/EligibleEmployeesForm'
import {
  getGlobalEligibilityMutator,
  globalEligiblityGroups,
} from '@src/api/performanceSettings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

export const PerformanceEligibility = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()

  return (
    <VStack maxWidth="720px">
      <SectionTitle
        title="Are all of your employees included in the performance review?"
        subtitle="This will define who is eligible to be reviewed during the feedback window and receive a grade"
      />
      <LapeBooleanRadioSwitch
        highlightSelected
        variant="horizontal"
        name="are_all_employees_eligible_for_review"
        yesDescription="I want to include all employees in the performance review process"
        noDescription="I would like to specify which employees are included in the performance reviews"
      />
      <SectionTitle
        title="Create your custom eligibility groups here"
        subtitle="You can skip this step now and add them later"
      />
      <EligibleEmployeesWidget
        disabled={values.are_all_employees_eligible_for_review}
        api={globalEligiblityGroups}
        configureFormMutator={getGlobalEligibilityMutator}
      />
    </VStack>
  )
}
