import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { VStack, HStack, Icon, Text } from '@revolut/ui-kit'
import DropdownAction from '@components/Inputs/DropdownAction/DropdownAction'
import React from 'react'
import { IdAndName } from '@src/interfaces'
import { QueryViewType } from '@src/interfaces/dataAnalytics'

interface QueryViewSelectProps {
  value: QueryViewType
  onChange: (viewType: QueryViewType) => void
}

const options: RadioSelectOption<IdAndName<QueryViewType>>[] = [
  { label: 'Table view', value: { id: 'table', name: 'Table view' } },
  { label: 'Chart view', value: { id: 'chart', name: 'Chart view' } },
]

export const QueryViewSelect = ({ onChange, value }: QueryViewSelectProps) => {
  const selectedOption = options.find(option => option.value.id === value)?.value

  return (
    <RadioSelectInput
      value={selectedOption}
      onChange={option => {
        if (option?.id) {
          onChange(option.id)
        }
      }}
      options={options}
      renderInput={(open, setOpen, ref) => (
        <VStack space="s-4">
          <DropdownAction open={open} onClick={() => setOpen(!open)} ref={ref}>
            <HStack gap="s-4" align="center">
              <Icon
                name={selectedOption?.id === 'chart' ? 'BarChart' : '16/ListBullet'}
                size={16}
              />
              <Text>{selectedOption?.name}</Text>
            </HStack>
          </DropdownAction>
        </VStack>
      )}
      searchable={false}
    />
  )
}
