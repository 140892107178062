import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ActionButton, Box, Item, Subheader, Switch, Text, Widget } from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import {
  getSlackIntegrationBots,
  useGetSlackPreferences,
  useUpdatePreferences,
} from '@src/api/integrations'
import { InternalLink } from '@components/InternalLink/InternalLink'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import {
  slackBotActionsColumn,
  slackBotDescriptionColumn,
  slackBotNameColumn,
} from '@src/constants/columns/slackBots'
import { SlackBotInterface } from '@src/interfaces/integrations'
import SlackBotActions from '@components/ColumnInserts/SlackBotActions/SlackBotActions'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'
import Tooltip from '@components/Tooltip/Tooltip'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { FormattedMessage } from 'react-intl'

const Title = () => (
  <FormattedMessage
    id="integrations.slack.title"
    defaultMessage="Slack integration settings"
  />
)

const Description = () => (
  <FormattedMessage
    id="integrations.slack.description"
    defaultMessage="Revolut People’s Slack Integration enables IT Admins to configure the
    platform to manage & configure HR/platform communications via Slack to
    employees, driving improved engagement through day to day comms."
  />
)

const getRows = (
  refresh: () => void,
  canEdit: boolean,
  botUrl: string,
): RowInterface<SlackBotInterface> => ({
  linkToForm: ({ id }) => navigateTo(pathToUrl(botUrl, { id })),
  cells: [
    {
      ...slackBotNameColumn,
      width: 100,
    },
    {
      ...slackBotDescriptionColumn,
      width: 200,
    },
    {
      ...slackBotActionsColumn,
      insert: ({ data }) =>
        canEdit ? <SlackBotActions id={data.id} onDelete={refresh} /> : '-',
      width: 80,
    },
  ],
})

const Slack = () => {
  const { data: preferences, isLoading } = useGetSlackPreferences()
  const isOnboarding = isOnboardingPath()
  const updatePreferences = useUpdatePreferences()
  const table = useTable({ getItems: getSlackIntegrationBots })
  const permissions = useSelector(selectPermissions)
  const queryClient = useQueryClient()

  const canEdit = permissions.includes(PermissionTypes.ChangeSlackIntegration)

  const botUrl = isOnboarding
    ? ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.BOT
    : ROUTES.FEATURES.INTEGRATION.SLACK.BOT

  const handleSwitch = async () => {
    if (!preferences?.id) {
      return
    }
    await updatePreferences.mutateAsync([
      preferences.id,
      { enabled: !preferences.enabled },
    ])

    // communication settings depend on slack integration
    queryClient.invalidateQueries(API.COMMUNICATION_SETTINGS)

    pushNotification({
      value: `Slack integration turned ${preferences.enabled ? 'off' : 'on'}`,
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
  }

  return (
    <PageWrapper>
      {isOnboarding ? (
        <OnboardingChecklistHeader
          title={<Title />}
          description={<Description />}
          backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
        />
      ) : (
        <PageHeader
          title={<Title />}
          subtitle={
            <Text whiteSpace="pre-wrap" maxWidth={720} use="div">
              <Description />
            </Text>
          }
          backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
        />
      )}
      <PageBody>
        <Item use="label">
          <Item.Content>
            <Item.Title>Enable Slack integration</Item.Title>
            <Item.Description>Turn on/off the integration with Slack</Item.Description>
          </Item.Content>
          <Item.Side>
            <Tooltip
              placement="right"
              text="You need at least one bot to turn on Slack integration"
              hide={table.count > 0}
              delay={0}
            >
              <Switch
                onChange={handleSwitch}
                checked={preferences?.enabled}
                disabled={
                  updatePreferences.isLoading ||
                  isLoading ||
                  !canEdit ||
                  table.count === 0
                }
              />
            </Tooltip>
          </Item.Side>
        </Item>
        <Widget mt="s-24" px="s-16" pb="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Slack bots</Subheader.Title>
          </Subheader>
          <ActionButton
            useIcon="Plus"
            use={InternalLink}
            to={pathToUrl(botUrl)}
            disabled={!canEdit}
          >
            Register Slack bot
          </ActionButton>

          <Box pt="s-16">
            <AdjustableTable
              name={TableNames.SlackBots}
              useWindowScroll
              row={getRows(table.refresh, canEdit, botUrl)}
              {...table}
              noDataMessage="Slack bots will appear here"
              hideCountAndButtonSection
            />
          </Box>
        </Widget>
      </PageBody>
    </PageWrapper>
  )
}

export default Slack
