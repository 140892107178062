import React from 'react'
import { DetailsCell, DetailsCellSkeleton } from '@revolut/ui-kit'
import { getCurrentTimezone, getTimeZoneLabel } from '@src/utils/timezones'
import { SchedulingInterviewInterface } from '@src/interfaces/interviewTool'

type Props = {
  interview?: SchedulingInterviewInterface
  isLoading?: boolean
}

const ScheduledDetails = ({ interview, isLoading }: Props) => {
  const currentTimezoneId = getCurrentTimezone()
  const timeZoneId = interview?.scheduling_timezone
    ? String(interview.scheduling_timezone.id)
    : currentTimezoneId
  const timeZoneName = interview?.scheduling_timezone
    ? String(interview.scheduling_timezone.name)
    : currentTimezoneId

  if (isLoading) {
    return <DetailsCellSkeleton />
  }

  if (!interview) {
    return null
  }

  return (
    <>
      {interview.event_date_time && (
        <DetailsCell>
          <DetailsCell.Title>Time</DetailsCell.Title>
          <DetailsCell.Content>
            {getTimeZoneLabel(
              interview.event_date_time!,
              timeZoneId,
              'd MMM yyyy hh:mm aa',
            )}
          </DetailsCell.Content>
        </DetailsCell>
      )}
      {interview.scheduling_timezone && (
        <DetailsCell>
          <DetailsCell.Title>Time zone</DetailsCell.Title>
          <DetailsCell.Content>{timeZoneName}</DetailsCell.Content>
        </DetailsCell>
      )}
    </>
  )
}

export default ScheduledDetails
