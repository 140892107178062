import React, { useState } from 'react'
import { ActionButton, Bar, Box, VStack } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import {
  CareersApplicationInterface,
  CareersApplicationWorkInterface,
} from '@src/interfaces/careers'
import { useLapeContext } from '@src/features/Form/LapeForm'
import WorkExperiencePopup from '@src/pages/Careers/CareersApplication/components/WorkExperiencePopup'
import InformationSection from '@src/pages/Careers/CareersApplication/components/InformationSection'
import { connect } from 'lape'
import { useCareersApplicationSettings } from '@src/api/recruitment/careers'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import SectionLayout from './components/Section'

type Props = {
  locked?: boolean
}

const CareersApplicationWorkExperience = ({ locked }: Props) => {
  const { values, errors } = useLapeContext<CareersApplicationInterface>()
  const { data: applicationSettings } = useCareersApplicationSettings()
  const [popupOpen, setPopupOpen] =
    useState<{ idx?: number; data: Partial<CareersApplicationWorkInterface> }>()

  const closePopup = () => {
    setPopupOpen(undefined)
  }

  const onSave = (workExp: CareersApplicationWorkInterface) => {
    if (!values.work_experiences) {
      values.work_experiences = [workExp]
    } else if (popupOpen?.idx !== undefined) {
      values.work_experiences[popupOpen.idx] = workExp
    } else {
      values.work_experiences.push(workExp)
    }

    delete errors.work_experiences
    closePopup()
  }

  return (
    <>
      {popupOpen && (
        <WorkExperiencePopup
          data={popupOpen.data}
          onClose={closePopup}
          onSubmit={onSave}
        />
      )}
      <Box data-testid="work_experience" data-name="work_experiences">
        <SectionLayout
          title="Experience"
          subtitle={
            applicationSettings?.is_work_experience_optional ? 'Optional' : undefined
          }
          text="Please highlight your work experience to help us to process your application faster"
          body={
            <>
              {!!values.work_experiences?.length && (
                <VStack gap="s-16">
                  {values.work_experiences.map((item, idx) => (
                    <InformationSection
                      key={idx}
                      title={item.company?.name}
                      subtitle={item.position?.name}
                      startDate={item.start_date}
                      endDate={item.end_date}
                      onRemove={() => {
                        if (values.work_experiences?.length === 1) {
                          delete values.work_experiences
                        } else {
                          values.work_experiences = values.work_experiences!.filter(
                            (_, itemIdx) => itemIdx !== idx,
                          )
                        }
                      }}
                      onEdit={() => {
                        setPopupOpen({ idx, data: item })
                      }}
                    />
                  ))}
                </VStack>
              )}
              {!locked && (
                <>
                  <Bar>
                    <ActionButton
                      useIcon={Plus}
                      onClick={() => setPopupOpen({ data: {} })}
                    >
                      Add
                    </ActionButton>
                  </Bar>
                  {errors.work_experiences && (
                    <InputErrorMessage message={errors.work_experiences} />
                  )}
                </>
              )}
            </>
          }
          locked={locked}
        />
      </Box>
    </>
  )
}

export default connect(CareersApplicationWorkExperience)
