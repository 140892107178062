import React from 'react'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { CompensationOverTime } from './CompensationOverTime'
import { CompensationTotal } from './CompensationTotal'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const CompensationWidget = ({ compensationParams, id }: Props) => {
  return (
    <TwoColumnsLayout
      left={<CompensationTotal compensationParams={compensationParams} id={id} />}
      right={<CompensationOverTime compensationParams={compensationParams} id={id} />}
      rightFlex={3}
    />
  )
}
