import React, { useMemo } from 'react'
import { SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useIsNewTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FilterButton, MoreBar } from '@revolut/ui-kit'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import {
  departmentAuditColumn,
  departmentBudgetColumn,
  departmentCommunicationColumn,
  departmentCultureColumn,
  departmentHeadcountColumn,
  departmentNameColumn,
  departmentRequisitionsColumn,
  departmentRoadmapColumn,
  nipsColumn,
  getDepartmentKPIColumn,
} from '@src/constants/columns/department'
import {
  circlesKriColumn,
  teamStatusColumn,
  teamNameIconColumn,
} from '@src/constants/columns/team'
import { teamOwnerColumn } from '@src/constants/columns/employee'
import { teamsRequests, useGetTeamsStats } from '@src/api/teams'
import { TeamInterface } from '@src/interfaces/teams'
import { Statuses } from '@src/interfaces'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import {
  useGetOrganisationSettings,
  useGetRoadmapSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { useGetSlackPreferences } from '@src/api/integrations'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import Table from '@src/components/TableV2/Table'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'
import { teamsConfig } from '@src/pages/Organisation/OrganisationSubTabs/common'
import Stat from '@components/Stat/Stat'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const getRow = (goalsEnabled?: boolean, isNewTable?: boolean) => ({
  linkToForm: ({ id }: { id: number }) => {
    if (id) {
      navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id }))
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SETTINGS))
    }
  },
  cells: [
    {
      ...teamNameIconColumn,
      width: 220,
    },
    {
      ...departmentNameColumn,
      width: 160,
    },
    {
      ...teamOwnerColumn,
      width: 160,
    },
    {
      ...departmentHeadcountColumn,
      width: 110,
    },
    {
      ...departmentRequisitionsColumn,
      width: 80,
    },
    {
      ...getDepartmentKPIColumn(goalsEnabled, isNewTable),
      width: isNewTable ? 120 : 80,
    },
    {
      ...departmentRoadmapColumn,
      width: 80,
    },
    {
      ...nipsColumn,
      width: 80,
    },
    {
      ...departmentAuditColumn,
      width: 80,
    },
    {
      ...circlesKriColumn,
      width: 80,
    },
    {
      ...departmentBudgetColumn,
      width: 100,
    },
    {
      ...departmentCommunicationColumn,
      width: 100,
    },
    {
      ...teamStatusColumn,
      width: 100,
    },
  ],
})

const TeamsTable = () => {
  const isNewTable = useIsNewTable()
  const [showMyTeams, setShowMyTeams] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_TEAMS,
    false,
  )
  const [showArchivedTeams, setShowArchivedTeams] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_TEAMS,
    false,
  )
  const user = useSelector(selectUser)
  const { data: settings } = useGetOrganisationSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: slackSettings } = useGetSlackPreferences()
  const { settings: globalSettings } = useGlobalSettings()

  const roadmapEnabled = !!roadmapSettings?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const riskEnabled = !!settings?.risk?.enabled
  const approvalsEnabled = !!settings?.enable_teams_approvals
  const budgetEnabled = !!settings?.enable_budget_management
  const slackEnabled = !!slackSettings?.enabled
  const engagementEnabled = !!globalSettings?.engagement_enabled

  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const { FavouritesFilter, initialFilter } = useFavouritesFilter('team')

  const getFilterByOwner = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'team_owner__id',
    nonResettable: true,
  })

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : [{ name: Statuses.active, id: Statuses.active }],
    columnName: 'status',
    nonResettable: true,
  })

  const getInitialFilter = () => {
    const filter = []

    if (initialFilter) {
      filter.push(initialFilter)
    }

    filter.push(getFilterByStatus(showArchivedTeams))

    filter.push(getFilterByOwner(showMyTeams))

    return filter
  }

  const sortBy = [
    {
      sortBy: 'department__name',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'headcount',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const filterBy = getInitialFilter()
  const table = useTable<TeamInterface>(teamsRequests, filterBy, sortBy)
  const permissions = useSelector(selectPermissions)

  const onToggleMyTeams = () => {
    setShowMyTeams(!showMyTeams)
    table.onFilterChange(getFilterByOwner(!showMyTeams))
  }

  const onToggleArchivedTeams = () => {
    setShowArchivedTeams(!showArchivedTeams)
    table.onFilterChange(getFilterByStatus(!showArchivedTeams))
  }

  const canAdd = permissions.includes(PermissionTypes.AddTeams)
  const canManage = permissions.includes(PermissionTypes.ManageTeams)

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const tableSettings = useMemo(
    () =>
      isNewTable
        ? {
            visible: [],
            hidden: [
              departmentAuditColumn.title,
              circlesKriColumn.title,
              departmentCommunicationColumn.title,
              teamStatusColumn.title,
            ],
          }
        : undefined,
    [isNewTable],
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {isNewTable ? (
          <StatNavigation api={useGetTeamsStats} config={teamsConfig} />
        ) : (
          <Stat label="Total" val={table?.loading ? undefined : table?.count} />
        )}
      </Table.Widget.Info>
      <Table.Widget.Search>
        <Table.Search placeholder="Search by team name" onFilter={table.onFilterChange} />
      </Table.Widget.Search>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {canAdd && (
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.TEAM.SETTINGS)}
              useIcon="Plus"
            >
              Add Team
            </PrimaryAction>
          )}
          {canManage && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.UPDATE_ORGANISATION_STRUCTURE.TEAM)}
              useIcon="Pencil"
            >
              Edit
            </MoreBar.Action>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.ORGANISATION.LIST}
            canView={settingsConfig.teams.canView}
          />
          <ExportMenu
            fileName="Teams"
            request={teamsRequests.getExport}
            filterQuery={filterQuery}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by team name"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <FilterButton onClick={onToggleMyTeams} active={showMyTeams}>
          My teams
        </FilterButton>
        <FilterButton onClick={onToggleArchivedTeams} active={showArchivedTeams}>
          Archived teams
        </FilterButton>
        <FavouritesFilter table={table} />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<TeamInterface>
          name={TableNames.Teams}
          useWindowScroll
          dataType="Team"
          row={getRow(goalsEnabled, isNewTable)}
          {...table}
          noDataMessage="Teams will appear here."
          hideCount={!!isNewTable}
          hiddenCells={{
            [departmentRoadmapColumn.idPoint]: !roadmapEnabled,
            [circlesKriColumn.idPoint]: !riskEnabled,
            [departmentCultureColumn.idPoint]: !engagementEnabled,
            [departmentAuditColumn.idPoint]: !findingsEnabled,
            [departmentBudgetColumn.idPoint]: !budgetEnabled,
            [departmentCommunicationColumn.idPoint]: !slackEnabled,
            [teamStatusColumn.idPoint]: !approvalsEnabled,
          }}
          tableSettings={tableSettings}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default withFavourites(TeamsTable)
