import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { Upload } from '@src/pages/Forms/ImportData/CompensationBands/Upload'
import { ReviewData } from '@src/pages/Forms/ImportData/CompensationBands/ReviewData'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

export const ImportCompensationBands = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.UPLOAD_FILE}>
        <Upload />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.REVIEW_DATA}>
        <ReviewData />
      </Route>
      <InternalRedirect to={ROUTES.FORMS.IMPORT_DATA.BONUSES.UPLOAD_FILE} />
    </Switch>
  )
}
