import { css } from 'styled-components'
import { Token } from '@revolut/ui-kit'

export const formattedHTMLContentCSS = css`
  word-break: break-word;
  line-height: 1.5;

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${Token.color.foreground};
  }

  a {
    color: ${Token.color.blue};
  }

  ul {
    padding-left: 24px;
  }

  p {
    padding: 0;
    margin: 0 0 16px;
  }

  ol,
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }

  ol > li,
  ul > li {
    list-style-type: none;
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }

  ol li:before {
    content: counter(list-0, decimal) '. ';
  }

  ol li.ql-indent-1 {
    counter-increment: list-1;
  }

  ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }

  ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-2 {
    counter-increment: list-2;
  }

  ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }

  ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-3 {
    counter-increment: list-3;
  }

  ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }

  ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-4 {
    counter-increment: list-4;
  }

  ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }

  ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-5 {
    counter-increment: list-5;
  }

  ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }

  ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }

  ol li.ql-indent-6 {
    counter-increment: list-6;
  }

  ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }

  ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }

  ol li.ql-indent-7 {
    counter-increment: list-7;
  }

  ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }

  ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }

  ol li.ql-indent-8 {
    counter-increment: list-8;
  }

  ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }

  ol li.ql-indent-8 {
    counter-reset: list-9;
  }

  ol li.ql-indent-9 {
    counter-increment: list-9;
  }

  ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }

  ${new Array(9).fill(0).map(
    (_, i) => `
    .ql-indent-${i + 1}:not(.ql-direction-rtl), li.ql-indent-${
      i + 1
    }:not(.ql-direction-rtl) {
      padding-left: ${24 * (i + 1)}px;
    }
    .ql-indent-${i + 1}.ql-direction-rtl.ql-align-right, li.ql-indent-${
      i + 1
    }.ql-direction-rtl.ql-align-right {
      padding-right: ${24 * (i + 1)}px;
    }
    `,
  )}
  ul > li::before {
    content: '\\2022';
  }

  li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }
`
