import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Token } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { PayGroupTable } from './PayGroupTable'

export const PayGroup = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.PAYROLL_V2.PAY_GROUP.LIST}>
        <PageWrapper>
          <PageHeader title="Payroll" backUrl={ROUTES.PEOPLE.PAYROLL} />
          <PageBody maxWidthMd={Token.breakpoint.xxl}>
            <PayGroupTable />
          </PageBody>
        </PageWrapper>
      </Route>
    </Switch>
  )
}
