import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import format from 'date-fns/format'
import { FilterOption, FilterType } from '@src/interfaces/data'
import { ActionButton, Calendar } from '@revolut/ui-kit'
import { Dropdown, DropdownProps } from '@revolut/ui-kit'
import NewGrid from '@components/CommonSC/NewGrid'
import { RangeValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'
import { endOfDay, startOfDay } from 'date-fns'

export interface FilterDateRangeProps
  extends Pick<DropdownProps, 'anchorRef' | 'fitInAnchor'> {
  open: boolean
  onClose: () => void
  onChange: (options: FilterOption[]) => void
  value?: FilterOption[]
  type?: FilterType
}

const convertFilterToRange = (filters: FilterOption[]): RangeValue => {
  const from = get(filters, '[0].id')
  const to = get(filters, '[1].id')

  if (!from && !to) {
    return null
  }

  return {
    from: from && new Date(from),
    to: to && new Date(to),
  }
}

export const FilterDateRange = ({
  open,
  onChange,
  onClose,
  anchorRef,
  value = [],
  type = FilterType.date,
  fitInAnchor,
}: FilterDateRangeProps) => {
  const [dateRange, setDateRange] = useState<RangeValue>()

  useEffect(() => {
    setDateRange(convertFilterToRange(value))
  }, [value[0], value[1]])

  const onCalendarChange = (selectedRange: RangeValue) => {
    setDateRange(selectedRange)
  }

  const onClear = () => {
    onChange([])
    onClose()
  }

  const onApply = () => {
    const filters: FilterOption[] = []
    const { from, to } = dateRange || { to: null, from: null }

    if (!from && !to) {
      onChange([])
      return
    }

    if (from) {
      const formattedFrom = startOfDay(from)
      const filterFrom =
        type === FilterType.date
          ? formattedFrom.toISOString()
          : format(formattedFrom, 'yyyy-MM-dd')

      filters.push({
        id: filterFrom,
        name: `${filterFrom}`,
      })
    } else {
      filters.push({ id: '', name: '' })
    }

    if (to) {
      const formattedTo = endOfDay(to)
      const filterTo =
        type === FilterType.date
          ? formattedTo.toISOString()
          : format(formattedTo, 'yyyy-MM-dd')

      filters.push({
        id: filterTo,
        name: `${filterTo}`,
      })
    } else {
      filters.push({ id: '', name: '' })
    }

    onChange(filters)
  }

  return (
    <Dropdown
      open={open}
      anchorRef={anchorRef}
      onClose={onClose}
      width={fitInAnchor ? undefined : 360}
      maxHeight={null}
      fitInAnchor={fitInAnchor}
    >
      <Dropdown.Group sticky="top">
        <Calendar
          variant="range"
          labelButtonClear="Clear"
          labelEmptyValue="Select range"
          labelPrev="Previous"
          labelNext="Next"
          value={dateRange}
          onChange={onCalendarChange}
        />
      </Dropdown.Group>
      <Dropdown.Group sticky="bottom" p="s-16">
        <NewGrid gridAutoFlow="column" gridColumnGap="1rem">
          <ActionButton width="100%" onClick={onClear}>
            Clear
          </ActionButton>
          <ActionButton variant="accent" width="100%" onClick={onApply}>
            Apply
          </ActionButton>
        </NewGrid>
      </Dropdown.Group>
    </Dropdown>
  )
}
