import { ActionButton, Item } from '@revolut/ui-kit'
import { useGlobalSettings } from '@src/api/settings'
import { useGetPersonalInfo } from '@src/api/employees'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ProcessStages } from '@src/interfaces/onboarding'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { OnboardingWarningV2 } from './OnboardingWarningV2'
import { canViewPersonal } from '../common'

interface Props {
  data: EmployeeInterface
}

const ONBOARDING_TABS: Record<ProcessStages, string> = {
  [ProcessStages.Start]: ROUTES.FORMS.ONBOARDING_TIMELINE.START,
  [ProcessStages.Work]: ROUTES.FORMS.ONBOARDING_TIMELINE.WORK,
  [ProcessStages.Documents]: ROUTES.FORMS.ONBOARDING_TIMELINE.DOCUMENTS,
  [ProcessStages.Contract]: ROUTES.FORMS.ONBOARDING_TIMELINE.CONTRACT,
  [ProcessStages.Screening]: ROUTES.FORMS.ONBOARDING_TIMELINE.SCREENING,
  [ProcessStages.RightToWork]: ROUTES.FORMS.ONBOARDING_TIMELINE.SCREENING,
  [ProcessStages.Finish]: ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH,
}

export const OnboardingWarning = ({ data }: Props) => {
  const {
    settings: { employee_onboarding_v2_enabled },
  } = useGlobalSettings()

  const { data: personalInfo, isLoading } = useGetPersonalInfo(
    data.id,
    canViewPersonal(data),
  )

  if (isLoading) {
    return null
  }

  if (!personalInfo?.onboarding_process?.id) {
    return employee_onboarding_v2_enabled ? <OnboardingWarningV2 data={data} /> : null
  }

  return (
    <Item>
      <Item.Content>
        <Item.Title>{data.first_name} is currently going through onboarding</Item.Title>
      </Item.Content>
      <Item.Side>
        <ActionButton
          use={InternalLink}
          to={pathToUrl(ONBOARDING_TABS[personalInfo.current_onboarding_stage.id || 1], {
            employeeId: String(data.id),
            id: personalInfo.onboarding_process.id,
          })}
        >
          Open timeline
        </ActionButton>
      </Item.Side>
    </Item>
  )
}
