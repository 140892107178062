import React, { useState } from 'react'
import { CellInsertParams, RowInterface, Stats } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useTableReturnType, useIsNewTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { HStack } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import {
  interviewScorecardTemplateObjectiveColumn,
  interviewScorecardTemplateNameColumn,
  interviewScorecardTemplateStatusColumn,
  interviewScorecardTemplateActionColumn,
} from '@src/constants/columns/interviewScorecardTemplate'
import {
  deleteInterviewScorecardTemplate,
  interviewScorecardTemplateRequests,
} from '@src/api/interviewScorecardTemplate'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

interface DeleteRowActionProps {
  data: InterviewScorecardTemplateInterface
  onDelete: () => void
}

const DeleteRowAction = ({ data, onDelete }: DeleteRowActionProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const onDeleteConfirm = async () => {
    setDeletePending(true)

    try {
      await deleteInterviewScorecardTemplate(data)
      onDelete()
      setConfirmationOpen(false)
    } finally {
      setDeletePending(false)
    }
  }

  return (
    <>
      <TableActionButton
        onClick={e => {
          e.stopPropagation()
          setConfirmationOpen(true)
        }}
        // cannot use Token here
        color="red"
      >
        Delete
      </TableActionButton>
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={deletePending}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Confirm"
        noMessage="Cancel"
        label="Are you sure you want to delete this scorecard template?"
        body=""
      />
    </>
  )
}

type InterviewScorecardTemplatesActions = {
  onAdd: () => void
  onEdit: (data: InterviewScorecardTemplateInterface) => void
  onDelete: () => void
}

const RowNotifications = (
  actions?: InterviewScorecardTemplatesActions,
): RowInterface<InterviewScorecardTemplateInterface> => {
  const row = {
    linkToForm: actions
      ? undefined
      : ({ id }: InterviewScorecardTemplateInterface) =>
          navigateTo(
            pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
              id,
            }),
          ),
    cells: [
      {
        ...interviewScorecardTemplateNameColumn,
        width: 314,
      },
      {
        ...ownerNameWithAvatarColumn,
        width: 290,
      },
      {
        ...interviewScorecardTemplateStatusColumn,
        width: 228,
      },
      {
        ...interviewScorecardTemplateObjectiveColumn,
        width: 600,
      },
    ],
  }
  if (actions) {
    row.cells.push({
      ...interviewScorecardTemplateActionColumn,
      width: 150,
      insert: ({ data }: CellInsertParams<InterviewScorecardTemplateInterface>) => {
        return (
          <HStack space="s-16">
            <TableActionButton
              onClick={() => {
                actions.onEdit(data)
              }}
            >
              Edit
            </TableActionButton>
            <DeleteRowAction data={data} onDelete={actions.onDelete} />
          </HStack>
        )
      },
    })
  }
  return row
}

type InterviewScorecardTemplatesTableProps = {
  actions?: InterviewScorecardTemplatesActions
  table: useTableReturnType<InterviewScorecardTemplateInterface, Stats, {}>
}

export const ScorecardTemplatesTable = ({
  actions,
  table,
}: InterviewScorecardTemplatesTableProps) => {
  const isNewTable = useIsNewTable()
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddInterviewScorecardTemplate)

  return (
    <Table.Widget>
      {isNewTable ? null : (
        <Table.Widget.Info>
          <Stat label="Total" val={table?.loading ? undefined : table?.count} />
        </Table.Widget.Info>
      )}
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd && (
            <PrimaryAction
              onClick={actions?.onAdd}
              use={!actions?.onAdd ? InternalLink : undefined}
              to={
                !actions?.onAdd
                  ? pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS)
                  : undefined
              }
              data-testid="add_scorecard_button"
              useIcon="Plus"
            >
              Add Scorecard
            </PrimaryAction>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<InterviewScorecardTemplateInterface>
          name={TableNames.InterviewTemplateScorecards}
          useWindowScroll
          dataType="Interview scorecard templates"
          row={RowNotifications(actions)}
          lockFirstColumn={!!actions}
          lockLastColumn={!!actions}
          {...table}
          emptyState={
            <EmptyTableRaw title="Interview scorecard templates will appear here" />
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export const InterviewScorecardTemplatesTable = () => {
  const table = useTable<InterviewScorecardTemplateInterface>(
    interviewScorecardTemplateRequests,
  )
  return <ScorecardTemplatesTable table={table} />
}
