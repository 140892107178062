import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { InputGroup, MoreBar, VStack } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { bulkUpdateDocuments } from '@src/api/importData'
import { updateDocumentBulkSessionUploadInfo } from '@src/api/documents'
import { ImportDocumentsItemInterface } from '@src/interfaces/importDocuments'
import { useTable } from '@components/Table/hooks'
import {
  ImportInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { PreviewDocSelectorBar } from '@src/pages/OnboardingChecklistV2/Documents/common/PreviewDocSelectorBar'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { DocumentPreview } from '@src/features/DocumentSidebar/DocumentPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces'
import SideBar from '@src/components/SideBar/SideBar'
import { getBulkSessionRow } from './row'
import {
  getUploadSessionTable,
  getUploadSessionTableStats,
} from '@src/api/bulkDataImport'

export const DocumentsSession = () => {
  const params = useParams<{ id: string }>()
  const [previewTableItem, setPreviewTableItem] =
    useState<ImportDocumentsItemInterface | null>(null)
  const [isUpdatingAssignee, setIsUpdatingAssignee] = useState(false)
  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false)

  const formatBulkData = (
    rowsData: { id: number }[] | undefined,
    field: 'category' | 'employee',
    optionId: string | number | undefined,
  ) =>
    rowsData
      ?.map(rowData =>
        typeof optionId === 'number'
          ? {
              id: rowData.id,
              [field]: { id: optionId },
            }
          : null,
      )
      .filter(Boolean) || []

  const table = useTable<
    ImportInterface<ImportDocumentsItemInterface>,
    ImportSessionStatsInterface
  >({
    getItems: getUploadSessionTable<ImportDocumentsItemInterface>(
      API.DOCUMENTS_UPLOADS_BULK,
      params.id,
    ),
    getStats: getUploadSessionTableStats(API.DOCUMENTS_UPLOADS_BULK, params.id),
  })
  // BE doesn't comply to standard import session data interface here, so we have to force by manual type casting
  const tableData = (table.data || []) as unknown as ImportDocumentsItemInterface[]

  return (
    <>
      <BulkDataUploadV2
        table={table}
        importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES}
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
        anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.ANY}
        apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
        category="document_upload"
        name="document"
        tableName={TableNames.ImportDocumentsV2}
        row={getBulkSessionRow(previewTableItem?.id)}
        entity="document"
        templateParams={{ version: '2' }}
        onActionColumnPreview={rowData => {
          // BE doesn't comply to standard import session data interface here, so we have to force by manual type casting
          setPreviewTableItem(rowData as unknown as ImportDocumentsItemInterface)
        }}
        hiddenColumns={{ 'state.id': true }}
        getCustomEditCellAction={(data, rowId, sessionId) => {
          return updateDocumentBulkSessionUploadInfo(sessionId!, rowId, data)
        }}
        header={
          <PageHeader
            title="Upload documents"
            backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.LIST)}
          />
        }
        tableActions={tableActionsProps => (
          <MoreBar>
            <BulkEditAction
              buttonIcon="Suitcase"
              field="category"
              selector={selectorKeys.document_categories}
              {...tableActionsProps}
              getCustomApiCall={(sessionId, selectionContext, option) => {
                const selectedRowsData = selectionContext?.selectedRowsData
                const excludedIds = selectionContext?.excludeListIds
                const rowsData =
                  Array.isArray(selectedRowsData) && selectedRowsData.length === 0
                    ? table.data?.filter(row => !excludedIds?.has(String(row.id)))
                    : selectedRowsData
                return () =>
                  bulkUpdateDocuments(
                    String(sessionId),
                    formatBulkData(rowsData, 'category', option?.id),
                  )
              }}
            />
            <BulkEditAction
              buttonIcon="Profile"
              field="employee"
              label="assignee"
              selector={selectorKeys.all_employees_avatar_email}
              {...tableActionsProps}
              getCustomApiCall={(sessionId, selectionContext, option) => {
                const selectedRowsData = selectionContext?.selectedRowsData
                const excludedIds = selectionContext?.excludeListIds
                const rowsData =
                  Array.isArray(selectedRowsData) && selectedRowsData.length === 0
                    ? table.data?.filter(row => !excludedIds?.has(String(row.id)))
                    : selectedRowsData
                return () =>
                  bulkUpdateDocuments(
                    String(sessionId),
                    formatBulkData(rowsData, 'employee', option?.id),
                  )
              }}
            />
            <BulkDeleteButton {...tableActionsProps} />
          </MoreBar>
        )}
      />
      <SideBar
        title="Document preview"
        subtitle={
          previewTableItem?.employee ? (
            <UserWithAvatar {...previewTableItem.employee} />
          ) : (
            'Unassigned'
          )
        }
        isOpen={!!previewTableItem}
        onClose={() => {
          setPreviewTableItem(null)
        }}
        sideProps={{ resizable: true }}
      >
        {previewTableItem && (
          <DocumentPreview
            id={previewTableItem.id}
            genericFileInfo={{
              id: previewTableItem.file.id,
              name: previewTableItem.file.name,
            }}
            renderActions={downloadButton => (
              <VStack space="s-16">
                <MoreBar>{downloadButton}</MoreBar>
                <PreviewDocSelectorBar
                  items={tableData}
                  selectedItem={previewTableItem}
                  setSelectedItem={setPreviewTableItem}
                />
              </VStack>
            )}
            renderAbovePreview={() => (
              <InputGroup>
                <RadioSelectInput<EmployeeOptionInterface>
                  label="Assignee"
                  loading={isUpdatingAssignee}
                  value={previewTableItem?.employee}
                  selector={selectorKeys.employee}
                  onChange={async newEmployee => {
                    if (newEmployee) {
                      try {
                        setIsUpdatingAssignee(true)
                        await bulkUpdateDocuments(params.id, [
                          {
                            id: previewTableItem.id,
                            employee: { id: newEmployee.id },
                          },
                        ])
                        setPreviewTableItem({
                          ...previewTableItem,
                          employee: newEmployee || null,
                        })
                      } finally {
                        setIsUpdatingAssignee(false)
                        table.refresh()
                      }
                    }
                  }}
                />
                <RadioSelectInput<IdAndName>
                  label="Category"
                  loading={isUpdatingCategory}
                  value={previewTableItem?.category}
                  selector={selectorKeys.document_categories}
                  onChange={async newCategory => {
                    if (newCategory) {
                      try {
                        setIsUpdatingCategory(true)
                        await bulkUpdateDocuments(params.id, [
                          {
                            id: previewTableItem.id,
                            category: { id: newCategory.id },
                          },
                        ])
                        setPreviewTableItem({
                          ...previewTableItem,
                          category: newCategory || null,
                        })
                      } finally {
                        setIsUpdatingCategory(false)
                        table.refresh()
                      }
                    }
                  }}
                />
              </InputGroup>
            )}
          />
        )}
      </SideBar>
    </>
  )
}
