import { Text, Item, IconButton, Color, Dropdown } from '@revolut/ui-kit'
import React, { useRef, useState } from 'react'
import { SystemNotificationInterface } from '@src/interfaces/systemNotifications'
import { NotificationsIcon } from '@src/features/Notifications/NotificationsIcon'
import { MoreAndroid, Cross, Check } from '@revolut/icons'

interface NotificationsListItemProps {
  notification: SystemNotificationInterface
  onNotificationClick: () => void
  onDismiss: (notificationId: number) => void
  markAsRead: (notification: SystemNotificationInterface) => void
}

export const NotificationsListItem = ({
  notification,
  onNotificationClick,
  onDismiss,
  markAsRead,
}: NotificationsListItemProps) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onDismiss(notification.id)
  }

  const handleMarkAsRead = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    markAsRead(notification)
    setOpen(false)
  }

  return (
    <Item use="button" onClick={onNotificationClick}>
      <Item.Avatar>
        <NotificationsIcon
          name={notification.category}
          unread={notification.status !== 'read'}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{notification.title}</Item.Title>
        {notification.short_description ? (
          <Item.Description>
            <Text>{notification.short_description}</Text>
          </Item.Description>
        ) : null}
      </Item.Content>
      <Item.Side>
        <IconButton
          useIcon={MoreAndroid}
          aria-label="Actions"
          color={Color.GREY_TONE_50}
          ref={anchorRef}
          onClick={e => {
            e.stopPropagation()
            setOpen(!open)
          }}
        />
        <Dropdown open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
          {notification.status === 'unread' ? (
            <Dropdown.Item
              use="button"
              useIcon={Check}
              iconColor="green"
              onClick={handleMarkAsRead}
            >
              Mark as read
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item use="button" useIcon={Cross} color="red" onClick={handleDismiss}>
            Remove
          </Dropdown.Item>
        </Dropdown>
      </Item.Side>
    </Item>
  )
}
