import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  NotificationsInterface,
  NotificationCategoryType,
} from '@src/interfaces/notifications'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isSameDay from 'date-fns/isSameDay'
import isAfter from 'date-fns/isAfter'
import React from 'react'
import styled from 'styled-components'
import { Capitalize } from '@components/CommonSC/General'
import Url from '@components/ColumnInserts/Url/Url'
import { HStack, Text, Token, IconName } from '@revolut/ui-kit'
import DateDistance from '@components/ColumnInserts/DateDistance/DateDistance'
import { InfoOutline } from '@revolut/icons'
import Employee from '@components/ColumnInserts/Employee/Employee'
import { KPICategoryExtraKeyWord } from '@src/interfaces/kpiNotifications'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { employeeInboxTitleSelector } from '@src/api/notifications'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import ChatMessagePreview from '@components/ColumnInserts/ChatMessagePreview/ChatMessagePreview'
import startCase from 'lodash/startCase'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import TodoDeadline from '@components/ColumnInserts/TodoDeadline/TodoDeadline'
import Tooltip from '@src/components/Tooltip/Tooltip'
import Table from '@src/components/TableV2/Table'
import { ColorTag } from '@components/ColorTag/ColorTag'

const InsertContainer = styled.div<{ red?: boolean }>`
  color: ${props => (props.red ? Token.color.red : Token.color.foreground)};
  padding-top: 5px;
  height: 37px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
`

const CustomTooltip = styled(Tooltip)`
  display: block;
  width: fit-content;
`

export const notificationsPriorityColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'priority',
  dataPoint: 'priority',
  sortKey: 'priority_level',
  filterKey: 'priority',
  selectorsKey: selectorKeys.notification_priorities,
  title: 'Priority',
  colors: data => {
    switch (data.priority) {
      case 'low':
        return Token.color.greyTone20
      case 'medium':
        return Token.color.warning
      case 'high':
        return Token.color.red
      default:
        return Token.color.foreground
    }
  },
}

export const notificationsActionColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
  title: 'Actions',
}

export const notificationsReceivedColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'action_date_time',
  dataPoint: 'action_date_time',
  sortKey: 'action_date_time',
  filterKey: 'action_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Received',
  insert: ({ data }) => {
    if (!data?.action_date_time) {
      return ''
    }
    return (
      <InsertContainer>
        {formatDistanceToNow(new Date(data?.action_date_time))}
        {` ago`}
      </InsertContainer>
    )
  },
}

export const notificationsLastActivity: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'action_date_time',
  dataPoint: 'action_date_time',
  sortKey: 'action_date_time',
  filterKey: 'deadline',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Last activity date',
  insert: ({ data }) => <DateDistance date={data?.action_date_time} />,
}

export const notificationsDeadlineDate: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'deadline',
  dataPoint: 'deadline',
  sortKey: 'deadline',
  filterKey: 'deadline',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Deadline date',
}

export const notificationsDeadline: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'deadline',
  dataPoint: 'deadline',
  sortKey: 'deadline',
  filterKey: 'deadline',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Deadline',
  insert: ({ data }) => <TodoDeadline data={data} />,
}

export const notificationCategory: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: 'category',
  filterKey: 'category',
  selectorsKey: selectorKeys.notification_categories,
  insert: ({ data }) => {
    const isServiceDeskCategory = data.category === 'service_desk'
    const isTrainingCategory = data.category === 'training'
    const isCalibrationCategory = data.category === 'review_calibration'
    const isPerformanceCalibration =
      data.category === 'comment' && data.category_extra === 'performance_calibration'
    const isDemoCategory =
      data.category === 'demo_performance' ||
      data.category === 'demo_hr' ||
      data.category === 'demo_platform' ||
      data.category === 'demo_recruitment'

    const tooltipText = (() => {
      if (isServiceDeskCategory) {
        return 'This category of notifications is not updated immediately, it will take up to 1 hour to update in the system.'
      }
      if (isTrainingCategory) {
        return 'This category of notifications is not updated immediately, it will take up to 1 day to update in the system.'
      }
      return null
    })()

    return (
      <CustomTooltip placement="top" text={tooltipText}>
        <HStack space="s-6" align="center">
          <ColorTag variant="neutral">
            {isCalibrationCategory || isPerformanceCalibration ? (
              <Text>Calibration</Text>
            ) : (
              <Capitalize>
                {isDemoCategory
                  ? data.category?.replace(/demo_/g, '')
                  : data.category?.replace(/_/g, ' ')}
              </Capitalize>
            )}
          </ColorTag>
          {tooltipText ? <InfoOutline size={15} color={Token.color.greyTone50} /> : null}
        </HStack>
      </CustomTooltip>
    )
  },
  title: 'Category',
}

const getChatMessageText = (data: NotificationsInterface) => {
  return data.category_extra === 'performance_calibration'
    ? `Calibration comment from ${data.generated_by?.full_name}`
    : data.text
}

const notificationTypeIconMap: {
  [key in NotificationCategoryType]: IconName | undefined
} = {
  document: 'Document',
  training: 'Megaphone',
  demo_hr: 'Megaphone',
  demo_performance: 'Megaphone',
  demo_platform: 'Megaphone',
  demo_recruitment: 'Megaphone',
  course_enrollment: 'Megaphone',
  tpsl_process: 'Megaphone',
  kpi: 'Wealth',
  kpi_to_assign: 'Wealth',
  kpi_to_approve: 'Wealth',
  kpi_bulk_upload: 'Upload',
  bulk_upload: 'Upload',
  role: 'RepairTool',
  requisition: 'AddContact',
  skill: 'RepairTool',
  data_access: 'Megaphone',
  job_posting: 'Megaphone',
  comment: 'Message',
  one_to_one_meeting: 'CalendarDate',
  upwards_review: 'StarSemi',
  review: 'StarSemi',
  probation: 'Megaphone',
  pip: 'Megaphone',
  employee_onboarding_tasks: 'AccountActions',
  pending_employee_review: 'Megaphone',
  deliverable_review: 'Megaphone',
  service_desk: 'Megaphone',
  interview: 'AddContact',
  candidate: 'AddContact',
  adhoc_interview: 'AddContact',
  succession: 'Megaphone',
  change_request: 'Megaphone',
  ownership_transfer: 'ArrowRightLeft',
  movers: 'Megaphone',
  promotion: 'Megaphone',
  finding: 'Megaphone',
  group: 'Megaphone',
  engagement: 'Questionnaire',
  review_calibration: 'StarSemi',
  time_off: 'Resort',
  group_access_request: 'LockClosed',
  succession_plans: 'ArrowRightLeft',
  goal_to_set: 'Target',
  goal_to_approve: 'Target',
  goal_to_calibrate: 'Target',
}

export const notificationDescription = (
  employeeId?: number,
  isNewTable?: boolean,
): ColumnInterface<NotificationsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: 'id',
  selectorsKey: employeeId
    ? () => employeeInboxTitleSelector(employeeId)
    : selectorKeys.notification_titles,
  title: 'Description',
  insert: ({ children, data }) => {
    if (data.category === 'comment') {
      return (
        <Table.ItemCell useIcon={notificationTypeIconMap[data.category] || 'Megaphone'}>
          <Table.ItemCell.Title>
            <ChatMessagePreview text={getChatMessageText(data)} />
          </Table.ItemCell.Title>
        </Table.ItemCell>
      )
    }
    if (!isNewTable) {
      return children
    }
    return (
      <Table.ItemCell useIcon={notificationTypeIconMap[data.category] || 'Megaphone'}>
        <Table.ItemCell.Title>{children}</Table.ItemCell.Title>
      </Table.ItemCell>
    )
  },
})

export const notificationDescriptionLegacy = (
  employeeId?: number,
): ColumnInterface<NotificationsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: 'id',
  selectorsKey: employeeId
    ? () => employeeInboxTitleSelector(employeeId)
    : selectorKeys.notification_titles,
  title: 'Description',
  insert: ({ children, data }) => {
    if (data.category === 'comment') {
      return <ChatMessagePreview text={getChatMessageText(data)} />
    }
    return children
  },
})

export const notificationsCommentText: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'text',
  dataPoint: 'text',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Text',
  insert: ({ data }) => {
    return <ChatMessagePreview text={getChatMessageText(data)} />
  },
}

export const notificationFindingsIssue: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  insert: ({ data }) => <Url href={data.text}>{data.item_uuid}</Url>,
  idPoint: 'item_uuid',
  dataPoint: 'item_uuid',
  sortKey: 'item_uuid',
  filterKey: 'item_uuid',
  selectorsKey: selectorKeys.audit_issue_id,
  title: 'Issue',
}

export const notificationsDocumentTitle: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: 'title',
  selectorsKey: selectorKeys.notification_titles,
  title: 'Document name',
}

export const notificationsCategoryExtra: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'category_extra',
  dataPoint: 'category_extra',
  sortKey: 'category_extra',
  filterKey: 'category_extra',
  selectorsKey: selectorKeys.employee_documents_sources,
  title: 'Category',
}

export const notificationsDocumentType: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'document_category.id',
  dataPoint: 'document_category.name',
  sortKey: 'document_category__name',
  filterKey: 'document_category__id',
  selectorsKey: selectorKeys.document_categories,
  title: 'Document type',
}

export const notificationsPermissionType: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'permission',
  dataPoint: 'permission',
  sortKey: 'permission',
  filterKey: 'permission',
  selectorsKey: selectorKeys.access_request_permissions,
  title: 'Access Level',
}

export const notificationsDatabaseType: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'database.id',
  dataPoint: 'database.name',
  sortKey: 'database__name',
  filterKey: 'database__id',
  selectorsKey: selectorKeys.helios_metabase_databases,
  title: 'Access to Database',
}

export const notificationsSeniorityColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority_id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const notificationsCandidateColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'candidate.id',
  dataPoint: 'candidate.full_name',
  sortKey: 'candidate__full_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate',
}

export const notificationsRoleColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const notificationsInterviewStageColumn: ColumnInterface<NotificationsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'interview_stage.id',
    dataPoint: 'interview_stage.title',
    sortKey: 'interview_stage_id',
    filterKey: 'interview_stage__interview_type',
    selectorsKey: selectorKeys.hiring_scorecard_template_type,
    title: 'Interview Stage',
  }

export const notificationsTextColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.text,
  idPoint: 'text',
  dataPoint: 'text',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const notificationsInterviewerColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'interviewer.id',
  dataPoint: 'interviewer.full_name',
  sortKey: 'interviewer__full_name',
  filterKey: 'interviewer_id',
  selectorsKey: selectorKeys.employee,
  title: 'Interviewer',
  insert: ({ data }) =>
    data.interviewer ? (
      <Employee
        id={data.interviewer.id}
        name={data.interviewer.full_name}
        avatar={data.interviewer.avatar}
      />
    ) : (
      '-'
    ),
}

export const notificationsEmployeeColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'generated_by.id',
  dataPoint: 'generated_by.full_name',
  sortKey: 'generated_by__full_name',
  filterKey: 'generated_by_id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <Table.EmployeeCell employee={data.generated_by} />,
}

export const notificationsReceiverColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'receiver.id',
  dataPoint: 'receiver.full_name',
  sortKey: 'receiver__full_name',
  filterKey: 'receiver__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => (
    <UserWithAvatar
      id={data.generated_by?.id}
      name={data?.generated_by?.full_name}
      avatar={data.generated_by?.avatar}
    />
  ),
}

export const notificationsPromotionStageColumn: ColumnInterface<NotificationsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'category_extra',
    dataPoint: 'category_extra',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Stage',
    insert: ({ data }) => startCase(data.category_extra),
  }

export const notificationsOrgUnitColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Organisation Unit',
  insert: ({ data }) => {
    if (data.category_extra.includes(KPICategoryExtraKeyWord.Employee)) {
      return (
        <Employee
          id={data.generated_by?.id}
          name={data?.generated_by?.full_name}
          avatar={data.generated_by?.avatar}
        />
      )
    }

    let url: string

    if (data.category_extra.includes(KPICategoryExtraKeyWord.Department)) {
      url = pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, { id: data.item_uuid })
    } else if (data.category_extra.includes(KPICategoryExtraKeyWord.Team)) {
      url = pathToUrl(ROUTES.FORMS.TEAM.KPI, { id: data.item_uuid })
    } else {
      url = '/'
    }

    return <TableCellLink to={getLocationDescriptor(url)}>{data.title}</TableCellLink>
  },
}

export const notificationsAssignKPILevelColumn: ColumnInterface<NotificationsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'category_extra',
    dataPoint: 'category_extra',
    sortKey: 'category_extra',
    filterKey: 'category_extra',
    selectorsKey: selectorKeys.kpi_to_assign_notification_categories,
    title: 'Level',
    insert: ({ data }) => {
      if (data.category_extra.includes(KPICategoryExtraKeyWord.Department)) {
        return 'Department'
      }
      if (data.category_extra.includes(KPICategoryExtraKeyWord.Team)) {
        return 'Team'
      }
      if (data.category_extra.includes(KPICategoryExtraKeyWord.Employee)) {
        return 'Employee'
      }
      return '-'
    },
  }

export const notificationsApproveKPILevelColumn: ColumnInterface<NotificationsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'category_extra',
    dataPoint: 'category_extra',
    sortKey: 'category_extra',
    filterKey: 'category_extra',
    selectorsKey: selectorKeys.kpi_to_approve_notification_categories,
    title: 'Level',
    insert: ({ data }) => {
      if (data.category_extra.includes(KPICategoryExtraKeyWord.Department)) {
        return 'Department'
      }
      if (data.category_extra.includes(KPICategoryExtraKeyWord.Team)) {
        return 'Team'
      }
      if (data.category_extra.includes(KPICategoryExtraKeyWord.Employee)) {
        return 'Employee'
      }
      return '-'
    },
  }

export const notificationsDuiInColumn: ColumnInterface<NotificationsInterface> = {
  type: CellTypes.insert,
  idPoint: 'due_in',
  dataPoint: 'due_in',
  sortKey: 'due_in',
  filterKey: 'due_in',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Due in',
  insert: ({ data }) => {
    if (!data?.due_in) {
      return '-'
    }

    if (isSameDay(new Date(), new Date(data?.due_in))) {
      return 'Today'
    }

    if (isAfter(new Date(), new Date(data?.due_in))) {
      return (
        <InsertContainer red>{`Past due ${formatDistanceToNow(
          new Date(data?.due_in),
        )}`}</InsertContainer>
      )
    }
    return (
      <InsertContainer>{formatDistanceToNow(new Date(data?.due_in))}</InsertContainer>
    )
  },
}
