import React from 'react'
import { Avatar, Box, Item, ItemProps } from '@revolut/ui-kit'
import { ChevronRight } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'

export interface NewSelectionCardProps extends ItemProps {
  title: string
  subtitle?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  disabledTooltip?: React.ReactNode
  icon?: ResolveIconType
  image?: string
  transparent?: boolean // TODO: REVCOR-3046 revisit after ui-kit update
}

const NewSelectionCard = (props: NewSelectionCardProps) => {
  const {
    title,
    subtitle,
    onClick,
    icon,
    image,
    disabled,
    use,
    disabledTooltip,
    variant,
    transparent = true,
    ...itemProps
  } = props
  return (
    <Tooltip
      placement="bottom"
      body={<TooltipContainer>{disabledTooltip}</TooltipContainer>}
      hide={!disabled || !disabledTooltip}
    >
      <Box width="100%">
        <Item
          onClick={onClick}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            ...(transparent ? { background: 'transparent' } : null),
          }}
          use={disabled ? 'button' : use}
          disabled={disabled}
          variant={variant}
          {...itemProps}
        >
          <Item.Avatar>
            {icon ? (
              <Avatar useIcon={icon} />
            ) : image ? (
              <Avatar variant="app" image={image} />
            ) : null}
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{title}</Item.Title>
            {subtitle ? <Item.Description>{subtitle}</Item.Description> : null}
          </Item.Content>
          {variant === 'disclosure' ? null : (
            <Item.Side>
              <ChevronRight color="grey-tone-20" />
            </Item.Side>
          )}
        </Item>
      </Box>
    </Tooltip>
  )
}

export default NewSelectionCard
