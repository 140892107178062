import {
  BulkEditItemsResponse,
  BulkEditItemsResponseResult,
} from '@src/interfaces/bulkEdit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  employeePreview,
  changedField,
  oldValue,
  newValue,
  validation,
} from '@src/constants/columns/bulkEditEmployees'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import React, { useEffect } from 'react'
import { AxiosPromise } from 'axios'

const REFRESH_INTERVAL = 10000

type Props = {
  id: string
  autoRefresh: boolean
  getItems: (id: string | number) => AxiosPromise<BulkEditItemsResponse>
  tableName: TableNames
}

const BulkEditTable = ({ id, autoRefresh, getItems, tableName }: Props) => {
  const Row: RowInterface<BulkEditItemsResponseResult> = {
    cells: [
      {
        ...employeePreview,
        width: 200,
      },
      {
        ...changedField,
        width: 200,
      },
      {
        ...oldValue,
        width: 200,
      },
      {
        ...newValue,
        width: 200,
      },
      {
        ...validation,
        width: 200,
      },
    ],
  }

  const table = useTable({
    getItems: () => getItems(id),
  })

  useEffect(() => {
    if (!autoRefresh) {
      return undefined
    }

    const interval = setInterval(() => {
      table.refresh()
    }, REFRESH_INTERVAL)

    return () => clearInterval(interval)
  }, [autoRefresh])

  return (
    <AdjustableTable<BulkEditItemsResponseResult>
      name={tableName}
      useWindowScroll
      dataType="Change"
      row={Row}
      {...table}
      noDataMessage="Changes will appear here."
    />
  )
}

export default BulkEditTable
