import React, { useEffect, useState } from 'react'
import { ProgressSteps, ProgressStepState, TextWidget } from '@revolut/ui-kit'
import { StageProgressStep } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/ProgressWidget/StageProgressStep'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { TimelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { stageToLabelMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/constants'
import { formatStagePeriod } from '@src/pages/Forms/ReviewCycle/ReviewCycle/formatters/formatStagePeriod'

const getState = (
  cycle: ReviewCyclesInterface,
  stage: ReviewCycleStage,
  availableStages: ReviewCycleStage[],
): ProgressStepState => {
  if (TimelineModel.isFinishedStage(cycle, stage, availableStages)) {
    return 'done'
  }

  if (TimelineModel.isOngoingStage(cycle, stage)) {
    return 'pending'
  }

  return 'default'
}

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  selectedStage: ReviewCycleStage
  handleSelectionChange: (stage: ReviewCycleStage) => () => void
}

export const ProgressWidget = ({
  cycle,
  stages,
  selectedStage,
  handleSelectionChange,
}: Props) => {
  const [selectedVisibleStage, setSelectedVisibleStage] = useState(selectedStage)

  useEffect(() => {
    if (selectedStage !== ReviewCycleStage.Completed) {
      setSelectedVisibleStage(selectedStage)
    } else {
      setSelectedVisibleStage(
        TimelineModel.getPreviousStage(cycle, stages) ?? TimelineModel.defaultStage,
      )
    }
  }, [cycle, stages, selectedStage])

  const visibleStages = stages.filter(id => id !== ReviewCycleStage.Completed)

  return (
    <TextWidget>
      <TextWidget.Title>Timeline</TextWidget.Title>
      <TextWidget.Content>
        <ProgressSteps>
          {visibleStages.map(stage => (
            <StageProgressStep
              key={stage}
              state={getState(cycle, stage, stages)}
              highlight={selectedVisibleStage === stage}
              title={stageToLabelMap[stage]}
              description={formatStagePeriod(cycle, stage)}
              onClick={handleSelectionChange(stage)}
            />
          ))}
        </ProgressSteps>
      </TextWidget.Content>
    </TextWidget>
  )
}
