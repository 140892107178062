import {
  jiraPreferencesRequests,
  lookerIntegrationPreferences,
  tableauIntegrationPreferences,
} from '@src/api/integrations'
import { RequestInterfaceNew } from '@src/interfaces'
import { UpdateTypes } from '@src/interfaces/kpis'
import { string } from 'zod'

export interface IntegrationForm {
  server: string
  enabled: boolean
  id: number
  client_id?: string
  server_id?: string
  secret_value?: string
  owner?: { id: number }
  client_guid?: string
  redirect_uri?: string
  is_external_app?: boolean
}

export const integrationApiFormMap: Record<
  UpdateTypes,
  RequestInterfaceNew<IntegrationForm> | undefined
> = {
  [UpdateTypes.looker]: lookerIntegrationPreferences,
  [UpdateTypes.roadmap]: jiraPreferencesRequests,
  [UpdateTypes.clickup]: undefined,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.tableau]: tableauIntegrationPreferences,
}

export const serverNameValidators = {
  [UpdateTypes.looker]: {
    server: string()
      .trim()
      .url()
      .regex(
        /^https:\/\/[\w\d-]+\.cloud\.looker.com$/,
        'Provide correct account URL without trailing /',
      ),
  },
  [UpdateTypes.roadmap]: {
    server: string()
      .trim()
      .url()
      .regex(
        /^https:\/\/[\w\d-]+\.atlassian\.net$/,
        'Provide correct server URL without trailing /',
      ),
  },
  [UpdateTypes.clickup]: undefined,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.tableau]: string()
    .trim()
    .url()
    .regex(
      /^https:\/\/[\w\d-]+\.tableau\.net$/,
      'Provide correct server URL without trailing /',
    ),
} as const
