import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { CommunicationIcons } from '@src/features/CommunicationGroups/CommunicationIcons'
import { getStatusColor } from '@src/components/CommonSC/General'

export const groupNameColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.communication_groups,
  title: 'Group name',
}

export const groupParentColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.text,
  idPoint: 'parent',
  dataPoint: 'parent',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Parent',
}

export const groupTypeColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.text,
  idPoint: 'audience_type.id',
  dataPoint: 'audience_type.name',
  sortKey: 'audience_type',
  filterKey: 'audience_type',
  selectorsKey: selectorKeys.communication_group_audience_types,
  title: 'Type',
}

export const groupMembersCountColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.text,
  idPoint: 'members_count',
  dataPoint: 'members_count',
  sortKey: 'members_count',
  filterKey: 'members_count',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Members',
}

export const groupChannelsColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.insert,
  idPoint: 'slack_channel_name',
  dataPoint: 'slack_channel_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => <CommunicationIcons group={data} />,
  title: 'Channels',
}

export const groupCreationDateColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Creation date',
}

export const groupOwnerColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__full_name',
  selectorsKey: selectorKeys.employee,
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
  title: 'Owner',
}

export const groupStatusColumn: ColumnInterface<CommunicationGroupInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.communication_group_statuses,
  title: 'Status',
  colors: data => getStatusColor(data.status.id),
}
