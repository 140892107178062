import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Group,
  InputGroup,
  Side,
  TransitionCollapse,
  Portal,
  VStack,
  Text,
  Token,
  Popup,
  Header,
  ActionButton,
  Flex,
} from '@revolut/ui-kit'
import { set } from 'lodash'

import Form from '@src/features/Form/Form'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { dragIconColumn } from '@src/constants/columns/ordering'
import { selectorKeys } from '@src/constants/api'
import { ApprovalFlowSettingStep, ApprovalProcess } from '@src/interfaces/settings'
import { useOrdering } from '@src/components/Table/hooks'
import SideBar from '@src/components/SideBar/SideBar'
import { rowWrapperMinHeight } from '@src/components/TableV2/common'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import {
  approvalStepSettingsRequests,
  APPROVAL_PROCESS_GROUPS_API,
  useApprovalStepConfigurations,
} from '@src/api/settings'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useQueryClient } from 'react-query'
import { successNotification } from '@src/actions/NotificationActions'
import {
  approvalStepsApproversColumn,
  approvalStepsApproverTypeColumn,
  approvalStepsNameColumn,
} from '@src/constants/columns/settings'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { RowHeight, TableTypes } from '@src/interfaces/table'
import { LocalStorageKeys } from '@src/store/auth/types'

interface ApprovalStepsProps {
  title?: ReactNode
  description?: ReactNode
  switchField: string
  hideSwitch?: boolean
  approvalProcess: ApprovalProcess
  entity: string
  disabled?: boolean
  formRef: { [approvalProcess in ApprovalProcess]?: ApprovalFlowSettingStep[] }
  formInPopup?: boolean
  switchEl?: ReactNode
}

interface SidebarState {
  open: boolean
  id: number | null
}

const row: RowInterface<ApprovalFlowSettingStep> = {
  cells: [
    dragIconColumn,
    {
      ...approvalStepsNameColumn,
      width: 200,
    },
    {
      ...approvalStepsApproverTypeColumn,
      width: 180,
    },
    {
      ...approvalStepsApproversColumn,
      width: 180,
    },
  ],
}

const SidebarFormWrapper = ({
  children,
  title,
  onClose,
  isOpen,
}: PropsWithChildren<{
  title: string
  isOpen: boolean
  onClose: () => void
}>) => {
  return (
    <Portal target="#approval-sidebar-portal">
      <SideBar title={title} isOpen={isOpen} onClose={onClose}>
        {children}
      </SideBar>
    </Portal>
  )
}

const PopupFormWrapper = ({
  children,
  title,
  onClose,
  isOpen,
}: PropsWithChildren<{
  title: string
  isOpen: boolean
  onClose: () => void
}>) => {
  return (
    <Popup open={isOpen} onClose={onClose}>
      <Header displayMode="inline">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>{title}</Header.Title>
      </Header>
      {children}
    </Popup>
  )
}

export const ApprovalSteps = <T extends {}>({
  title,
  description,
  switchField,
  hideSwitch,
  approvalProcess,
  entity,
  disabled,
  formRef,
  formInPopup = false,
  switchEl,
}: ApprovalStepsProps) => {
  const { values, reset } = useLapeContext<T>()
  const queryClient = useQueryClient()
  const isNewTable = useIsNewTable()
  const [orderedTableData, setOrderedTableData] = useState<ApprovalFlowSettingStep[]>([])

  const steps = useApprovalStepConfigurations(approvalProcess)

  const handleOrderChange = (order: ApprovalFlowSettingStep[]) => {
    setOrderedTableData(order)

    if (!order.length || !steps?.length || order.length !== steps.length) {
      delete formRef[approvalProcess]
      return
    }
    for (let i = 0; i < order.length; i++) {
      if (order[i].id !== steps[i].id) {
        set(formRef, approvalProcess, order)
        return
      }
    }
    delete formRef[approvalProcess]
  }

  useEffect(() => {
    if (steps) {
      handleOrderChange(steps)
    }
  }, [steps])

  const ordering = useOrdering(orderedTableData, handleOrderChange)

  const [sidebarState, setSidebarState] = useState<SidebarState>({
    open: false,
    id: null,
  })

  const rowHeight: RowHeight = useMemo(() => {
    const localStorageRowHeight = localStorage.getItem(LocalStorageKeys.TABLE_ROW_HEIGHT)

    if (localStorageRowHeight === 'large') {
      return 'large'
    }
    return 'medium'
  }, [])

  /** `AdjustableTable` inside `TransitionCollapse` doesn't work well, so it's needed to specify the height */
  const tableWrapperHeight = useMemo(() => {
    if (!steps || steps.length === 0) {
      /** To account for header and empty state */
      return rowWrapperMinHeight[rowHeight] + 200
    }
    return (
      (steps.length + 1) * rowWrapperMinHeight[rowHeight] -
      (rowHeight === 'large' ? 22 : 0)
    ) // to reduce bottom gap on large
  }, [steps])

  const onCloseSidebar = () => setSidebarState({ open: false, id: null })

  const renderForm = (form: ReactNode) => {
    const wrapperProps = {
      title: sidebarState.id
        ? `Edit ${entity} approval step`
        : `Add ${entity} approval step`,
      isOpen: sidebarState.open,
      onClose: onCloseSidebar,
    }
    return formInPopup ? (
      <PopupFormWrapper {...wrapperProps}>{form}</PopupFormWrapper>
    ) : (
      <SidebarFormWrapper {...wrapperProps}>{form}</SidebarFormWrapper>
    )
  }

  return (
    <>
      <Group>
        {!hideSwitch
          ? switchEl || (
              <LapeNewSwitch
                itemTypeProps={{ title, description }}
                name={switchField}
                disabled={disabled}
              />
            )
          : null}
        {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
        <TransitionCollapse in={values[switchField]}>
          <Box
            mx={isNewTable ? 0 : 's-16'}
            mt={hideSwitch ? 's-16' : undefined}
            px={isNewTable ? 0 : 's-16'}
          >
            {disabled ? null : (
              <Flex justifyContent="flex-end" width="100%" px="s-16">
                <ActionButton
                  onClick={() => setSidebarState({ open: true, id: null })}
                  useIcon="Plus"
                >
                  Add approval step
                </ActionButton>
              </Flex>
            )}
            <Box height={tableWrapperHeight} overflow="hidden">
              <AdjustableTable<ApprovalFlowSettingStep>
                name={TableNames.ApprovalFlow}
                dataType="Approval flow"
                row={row}
                data={orderedTableData}
                count={orderedTableData.length}
                orderingMode={!disabled}
                disabledFiltering
                mainColumnIndex={1}
                type={TableTypes.Contained}
                hideCount
                emptyState={<EmptyTableRaw title="No data" />}
                hideCountAndButtonSection
                rowHeight="medium"
                onRowClick={({ id }) =>
                  disabled ? undefined : setSidebarState({ open: true, id })
                }
                {...ordering}
              />
            </Box>
          </Box>
        </TransitionCollapse>
      </Group>

      {renderForm(
        <Form
          api={approvalStepSettingsRequests}
          forceParams={{
            id: sidebarState.id ? `${sidebarState.id}` : undefined,
          }}
          disableLocalStorageCaching
        >
          <ApprovalStepForm
            id={sidebarState.id}
            approvalProcess={approvalProcess}
            onComplete={onCloseSidebar}
            onDelete={() => {
              const stepCount = orderedTableData?.filter(
                step => step.id !== sidebarState.id,
              ).length

              /** If all the steps are removed, mark the approval steps as turned "off" */
              if (stepCount === 0) {
                reset({ ...values, [switchField]: false })
              }
              queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
              onCloseSidebar()
            }}
          />
        </Form>,
      )}
    </>
  )
}

interface ApprovalStepFormProps {
  id: number | null
  approvalProcess: ApprovalProcess
  onComplete: () => void
  onDelete: () => void
}

const approvalProcessToSelector = {
  teams_approval_steps: selectorKeys.team_approval_relationships,
  departments_approval_steps: selectorKeys.department_approval_relationships,
  roles_approval_steps: selectorKeys.role_approval_relationships,
  specialisations_approval_steps: selectorKeys.specialisation_approval_relationships,
  company_kpis_approvals: selectorKeys.company_approval_relationships,
  department_kpis_approvals: selectorKeys.department_kpi_approval_relationships,
  skills_approvals: selectorKeys.skill_approval_relationships,
  team_kpis_approvals: selectorKeys.team_kpi_approval_relationships,
  employee_kpis_approvals: selectorKeys.employee_kpi_approval_relationships,
  function_kpis_approvals: selectorKeys.function_kpi_approval_relationships,
  role_kpis_approvals: selectorKeys.role_kpi_approval_relationships,
  requisition_approvals: selectorKeys.requisition_approval_relationships,
  job_posting_approvals: selectorKeys.job_posting_approval_relationships,
  group_access_request_approvals:
    selectorKeys.group_access_request_approval_relationships,
  employee_attendance_tracking_overtime_approvals:
    selectorKeys.employee_attendance_tracking_relationships,
  offer_approvals: selectorKeys.offer_approval_relationships,
  benchmarks_approvals: selectorKeys.benchmarks_approvals_relationships,
}

const ApprovalStepForm = ({
  id,
  approvalProcess,
  onComplete,
  onDelete,
}: ApprovalStepFormProps) => {
  const { values } = useLapeContext<ApprovalFlowSettingStep>()
  const queryClient = useQueryClient()

  useEffect(() => {
    values.approval_process = approvalProcess
  }, [approvalProcess])

  return (
    <>
      {id ? (
        <SettingsButtons mb="s-16">
          <DeleteButton
            deleteApi={approvalStepSettingsRequests.delete!}
            title="Approval step"
            onAfterDelete={() => {
              successNotification('Approval step removed')
              onDelete()
            }}
          />
        </SettingsButtons>
      ) : null}

      <InputGroup>
        <LapeNewInput label="Approval step" name="name" required />
        <LapeRadioSelectInput
          label="Approval type"
          selector={selectorKeys.approval_step_approver_types}
          name="approver_type"
        >
          {option => {
            if (!option.value.id) {
              return null
            }

            const description = {
              relationship: `A person who has some form of relationship to the the object that needs to be approved. Example of relationships: For Departments can be the department owner, for a job posting can be the assigned recruiter.`,
              employee: `A specific person in the organisation who will approve.`,
              group: `A group of people from which the approver can be selected. The groups are managed in the Groups application.`,
            }[option.value.id]

            return (
              <VStack space="s-4">
                <Text>{option.value.name}</Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  {description}
                </Text>
              </VStack>
            )
          }}
        </LapeRadioSelectInput>
        {values.approver_type?.id === 'relationship' && (
          <LapeRadioSelectInput
            label="Relationship"
            selector={approvalProcessToSelector[approvalProcess]}
            name="relationship"
          />
        )}
        {values.approver_type?.id === 'employee' && (
          <LapeRadioSelectInput
            label="Employee"
            selector={selectorKeys.employee}
            name="employee_approver"
          />
        )}
        {values.approver_type?.id === 'group' && (
          <LapeRadioSelectInput
            label="Group"
            selector={selectorKeys.dynamic_groups}
            name="group_approver"
          />
        )}
      </InputGroup>

      <Side.Actions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
            onComplete()
          }}
          successText="Approval step saved"
          useValidator
        >
          {id ? 'Update step' : 'Save step'}
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
}
