import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import { FinalGradeToShortString, FinalGradeToString } from '@src/interfaces/scorecard'
import { GradesMapInterface } from '@src/utils/grades'

type Props = {
  grade?: FinalGrade | null
  inverse?: boolean
  className?: string
  withPending?: boolean
  gradesMap?: GradesMapInterface
}

export const getColorsAndGrade = (grade?: FinalGrade | null) => {
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: Token.color.background,
        background: Token.color.red,
        grade: FinalGradeToShortString[FinalGrade.Poor],
        gradeColor: Token.color.danger,
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        background: Token.color.greyTone50,
        grade: FinalGradeToShortString[FinalGrade.AverageMinus],
        gradeColor: Token.color.warning,
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.foreground,
        background: Token.color.teal,
        grade: FinalGradeToShortString[FinalGrade.AveragePlus],
        gradeColor: Token.color.foreground,
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: FinalGradeToShortString[FinalGrade.Strong],
        gradeColor: Token.color.success,
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: FinalGradeToShortString[FinalGrade.Exceptional],
        gradeColor: Token.color.success,
      }
    default:
      return null
  }
}

export const getInverseColorsAndGrade = (
  grade?: FinalGrade | null,
  gradesMap?: GradesMapInterface,
) => {
  const FinalGradeMap = gradesMap || FinalGradeToString
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: Token.color.red,
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.Poor],
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.AverageMinus],
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.foreground,
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.AveragePlus],
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.green,
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.Strong],
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.green,
        background: 'transparent',
        grade: FinalGradeMap[FinalGrade.Exceptional],
      }
    default:
      return null
  }
}

const PerformanceGrade = ({
  grade,
  inverse,
  className,
  withPending,
  gradesMap,
}: Props) => {
  const data = inverse
    ? getInverseColorsAndGrade(grade, gradesMap)
    : getColorsAndGrade(grade)

  if (!data) {
    if (withPending) {
      return (
        <Text className={className} color={Token.color.greyTone20}>
          Pending
        </Text>
      )
    }

    return null
  }

  return (
    <Text className={className} backgroundColor={data.background} color={data.color}>
      {data.grade}
    </Text>
  )
}

export default PerformanceGrade
