import { DateType } from '@components/LargeWeeklyCalendar/types'

export const getDateFromEventDate = (eventDate?: DateType) => {
  if (!eventDate) {
    return null
  }

  if (typeof eventDate === 'string' || typeof eventDate === 'number') {
    return new Date(eventDate)
  }

  if ('toDate' in eventDate) {
    return eventDate.toDate()
  }

  return eventDate
}
