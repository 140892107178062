import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import General from '@src/pages/CandidateScheduling/General'
import CandidateSelectSlots from '@src/pages/CandidateScheduling/CandidateSelectSlots'
import PublicPageLayout from '@components/CommonSC/PublicPageLayout'

const Router = () => {
  return (
    <PublicPageLayout noPadding>
      <Switch>
        <Route
          exact
          path={PUBLIC_ROUTES.CANDIDATE_SCHEDULING.GENERAL}
          component={General}
        />
        <Route
          exact
          path={PUBLIC_ROUTES.CANDIDATE_SCHEDULING.SELECT}
          component={CandidateSelectSlots}
        />
      </Switch>
    </PublicPageLayout>
  )
}

export default Router
