import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Action, Flex, HStack, MoreBar, Widget } from '@revolut/ui-kit'
import SettingsButtons from '@src/features/SettingsButtons'
import { useParams } from 'react-router-dom'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  AssignGroupAccessManagementInterface,
  GroupAccessManagementInterface,
} from '@src/interfaces/accessManagement'
import { useTable } from '@components/Table/hooks'
import {
  groupUserGroupsRequests,
  revokeGroup,
  userUserGroupsRequests,
} from '@src/api/accessManagement'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import {
  actionsColumn,
  assignedByColumn,
  groupNameColumn,
  notesColumn,
  statusColumn,
  userNameColumn,
  validFromColumn,
  validUntilColumn,
} from '@src/constants/columns/accessManagement/accessManagementAssignGroups'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import RevokeAction from '@src/features/TableRowRevokeAction/RevokeAction'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetAccessManagementSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'

type Type = 'user' | 'group'

const Row = (
  type: Type,
  onRevoke: (id: number) => void,
): RowInterface<AssignGroupAccessManagementInterface> => {
  const linkToForm = (data: AssignGroupAccessManagementInterface) => {
    switch (type) {
      case 'user':
        navigateTo(
          pathToUrl(ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION_GROUP.GENERAL, {
            userId: data.user.id,
            id: data.id,
          }),
        )
        break

      case 'group':
        navigateTo(
          pathToUrl(ROUTES.FORMS.ASSIGN_PERMISSION_GROUP_TO_USER.GENERAL, {
            groupId: data.group.id,
            id: data.id,
          }),
        )
        break
    }
  }

  return {
    linkToForm,
    cells: [
      type === 'group'
        ? {
            ...userNameColumn,
            width: 300,
          }
        : {
            ...groupNameColumn,
            width: 300,
          },
      {
        ...assignedByColumn,
        width: 300,
      },
      {
        ...validFromColumn,
        width: 150,
      },
      {
        ...validUntilColumn,
        width: 150,
      },
      {
        ...notesColumn,
        width: 300,
      },
      {
        ...statusColumn,
        width: 150,
      },
      {
        ...actionsColumn,
        width: 200,
        insert: ({ data }) =>
          data.source?.id === 'group' ? null : (
            <>
              <HStack gap="s-8">
                <Action
                  onClick={e => {
                    e.stopPropagation()
                    linkToForm(data)
                  }}
                >
                  Edit
                </Action>
                {!['expired', 'pending', 'rejected'].includes(data.status?.id) && (
                  <RevokeAction onClick={() => onRevoke(data.id!)} />
                )}
              </HStack>
            </>
          ),
      },
    ],
  }
}

type Props = {
  type: 'user' | 'group'
}

const AssignedGroupsUsersTable = ({ type }: Props) => {
  const permissions = useSelector(selectPermissions)
  const { data: settings } = useGetAccessManagementSettings()
  const { values } = useLapeContext<GroupAccessManagementInterface>()
  const { id } = useParams<{ id: string }>()

  const onRevoke = async (itemId: number) => {
    await revokeGroup(itemId)
    table.refresh()
  }

  const table = useTable<AssignGroupAccessManagementInterface>(
    type === 'group' ? groupUserGroupsRequests(id) : userUserGroupsRequests(id),
  )

  const canAddUserToGroup =
    type === 'user' && permissions?.includes(PermissionTypes.AddUserGroups)
  const canAddGroupToUser =
    type === 'group' &&
    values?.field_options?.permissions?.includes(PermissionTypes.AddUserGroups)
  const canAdd = canAddUserToGroup || canAddGroupToUser

  return (
    <Widget p="s-16">
      {canAdd && (
        <SettingsButtons pb="s-16">
          {canAddUserToGroup && (
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION_GROUPS.GENERAL, {
                userId: id,
              })}
            >
              Assign groups
            </MoreBar.Action>
          )}

          {canAddGroupToUser && (
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.ASSIGN_PERMISSION_GROUP_TO_USER.GENERAL, {
                groupId: id,
              })}
            >
              Assign to user
            </MoreBar.Action>
          )}
        </SettingsButtons>
      )}
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<AssignGroupAccessManagementInterface>
            name={TableNames.AssignedGroupsUsers}
            useWindowScroll
            dataType="Groups"
            row={Row(type, onRevoke)}
            {...table}
            noDataMessage="Users will appear here."
            hiddenCells={{
              [validUntilColumn.idPoint]: !settings?.enable_limited_time_access,
            }}
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default AssignedGroupsUsersTable
