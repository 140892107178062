import React from 'react'
import { ApplicationForm as ApplicationFormBody } from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationForm'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { Button } from '@revolut/ui-kit'
import { useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type ApplicationFormProps = {
  onAfterSubmit: () => void
}

export const ApplicationForm = ({ onAfterSubmit }: ApplicationFormProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  return (
    <>
      <PageBody>
        <ApplicationFormBody />
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          id={String(values.id)}
          useValidator
          hideWhenNoChanges={false}
          onAfterSubmit={() => {
            onAfterSubmit()
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.HIRING_STAGES, params),
              locationState,
            )
          }}
          noPopup
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
