import React from 'react'
import { Header, InputGroup, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import { SectionOptions } from '@src/interfaces/customFields'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { TabProps } from '../common/interface'
import { BackButton, NextButton } from '../components/Buttons'

export const Payroll = ({
  dynamicGroups,
  onComplete,
  nextRoute,
  prevRoute,
}: TabProps) => {
  const navigateToPrevStep = () => {
    if (prevRoute) {
      navigateTo(prevRoute)
    }
  }

  const navigateToNextStep = () => {
    if (nextRoute) {
      navigateTo(nextRoute)
      onComplete?.()
    }
  }

  return (
    <>
      <PageBody pb="s-24">
        <Header mb="s-24" variant="main">
          <Header.Title>Payroll</Header.Title>
          <Header.Subtitle>
            Please fill in your bank details so that we can add you to our payroll.
          </Header.Subtitle>
        </Header>

        <NewStepperSectionCustomFields
          dynamicGroups={dynamicGroups}
          sectionId={SectionOptions.EmployeeProfile}
          subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BANK_DETAILS}
          useExternalRequests
        >
          <InputGroup>
            <LapeNewInput label="Bank name" name="bank_name" required />
            <LapeNewInput
              label="Name on account"
              name="account_name"
              description="Your name as it appears on your bank statements"
              required
            />
            <LapeRadioSelectInput
              label="Account currency"
              name="bank_currency"
              selector={selectorKeys.currencies}
            />
          </InputGroup>
        </NewStepperSectionCustomFields>
      </PageBody>

      <PageActions alignSelf="center" maxWidthMd={Token.breakpoint.sm}>
        <BackButton onClick={navigateToPrevStep} />
        <NextButton afterSubmit={navigateToNextStep} />
      </PageActions>
    </>
  )
}
