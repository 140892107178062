import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'

type GoalsSettingsItemId =
  | 'goalsMetricTypes'
  | 'goalsRoadmaps'
  | 'goalsApprovalProcess'
  | 'goalsJiraIntegration'
  | 'goalsTableauIntegration'
  | 'goalsLookerIntergration'
  | 'goalsClickUpIntergration'

export const goalsSettingsLinkConfig: SettingsLinkItemConfig<GoalsSettingsItemId>[] = [
  {
    id: 'goalsMetricTypes',
    section: 'Goals settings',
    icon: 'Switches',
    title: 'Metric types',
    searchTitle: 'Goals metric types',
    to: ROUTES.SETTINGS.GOALS.TARGET_TYPES,
    permissions: [PermissionTypes.ViewPerformancePreferences],
  },
  {
    id: 'goalsRoadmaps',
    section: 'Goals settings',
    icon: 'DataLimit',
    title: 'Roadmaps',
    searchTitle: 'Goals roadmaps',
    to: ROUTES.SETTINGS.GOALS.ROADMAPS,
    permissions: [PermissionTypes.ViewPerformancePreferences],
  },
  {
    id: 'goalsApprovalProcess',
    section: 'Goals settings',
    icon: 'CheckVerification',
    title: 'Approval processes',
    searchTitle: 'Goals approval processes',
    subtitle: 'Create and manage custom approval processes for your business',
    to: ROUTES.SETTINGS.GOALS.APPROVAL_PROCESS,
    permissions: [PermissionTypes.ViewPerformancePreferences],
  },
  {
    id: 'goalsJiraIntegration',
    section: 'Goals settings',
    icon: 'Jira|image',
    title: 'Jira',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.JIRA,
    subtitle: 'Enable to import data from your Jira workspace',
    permissions: [PermissionTypes.ViewJiraIntegration],
  },
  {
    id: 'goalsTableauIntegration',
    section: 'Goals settings',
    image: 'https://assets.revolut.com/assets/apps/Tableau.webp',
    title: 'Tableau',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.TABLEAU,
    subtitle: 'Enable to import data from your Tableau workspace',
    permissions: [PermissionTypes.ViewTableauIntegration],
  },
  {
    id: 'goalsLookerIntergration',
    section: 'Goals settings',
    image: 'https://assets.revolut.com/assets/apps/Looker.webp',
    title: 'Looker',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.LOOKER,
    subtitle: 'Enable to import data from your Looker workspace',
    permissions: [PermissionTypes.ViewLookerIntegration],
  },
  {
    id: 'goalsClickUpIntergration',
    section: 'Goals settings',
    image: 'https://assets.revolut.com/assets/apps/ClickUp.webp',
    title: 'ClickUp',
    to: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.CLICKUP,
    subtitle: 'Enable to import data from your ClickUp workspace',
    permissions: [PermissionTypes.ViewClickupIntegration],
  },
]
