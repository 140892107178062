import React from 'react'
import { Color, ErrorWidget, Flex, Text } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import Lock from '@src/assets/3D/Lock@2x.png'

type Props = {
  noButton?: boolean
}

const PageAccessDenied = ({ noButton }: Props) => {
  return (
    <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
      <ErrorWidget>
        <ErrorWidget.Image src={Lock} />
        <ErrorWidget.Title>
          Access denied
          <Text
            color={Color.GREY_TONE_50}
            variant="caption"
            use="p"
            textAlign="center"
            width="100%"
            mt="1em"
          >
            You don't have access to view this page
          </Text>
        </ErrorWidget.Title>
        {!noButton && (
          <ErrorWidget.Action onClick={() => navigateTo(ROUTES.MAIN)}>
            Go to Revoluters Dashboard
          </ErrorWidget.Action>
        )}
      </ErrorWidget>
    </Flex>
  )
}

export default PageAccessDenied
