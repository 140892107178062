import React, { useState } from 'react'
import { Banner, Icon, Token } from '@revolut/ui-kit'

import { TableActionButton } from '@components/Button/TableActionButton'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import {
  getAllKeyPersonResponsibilitiesAssignments,
  getAllKeyPersonResponsibilitiesAssignmentsStats,
  transferResponsibilityAssignment,
} from '@src/api/keyPerson'
import { TransferResponsibilityConfirmation } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/TransferResponsibilityConfirmation'
import { keyPersonOwnershipActionsColumn } from '@src/constants/columns/keyPersonOwnership'
import {
  keyPersonsResponsibilitiesAssignmentsEndDateColumn,
  keyPersonsResponsibilitiesAssignmentsPeriodColumn,
  keyPersonsResponsibilitiesAssignmentsResponsibilityCategoryColumn,
  keyPersonsResponsibilitiesAssignmentsResponsibilityColumn,
  keyPersonsResponsibilitiesAssignmentsStartDateColumn,
  keyPersonsResponsibilitiesAssignmentsStatusColumn,
  keyPersonsResponsibilitiesAssignmentsSuccessionColumn,
  keyPersonsResponsibilitiesAssignmentsUnitColumn,
} from '@src/constants/columns/keyPersonsResponsibilitiesAssignments'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonResponsibilityAssignmentsStatsInterface,
  KeyPersonRolesStatsInterface,
} from '@src/interfaces/keyPerson'
import { pathToUrl } from '@src/utils/router'

const getRows = (
  onTransferResponsibility: (id: number) => void,
): RowInterface<KeyPersonResponsibilityAssignmentInterface> => ({
  cells: [
    {
      ...keyPersonsResponsibilitiesAssignmentsResponsibilityColumn,
      width: 240,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsResponsibilityCategoryColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsUnitColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsStartDateColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsEndDateColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsPeriodColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsSuccessionColumn,
      width: 100,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsStatusColumn,
      width: 160,
    },
    {
      ...keyPersonOwnershipActionsColumn,
      width: 140,
      insert: ({ data }) => {
        return data.assignment_status?.id === 'archived' ? null : (
          <TableActionButton
            useIcon="ArrowRightLeft"
            onClick={e => {
              e.stopPropagation()
              onTransferResponsibility(data.id)
            }}
          >
            Transfer
          </TableActionButton>
        )
      },
    },
  ],
})

const statsConfig: StatsConfig<KeyPersonRolesStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.success,
  },
  {
    key: 'pending_start',
    title: 'Pending start',
    filterKey: 'pending_start',
    color: Token.color.warning,
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: Token.color.danger,
  },
]

type Props = {
  data: EmployeeInterface
}
const KeyPersonOwnership = ({ data }: Props) => {
  const [submitTransferPopupOpen, setSubmitTransferPopupOpen] = useState(false)
  const [transferToEmployee, setTransferToEmployee] = useState<EmployeeOptionInterface>()
  const [isTransferPending, setIsTransferPending] = useState(false)
  const [transferredResponsibilityId, setTransferredResponsibilityId] = useState<number>()

  const table = useTable<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >({
    getItems: getAllKeyPersonResponsibilitiesAssignments(data.id),
    getStats: getAllKeyPersonResponsibilitiesAssignmentsStats(data.id),
  })
  const statFiltersProps = useSelectableTableStats<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
  })

  const noActiveAssignments = !table.stats?.active && !table.stats?.pending_start

  return (
    <>
      {noActiveAssignments && (
        <Banner mb="s-16">
          <Banner.Avatar>
            <Icon name="16/CheckCircle" size={32} color={Token.color.blue} />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>{`${data.full_name} is not a key person`}</Banner.Title>
            <Banner.Description>No action required</Banner.Description>
          </Banner.Content>
        </Banner>
      )}
      <Table.Widget>
        <Table.Widget.Info>
          <StatFilters {...statFiltersProps} />
        </Table.Widget.Info>

        <Table.Widget.Table>
          <AdjustableTable<
            KeyPersonResponsibilityAssignmentInterface,
            KeyPersonResponsibilityAssignmentsStatsInterface
          >
            hideCount
            name={TableNames.KeyPersonEmployeeOwnershipResponsibilitiesList}
            useWindowScroll
            row={getRows((responsibilityId: number) => {
              setTransferredResponsibilityId(responsibilityId)
              setSubmitTransferPopupOpen(true)
            })}
            {...table}
            onRowClick={row => {
              navigateTo(
                pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.PREVIEW, {
                  id: row.key_person_type.id,
                }),
              )
            }}
            noDataMessage="Key person responsibilities will appear here"
            dataType="Responsibility"
          />
        </Table.Widget.Table>
      </Table.Widget>
      <TransferResponsibilityConfirmation
        open={submitTransferPopupOpen}
        onClose={() => setSubmitTransferPopupOpen(false)}
        onReject={() => setSubmitTransferPopupOpen(false)}
        loading={isTransferPending}
        transferToEmployee={transferToEmployee}
        setTransferToEmployee={setTransferToEmployee}
        onConfirm={async () => {
          if (!transferToEmployee?.id || !transferredResponsibilityId) {
            return
          }
          try {
            setIsTransferPending(true)
            await transferResponsibilityAssignment(
              transferredResponsibilityId,
              transferToEmployee.id,
            )
            table.refresh()
            setSubmitTransferPopupOpen(false)
          } finally {
            setIsTransferPending(false)
          }
        }}
      />
    </>
  )
}

export default KeyPersonOwnership
