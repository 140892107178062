import React, { useEffect, useMemo, useState } from 'react'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import { useKPIColumns } from '@src/api/kpis'
import { debounce, isArray, isObject, isString } from 'lodash'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const typeToValueKeyMap = {
  looker: 'look_url',
  tableau: 'tableau_view_url',
} as const

const variantToValueKeyMap = {
  target: 'counted_sql_column',
  date: 'counted_sql_date_column',
} as const

const variantToLabelMap = {
  target: 'Target column',
  date: 'Date column',
} as const

export const FetchColumn = ({
  type,
  variant,
}: {
  type: 'looker' | 'tableau'
  variant: 'target' | 'date'
}) => {
  const { values, initialValues } = useLapeContext<KpiInterface>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const { mutateAsync: fetchOptions, data, isLoading } = useKPIColumns()

  const options = isArray(data?.data)
    ? data?.data.map(opt => ({
        id: isString(opt) ? opt : opt.id,
        label: isString(opt) ? opt : opt.name,
        value: isString(opt) ? { id: opt, name: opt } : opt,
      })) || []
    : []

  const searchDebounced = useMemo(
    () =>
      debounce(async () => {
        setErrorMessage(undefined)
        if (values[typeToValueKeyMap[type]] !== initialValues[typeToValueKeyMap[type]]) {
          // clear column if look url changed
          values[variantToValueKeyMap[variant]] = undefined
        }
        if (values[typeToValueKeyMap[type]]) {
          setErrorMessage(undefined)
          try {
            const { data: fetchData } = await fetchOptions({
              query_db: type,
              [typeToValueKeyMap[type]]: values[typeToValueKeyMap[type]],
              result_format: type === 'looker' ? 'json_bi' : undefined,
              column_type: variant === 'date' ? 'date' : undefined,
            })
            if ('message' in fetchData) {
              setErrorMessage(fetchData.message as string)
            } else if (
              isArray(fetchData) &&
              !initialValues[variantToValueKeyMap[variant]]
            ) {
              const preselectedItem = fetchData.at(variant === 'target' ? -1 : 0)

              values[variantToValueKeyMap[variant]] = isObject(preselectedItem)
                ? preselectedItem.id
                : preselectedItem
            }
          } catch (err) {
            setErrorMessage(getStringMessageFromError(err))
          }
        }
      }, 1000),
    [],
  )

  useEffect(() => {
    searchDebounced()
  }, [values.look_url, values.tableau_view_url])

  return (
    <LapeRadioSelectInput
      loading={isLoading}
      options={options}
      value={
        values[variantToValueKeyMap[variant]]
          ? {
              id: values[variantToValueKeyMap[variant]],
              name: values[variantToValueKeyMap[variant]],
            }
          : undefined
      }
      onChange={value => {
        if (value?.id) {
          values[variantToValueKeyMap[variant]] = value.id
        }
      }}
      message={errorMessage}
      hasError={!!errorMessage}
      name={variantToValueKeyMap[variant]}
      label={variantToLabelMap[variant]}
    />
  )
}
