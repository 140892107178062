import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import * as yup from 'yup'
import { connectionRequestsNew } from '@src/api/dataAnalytics'
import { ROUTES } from '@src/constants/routes'
import { ConnectionInterface } from '@src/interfaces/dataAnalytics'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { GeneralConnectionForm } from './ConnectionForm'
import { PermissionTable } from './PermissionTable'
import { extraFieldValidation } from './utils'

export const validator = {
  extra: yup
    .string()
    .nullable()
    .test(
      'extra-field',
      'Extra field should be a valid string',
      extraFieldValidation as any,
    ),
}

const BACK_URL = ROUTES.APPS.DATA_ANALYTICS.CONNECTIONS

const getTabs = (id: string, canManagePermissions: boolean) => [
  {
    title: 'Connection Configuration',
    path: ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL,
    to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL, { id }),
    component: GeneralConnectionForm,
    canView: true,
  },
  {
    title: 'Access Details',
    path: ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.PERMISSIONS,
    to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.PERMISSIONS, { id }),
    component: PermissionTable,
    canView: canManagePermissions,
  },
]

const Connection = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<ConnectionInterface>()

  const permissions = values.field_options?.permissions || []
  const canManagePermissions = permissions.includes(
    PermissionTypes.AccessObjectPermissionsConnection,
  )
  const isNewQuery = !params.id
  const tabs = isNewQuery
    ? []
    : getTabs(params.id, canManagePermissions).filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader backUrl={BACK_URL} title={isNewQuery ? 'New Connection' : values.name}>
        {!isNewQuery && <TabBarNavigation tabs={tabs} />}
      </PageHeader>
      <Switch>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.GENERAL}>
          <GeneralConnectionForm />
        </Route>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.PERMISSIONS}>
          <PermissionTable />
        </Route>
      </Switch>
    </PageWrapper>
  )
})

const ConnectionForm = () => {
  return (
    <Form api={connectionRequestsNew} validator={validator}>
      <Connection />
    </Form>
  )
}

export default connect(ConnectionForm)
