import React, { useState, useMemo, useEffect, useContext } from 'react'
import { DeltaStatic, Sources } from 'quill'
import ReactQuill, { UnprivilegedEditor } from 'react-quill'
import { RadioSelect, SelectOptionItemType, useResizeObserver } from '@revolut/ui-kit'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { setTagValueToNode, TAG_CLASS } from '@components/Chat/ChatTextEditor/TagBlot'
import isEmpty from 'lodash/isEmpty'
import { TaggedUsersMap } from '@src/interfaces/chat'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import {
  TagsManagerContext,
  TagsManagerContextValue,
} from '@components/Chat/ChatTagsManager'
import { LocalStorageKeys } from '@src/store/auth/types'

type UnsavedTag = {
  text: string
  index: number
}

export type UseTaggingOptions = {
  quillRef: React.RefObject<ReactQuill>
  containerRef: React.RefObject<HTMLDivElement>
  defaultValue?: string
  taggedUsers?: TaggedUsersMap
}

export type UseTaggingReturnType = {
  onChange(changes: DeltaStatic, source: Sources, editor: UnprivilegedEditor): void
  transformValueForSubmit(resetAfter: boolean): string
  onAfterSubmit(): void
  tagSelector: React.ReactNode
  onKeyDown: React.KeyboardEventHandler
  forceFocus: boolean
  hasTags?: boolean
}

// Tag mode is for choosing a tag. The dropdown for selecting an employee is shown
// Text mode is a default mode where user simply edits the text
enum Mode {
  Tag = 'Tag',
  Text = 'Text',
}

const getEmployeeName = (employee: EmployeeOptionInterface) => {
  return employee.display_name || employee.full_name || employee.name || 'unknown'
}

const useTagging = ({
  quillRef,
  containerRef,
  taggedUsers = {},
}: UseTaggingOptions): UseTaggingReturnType => {
  const [hasTags, setHasTags] = useState<boolean>(false)
  const [mode, setMode] = useState<Mode>(Mode.Text)
  const [unsavedTag, setUnsavedTag] = useState<UnsavedTag | null>(null)
  const [forceFocus, setForceFocus] = useState<boolean>(false)
  const { employeeOptions } = useContext<TagsManagerContextValue>(TagsManagerContext)

  const { width } = useResizeObserver(containerRef)

  const filteredOptions: SelectOptionItemType<EmployeeOptionInterface>[] = useMemo(() => {
    const filter = new RegExp(unsavedTag ? unsavedTag.text : '\\c', 'i')

    return employeeOptions.reduce((result, opt) => {
      if ((opt.label as string).match(filter)) {
        result.push({
          ...opt,
          key: opt.value.id,
        })
      }
      return result
    }, [] as SelectOptionItemType<EmployeeOptionInterface>[])
  }, [employeeOptions, unsavedTag?.text])

  useEffect(() => {
    if (!isEmpty(taggedUsers) && containerRef.current) {
      const tagElms = containerRef.current.getElementsByClassName(TAG_CLASS)

      // elements collection is not an array and doesn't have forEach
      for (let i = 0; i < tagElms.length; i++) {
        const tag = tagElms[i] as HTMLAnchorElement
        const id = Number(tag.innerText.replaceAll(/(\[id:|]|\s)/gm, ''))

        const userData = taggedUsers[id]

        if (userData) {
          const name = (id && getEmployeeName(userData)) || 'unknown'
          tag.innerHTML = `@${name.replaceAll(' ', '.')}`

          setTagValueToNode(tag, {
            id,
            name,
            avatar: userData.avatar,
            jobTitle: userData.job_title,
          })
          const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)
          const url = pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, { id })
          tag.setAttribute('href', activeWorkspace ? `/${activeWorkspace}${url}` : url)
        }
      }
    }
  }, [taggedUsers, employeeOptions])

  const transformValueForSubmit = (resetAfter: boolean): string => {
    const container = containerRef.current
    const editor = quillRef.current?.getEditor()

    if (!editor || !container) {
      return ''
    }
    // Make a node clone not modify text in the editor
    const rootClone = editor.root.cloneNode(true) as HTMLElement
    const allTags = rootClone.getElementsByClassName(TAG_CLASS)

    // elements collection is not an array and doesn't have forEach
    for (let i = 0; i < allTags.length; i++) {
      const node = allTags[i] as HTMLAnchorElement
      const userId = node.dataset.userid
      node.innerText = `[id:${userId}]`
    }
    if (resetAfter) {
      editor.setContents({ ops: [{ insert: '\n' }] } as DeltaStatic)
    }

    return rootClone.innerHTML || ''
  }

  const addTag = (index: number, employee: EmployeeOptionInterface) => {
    const quill = quillRef.current
    const editor = quill?.getEditor()
    if (!editor) {
      return
    }
    // Remove the search string + @ sign
    unsavedTag && editor.deleteText(index - 1, unsavedTag.text.length + 1)
    // Focus the editor on the index of the tag
    editor.setSelection(index - 1, 0)

    const nameStr = getEmployeeName(employee)
    // Create a tag
    editor.format('tag', {
      id: employee.id,
      name: nameStr,
      avatar: employee.avatar,
      jobTitle: employee.job_title,
    })
    editor.insertText(index + nameStr.length, ' ')
    editor.removeFormat(index + nameStr.length, 1)
    editor.focus()
    setTimeout(() => {
      editor.setSelection(index + nameStr.length + 1, 0)
    })

    // Leave tag editing mode and reset the search text
    setUnsavedTag(null)
    setMode(Mode.Text)

    // Async needed to not render checkbox while dropdown is closing
    setTimeout(() => setHasTags(true))
  }

  const onChangeInText = (
    changes: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    const lastAction = changes.ops?.[changes.ops?.length - 1]

    // If user types '@' enter tag editing mode
    if (source === 'user' && lastAction?.insert === '@') {
      const index = editor.getSelection(true).index

      // Check that @ sign stands alone (to not trigger tag mode when user types an email)
      const isValidTagTrigger = index === 1 || editor.getText(index - 2, 1).match(/\s/)

      if (!isValidTagTrigger) {
        return
      }

      setTimeout(() => {
        setUnsavedTag({
          index,
          text: '',
        })
        setMode(Mode.Tag)
      })
    }
  }

  const handleTagDeleteOp = (
    tag: UnsavedTag,
    index: number,
    deleteLength: number,
  ): UnsavedTag | null => {
    const tagLastIndex = tag.index + tag.text.length
    const deleteLastIndex = index + deleteLength

    // Case 1. Tag's @ sign is deleted, so tag is removed
    if (index < tag.index) {
      return null
    }
    // Case 2. Tag's end is deleted, tag string is shortened
    if (index >= tag.index && tagLastIndex <= deleteLastIndex) {
      return {
        ...tag,
        text: tag.text.slice(0, -deleteLength),
      }
    }
    // Case 3. Delete happened in the middle of the tag
    if (index >= tag.index && tagLastIndex > deleteLastIndex) {
      const tagTail = tag.text.slice(-(tagLastIndex - deleteLastIndex))
      const tagHead = tag.text.slice(0, -(deleteLength + tagTail.length))

      return {
        ...tag,
        text: `${tagHead}${tagTail}`,
      }
    }

    // Case 4. Delete happened somewhere else, tag isn't affected
    return tag
  }

  const handleTagInsertOp = (
    tag: UnsavedTag,
    index: number,
    insert: string,
  ): UnsavedTag | null => {
    const tagLastIndex = tag.index + tag.text.length

    // Case 1. Text inserted between tag text bounds
    if (index > tag.index && index < tagLastIndex) {
      const tagTail = tag.text.slice(index)
      const tagHead = tag.text.slice(-tagTail.length)
      const split = insert.split(/\s/)

      // if inserted string doesn't contain spaces, it's inserted between tag's text
      if (split.length === 1) {
        const newText = `${tagHead}${insert}${tagTail}`
        return {
          ...tag,
          text: newText,
        }
      }
      // if inserted contains spaces, it's first chunk is added to the tag and tag's tail is removed
      if (split.length > 1) {
        const newText = `${tagHead}${split[0]}`
        return {
          ...tag,
          text: newText,
        }
      }
      return tag
    }

    // Case 2. Text is inserted right after tag bounds and added to the tag
    if (index === tagLastIndex) {
      const split = insert.split(/\s/)
      return {
        ...tag,
        text: `${tag.text}${split[0]}`,
      }
    }

    // Case 3. Text inserted out of tag bounds, tag is not saved
    return null
  }

  const onChangeInTag = (changes: DeltaStatic, source: Sources) => {
    // This should never happen
    if (!unsavedTag) {
      setMode(Mode.Text)
      return
    }
    // If action wasn't performed by a user, ignore it
    if (source !== 'user') {
      return
    }

    let index = 0
    let tag: UnsavedTag | null = { ...unsavedTag }

    changes.ops?.forEach(op => {
      if (op.retain) {
        index += op.retain
      }
      if (op.delete && tag) {
        tag = handleTagDeleteOp(tag, index, op.delete)
      }
      if (op.insert && tag) {
        tag = handleTagInsertOp(tag, index, op.insert)
      }
    })

    if (tag) {
      setUnsavedTag({ ...tag })
    } else {
      setMode(Mode.Text)
    }
  }

  const onChange = (
    changes: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    switch (mode) {
      case Mode.Text:
        onChangeInText(changes, source, editor)
        setForceFocus(false)
        break
      case Mode.Tag:
        onChangeInTag(changes, source)
        break
    }
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Escape' && mode === Mode.Tag) {
      setMode(Mode.Text)
      setUnsavedTag(null)
      quillRef.current?.getEditor()?.focus()
    }
  }

  const onAfterSubmit = () => setHasTags(false)

  const tagSelector: React.ReactNode = (
    <RadioSelect<EmployeeOptionInterface>
      minWidth={width}
      anchorRef={containerRef}
      // We don't want it to show the last selected value
      value={null}
      placement="top"
      searchable
      onKeyDown={onKeyDown}
      options={filteredOptions}
      labelList="users"
      open={mode === Mode.Tag}
      indicatorStyle="highlight"
      onChange={employee => {
        if (employee && unsavedTag) {
          addTag(unsavedTag.index, employee)
          setForceFocus(true)
        }
        setMode(Mode.Text)
        setUnsavedTag(null)
      }}
      flip
    >
      {option => <UserWithAvatar {...option.value} asText />}
    </RadioSelect>
  )

  return {
    onChange,
    tagSelector,
    transformValueForSubmit,
    onKeyDown,
    forceFocus,
    hasTags,
    onAfterSubmit,
  }
}

export default useTagging
