import React, { useEffect, useState } from 'react'
import {
  InputGroup,
  Header,
  Text,
  Flex,
  DetailsCell,
  Link,
  Popup,
  Color,
  Box,
  Button,
} from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { connect } from 'lape'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EmployeeInterface,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
} from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { onboardingSteps } from '@src/interfaces/onboarding'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { BackButton, NextButton, TabProps, useOnboardingPreviewMode } from '../common'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { onboardingGetEmailPropositions } from '@src/api/onboarding'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { Statuses } from '@src/interfaces'
import { useGetLifecycleSettings } from '@src/api/settings'
import { SectionOptions } from '@src/interfaces/customFields'

const BasicInfo = ({ data, dynamicGroups, onComplete }: TabProps) => {
  const { values, submit } = useLapeContext<EmployeeInterface>()
  const formValidator = useSafeFormValidator()

  const { data: lifecycleSettings } = useGetLifecycleSettings()
  const contactHREmail = lifecycleSettings?.contact_hr_email
  const enableEmailSelector = !!lifecycleSettings?.enable_email_selector

  const [preferredEmails, setPreferredEmails] = useState<string[]>([])
  const [emailConfirmationVisible, setEmailConfirmationVisible] = useState(false)

  const isStepComplete =
    data.onboarding_steps.find(step => step.type === onboardingSteps.basic_info)?.status
      .id === Statuses.completed
  const contactHRLink = `mailto:${contactHREmail}`

  useEffect(() => {
    if (!values.id && values.first_name && values.last_name) {
      values.display_name = `${values.first_name} ${values.last_name}`
    }
  }, [values.first_name, values.last_name])

  const isPreview = !!useOnboardingPreviewMode().config

  useEffect(() => {
    if (isPreview) {
      return
    }
    if (!isStepComplete && enableEmailSelector) {
      onboardingGetEmailPropositions(values.id).then(response =>
        setPreferredEmails(response.data),
      )
    }
  }, [enableEmailSelector])

  const navigateToNextStep = () => {
    navigateTo(pathToUrl(ROUTES.ONBOARDING.PERSONAL_INFO, { id: data.id }))
    onComplete(onboardingSteps.basic_info)
  }

  const onSubmit = formValidator.validate(() => {
    return submit().then(result => {
      navigateTo(pathToUrl(ROUTES.ONBOARDING.PERSONAL_INFO, { id: data.id }))
      onComplete(onboardingSteps.basic_info)
      return result
    })
  })

  const onNextButtonClick = () => {
    if (!isStepComplete && enableEmailSelector && values.email) {
      setEmailConfirmationVisible(true)
    } else if (isPreview) {
      navigateTo(pathToUrl(ROUTES.ONBOARDING.PERSONAL_INFO, { id: data.id }))
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <PageBody pb="s-24">
        <AutoStepper>
          <Header variant="main" mb="s-24">
            <Header.Title>Basic</Header.Title>
            {contactHREmail && (
              <Header.Subtitle>
                Please verify that the below information matches with your government
                issued identification document. If this doesn't look right, please write
                to{' '}
                <Text
                  use="a"
                  href={contactHRLink}
                  target="_blank"
                  color="primary"
                  textDecoration="none"
                >
                  {contactHREmail}
                </Text>
                .
              </Header.Subtitle>
            )}
          </Header>

          <NewStepperTitle title="Legal names" />
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BASIC_INFO}
            dynamicGroups={dynamicGroups}
            useExternalRequests
          >
            <InputGroup>
              <LapeNewInput
                name="first_name"
                label="Legal first name"
                message="Your first name as it appears on your identification documents"
                hideOptional
              />
              <LapeNewInput
                name="middle_name"
                label="Legal middle name"
                message="Your middle name as it appears on your identification documents"
              />
              <LapeNewInput
                name="last_name"
                label="Legal last name"
                message="Your last name as it appears on your identification documents"
                hideOptional
              />
            </InputGroup>
          </NewStepperSectionCustomFields>

          <NewStepperTitle title="Preferred name" />
          <LapeNewInput
            name="display_name"
            message="The name you prefer to be called. E. g. your legal first name is Jonathan, but your friends call you Jon"
            label="Preferred name"
          />

          {enableEmailSelector ? (
            <>
              <NewStepperTitle
                title="Company email"
                subtitle={
                  isStepComplete || !contactHREmail ? null : (
                    <>
                      Please choose the preferred email address from the list of available
                      ones below. If you spot any major problem with the proposed options,
                      please write to{' '}
                      <Link href={contactHRLink} target="_blank">
                        {contactHREmail}
                      </Link>
                    </>
                  )
                }
              />
              {isStepComplete ? (
                <DetailsCell>
                  <DetailsCell.Title>Chosen email address</DetailsCell.Title>
                  <DetailsCell.Content>{values.email}</DetailsCell.Content>
                </DetailsCell>
              ) : (
                <LapeNewRadioButtons
                  name="email"
                  options={preferredEmails.map(email => ({
                    value: email,
                    label: email,
                  }))}
                  variant="group-items"
                />
              )}
            </>
          ) : null}
        </AutoStepper>
      </PageBody>

      <PageActions>
        <Flex gap="s-16">
          <BackButton
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.ONBOARDING.START, { id: data.id }))
            }
          />
          <NextButton
            onClick={onNextButtonClick}
            onClickInPreviewMode={onNextButtonClick}
            afterSubmit={navigateToNextStep}
            elevated
          />
        </Flex>
      </PageActions>

      <Popup
        open={emailConfirmationVisible}
        onClose={() => setEmailConfirmationVisible(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet" displayMode="inline">
          <Header.Actions>
            <ExclamationTriangle color={Color.BLUE} size={40} />
          </Header.Actions>
          <Text fontSize="h5">Please confirm your email address preference</Text>
          <Header.Subtitle>
            <Box mt="s-16" mb="-s-16">
              Email chosen:
              <br />
              <Text fontWeight={500}>{values.email}</Text>
              <br />
              <br />
              Important! Once you have confirmed your email address, you will not be able
              to change it
            </Box>
          </Header.Subtitle>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setEmailConfirmationVisible(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setEmailConfirmationVisible(false)
              onSubmit()
            }}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default connect(BasicInfo)
