import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { PageBody } from '@components/Page/PageBody'
import { InputGroup, MoreBar } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { API, selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { NewCandidateInterface } from '@src/interfaces/newCandidate'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import SenioritiesSelect from '@src/pages/Forms/RequisitionForm/General/SenioritiesSelect'
import { LocationInterface, RequisitionInterface } from '@src/interfaces/requisitions'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { requisitionPostingSimpleRequests } from '@src/api/requisitions'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { IdAndName } from '@src/interfaces'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CreateLocationPopup } from '@src/features/GenericEditableTable/CellPopups/CreateLocation'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { CareersPositionPreviewSidebar } from '@components/CareersPositionPreviewSidebar/CareersPositionPreviewSidebar'
import { AboutCompanyWidget } from '@src/pages/OnboardingChecklistV2/Jobs/AboutCompanyWidget'

type CreateNewItemType = 'locations' | 'role'

const NewRequisitionFormComponent = () => {
  const { values } = useLapeContext<RequisitionInterface>()

  const queryClient = useQueryClient()

  const [createNewTypeState, setCreateNewTypeState] =
    useState<{ type: CreateNewItemType }>()
  const [openPreview, setOpenPreview] = useState(false)

  // prefilling with the most common value (1) to help users complete the form faster
  if (values.headcount === undefined) {
    values.headcount = 1
  }

  const invalidateSelector = (name: selectorKeys) => {
    queryClient.invalidateQueries([API.SELECTORS, 'v1', { params: { name } }])
  }

  return (
    <>
      <PageHeader
        title="Add requisition"
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.IMPORT}
      />
      <PageBody>
        <AutoStepperTitle title="General details" />
        <InputGroup>
          <LapeRadioSelectInput
            name="specialisation"
            label="Role"
            selector={selectorKeys.approved_pending_specialisations}
            showCreateNewButton
            onChange={option => {
              if (option?.id === createNewKey) {
                setCreateNewTypeState({ type: 'role' })
              } else {
                values.specialisation = option as SpecialisationInterface
              }
            }}
            useQuery
          />
          <LapeNewInput
            required
            name="requisition_title"
            label="Requisition title"
            message="For example: “Android developer”"
          />
          <InputGroup variant="horizontal">
            <LapeRadioSelectInput<IdAndName>
              name="team"
              required
              label="Team"
              selector={selectorKeys.team}
              message="Candidate(s) will be hired to this team "
            />
            <LapeNewInput
              name="headcount"
              label="Headcount"
              type="number"
              required
              message="New hires amount"
            />
          </InputGroup>
          <SenioritiesSelect />
          <LapeNewMultiSelect<LocationInterface>
            name="locations"
            placeholder="Locations"
            required
            selector={selectorKeys.location}
            showCreateNewButton
            onChange={options => {
              if ((options[0]?.value.id as string | number) === createNewKey) {
                setCreateNewTypeState({ type: 'locations' })
              } else {
                values.locations = options.map(opt => opt.value)
              }
            }}
            clearable
            useQuery
          />
          <LapeRadioSelectInput
            name="recruiter"
            label="Recruiter"
            selector={selectorKeys.employee}
          />
        </InputGroup>

        <NewStepperTitle title="Job description" />
        <InputGroup>
          <AboutCompanyWidget />
          <LapeHTMLEditor
            name="job_description"
            placeholder="Job description"
            height={350}
            required
            addMarginToParagraphs
          />
          <MoreBar>
            <MoreBar.Action useIcon="Play" onClick={() => setOpenPreview(true)}>
              Show preview
            </MoreBar.Action>
          </MoreBar>
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<NewCandidateInterface>
          successText="New requisition added successfully"
          useValidator
          afterSubmitUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.IMPORT}
        />
      </PageActions>

      <CreateLocationPopup
        open={createNewTypeState?.type === 'locations'}
        onSuccess={location => {
          invalidateSelector(selectorKeys.location)
          values.locations = [...(values.locations || []), location as LocationInterface]
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        defaultToMaxSeniorityRange
        open={createNewTypeState?.type === 'role'}
        onSuccess={specialisation => {
          invalidateSelector(selectorKeys.specialisations)
          values.specialisation = specialisation as SpecialisationInterface
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CareersPositionPreviewSidebar
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        text={values.specialisation?.name}
        locations={values.locations || []}
        description={`<b>About the role</b><br />${values.job_description || ''}`}
      />
    </>
  )
}

export const NewRequisitionForm = connect(() => (
  <Form api={requisitionPostingSimpleRequests}>
    <NewRequisitionFormComponent />
  </Form>
))
