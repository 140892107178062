import React, { useState } from 'react'
import { MoreBar, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { refreshInterviewSlots } from '@src/api/interviewAvailability'
import { FormattedMessage } from 'react-intl'

type InterviewAvailabilitySyncActionProps = {
  employeeId: string | number
  onSuccess: () => void
}

export const InterviewAvailabilitySyncAction = ({
  employeeId,
  onSuccess,
}: InterviewAvailabilitySyncActionProps) => {
  const [pending, setPending] = useState(false)
  const statusPopup = useStatusPopup()
  const handleSyncCalendar = async () => {
    setPending(true)
    try {
      await refreshInterviewSlots(employeeId)
      onSuccess()
    } catch {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            Failed to refresh the slots from your calendar
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setPending(false)
    }
  }
  return (
    <MoreBar.Action
      pending={pending}
      useIcon="Retry"
      onClick={() => {
        handleSyncCalendar()
      }}
    >
      <FormattedMessage
        id="recruitment.interviewAvailability.actions.syncCalendar"
        defaultMessage="Sync calendar"
      />
    </MoreBar.Action>
  )
}
