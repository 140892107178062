import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import {
  useGetOrganisationSettings,
  useGlobalSettings,
  useGetRoadmapSettings,
  useGetRequisitionSettings,
} from '@src/api/settings'
import { FieldOptions } from '@src/interfaces'
import { useMemo } from 'react'

export const useCanView = (data?: { id: number; field_options: FieldOptions }) => {
  const { data: settings } = useGetOrganisationSettings()
  const {
    settings: { engagement_enabled },
  } = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: requisitionSettings } = useGetRequisitionSettings()

  return useMemo(
    () => ({
      canViewRisk:
        !!data?.id &&
        !!settings?.risk?.enabled &&
        data.field_options.permissions?.includes(EntityPermissions.ViewKri),
      canViewEngagement:
        !!data?.id &&
        engagement_enabled &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
      // TODO: hiding CX tab until decision to remove or bring it back is made
      canViewCX: false, // !data?.id && !!settings?.customer_experience?.enabled,
      canViewTalent: !!data?.id,
      canViewRequisitions: !!data?.id && !!requisitionSettings?.enabled,
      canViewLeadership: !!data?.id && !!settings?.enable_leadership,
      canViewAnalytics: !!data?.id,
      canViewRoadmap: !!data?.id && !!roadmapSettings?.enabled,
      canViewIssues:
        !!data?.id &&
        (!!settings?.bugs_tracking?.enabled || !!settings?.findings?.enabled),
    }),
    [data, permissions, roadmapSettings, featureFlags, engagement_enabled, settings],
  )
}
