import React, { useState } from 'react'
import { ActionButton, Avatar, Item, Portal } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import JobPostingPopup from '@src/features/JobPostingFlow/components/JobPostingPopup'
import { ApplicationFormPreview } from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormPreview'

type ReuseApplicationFormProps = {
  specialisationId?: number | string
}

export const ReuseApplicationForm = ({ specialisationId }: ReuseApplicationFormProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [openJobPostings, setOpenJobPostings] = useState(false)
  return (
    <>
      {openJobPostings && (
        <Portal>
          <JobPostingPopup
            confirmLabel="Reuse form"
            renderJobPosting={ApplicationFormPreview}
            specialisationId={specialisationId}
            titleLabel="Application forms"
            onClose={() => {
              setOpenJobPostings(false)
            }}
            onConfirm={jobPosting => {
              if (jobPosting.application_form_sections) {
                values.application_form_sections = jobPosting.application_form_sections
              }
            }}
          />
        </Portal>
      )}
      <Item
        use="label"
        onClick={() => {
          setOpenJobPostings(true)
        }}
      >
        <Item.Avatar>
          <Avatar useIcon="WizardHat" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Reuse existing application forms </Item.Title>
          <Item.Description>
            Speed up by reusing content from previously posted applications
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton useIcon="Search">Browse applications</ActionButton>
        </Item.Side>
      </Item>
    </>
  )
}
