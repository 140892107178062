import React, { useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import { Button, Header, Side } from '@revolut/ui-kit'
import pluralize from 'pluralize'
import EmailForm from '@src/features/CRM/SendEngagementEmail/EmailForm'
import SlotsForm from '@src/features/CRM/SendEngagementEmail/SlotsForm'
import Form from '@src/features/Form/Form'
import { bulkEngagementEmailsRequests } from '@src/api/CRM'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { SendEngagementEmailInterface } from '@src/interfaces/CRM'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SideBarHeaderTitle } from '@src/components/SideBar/SideBarHeaderTitle'
import BulkStatusPopup from '@src/components/BulkStatusPopup/BulkStatusPopup'
import { ScheduleInterviewProvider } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'

type SendEngagementEmailMode = 'emailForm' | 'slotsForm'

type SendEngagementEmailProps = {
  candidates: {
    candidateId: number
    roundId: number
    stageId: number
  }[]
  open: boolean
  onClose: () => void
}

type SendEngagementEmailHeaderProps = {
  candidateIds: (string | number)[]
  mode: SendEngagementEmailMode
  onClickBack: () => void
  onClickClose: () => void
}

const SendEngagementEmailHeader = ({
  candidateIds,
  mode,
  onClickBack,
  onClickClose,
}: SendEngagementEmailHeaderProps) => {
  const getIcon = () => {
    if (mode === 'emailForm') {
      return (
        <Header.CloseButton
          aria-label="Close"
          onClick={() => {
            onClickClose()
          }}
        />
      )
    }
    return (
      <Header.BackButton
        aria-label="Back"
        onClick={() => {
          onClickBack()
        }}
      />
    )
  }
  const getTitle = () => {
    if (mode === 'emailForm') {
      return `Send email to ${pluralize('candidate', candidateIds?.length || 0, true)}`
    }
    return 'Booking link'
  }
  const getDescription = () => {
    if (mode === 'slotsForm') {
      return 'Confirm the details before sending the email'
    }
    return null
  }
  return (
    <Header variant="item" pb={0}>
      {getIcon()}
      <SideBarHeaderTitle>{getTitle()}</SideBarHeaderTitle>
      <Header.Description>{getDescription()}</Header.Description>
    </Header>
  )
}

const SendEngagementEmail = ({ candidates, open, onClose }: SendEngagementEmailProps) => {
  const { values } = useLapeContext<SendEngagementEmailInterface>()
  if (!values.candidate_ids) {
    values.candidate_ids = candidates.map(({ candidateId }) => candidateId)
    values.interview_round_ids = candidates.map(({ roundId }) => roundId)
  }
  const [mode, setMode] = useState<SendEngagementEmailMode>('emailForm')
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const getSubmitButton = () => {
    if (mode === 'emailForm' && values.add_booking_link) {
      return (
        <Button
          onClick={() => {
            setMode('slotsForm')
          }}
        >
          Continue
        </Button>
      )
    }
    return (
      <NewSaveButtonWithPopup<SendEngagementEmailInterface>
        successText="Email has been sent"
        noPopup
        onAfterSubmit={() => {
          setIsSuccess(true)
        }}
        onSubmitError={e => {
          if (e?.response?.status !== 400) {
            setIsSuccess(false)
          }
        }}
        useValidator
        hideWhenNoChanges={false}
        data-testid="btn-send-email"
      >
        Send engagement email
      </NewSaveButtonWithPopup>
    )
  }
  return (
    <>
      <BulkStatusPopup
        isSuccess={isSuccess}
        onClose={() => {
          setIsSuccess(null)
          onClose()
        }}
      />
      <SideBar
        isOpen={open}
        variant="wide"
        onClose={() => {
          onClose()
        }}
        customHeader={
          <SendEngagementEmailHeader
            mode={mode}
            candidateIds={values.candidate_ids}
            onClickBack={() => {
              setMode('emailForm')
            }}
            onClickClose={onClose}
          />
        }
      >
        {mode === 'emailForm' && <EmailForm />}
        {mode === 'slotsForm' && (
          <ScheduleInterviewProvider>
            <SlotsForm
              roundId={candidates[0]?.roundId}
              stageId={candidates[0]?.stageId}
            />
          </ScheduleInterviewProvider>
        )}
        <Side.Actions horizontal>{getSubmitButton()}</Side.Actions>
      </SideBar>
    </>
  )
}

export default (props: SendEngagementEmailProps) => {
  const { candidates } = props
  if (!props.open) {
    return null
  }
  return (
    <Form
      api={bulkEngagementEmailsRequests}
      forceParams={{
        new: 'new',
        candidateId: String(candidates?.[0].candidateId),
      }}
      disableLocalStorageCaching
    >
      <SendEngagementEmail {...props} />
    </Form>
  )
}
