import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ActionButton, Box, Flex, VStack } from '@revolut/ui-kit'
import { requestEditScorecard } from '@src/api/pip'
import { Statuses } from '@src/interfaces'
import {
  FinalGrade,
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import AvatarItemSkeleton from '@components/Skeletons/AvatarItemSkeleton'
import PerformanceGrade from '@components/PerformanceGrade/PerformanceGrade'
import { relationToString } from '@src/features/Scorecard/constants'
import { getUpdatedTimeReview } from '@src/utils/performance'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { EditScorecardPopup } from './EditScorecardPopup'
import { ScorecardComments } from './ScorecardComments'
import { GradesMapInterface } from '@src/utils/grades'

const PerformanceGradeAligned = styled(PerformanceGrade)`
  text-align: right;
  font-size: 16px;
  flex-shrink: 0;
  align-self: center;
`

export interface GradedRequestInterface
  extends Omit<PerfReviewRequestFeedbackInterface, 'updated_date'> {
  grade?: FinalGrade
  updated_date?: string
}

type Props = {
  canViewComments?: boolean
  category?: ReviewCategory
  checkpointStatus?: Statuses
  cycleId: string
  employeeId: number
  loading?: boolean
  reviews?: GradedRequestInterface[]
  onAfterCommentsClick?: () => void
  gradesMap?: GradesMapInterface
}

export const CheckpointReviewers = ({
  canViewComments,
  category,
  checkpointStatus,
  cycleId,
  employeeId,
  loading,
  reviews,
  onAfterCommentsClick,
  gradesMap,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [editScorecardId, setEditScorecardId] = useState<number | null>(null)
  const [viewScorecardId, setViewScorecardId] = useState<number | null>(null)

  const permissions = useSelector(selectPermissions)
  const canManagePIPCycle = permissions.includes(PermissionTypes.ManagePipCycles)
  const canManagePIPCommitteeDecision = permissions.includes(
    PermissionTypes.ManagePipCommitteeDecision,
  )

  if (loading) {
    return (
      <Box>
        <AvatarItemSkeleton px={0} />
        <AvatarItemSkeleton px={0} />
      </Box>
    )
  }

  if (!reviews) {
    return null
  }

  const handleRequestEdit = async (scorecardId: number, commentValue: string) => {
    setIsSubmitting(true)
    try {
      await requestEditScorecard(employeeId, cycleId, scorecardId, commentValue)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onRequestEdit = (scorecardId: number | null) => {
    setEditScorecardId(scorecardId)
  }

  const onViewComments = (scorecardId: number | null) => {
    setViewScorecardId(scorecardId)
  }

  return (
    <Box width="100%">
      <VStack gap="s-20">
        {reviews.map(item => (
          <Flex alignItems="flex-start" justifyContent="space-between" key={item.id}>
            <AvatarSnippet
              avatar={item.reviewer.avatar}
              status={item.status}
              fullName={item.reviewer.full_name}
              label={`(${relationToString(item.reviewer_relation, true)})`}
              text={getUpdatedTimeReview(item.updated_date, item.status)}
              noItemWrap
            />
            <Flex>
              {canManagePIPCommitteeDecision && (
                <PerformanceGradeAligned
                  grade={item.grade}
                  inverse
                  gradesMap={gradesMap}
                />
              )}
              {category === ReviewCategory.PIP_V2 &&
                checkpointStatus !== Statuses.completed && (
                  <Flex ml="s-16" gap="s-8">
                    {canViewComments && (
                      <ActionButton
                        onClick={() => {
                          onViewComments(item.id!)
                          onAfterCommentsClick?.()
                        }}
                        useIcon="Chat"
                      >
                        Comments
                      </ActionButton>
                    )}
                    {(canManagePIPCycle || canManagePIPCommitteeDecision) && (
                      <ActionButton
                        variant="negative"
                        pending={isSubmitting}
                        onClick={() => onRequestEdit(item.id!)}
                        useIcon="Pencil"
                      >
                        Request edit
                      </ActionButton>
                    )}
                  </Flex>
                )}
            </Flex>
          </Flex>
        ))}
      </VStack>
      {viewScorecardId && (
        <ScorecardComments
          onClose={() => setViewScorecardId(null)}
          scorecardId={viewScorecardId}
        />
      )}
      {editScorecardId && (
        <EditScorecardPopup
          onClose={() => setEditScorecardId(null)}
          onSubmit={async (commentValue: string) => {
            await handleRequestEdit(editScorecardId, commentValue)
            setEditScorecardId(null)
          }}
        />
      )}
    </Box>
  )
}
