import { useCallback, useState } from 'react'
import {
  useGetEligibleEmployeesStats,
  useTriggerValidateScorecards,
} from '@src/api/supportTool/eligibleEmployees'

interface ScorecardsValidation {
  progress: number
  isLoading: boolean
  isValid: boolean
  isPending: boolean
  isCompleted: boolean
  isFailed: boolean
  canValidate: boolean
  validate: () => Promise<void>
}

enum ValidationStatus {
  None,
  Pending,
  Completed,
  Failed,
}

export const useScorecardsValidation = (
  cycleId: number | string,
): ScorecardsValidation => {
  const [validationStatus, setValidationStatus] = useState(ValidationStatus.None)
  const [progress, setProgress] = useState(0)

  const isPending = validationStatus === ValidationStatus.Pending
  const isCompleted = validationStatus === ValidationStatus.Completed
  const isFailed = validationStatus === ValidationStatus.Failed

  const {
    data: stats,
    isLoading,
    refetch,
  } = useGetEligibleEmployeesStats(cycleId, {
    refetchIntervalInBackground: true,
    refetchInterval: data =>
      data
        ? isPending && data.valid + data.invalid !== data.total
          ? 5000
          : false
        : false,
    onSuccess: data => {
      if (data.total > 0 && isPending) {
        const currentProgress = (data.valid + data.invalid) / data.total
        setProgress(currentProgress)

        if (currentProgress === 1) {
          setValidationStatus(ValidationStatus.Completed)
          setProgress(0)
        }
      }
    },
    onError: _error => {
      setValidationStatus(ValidationStatus.Failed)
      setProgress(0)
    },
  })
  const { mutateAsync: triggerValidation } = useTriggerValidateScorecards()

  const canValidate = Number(stats?.total) > 0
  const isValid = stats?.invalid === 0 && stats?.in_progress === 0 && stats?.total > 0

  const validate = useCallback(async () => {
    setValidationStatus(ValidationStatus.Pending)

    await triggerValidation({ cycle_id: cycleId })
    await refetch()
  }, [cycleId])

  return {
    progress,
    isLoading,
    isValid,
    isPending,
    isCompleted,
    isFailed,
    canValidate,
    validate,
  }
}
