import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetMonthDays } from '@src/api/calendar'
import { VStack, Text, Color, InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import React, { useEffect } from 'react'
import { OptionInterface, SelectorType } from '@src/interfaces/selectors'
import { ScheduleInterface } from '@src/interfaces/schedule'
import { getCurrentTimezone } from '@src/utils/timezones'
import { useGetSelectors } from '@src/api/selectors'
import { Timezone } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'

const mapDaysToOptionsSelector = (days?: { options: OptionInterface[] }) => () =>
  Promise.resolve(days?.options || [])

type Props = {
  width?: string
  selector?: SelectorType
}
const ScheduleWidget = ({ width, selector }: Props) => {
  const { values } = useLapeContext<ScheduleInterface>()
  const currentTimezoneId = getCurrentTimezone()

  const { data: timezones } = useGetSelectors<Timezone>(selectorKeys.timezones)

  const timeZoneId = values.send_on_timezone
    ? String(values.send_on_timezone.id)
    : currentTimezoneId

  useEffect(() => {
    const currentTimezone = timezones?.find(item => item.id === timeZoneId)

    if (
      currentTimezone &&
      values.sent_every &&
      values.sent_every.id !== 'manual' &&
      !values.send_on_timezone
    ) {
      values.send_on_timezone = currentTimezone
    }
  }, [timezones, values.sent_every])

  const { data: allMonthDays } = useGetMonthDays(
    values.sent_every?.id === 'year' || values.sent_every?.id === 'month'
      ? values.month?.id || 1 // using Jan to get most days
      : undefined,
  )

  const { data: firstQuarterMonthDays } = useGetMonthDays(values.first_quarter_month?.id)
  const { data: secondQuarterMonthDays } = useGetMonthDays(
    values.second_quarter_month?.id,
  )
  const { data: thirdQuarterMonthDays } = useGetMonthDays(values.third_quarter_month?.id)
  const { data: fourthQuarterMonthDays } = useGetMonthDays(
    values.fourth_quarter_month?.id,
  )

  useEffect(() => {
    if (!values.send_on_time) {
      values.send_on_time = '1970-01-01T00:00:00'
    }
  }, [])

  const sentEveryId = values.sent_every?.id

  return (
    <VStack space="s-16" width={width}>
      <LapeRadioSelectInput
        required
        name="sent_every"
        label="Sent every"
        selector={selector || selectorKeys.engagement_settings_sent_every_options}
      />
      {(sentEveryId === 'week' || sentEveryId === 'two_weeks') && (
        <LapeRadioSelectInput
          required
          name="day_of_week"
          label="Sent on"
          selector={selectorKeys.days_of_the_week}
        />
      )}
      {sentEveryId === 'month' && (
        <LapeRadioSelectInput
          required
          name="day_of_month"
          label="Day of the month"
          selector={mapDaysToOptionsSelector(allMonthDays)}
        />
      )}
      {sentEveryId === 'quarter' && (
        <VStack space="s-16" py="s-16">
          <Text variant="h6" color={Color.GREY_TONE_50}>
            Quarter 1
          </Text>
          <InputGroup variant="horizontal" data-testid="1_quarter">
            <LapeRadioSelectInput
              required
              name="first_quarter_month"
              label="Month"
              selector={selectorKeys.quarter_1_months}
            />
            <LapeRadioSelectInput
              required
              name="first_quarter_day"
              label="Day of the month"
              selector={mapDaysToOptionsSelector(firstQuarterMonthDays)}
            />
          </InputGroup>
          <Text variant="h6" color={Color.GREY_TONE_50}>
            Quarter 2
          </Text>
          <InputGroup variant="horizontal" data-testid="2_quarter">
            <LapeRadioSelectInput
              required
              name="second_quarter_month"
              label="Month"
              selector={selectorKeys.quarter_2_months}
            />
            <LapeRadioSelectInput
              required
              name="second_quarter_day"
              label="Day of the month"
              selector={mapDaysToOptionsSelector(secondQuarterMonthDays)}
            />
          </InputGroup>
          <Text variant="h6" color={Color.GREY_TONE_50}>
            Quarter 3
          </Text>
          <InputGroup variant="horizontal" data-testid="3_quarter">
            <LapeRadioSelectInput
              required
              name="third_quarter_month"
              label="Month"
              selector={selectorKeys.quarter_3_months}
            />
            <LapeRadioSelectInput
              required
              name="third_quarter_day"
              label="Day of the month"
              selector={mapDaysToOptionsSelector(thirdQuarterMonthDays)}
            />
          </InputGroup>
          <Text variant="h6" color={Color.GREY_TONE_50}>
            Quarter 4
          </Text>
          <InputGroup variant="horizontal" data-testid="4_quarter">
            <LapeRadioSelectInput
              required
              name="fourth_quarter_month"
              label="Month"
              selector={selectorKeys.quarter_4_months}
            />
            <LapeRadioSelectInput
              required
              name="fourth_quarter_day"
              label="Day of the month"
              selector={mapDaysToOptionsSelector(fourthQuarterMonthDays)}
            />
          </InputGroup>
        </VStack>
      )}
      {sentEveryId === 'year' && (
        <InputGroup variant="horizontal">
          <LapeRadioSelectInput
            required
            name="month"
            label="Month"
            selector={selectorKeys.months}
          />
          <LapeRadioSelectInput
            required
            name="day_of_month"
            label="Day"
            selector={mapDaysToOptionsSelector(allMonthDays)}
          />
        </InputGroup>
      )}
      {sentEveryId === 'quarter' && (
        <Text variant="h6" color={Color.GREY_TONE_50}>
          Time
        </Text>
      )}
      {sentEveryId && sentEveryId !== 'manual' && (
        <InputGroup variant="horizontal">
          <LapeNewTimeInput name="send_on_time" label="Time" required />
          <LapeRadioSelectInput
            selector={selectorKeys.timezones}
            label="Timezone"
            name="send_on_timezone"
          />
        </InputGroup>
      )}
    </VStack>
  )
}

export default ScheduleWidget
