import React from 'react'
import { Box } from '@revolut/ui-kit'
import ReviewersFilter from '@src/pages/EmployeeProfile/Preview/Performance/Summary/ReviewersFilter'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import CheckpointsFilter from '@src/pages/EmployeeProfile/Preview/Performance/Summary/CheckpointsFilter'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { useGetPipCheckpoints } from '@src/api/pip'
import { OptionInterface } from '@src/interfaces/selectors'
import { useNewPerformanceSummary } from '@src/pages/EmployeeProfile/Layout/Performance/useNewPerformanceSummary'

type Props = {
  cycleId: string
  onFilterChange?: (options: OptionInterface[] | undefined) => void
}

const Filters = ({ cycleId, onFilterChange }: Props) => {
  const form = useLapeContext<ReviewSummaryInterface>()
  const isNewPerformanceSummary = useNewPerformanceSummary()

  const { initialValues } = form
  const review = initialValues?.reviews?.[0]
  const employeeId = review?.reviewed_employee?.id
  const { data: probationCheckpoints } = useGetProbationCheckpoints(
    review?.category === ReviewCategory.Probation ? employeeId! : null,
    cycleId,
  )
  const { data: pipCheckpoints } = useGetPipCheckpoints(
    review?.category === ReviewCategory.PIP_V2 ? employeeId! : null,
    cycleId,
  )
  const checkpoints = probationCheckpoints || pipCheckpoints
  const isReviewersAdditionalFilter =
    checkpoints &&
    isNewPerformanceSummary &&
    (initialValues?.reviews?.[0]?.category === ReviewCategory.Probation ||
      initialValues?.reviews?.[0]?.category === ReviewCategory.PIP_V2)

  return (
    <>
      <Box mb="s-16">
        <ReviewersFilter
          isNewDesign={isNewPerformanceSummary}
          data={initialValues.reviews!}
          onFilterChange={onFilterChange}
          showFilters
          additionalFilter={
            isReviewersAdditionalFilter && (
              <CheckpointsFilter
                isNewDesign={isNewPerformanceSummary}
                checkpoints={checkpoints.checkpoints}
                showFilters={false}
              />
            )
          }
        />
      </Box>
      {checkpoints && !isNewPerformanceSummary && (
        <Box mb="s-16">
          <CheckpointsFilter
            checkpoints={checkpoints.checkpoints}
            showFilters={
              initialValues?.reviews?.[0]?.category === ReviewCategory.Probation ||
              initialValues?.reviews?.[0]?.category === ReviewCategory.PIP_V2
            }
          />
        </Box>
      )}
    </>
  )
}

export default Filters
