import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { FinalGrade } from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { GradesMapInterface } from '@src/utils/grades'

export const useGradeMap = (cycle?: Pick<OptionInterface, 'grade_labels_version'>) => {
  const calibrationSelector =
    cycle?.grade_labels_version === 1
      ? selectorKeys.performance_grades
      : selectorKeys.calibration_grades

  const { options: calibrationGradesOptions } = useFetchOptions<{
    id: FinalGrade
    name: string
  }>(calibrationSelector)
  const gradeOptions = calibrationGradesOptions.map(el => {
    if (el.value.id === '_empty') {
      return { id: FinalGrade.None, name: '' }
    }
    return el.value
  })

  const gradesMap: GradesMapInterface = {}
  calibrationGradesOptions.forEach(option => {
    gradesMap[option.value.id] = option.value.name
  })

  return { gradesMap, gradeOptions }
}
