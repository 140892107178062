import React from 'react'
import { Box, Group, HStack, Link, Widget } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { FunctionInterface } from '@src/interfaces/functions'
import { PermissionTypes } from '@src/store/auth/types'
import Stat from '@src/components/Stat/Stat'
import { formatPercentage } from '@src/utils/format'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { useGetOrganisationSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const Summary = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const { data: settings } = useGetOrganisationSettings()
  const showCostControlAssignment = !!settings?.enable_cost_control_assignment
  return (
    <PageBody>
      <Widget p="s-16">
        <HStack space="s-24">
          <Stat label="Headcount" val={values.headcount} />
          <Stat label="Requisitions" val={values.requisition_headcount} />
          <Stat
            label="NIPS"
            val={formatPercentage(values.nips ?? null)}
            color={getPercentColor(values.nips && values.nips * 100)}
          />
        </HStack>
      </Widget>
      <Box mt="s-16">
        <FormPreview<FunctionInterface>
          data={values}
          title="Details"
          onEdit={() =>
            navigateTo(pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS, { id: values.id }))
          }
          hideEdit={d =>
            !d?.field_options?.permissions?.includes(PermissionTypes.ChangeFunction)
          }
        >
          <Group>
            <FormPreview.Item<FunctionInterface> field="name" title="Department name" />
            <FormPreview.Item<FunctionInterface>
              field="owner"
              title="Owner"
              type="employee"
            />
            <FormPreview.Item<FunctionInterface>
              field="delegated_requisition_approver"
              title="Requisition approver"
              type="employee"
            />
            <FormPreview.Item<FunctionInterface>
              field="delegated_offer_approver"
              title="Requisition approver"
              type="employee"
            />
            {showCostControlAssignment && (
              <FormPreview.Item<FunctionInterface>
                field="cost_control"
                title="Cost control"
                type="employee"
              />
            )}
            <FormPreview.Item<FunctionInterface>
              field="documentation_link"
              title="Link to playbooks"
              insert={({ documentation_link }) =>
                documentation_link ? (
                  <Link href={documentation_link} target="_blank">
                    Open
                  </Link>
                ) : (
                  '-'
                )
              }
            />
            <FormPreview.Item<FunctionInterface> field="mission" title="Mission" />
            <CommunicationDetails
              group={values.communication_group}
              linkedGroups={values.linked_communication_groups}
            />
          </Group>
        </FormPreview>
      </Box>
    </PageBody>
  )
}
