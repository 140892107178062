import React from 'react'
import { Box, Color } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ApplicationFormSettingsInterface } from '@src/interfaces/settings'
import { Text } from '@revolut/ui-kit'
import ApplicationFormQuestionContent from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormQuestionContent'
import Tags from '@components/Tags/Tags'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

type Props = {
  sectionIdx: number
  questionIdx: number
}

const ApplicationFormPreviewQuestion = ({ sectionIdx, questionIdx }: Props) => {
  const { values } = useLapeContext<ApplicationFormSettingsInterface>()
  const question = values.global_sections![sectionIdx]?.questions[questionIdx]
  const type = question.question_type.id
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeApplicationFormPreferences,
  )
  const disableDelete = !permissions.includes(
    PermissionTypes.DeleteApplicationFormPreferences,
  )

  return (
    <Box
      mt={questionIdx === 0 ? 's-16' : 's-32'}
      data-testid={`preview_question_${sectionIdx}_${questionIdx}`}
    >
      <Text variant="primary" display="block">
        {questionIdx + 1}. {question?.title || `Question ${questionIdx + 1}`}{' '}
        {question?.optional && <Text color={Color.GREY_TONE_50}>(optional)</Text>}
      </Text>
      {question?.subtitle && (
        <Box mt="s-8">
          <Text variant="caption">{question?.subtitle}</Text>
        </Box>
      )}
      <Box mt="s-8">
        <Tags
          tags={
            question?.locations?.length
              ? question.locations
              : [
                  {
                    id: 'all',
                    name: 'All locations',
                  },
                ]
          }
          title="Shown for"
          variant="outlined"
          color={Color.DEEP_GREY}
        />
      </Box>
      <Box mt="s-16">
        <ApplicationFormQuestionContent
          isPreview
          options={question?.options}
          placeholder={question?.placeholder_text}
          type={type}
          disableEdit={disableEdit}
          disableDelete={disableDelete}
        />
      </Box>
    </Box>
  )
}

export default ApplicationFormPreviewQuestion
