export enum AuditSeverities {
  Blocker = 'blocker',
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Major = 'major',
  Minor = 'minor',
  Improvement = 'improvement',
}

export enum AuditTypes {
  AUDIT = 'audit',
  RISK = 'risk',
  VALIDATION = 'model',
  LEGAL = 'legal',
  SECURITY = 'security',
  COMPLIANCE_ASSURANCE = 'compliance',
  COMPLAINTS = 'complaints',
  OTHER = 'other',
}

export enum AuditStatuses {
  DONE = 'Done',
  IN_PROGRESS = 'In Progress',
  TO_DO = 'To Do',
  PENDING_FILING = 'Pending initiative filing',
  QUALITY_REVIEW = 'Quality review',
}

export enum AuditLatenessText {
  Overdue = 'Overdue',
  Very_old = 'Not specified',
  Soon_overdue = 'ETA < 14d',
  All_other = 'ETA > 14d',
}

export interface AuditInterface {
  assignee_name: string
  due_date: string
  created_date_time: string
  issue_id: string
  issue_lateness: string
  issue_link: string
  severity: { id: AuditSeverities; name: AuditSeverities }
  severity_v2: { id: AuditSeverities; name: AuditSeverities }
  summary: string
  team_name: string
  category: AuditTypes
  bonus_impact: number
  parent_issue_key: string
  parent_issue_link: string
}

export interface AuditBugCountsInterface {
  blocker: number
  bug_critical: number
  bug_high: number
  bug_low: number
  bug_medium: number
  complaints_critical: number
  complaints_high: number
  complaints_low: number
  complaints_medium: number
  critical: number
  improvement: number
  major: number
  minor: number
  high: number
  low: number
  medium: number
}

export interface AuditCyclesInterface {
  audits_critical: number
  audits_exceptional: number
  audits_recommended: number
  audits_trivial: number
}
