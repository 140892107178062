import { AxiosError } from 'axios'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { stageToLabelMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/constants'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const useSwitchStageStatusPopup = () => {
  const showStatusPopup = useShowStatusPopup()

  const showSwitchNextCompleted = (stage: ReviewCycleStage) => {
    showStatusPopup({
      status: 'success',
      title: `${stageToLabelMap[stage]} period completed`,
    })
  }

  const showSwitchNextFailed = (stage: ReviewCycleStage, err: AxiosError) => {
    showStatusPopup({
      status: 'error',
      title: `${stageToLabelMap[stage]} period not completed`,
      description: getStringMessageFromError(err, 'Please try again later'),
    })
  }

  return { showSwitchNextCompleted, showSwitchNextFailed }
}
