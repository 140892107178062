import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, InputGroup } from '@revolut/ui-kit'
import addYears from 'date-fns/addYears'
import addDays from 'date-fns/addDays'
import isBefore from 'date-fns/isBefore'

import { EmployeeInterface, TerminationInterface } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import { goBack } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import EmployeeDocSection from '@src/features/FormTabs/Employee/EmployeeDocSectionLape'
import { terminationRequestsV2 } from '@src/api/employees'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { getDate, getTimeFromString } from '@src/utils/timezones'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Form from '@src/features/Form/Form'
import { useNonFieldValidationErrors } from './hooks'
import useIsCommercial from '@src/hooks/useIsCommercial'

export interface TerminationProps {
  data: EmployeeInterface
  refreshData: () => void
}

const Termination = ({ data, refreshData }: TerminationProps) => {
  const { values } = useLapeContext<TerminationInterface>()
  const params = useParams()
  const isCommercial = useIsCommercial()

  const isInternal = data.employee_type === 'internal'

  useEffect(() => {
    if (data?.location?.timezone) {
      values.termination_date_timezone = data?.location?.timezone
    }
  }, [data])

  const onAfterSubmit = () => {
    refreshData()
    goBack(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params))
  }
  const errorHandler = useNonFieldValidationErrors(data)

  const lastDayOfContractBeforeLastDayInOffice = Boolean(
    values.last_day_of_contract != null &&
      values.termination_date_time != null &&
      isBefore(
        addDays(new Date(values.last_day_of_contract), 1),
        new Date(values.termination_date_time),
      ),
  )

  let disabledDays

  if (!isCommercial) {
    disabledDays = isInternal
      ? { before: new Date() }
      : { after: addYears(new Date(), 1) }
  }

  return (
    <>
      <PageBody>
        <AutoStepper pb="s-16">
          <Box mb="s-24">
            <NewStepperTitle title="Termination details" />
            <InputGroup>
              <LapeDatePickerInput
                onChange={value => {
                  if (!value) {
                    return
                  }
                  const time = values.termination_date_time
                    ? getTimeFromString(values.termination_date_time)
                    : '18:00'
                  values.termination_date_time = `${getDate(value)}T${time}`
                }}
                disabledDays={disabledDays}
                clearable={false}
                name="termination_date_time"
                displayInUtc={false}
                label={isInternal ? 'Last day in office' : 'Termination date'}
                message={
                  isInternal
                    ? 'The day employee loses access to office & all corresponding platform access. ' +
                      'Before entering the date, please make sure that it was aligned both with employee and employee’s line manager.'
                    : 'Please select the date up to one year ahead starting from the start date. Once the day is reached, employee will be automatically set as inactive.'
                }
                required
              />
              <InputGroup variant="horizontal">
                <LapeNewTimeInput name="termination_date_time" label="Time" required />
                <LapeRadioSelectInput
                  selector={selectorKeys.timezones}
                  label="Timezone"
                  name="termination_date_timezone"
                />
              </InputGroup>
              {isInternal ? (
                <LapeDatePickerInput
                  name="last_day_of_contract"
                  label="Last day of contract"
                  hasError={lastDayOfContractBeforeLastDayInOffice}
                  message="Day on which employee’s employment contract ends. This date cannot be prior to the last day in office."
                  required
                />
              ) : null}
              <LapeRadioSelectInput
                name="termination_type"
                label="Termination type"
                selector={selectorKeys.termination_type}
              />
              {isInternal ? (
                <LapeRadioSelectInput
                  name="termination_reason"
                  label="Termination reason"
                  selector={selectorKeys.termination_reason}
                />
              ) : null}
            </InputGroup>

            {isInternal && (
              <>
                <NewStepperTitle title="Documents" />
                <InputGroup>
                  <EmployeeDocSection
                    initialValues={{
                      category: {
                        id: 14,
                        name: 'Termination Documentation - Mutual Separation Agreement',
                      },
                      confidentiality: { id: 'hr_manager', name: 'HR Manager' },
                    }}
                    employee={data}
                  />
                </InputGroup>
              </>
            )}
          </Box>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Employee termination was submitted"
          onAfterSubmit={onAfterSubmit}
          errorHandler={errorHandler}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default (props: TerminationProps) => (
  <Form api={terminationRequestsV2}>
    <Termination {...props} />
  </Form>
)
