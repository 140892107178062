import React, { useEffect } from 'react'
import { Box, Layout, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'

import Sidebar from './SideBar/SideBar'
import { PinnedAppsProvider } from '@src/pages/Hub/Apps/common'
import { PinnedTabsProvider } from '@src/features/TabPinning/pinnedTabs'
import { DemoModeWarningBar } from '@src/features/DemoMode/DemoModeWarningBar'
import { BugReportSide } from '@components/HelpCenter/components/BugReportSide'
import bugReportSideState from '@components/HelpCenter/components/BugReportSideState'
import { Warning } from '@src/features/MainHeader/Warning'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { useCoreNavigation } from './useCoreNavigation'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'

const withDemoBarCss = css`
  @media ${Token.media.md} {
    top: 30px;
    height: calc(100vh - 30px);
  }
`

export const MainLayout: React.FC = ({ children }) => {
  const { visible } = useBanner()
  const appTheme = useAppTheme()
  const isCoreNavigation = useCoreNavigation()

  useEffect(() => {
    appTheme?.setTopNavEnabled(true)

    return () => {
      appTheme?.setTopNavEnabled(false)
    }
  }, [])

  return (
    <Box width="100%">
      <DemoModeWarningBar />
      <PinnedAppsProvider>
        <PinnedTabsProvider>
          <Layout variant="container(wide) main(wide) side(wide)">
            <Layout.Menu css={visible ? withDemoBarCss : undefined}>
              <Sidebar />
            </Layout.Menu>
            <Layout.Main
              style={{
                isolation: 'isolate',
                paddingTop: isCoreNavigation ? 0 : undefined,
              }}
            >
              {children}
            </Layout.Main>
            <Layout.Side />
            {isCoreNavigation ? <Warning /> : null}
            <BugReportSide
              open={bugReportSideState.open}
              onClose={() => {
                bugReportSideState.open = false
              }}
            />
          </Layout>
        </PinnedTabsProvider>
      </PinnedAppsProvider>
    </Box>
  )
}
