import { api } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import { filterSortPageIntoQuery } from '../utils/table'
import {
  BudgetStats,
  CompanyBudgetStats,
  FetchDataQueryInterface,
} from '../interfaces/data'
import { AxiosPromise } from 'axios'
import { GetRequestInterface } from '../interfaces'
import { BudgetInterface, CompanyBudgetInterface } from '../interfaces/budgets'
import { PerformanceChartCycles } from '../interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'

export const getBudgetsForCompanyProfile = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<CompanyBudgetInterface>> =>
  api.get(`${API.DEPARTMENT}/budget`, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters, page),
    },
  })

export const getBudgetsForCompanyProfileStat = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<CompanyBudgetStats> =>
  api.get(`${API.DEPARTMENT}/budget/stats`, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters, page),
    },
  })

export const getBudgetsForCompanyProfileGraph = (
  _: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.COMPANY}/cyclesSpending`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getBudgetsForDepartment = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<BudgetInterface>> =>
  api.get(`${API.TEAMS}/budget`, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters, page),
    },
  })

export const getBudgetsForDepartmentGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.DEPARTMENT}/${id}/cyclesSpending`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getBudgetsForRolesGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.ROLES}/${id}/cyclesSpending`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getBudgetsForSpecialisationsGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.SPECIALISATIONS}/${id}/cyclesSpending`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getTalent = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<BudgetInterface>> =>
  api.get(`${API.EMPLOYEES}/talent`, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters, page),
    },
  })

export const getTalentStat = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<BudgetStats> =>
  api.get(`${API.EMPLOYEES}/talent/stats`, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters, page),
    },
  })

export const getBudgetsGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.TEAMS}/${id}/cyclesSpending`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getBudgetsForFunctionsGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.FUNCTIONS}/${id}/cyclesSpending`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })
