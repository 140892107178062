import React, { Suspense, memo } from 'react'
import { Box, Color, ErrorWidget, Flex, Skeleton, Spinner } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { RowInterface } from '@src/interfaces/data'
import {
  ChartSettings,
  QueryRunResultInterface,
  QueryViewType,
  ReportColumnsTableInterface,
} from '@src/interfaces/dataAnalytics'
import { useTableReturnType } from '@src/components/Table/hooks'
import { Counter } from '@src/pages/Forms/QueryForm/components/Visualisation/Counter'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { VisualisationType } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/common'

const BarChartComponent = React.lazy(() => import('./Visualisation/Bar'))
const ComboChartComponent = React.lazy(() => import('./Visualisation/Combo'))
const BarHorizontalChartComponent = React.lazy(
  () => import('./Visualisation/BarHorizontal'),
)
const BarStackedChartComponent = React.lazy(() => import('./Visualisation/BarStacked'))
const LineChartComponent = React.lazy(() => import('./Visualisation/Line'))
const PieChartComponent = React.lazy(() => import('./Visualisation/Pie'))

interface VisualisationResultProps {
  runId: number
  tab?: QueryViewType
  queryId: number
  chartSettings: ChartSettings
  table: useTableReturnType<QueryRunResultInterface>
  tableRows: RowInterface<QueryRunResultInterface>
  tableColumns: ReportColumnsTableInterface[]
}

interface ChartContentProps {
  chartType?: VisualisationType
  data: QueryRunResultInterface
  chartSettings: ChartSettings
  tableColumns: ReportColumnsTableInterface[]
}

export const ChartContent = ({
  chartType,
  data,
  chartSettings,
  tableColumns,
}: ChartContentProps) => {
  switch (chartType) {
    case 'bar':
      return (
        <BarChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
          colors={chartSettings.colors}
          tableColumns={tableColumns}
        />
      )
    case 'stack':
      return (
        <BarStackedChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
        />
      )
    case 'combo':
      return (
        <ComboChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
          colors={chartSettings.colors}
          tableColumns={tableColumns}
        />
      )
    case 'row':
      return (
        <BarHorizontalChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
          colors={chartSettings.colors}
          tableColumns={tableColumns}
        />
      )
    case 'line':
      return (
        <LineChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
          colors={chartSettings.colors}
          tableColumns={tableColumns}
        />
      )
    case 'pie':
      return (
        <PieChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
        />
      )
    case 'counter':
      return (
        <Counter
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
          title={chartSettings.title}
          subtitle={chartSettings.subtitle}
        />
      )
    default:
      return null
  }
}

export const VisualisationResult = memo(
  ({ tab, chartSettings, table, tableRows, tableColumns }: VisualisationResultProps) => {
    if (table.loading) {
      return (
        <Flex alignItems="center" flex="1 0" justifyContent="center">
          <Spinner size={48} color={Color.BLUE} />
        </Flex>
      )
    }

    if (table.fetchError) {
      return (
        <Flex
          alignItems="center"
          flex="1 0"
          flexDirection="column"
          justifyContent="center"
        >
          <ErrorWidget>
            <ErrorWidget.Title>
              <ExclamationTriangle color={Color.RED} />
            </ErrorWidget.Title>
            <ErrorWidget.Description>{table.fetchError.message}</ErrorWidget.Description>
          </ErrorWidget>
        </Flex>
      )
    }

    return (
      <>
        {tab === 'chart' ? (
          <Flex gap="s-16" height="100%" width="100%">
            <Suspense fallback={<Skeleton />}>
              <ChartContent
                chartType={chartSettings.chartType || 'line'}
                data={table.data}
                chartSettings={chartSettings}
                tableColumns={tableColumns}
              />
            </Suspense>
          </Flex>
        ) : (
          <>
            <Box overflow="auto" width="100%">
              {tableRows.cells.length > 0 ? (
                <AdjustableTable
                  hideCountAndButtonSection
                  name={TableNames.ReportingAppQueriesRunResult}
                  noDataMessage="No data to display"
                  row={tableRows}
                  {...table}
                />
              ) : null}
            </Box>
          </>
        )}
      </>
    )
  },
)
