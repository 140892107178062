import isNumber from 'lodash/isNumber'
import formatDistance from 'date-fns/formatDistance'

import { chain, Token } from '@revolut/ui-kit'
import {
  EngagementDriverInterface,
  EngagementQuestionInterface,
  EngagementSurveyHistoryInterface,
  EngagementSurveyInterface,
  EngagementSurveyQuestionInterface,
} from '@src/interfaces/engagement'
import { Statuses } from '@src/interfaces'

export const mapStatusToColor = (
  data: Partial<
    EngagementSurveyInterface | EngagementDriverInterface | EngagementQuestionInterface
  >,
) => {
  switch (data.status?.id) {
    case Statuses.active:
      return Token.color.green
    case Statuses.scheduled:
      return Token.color.orange
    case Statuses.archived:
    case Statuses.closed:
    case Statuses.completed:
    case Statuses.inactive:
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const mapSurveyRoundStatusToColorV2 = (
  data: Partial<EngagementSurveyInterface>,
) => {
  switch (data.round_status?.id) {
    case Statuses.running:
      return Token.color.success
    case Statuses.planned:
      return Token.color.lightBlue
    default:
      return Token.color.greyTone50
  }
}

export const mapSurveyRoundStatusToColor = (
  data: Partial<EngagementSurveyHistoryInterface>,
) => {
  switch (data.status?.id) {
    case 'ongoing':
      return Token.color.green
    case 'upcoming':
      return Token.color.lightBlue
    case 'terminated':
      return Token.color.red
    default:
      return Token.color.greyTone50
  }
}

export const participationRateToColor = (num?: number | null) => {
  if (!isNumber(num)) {
    return Token.color.greyTone50
  }
  if (num >= 80) {
    return Token.color.green
  }
  if (num >= 50) {
    return Token.color.orange
  }
  return Token.color.red
}

export const overallScoreToColor = (num?: number | null) => {
  if (!isNumber(num)) {
    return Token.color.greyTone50
  }
  if (num >= 80) {
    return Token.color.green
  }
  if (num >= 50) {
    return Token.color.warning
  }
  return Token.color.danger
}

export const formatLastAnswered = (answerDate?: string | null) => {
  return answerDate
    ? `Last asked ${formatDistance(Date.now(), new Date(answerDate))} ago`
    : 'Was not answered yet'
}

export const getQuestionDescription = (question: EngagementQuestionInterface) =>
  chain(
    question.driver.name,
    question.type?.id === 'open_ended' ? 'Open-ended' : 'Rating (1-5 scale)',
  )

export const sortSurveyQuestionsByOrder = (
  questions: EngagementSurveyQuestionInterface[] = [],
) => {
  return questions.reduce<(EngagementQuestionInterface & { parentId?: number })[]>(
    (acc, { order, question, id }, idx) => {
      if (acc[0] && order === 0) {
        // if all order's values are 0 we treat the items as if they don't need any sorting
        acc[idx] = { ...question, parentId: id }
      } else {
        acc[order] = { ...question, parentId: id }
      }
      return acc
    },
    [],
  )
}

export const getScheduleString = (values: Partial<EngagementSurveyInterface>) => {
  const firstQDay = values.first_quarter_day?.name
  const secondQDay = values.second_quarter_day?.name
  const thirdQDay = values.third_quarter_day?.name
  const fourthQDay = values.fourth_quarter_day?.name
  const firstQMonth = values.first_quarter_month?.name
  const secondQMonth = values.second_quarter_month?.name
  const thirdQMonth = values.third_quarter_month?.name
  const fourthQMonth = values.fourth_quarter_month?.name

  if (values.sent_every?.id === 'manual') {
    return values.sent_every?.name
  }
  if (values.sent_every?.id === 'week') {
    return `Every week on ${values.day_of_week?.name}`
  }
  if (values.sent_every?.id === 'two_weeks') {
    return `Every two weeks on ${values.day_of_week?.name}`
  }
  if (values.sent_every?.id === 'month') {
    return `Every month on ${values.day_of_month?.name} day`
  }
  if (values.sent_every?.id === 'quarter') {
    return `Every quarter
 (${firstQDay} of ${firstQMonth},
 ${secondQDay} of ${secondQMonth},
 ${thirdQDay} of ${thirdQMonth},
 ${fourthQDay} of ${fourthQMonth})`
  }
  if (values.sent_every?.id === 'year') {
    return `Every year on ${values.day_of_month?.name} of ${values.month?.name}`
  }
  return ''
}
