import React, { useEffect, useState } from 'react'
import { ActionButton, Box, Flex, Item, Subheader, VStack, Widget } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import EmailTemplatePreview from '@src/pages/Settings/Candidates/EmailTemplatePreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'

const hasNoSchedulingPreferences = (values?: Partial<HiringProcessInterface>) => {
  if (values?.stage_type?.id) {
    return ['cv_screening', 'home_assessment', 'online_test', 'final_round'].includes(
      values.stage_type.id,
    )
  }
  return false
}

export const SchedulingPreferences = () => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const [emailPreviewOpen, setEmailPreviewOpen] = useState<number | string>()
  const handlePreviewTemplate = (templateId?: number | string) => {
    setEmailPreviewOpen(
      !templateId || emailPreviewOpen === templateId ? undefined : templateId,
    )
  }
  useEffect(() => {
    if (hasNoSchedulingPreferences(values) && values.schedule_interview_automatically) {
      values.schedule_interview_automatically = false
      values.exclude_previous_interviewers = false
    }
  }, [values.stage_type])
  if (hasNoSchedulingPreferences(values)) {
    return null
  }
  return (
    <VStack gap="s-16">
      {emailPreviewOpen && (
        <EmailTemplatePreview
          id={emailPreviewOpen}
          onClose={() => {
            handlePreviewTemplate(undefined)
          }}
        />
      )}
      <Subheader>
        <FormattedMessage
          id="recruitment.hiringStageForm.schedulingPreferences.sectionTitle"
          defaultMessage="Scheduling preferences"
        />
      </Subheader>
      <LapeNewSwitch
        itemTypeProps={{
          title: (
            <FormattedMessage
              id="recruitment.hiringStageForm.schedulingPreferences.scheduleInterviewAutomatically.title"
              defaultMessage="Schedule interview automatically"
            />
          ),
          description: (
            <FormattedMessage
              id="recruitment.hiringStageForm.schedulingPreferences.scheduleInterviewAutomatically.description"
              defaultMessage="When enabled, a booking link to the candidate will be sent  once they arrive at this interview stage."
            />
          ),
        }}
        name="schedule_interview_automatically"
      />
      <Widget p="s-16">
        <Item p={0}>
          <Item.Content>
            <Item.Title>
              <Flex alignItems="center">
                <FormattedMessage
                  id="recruitment.hiringStageForm.schedulingPreferences.daysToSuggestSlotsFor.title"
                  defaultMessage="Default amount of days for automated interview slots"
                />
              </Flex>
            </Item.Title>
            <Item.Description>
              <FormattedMessage
                id="recruitment.hiringStageForm.schedulingPreferences.daysToSuggestSlotsFor.description"
                defaultMessage="Define the amount of days for which interview slots will be proposed to the candidate"
              />
            </Item.Description>
          </Item.Content>
        </Item>
        <Box mt="s-8">
          <LapeNewInput
            required={values.schedule_interview_automatically}
            label="Days"
            name="days_to_suggest_slots_for"
            type="number"
          />
        </Box>
      </Widget>
      <Widget p="s-16">
        <Item p={0}>
          <Item.Content>
            <Item.Title>
              <FormattedMessage
                id="recruitment.hiringStageForm.schedulingPreferences.candidateSchedulingEmailTemplate.title"
                defaultMessage="Email template for choosing the interview time and date"
              />
            </Item.Title>
            <Item.Description>
              <FormattedMessage
                id="recruitment.hiringStageForm.schedulingPreferences.candidateSchedulingEmailTemplate.description"
                defaultMessage="This email will be sent automatically once the candidate is moved to this stage. The email would include the link to book the interview, allowing the candidate to choose from all available interview slots of selected interviewers."
              />
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton
              onClick={() => {
                handlePreviewTemplate(values.candidate_scheduling_email_template?.id)
              }}
            >
              Show preview
            </ActionButton>
          </Item.Side>
        </Item>
        <Box mt="s-8">
          <LapeRadioSelectInput
            label="Select template"
            name="candidate_scheduling_email_template"
            selector={selectorKeys.email_templates_active}
            onAfterChange={val => {
              setEmailPreviewOpen(val?.id)
            }}
          />
        </Box>
      </Widget>
      <LapeNewSwitch
        itemTypeProps={{
          title: (
            <FormattedMessage
              id="recruitment.hiringStageForm.schedulingPreferences.excludePreviousInterviewers.title"
              defaultMessage="Only allow unique interviewers"
            />
          ),
          description: (
            <FormattedMessage
              id="recruitment.hiringStageForm.schedulingPreferences.excludePreviousInterviewers.description"
              defaultMessage="When enabled, only interviewers, who didn't conduct previous interviews with the candidate will be recommended when scheduling"
            />
          ),
        }}
        name="exclude_previous_interviewers"
      />
    </VStack>
  )
}
