import React from 'react'
import { Text } from '@revolut/ui-kit'

import {
  PayrollTimelineChangeInterface,
  PayrollTimelineDomainName,
} from '@src/interfaces/payrollV2'
import { FieldConfig } from '.'
import { getStatusColor } from '@src/components/CommonSC/General'
import { IdStatuses } from '@src/interfaces/employees'
import { formatSnakeCase } from '@src/utils/string'
import { formatSalaryAmount } from './domainToCustomWidget'

export const domainNameToFieldsConfig: Record<
  PayrollTimelineDomainName,
  {
    fields: Array<string | FieldConfig>
    renderAll?: (change: PayrollTimelineChangeInterface) => React.ReactNode
  }
> = {
  // Work details:
  name: { fields: ['name', 'full_name', 'first_name', 'middle_name', 'last_name'] },
  status: {
    fields: [
      {
        path: 'status',
        label: 'Employee status',
        renderField: data => {
          const statusValue = data && 'status' in data ? data.status : undefined
          return statusValue ? (
            <Text color={getStatusColor(statusValue as IdStatuses)}>
              {formatSnakeCase(String(statusValue))}
            </Text>
          ) : null
        },
      },
    ],
  },
  end_of_probation: { fields: ['end_of_probation_date_time'] },
  employee_type: { fields: ['employee_type'] },

  // Contract:
  contract: {
    fields: [
      'approval_status',
      'company_entity',
      'contract_status',
      'contract_term',
      'contract_type',
      { path: 'end_date', type: 'dateTime' },
      'full_time_equivalent',
      'inactivity_end_date',
      'inactivity_reason',
      'inactivity_start_date',
      'location',
      'notice_period_after_probation',
      'notice_period_after_probation_unit',
      'notice_period_during_probation',
      'notice_period_during_probation_unit',
      'position',
      'salary_amount',
      'salary_currency',
      'salary_payment_frequency',
      'salary_time_unit',
      'seniority',
      'sign_on_bonus_amount',
      'sign_on_bonus_currency',
      'sign_on_bonus_type',
      'specialisation',
      'specialisation_seniority_sublevel',
      { path: 'start_date', type: 'dateTime' },
      'title',
      'weekly_working_hours',
    ],
  },
  contract_type: { fields: [{ path: 'contract_type', type: 'snakeCase' }] },
  contract_status: {
    fields: [
      {
        path: 'contract_status',
        label: 'Contract status',
        renderField: data => {
          const statusValue =
            data && 'contract_status' in data ? data.contract_status : undefined
          return statusValue ? (
            <Text color={getStatusColor(statusValue as IdStatuses)}>
              {formatSnakeCase(String(statusValue))}
            </Text>
          ) : null
        },
      },
    ],
  },
  contract_term: { fields: ['contract_term'] },
  start_date: { fields: [{ path: 'start_date', type: 'date' }] },
  end_date: { fields: [{ path: 'end_date', type: 'date' }] },
  entity: { fields: ['entity'] },
  location: { fields: ['location'] },
  salary: {
    renderAll: formatSalaryAmount,
    fields: [
      'salary_amount',
      'salary_currency',
      'salary_payment_frequency',
      'salary_time_unit',
    ],
  },
  sign_on_bonus: {
    fields: ['sign_on_bonus_amount', 'sign_on_bonus_currency', 'sign_on_bonus_type'],
  },
  position: { fields: ['position'] },
  specialisation: { fields: ['specialisation'] },
  seniority: { fields: ['seniority', 'specialisation_seniority_sublevel'] },
  job_title: { fields: ['title'] },
  weekly_working_hours: { fields: ['weekly_working_hours'] },
  full_time_equivalent: { fields: ['full_time_equivalent'] },
  notice_period_during_probation: {
    fields: [
      {
        path: 'notice_period_during_probation',
        renderField: data => {
          const periodValue =
            data && 'notice_period_during_probation' in data
              ? data.notice_period_during_probation
              : undefined
          const unitValue: string | undefined =
            data &&
            'notice_period_during_probation_unit' in data &&
            typeof data.notice_period_during_probation_unit === 'string'
              ? data.notice_period_during_probation_unit
              : undefined
          if (periodValue && unitValue) {
            return `${periodValue} ${formatSnakeCase(unitValue)}`
          }
          return null
        },
      },
      { path: 'notice_period_during_probation_unit', type: 'snakeCase' },
    ],
  },
  notice_period_after_probation: {
    fields: [
      {
        path: 'notice_period_after_probation',
        renderField: data => {
          const periodValue =
            data && 'notice_period_after_probation' in data
              ? data.notice_period_after_probation
              : undefined
          const unitValue: string | undefined =
            data &&
            'notice_period_after_probation_unit' in data &&
            typeof data.notice_period_after_probation_unit === 'string'
              ? data.notice_period_after_probation_unit
              : undefined
          if (periodValue && unitValue) {
            return `${periodValue} ${formatSnakeCase(unitValue)}`
          }
          return null
        },
      },
      { path: 'notice_period_after_probation_unit', type: 'snakeCase' },
    ],
  },
  inactivity: {
    fields: [
      { path: 'inactivity_reason', type: 'snakeCase' },
      { path: 'inactivity_start_date', type: 'date' },
      { path: 'inactivity_end_date', type: 'date' },
    ],
  },
  approval_status: {
    fields: [
      {
        path: 'approval_status',
        renderField: data => {
          const statusValue =
            data && 'approval_status' in data ? data.approval_status : undefined
          return statusValue ? (
            <Text color={getStatusColor(statusValue as IdStatuses)}>
              {formatSnakeCase(String(statusValue))}
            </Text>
          ) : null
        },
      },
    ],
  },

  // Personal details:
  personal_email: { fields: ['personal_email'] },
  legal_sex: { fields: ['legal_sex'] },
  marital_status: { fields: ['marital_status'] },
  birth_date: { fields: ['birth_date'] },
  phone_number: { fields: ['phone_number', 'phone_number_short', 'phone_country_code'] },
  nationality: { fields: ['nationality'] },
  nationalities: { fields: ['nationalities'] },
  languages: { fields: ['languages'] },
  address: {
    fields: [
      'country',
      'county',
      'city',
      'address_line_1',
      'address_line_2',
      'address_line_3',
      'post_code',
    ],
  },
  emergency_contact: {
    fields: [
      'emergency_contact_full_name',
      'emergency_contact_email',
      'emergency_contact_mobile_phone',
      'emergency_contact_phone_country_code',
      'emergency_contact_phone_number',
      'emergency_contact_relationship',
    ],
  },

  // Time Off:
  time_off_request: {
    fields: [
      'duration',
      'from_date_time',
      { path: 'start_date_time', type: 'date' },
      { path: 'end_date_time', type: 'date' },
      'total_duration',
      'balance',
      'unit',
    ],
  },
}
