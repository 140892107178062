import React, { useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { Flex } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import {
  feedbackDate,
  feedbackUserID,
  feedbackScore,
  feedbackStatus,
  feedbackText,
  feedbackTopic,
} from '@src/constants/columns/feedback'
import { FeedbackInterface } from '@src/store/feedback/types'
import { feedbackRequests } from '@src/api/feedback'
import { FeedbackTableSidebar } from '@src/pages/Admin/AdminSideBars/Feedback/FeedbackTableSidebar'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const RowNotifications = (
  setSelectedId: (id?: number) => void,
): RowInterface<FeedbackInterface> => ({
  hideAddButton: true,
  linkToForm: ({ id }) => setSelectedId(id),
  cells: [
    {
      ...feedbackUserID,
      width: 220,
    },
    {
      ...feedbackScore,
      width: 160,
    },
    {
      ...feedbackTopic,
      width: 120,
    },
    {
      ...feedbackStatus,
      width: 160,
    },
    {
      ...feedbackDate,
      width: 160,
    },
    {
      ...feedbackText,
      width: 1052,
    },
  ],
})

const FeedbackTable = () => {
  const [selectedId, setSelectedId] = useState<number>()
  const filterBy = [
    {
      filters: [{ name: 'completed', id: 'completed' }],
      columnName: 'status',
    },
  ]
  const sortBy = [
    {
      sortBy: 'creation_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const table = useTable<FeedbackInterface>(feedbackRequests, filterBy, sortBy)

  return (
    <>
      <TableWrapper>
        <Flex mb="s-24">
          <Stat label="Total" val={table?.loading ? undefined : table?.count} mr="s-32" />
        </Flex>
        <AdjustableTable<FeedbackInterface>
          name={TableNames.Feedback}
          useWindowScroll
          dataType="Feedback"
          row={RowNotifications(setSelectedId)}
          {...table}
          noDataMessage="Feedback will appear here."
        />
      </TableWrapper>
      <FeedbackTableSidebar
        selectedId={selectedId}
        onClose={() => setSelectedId(undefined)}
      />
    </>
  )
}

export default FeedbackTable
