import React from 'react'
import isString from 'lodash/isString'

import RadioSelectInput, {
  RadioSelectInputProps,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useLapeField } from '@src/features/Form/LapeForm'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { getCommonInputProps } from './helpers'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { selectorToUrl } from '@src/constants/api'

export interface LapeRadioSelectInputProps<Value> extends RadioSelectInputProps<Value> {
  name: string
  /** Displays label with "(optional)" */
  optional?: boolean
  onAfterChange?: (value: Value | null) => void
  clearable?: boolean
}

const LapeRadioSelectInput = <Value extends { id?: number | string; name?: string }>({
  name,
  optional,
  clearable,
  onAfterChange,
  inputProps,
  message,
  children,
  ...props
}: LapeRadioSelectInputProps<Value>) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const { value, onChange, hidden, error, touched } = lapeProps

  if (hidden) {
    return null
  }

  const commonInputProps = getCommonInputProps(
    {
      name,
      required: !optional,
      message,
      ...props,
    },
    lapeProps,
    !!formValidator?.validated,
  )

  const onClear = () => onChange(null)

  const getLinkToSelectedItem = () => {
    if (isString(props.selector) && selectorToUrl[props.selector] && value?.id) {
      return {
        referenceUrl: getLocationDescriptor(
          pathToUrl(selectorToUrl[props.selector as string] as string, {
            id: value?.id,
          }),
          {
            returnAs: name,
          },
        ),
      }
    }
    return {}
  }

  return (
    <RadioSelectInput
      inputProps={{
        'aria-invalid': (touched && error) || commonInputProps.hasError,
        label: commonInputProps.label,
        onClear: clearable ? onClear : undefined,
        'data-name': name,
        ...inputProps,
      }}
      message={commonInputProps.message}
      value={value}
      onChange={option => {
        onChange(option)
        onAfterChange?.(option)
      }}
      disabled={commonInputProps.disabled}
      {...getLinkToSelectedItem()}
      {...props}
    >
      {children}
    </RadioSelectInput>
  )
}

export default LapeRadioSelectInput
