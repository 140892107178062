import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { EmployeePageHeader } from '@src/pages/EmployeeProfile/Layout/common/EmployeePageHeader'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { Bonuses } from '@src/pages/EmployeeProfile/Preview/Rewards/Bonuses'
import { Salary } from '@src/pages/EmployeeProfile/Preview/Rewards/Salary'
import { Equity } from './Equity'

type Props = {
  data: EmployeeInterface
  compensationParams: CompensationParams
}
export const CompensationV2Pages = ({ compensationParams, ...props }: Props) => {
  const tabs = [
    {
      title: 'Equity',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS_V2.EQUITY,
      component: Equity,
    },
    {
      title: 'Bonuses',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS_V2.BONUSES,
      component: Bonuses,
    },
    {
      title: 'Salary',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS_V2.SALARY,
      component: Salary,
    },
  ]

  return (
    <Switch>
      {tabs.map(tab => {
        return (
          <Route exact path={tab.path} key={tab.path}>
            <EmployeePageHeader data={props.data} title={tab.title} />
            <tab.component
              {...props}
              bonusType="cash"
              compensationParams={compensationParams}
              currency={compensationParams?.currency}
              hideBanner
              hideBonusesStats
              setCurrency={compensationParams?.setCurrency}
            />
          </Route>
        )
      })}
    </Switch>
  )
}
