import * as React from 'react'
import { connect } from 'lape'

import { useLapeField } from '@src/features/Form/LapeForm'
import { SwitchProps, Switch, Text, TextProps } from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types/image'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'

export interface LapeNewSwitchProps extends SwitchProps {
  name: string
  label?: string
  labelProps?: TextProps
  itemTypeProps?: {
    title?: string | React.ReactNode
    description?: string | React.ReactNode
    image?: ImageProp
    avatar?: React.ReactNode
    onLabelClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void
    transparent?: boolean // TODO: REVCOR-3046 revisit after ui-kit update
  }
  onAfterChange?: (checked: boolean) => void
}

const defaultProps: Partial<LapeNewSwitchProps> = {
  label: '',
  labelProps: {},
}

const LapeNewSwitch = ({
  name,
  children,
  itemTypeProps,
  label,
  onAfterChange,
  ...props
}: LapeNewSwitchProps) => {
  const { value, onChange, hidden, disabled, apiError } = useLapeField(name)

  if (hidden || props.hidden) {
    return null
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    onChange(checked)
    onAfterChange?.(checked)
  }

  if (itemTypeProps) {
    return (
      <ItemSwitch
        title={itemTypeProps.title}
        name={name}
        image={itemTypeProps.image}
        avatar={itemTypeProps.avatar}
        description={itemTypeProps.description}
        transparent={itemTypeProps.transparent}
        error={apiError}
        checked={!!value}
        disabled={disabled}
        onChange={handleChange}
        {...props}
        onClick={itemTypeProps.onLabelClick}
      />
    )
  }

  return (
    <Switch
      onChange={handleChange}
      value={value || false}
      checked={!!value}
      disabled={disabled}
      {...props}
    >
      {label ? <Text {...props.labelProps}>{label}</Text> : null}
    </Switch>
  )
}

LapeNewSwitch.defaultProps = defaultProps
export default connect(LapeNewSwitch)
