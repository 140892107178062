import React, { useMemo, useState } from 'react'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import getStartOfMonth from 'date-fns/startOfMonth'

import { Box, Color, Flex, Token } from '@revolut/ui-kit'
import {
  EmployeeShiftDetailsInterface,
  EmployeeShiftsStatsInterface,
} from '@src/interfaces/attendance'
import { CellTypes, ColumnCellInterface, FilterByInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { formatShiftDuration } from '@src/constants/columns/attendance'
import {
  isToday,
  getDaysInMonth,
  startOfISOWeek,
  differenceInDays,
  addMonths,
  addWeeks,
  endOfISOWeek,
  endOfMonth as getEndOfMonth,
} from 'date-fns'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'
import { TimeOffTableWeekCell } from './TimeOffTableWeekCell'
import { TimeOffTableMonthCell } from '@src/features/TimeOffCalendarTable/TimeOffTableMonthCell'
import {
  DAYS_IN_WEEK,
  END_DATE_FILTER_KEY,
  MONTH_CELL_PADDING,
  MONTH_CELL_WIDTH,
  START_DATE_FILTER_KEY,
  TableCalendarTabs,
  WeekMonthTabs,
  WEEK_CELL_PADDING,
  WEEK_CELL_WIDTH,
} from '@src/features/TimeOffCalendarTable/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { LargeWeeklyCalendarEventInterface } from '@src/components/LargeWeeklyCalendar'
import { utcToLocalDate } from '@src/utils/timezones'
import {
  approvalStatusIdToBgColor,
  getDateWithTime,
  getTimeOffRequestRange,
} from './helpers'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const useCurrentWeekRow = (
  currentDay: Date,
  cellsBefore?: ColumnCellInterface<EmployeeTimeOffRequestsCalendarInterface>[],
  canViewPolicy?: boolean,
) => {
  const featureFlags = useSelector(selectFeatureFlags)

  const cells = useMemo(() => {
    const res = cellsBefore || []
    const startOfWeek = startOfISOWeek(currentDay)

    for (let dayIdx = 0; dayIdx < DAYS_IN_WEEK; dayIdx++) {
      const day = addDays(startOfWeek, dayIdx)

      res.push({
        type: CellTypes.insert,
        dataPoint: 'id',
        idPoint: 'id',
        selectorsKey: selectorKeys.none,
        filterKey: null,
        sortKey: null,
        width: WEEK_CELL_WIDTH + WEEK_CELL_PADDING * 2,
        title: format(day, 'MMM, d'),
        padding: `${WEEK_CELL_PADDING}px`,
        renderCustomHeader: () => {
          const wrapperProps = isToday(day)
            ? {
                bg: Color.BLUE,
                color: Color.BACKGROUND,
                px: 's-8',
                borderRadius: 100,
              }
            : {}

          return (
            <Flex
              width="100%"
              bg={
                featureFlags?.includes(FeatureFlags.CoreNavigation)
                  ? Token.color.widgetBackground
                  : undefined
              }
            >
              <Flex width={140} justifyContent="center" alignItems="center">
                <Box {...wrapperProps}>{format(day, 'MMM, d')}</Box>
              </Flex>
            </Flex>
          )
        },
        insert: ({ data }) => {
          return (
            <TimeOffTableWeekCell
              day={day}
              requestsCalendar={data}
              canViewPolicy={canViewPolicy}
            />
          )
        },
      })
    }
    return res
  }, [currentDay, canViewPolicy, featureFlags])

  return { cells }
}

export const useCurrentMonthRow = (
  currentDay: Date,
  cellsBefore?: ColumnCellInterface<EmployeeTimeOffRequestsCalendarInterface>[],
  canViewPolicy?: boolean,
) => {
  const featureFlags = useSelector(selectFeatureFlags)

  const cells = useMemo(() => {
    const res = cellsBefore || []
    const startOfMonth = getStartOfMonth(currentDay)
    const daysInMonth = getDaysInMonth(currentDay)

    for (let dayIdx = 0; dayIdx < daysInMonth; dayIdx++) {
      const day = addDays(startOfMonth, dayIdx)

      res.push({
        type: CellTypes.insert,
        dataPoint: 'id',
        idPoint: 'id',
        selectorsKey: selectorKeys.none,
        filterKey: null,
        sortKey: null,
        width: MONTH_CELL_WIDTH + MONTH_CELL_PADDING * 2,
        title: String(dayIdx + 1),
        padding: `${MONTH_CELL_PADDING}px`,
        renderCustomHeader: () => {
          const wrapperProps = isToday(day)
            ? {
                bg: Color.BLUE,
                color: Color.BACKGROUND,
                px: 's-4',
                borderRadius: 100,
              }
            : {}

          return (
            <Flex
              width="100%"
              bg={
                featureFlags?.includes(FeatureFlags.CoreNavigation)
                  ? Token.color.widgetBackground
                  : undefined
              }
            >
              <Flex width={36} justifyContent="center" alignItems="center">
                <Box {...wrapperProps}>{dayIdx + 1}</Box>
              </Flex>
            </Flex>
          )
        },
        insert: ({ data }) => (
          <TimeOffTableMonthCell
            day={day}
            requestsCalendar={data}
            canViewPolicy={canViewPolicy}
          />
        ),
      })
    }
    return res
  }, [currentDay, canViewPolicy, featureFlags])

  return { cells }
}

export const useCalendarFilters = () => {
  const { query, deleteQueryParam } = useQuery()

  const hasCalendarViewFilters =
    query[START_DATE_FILTER_KEY] && query[END_DATE_FILTER_KEY]
  const isMonthView = hasCalendarViewFilters
    ? differenceInDays(new Date(query.end_date), new Date(query.start_date)) >
      DAYS_IN_WEEK
    : false

  return {
    isMonthView,
    isCalendarView: hasCalendarViewFilters,
    clearCalendarFilters: () => {
      deleteQueryParam(START_DATE_FILTER_KEY)
      deleteQueryParam(END_DATE_FILTER_KEY)
    },
  }
}

const tabsIcons = {
  [TableCalendarTabs.Table]: '16/ListBullet' as const,
  [TableCalendarTabs.Calendar]: 'CalendarWeek' as const,
}

export const useTableCalendarSwitcher = (
  onTabChange?: (tab: TableCalendarTabs) => void,
) => {
  return useTabBarSwitcher({
    tabs: [TableCalendarTabs.Calendar, TableCalendarTabs.Table],
    useIcons: tabsIcons,
    highlightSelected: false,
    defaultTab: TableCalendarTabs.Calendar,
    onTabChange,
  })
}

export const useWeekMonthSwitcher = (isMonthView: boolean) => {
  return useTabBarSwitcher({
    tabs: [WeekMonthTabs.Week, WeekMonthTabs.Month],
    highlightSelected: false,
    defaultTab: isMonthView ? WeekMonthTabs.Month : WeekMonthTabs.Week,
  })
}

export const useTimeOffCalendarControls = () => {
  const { query } = useQuery()

  const [currentDay, setCurrentDay] = useState(
    query.start_date ? new Date(query.start_date) : new Date(),
  )

  const onClickNextWeek = () => {
    setCurrentDay(addWeeks(currentDay, 1))
  }

  const onClickPrevWeek = () => {
    setCurrentDay(addWeeks(currentDay, -1))
  }

  const onClickNextMonth = () => {
    setCurrentDay(addMonths(currentDay, 1))
  }

  const onClickPrevMonth = () => {
    setCurrentDay(addMonths(currentDay, -1))
  }

  const onClickToday = () => {
    setCurrentDay(new Date())
  }

  return {
    currentDay,
    onClickNextWeek,
    onClickPrevWeek,
    onClickNextMonth,
    onClickPrevMonth,
    onClickToday,
  }
}

export const useTimeOffCalendarFilters = (
  isWeekView: boolean,
  currentDay: Date,
  initialFilters?: FilterByInterface[],
) => {
  const startOfWeek = startOfISOWeek(currentDay)
  const endOfWeek = endOfISOWeek(currentDay)

  const startOfMonth = getStartOfMonth(currentDay)
  const endOfMonth = getEndOfMonth(currentDay)

  const startDateFilter = format(isWeekView ? startOfWeek : startOfMonth, 'yyyy-MM-dd')
  const endDateFilter = format(isWeekView ? endOfWeek : endOfMonth, 'yyyy-MM-dd')

  const getFilters = () => {
    return [
      {
        filters: [
          {
            id: startDateFilter,
            name: startDateFilter,
          },
        ],
        columnName: START_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [
          {
            id: endDateFilter,
            name: endDateFilter,
          },
        ],
        columnName: END_DATE_FILTER_KEY,
        nonResettable: true,
      },
      ...(initialFilters || []),
    ]
  }

  return {
    startOfWeek,
    endOfWeek,
    getFilters,
  }
}

export const useEmployeeTimeOffRequestsCalendarData = (
  canViewPolicy?: boolean,
  data?: EmployeeTimeOffRequestsCalendarInterface,
) => {
  return useMemo<{
    non_working_days: LargeWeeklyCalendarEventInterface[]
    public_holidays: LargeWeeklyCalendarEventInterface[]
    time_off_requests: LargeWeeklyCalendarEventInterface[]
  }>(
    () => ({
      non_working_days:
        data?.non_working_days?.map(date => {
          const startDate = utcToLocalDate(date)

          return {
            start: startDate,
            end: addDays(startDate, 1),
            backgroundColor: Token.color.greyTone8,
            isReadOnly: true,
            title: 'Non working',
          }
        }) || [],
      public_holidays:
        data?.public_holidays?.map(holiday => {
          const startDate = utcToLocalDate(holiday.day)

          return {
            start: startDate,
            end: addDays(startDate, 1),
            backgroundColor: Token.color.greyTone8,
            isReadOnly: true,
            title: 'Bank holiday',
          }
        }) || [],
      time_off_requests:
        data?.time_off_requests?.map(request => {
          const range = getTimeOffRequestRange(request)
          return {
            id: String(request.id),
            calendarId: 'time_off',
            end: range?.end,
            start: range?.start,
            backgroundColor: approvalStatusIdToBgColor(request.approval_status.id, true),
            isReadOnly: true,
            title:
              canViewPolicy && request.category?.name
                ? request.category.name
                : 'Time off',
          }
        }) || [],
    }),
    [data],
  )
}

export const useEmployeeShiftsCalendarData = (
  data: EmployeeShiftDetailsInterface[],
  metadata?: EmployeeShiftsStatsInterface,
  canViewPolicy?: boolean,
) => {
  return useMemo<{
    non_working_days: LargeWeeklyCalendarEventInterface[]
    public_holidays: LargeWeeklyCalendarEventInterface[]
    shifts: LargeWeeklyCalendarEventInterface[]
  }>(
    () => ({
      non_working_days:
        metadata?.non_working_days?.map(date => {
          const startDate = utcToLocalDate(date)

          return {
            start: startDate,
            end: addDays(startDate, 1),
            backgroundColor: Token.color.greyTone8,
            color: Token.color.greyTone50,
            isReadOnly: true,
            title: 'Non working',
          }
        }) || [],
      public_holidays:
        metadata?.public_holidays?.map(holiday => {
          const startDate = utcToLocalDate(holiday.day)

          return {
            start: startDate,
            end: addDays(startDate, 1),
            backgroundColor: Token.color.greyTone8,
            color: Token.color.greyTone50,
            isReadOnly: true,
            title: 'Bank holiday',
          }
        }) || [],
      shifts: data.flatMap(parent =>
        parent.children.flatMap(shift => {
          const isBreak = shift.type.id === 'break'
          const isWork = shift.type.id === 'work'

          const startDate = new Date(parent.date)
          const endDate = shift.time_range ? startDate : addDays(startDate, 1)

          const body =
            isWork && shift.sub_shifts?.length
              ? shift.sub_shifts.map(subShift => {
                  return `<span style='color: ${
                    subShift.type.id === 'regular'
                      ? Token.color.greyTone50
                      : Token.color.orange
                  }; font-size: 12px; white-space: pre-wrap; line-height: 18px;'>${
                    subShift.type.name
                  } (${formatShiftDuration({
                    hours: subShift.time_range.duration_hours,
                    minutes: subShift.time_range.duration_minutes,
                  })})</span>`
                })
              : undefined

          return {
            id: String(shift.id),
            calendarId: shift.type.id,
            start: getDateWithTime(startDate, shift.time_range?.start || '00:00'),
            end: getDateWithTime(endDate, shift.time_range?.end || '00:00'),
            backgroundColor: isWork
              ? Token.color.greyTone2
              : isBreak
              ? Token.color.lightBlue_20
              : approvalStatusIdToBgColor(shift.approval_status?.id, true),
            body: body?.join(' · '),
            isReadOnly: shift.type.id === 'time_off',
            title: canViewPolicy ? shift.category || shift.type.name : shift.type.name,
            color: Token.color.foreground,
          }
        }),
      ),
    }),
    [data, metadata],
  )
}
