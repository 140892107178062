import { Header, Subheader, Grid, SubheaderSkeleton } from '@revolut/ui-kit'
import { goBack } from '@src/actions/RouterActions'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { SelectCustomTile } from './selectComponents/SelectCustomTile'
import { useEngagementSurveysTemplates } from '@src/api/engagement'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { SelectTemplateTile } from './selectComponents/SelectTemplateTile'

export const SelectNewPage = () => {
  const { data, isLoading } = useEngagementSurveysTemplates()
  const { recommended, other } = useMemo(
    () =>
      data?.results.reduce(
        (
          acc: {
            recommended: EngagementSurveyInterface[]
            other: EngagementSurveyInterface[]
          },
          current,
        ) => {
          if (current.is_recommended) {
            acc.recommended.push(current)
          } else {
            acc.other.push(current)
          }
          return acc
        },
        { recommended: [], other: [] },
      ) || { recommended: [], other: [] },
    [data?.results],
  )
  return (
    <PageWrapper>
      <Header variant="item">
        <Header.BackButton
          onClick={() => goBack(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)}
          aria-label="Back"
        />
        <Header.Title use="h1">
          <FormattedMessage
            id='engagement.selectNew.title"'
            defaultMessage="Create a new survey"
          />
        </Header.Title>
        <Header.Description>
          <FormattedMessage
            id='engagement.selectNew.description"'
            defaultMessage="Use one of our templates, or start from scratch by building your own survey"
          />
        </Header.Description>
      </Header>
      <Subheader>
        <Subheader.Title>
          <FormattedMessage
            id='engagement.selectNew.custom.header"'
            defaultMessage="Start from scratch"
          />
        </Subheader.Title>
      </Subheader>
      <Grid columns={{ lg: 3, md: 2, sm: 1 }} gap="s-16">
        <SelectCustomTile />
      </Grid>
      {isLoading ? (
        <>
          <SubheaderSkeleton>
            <SubheaderSkeleton.Title />
          </SubheaderSkeleton>
          <SubheaderSkeleton>
            <SubheaderSkeleton.Title />
          </SubheaderSkeleton>
        </>
      ) : (
        (!!recommended.length || !!other.length) && (
          <>
            <Subheader>
              <Subheader.Title>
                <FormattedMessage
                  id='engagement.selectNew.template.header"'
                  defaultMessage="Explore our templates"
                />
              </Subheader.Title>
            </Subheader>

            {!!recommended.length && (
              <>
                <Subheader variant="nested">
                  <Subheader.Title>
                    <FormattedMessage
                      id='engagement.selectNew.template.recommended"'
                      defaultMessage="Recommended"
                    />
                  </Subheader.Title>
                </Subheader>
                <Grid columns={{ lg: 3, md: 2, sm: 1 }} gap="s-16">
                  {recommended.map(template => (
                    <SelectTemplateTile key={template.id} template={template} />
                  ))}
                </Grid>
              </>
            )}

            {!!other.length && (
              <>
                <Subheader variant="nested">
                  <Subheader.Title>
                    <FormattedMessage
                      id='engagement.selectNew.template.all"'
                      defaultMessage="All templates"
                    />
                  </Subheader.Title>
                </Subheader>
                <Grid columns={{ lg: 3, md: 2, sm: 1 }} gap="s-16">
                  {other.map(template => (
                    <SelectTemplateTile key={template.id} template={template} />
                  ))}
                </Grid>
              </>
            )}
          </>
        )
      )}
    </PageWrapper>
  )
}
