import React from 'react'
import { Ellipsis, IconName, Tag, Token } from '@revolut/ui-kit'
import { notReachable } from '@src/utils/notReachable'
import { TagVariant } from '@revolut/ui-kit/types/dist/components/Tag/variants'

export type ColorTagVariant =
  | 'success'
  | 'warning'
  | 'danger'
  | 'neutral'
  | 'default'
  | 'outlined'

type Props = {
  icon?: IconName
  variant?: ColorTagVariant
  children: React.ReactNode
}

export const ColorTag = ({ icon, variant = 'default', children }: Props) => {
  let color
  let bg
  let tagVariant: TagVariant = 'faded'

  switch (variant) {
    case 'success': {
      color = Token.color.success
      bg = Token.color.teal_20
      break
    }

    case 'warning': {
      color = Token.color.warning
      bg = Token.color.orange_10
      break
    }

    case 'danger': {
      color = Token.color.danger
      bg = Token.color.inputError
      break
    }

    case 'neutral': {
      color = Token.color.greyTone50
      bg = Token.color.greyTone10
      break
    }

    case 'outlined': {
      color = undefined
      bg = undefined
      tagVariant = 'outlined'
      break
    }

    case 'default': {
      color = undefined
      bg = undefined
      break
    }

    default:
      notReachable(variant)
  }
  return (
    <Tag
      variant={tagVariant}
      color={color}
      bg={bg}
      style={{ maxWidth: '100%' }}
      useIcon={icon}
    >
      <Ellipsis>{children}</Ellipsis>
    </Tag>
  )
}
