import React, { useState } from 'react'
import { connect } from 'lape'
import capitalize from 'lodash/capitalize'
import { Route, Switch, useParams } from 'react-router-dom'
import {
  Token,
  VStack,
  Text,
  DetailsCell,
  HStack,
  Icon,
  Box,
  TextButton,
  Skeleton,
  Item,
  Avatar,
  abbreviate,
  DetailsCellSkeleton,
  Tag,
  IconName,
} from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  employeeTimeOffRequests,
  useEmployeeTimeOffStats,
  useGetPendingTimeOffRequestsByCurrentUser,
} from '@src/api/timeOff'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { navigateReplace } from '@src/actions/RouterActions'
import SideBar from '@components/SideBar/SideBar'
import { formatPeriod } from '@src/utils/format'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { getAvatarUrl } from '@src/utils/employees'

import Preview from './Preview'
import General from './General'
import Changelog from './Changelog'

interface TabsProps {
  backUrl: string
  employee?: EmployeeOptionInterface
}

export interface NavigateBarRequest {
  id: number
  status:
    | ApprovalStatuses.Approved
    | ApprovalStatuses.Cancelled
    | ApprovalStatuses.Rejected
}

export const getStatusColorAndIcon = (
  status?: ApprovalStatuses,
): { bgColor?: string; color?: string; icon?: IconName } => {
  if (status === ApprovalStatuses.Approved) {
    return { bgColor: Token.color.teal_20, color: Token.color.teal, icon: 'Check' }
  }
  if (status === ApprovalStatuses.Cancelled) {
    return {
      bgColor: Token.color.red_20,
      color: Token.color.red,
      icon: 'Reverted',
    }
  }
  if (status === ApprovalStatuses.Rejected) {
    return {
      bgColor: Token.color.red_20,
      color: Token.color.red,
      icon: 'Cross',
    }
  }
  return {}
}

const NavigateRequestsBar = ({
  onNavigate,
  updatedRequests,
}: {
  onNavigate: () => void
  updatedRequests: NavigateBarRequest[]
}) => {
  const { id } = useParams<{ id: string; employeeId: string }>()

  const [openQueueSidebar, setOpenQueueSidebar] = useState(true)

  const { data: allPendingRequests = [], isLoading: isLoadingPendingRequests } =
    useGetPendingTimeOffRequestsByCurrentUser()

  const idx = allPendingRequests.findIndex(req => req.id === Number(id))

  const onClickNext = () => {
    const nextIdx = idx + 1
    const nextRequest =
      nextIdx === allPendingRequests.length
        ? allPendingRequests[0]
        : allPendingRequests[nextIdx]

    navigateReplace(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
        id: nextRequest.id,
        employeeId: nextRequest.employee.id,
      }),
    )
    onNavigate()
  }

  const onClickPrev = () => {
    const prevIdx = idx - 1
    const prevRequest =
      prevIdx < 0
        ? allPendingRequests[allPendingRequests.length - 1]
        : allPendingRequests[prevIdx]

    navigateReplace(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
        id: prevRequest.id,
        employeeId: prevRequest.employee.id,
      }),
    )
    onNavigate()
  }

  if (idx < 0 || allPendingRequests.length <= 1) {
    if (!isLoadingPendingRequests) {
      return null
    }
    return (
      <Box width={720} py="s-16">
        <DetailsCellSkeleton />
      </Box>
    )
  }
  return (
    <>
      <Box width={720} py="s-16">
        <DetailsCell>
          <DetailsCell.Title>
            <TextButton
              color={Token.color.greyTone50}
              onClick={() => setOpenQueueSidebar(true)}
            >
              <HStack align="center" space="s-8">
                <Text>Request</Text>
                <Icon name="Dot" size={8} />
                <Text>
                  {idx + 1}/{allPendingRequests.length}
                </Text>
              </HStack>
            </TextButton>
          </DetailsCell.Title>
          <DetailsCell.Content>
            <HStack align="center" space="s-8">
              <TextButton onClick={onClickPrev}>
                <HStack align="center">
                  <Icon name="ChevronLeft" size={18} color={Token.color.greyTone50} />
                  <Text color={Token.color.greyTone50} fontWeight="500">
                    Previous
                  </Text>
                </HStack>
              </TextButton>
              <Icon name="Dot" size={8} color={Token.color.greyTone50} />
              <TextButton onClick={onClickNext}>
                <HStack align="center">
                  <Text color={Token.color.greyTone50} fontWeight="500">
                    Next
                  </Text>
                  <Icon name="ChevronRight" size={18} color={Token.color.greyTone50} />
                </HStack>
              </TextButton>
            </HStack>
          </DetailsCell.Content>
        </DetailsCell>
      </Box>
      <SideBar
        title="Time-off requests"
        isOpen={openQueueSidebar}
        onClose={() => setOpenQueueSidebar(false)}
      >
        <VStack space="s-8">
          {allPendingRequests.map(req => {
            const updatedRequest = updatedRequests.find(item => item.id === req.id)
            const { bgColor, color, icon } = getStatusColorAndIcon(updatedRequest?.status)

            return (
              <Item
                key={req.id}
                aria-pressed={req.id === Number(id)}
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                  employeeId: req.employee.id,
                  id: String(req.id),
                })}
              >
                <Item.Avatar>
                  <Avatar
                    image={getAvatarUrl(req.employee.avatar)}
                    label={
                      req.employee.full_name
                        ? abbreviate(req.employee.full_name)
                        : undefined
                    }
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{req.employee.full_name}</Item.Title>
                  {!!req.from_date_time && !!req.to_date_time && (
                    <Item.Description>
                      Time off request{' '}
                      {formatPeriod(req.from_date_time, req.to_date_time)}
                    </Item.Description>
                  )}
                </Item.Content>
                <Item.Side>
                  {updatedRequest && (
                    <Tag bg={bgColor} color={color} useIcon={icon} variant="faded">
                      {capitalize(updatedRequest.status)}
                    </Tag>
                  )}
                </Item.Side>
              </Item>
            )
          })}
        </VStack>
      </SideBar>
    </>
  )
}

const PreviewPages = ({ backUrl, employee }: TabsProps) => {
  const params = useParams<{ id: string; employeeId: string }>()
  const [categoryName, setCategoryName] = useState('')
  const [updatedRequests, setUpdatedRequests] = useState<NavigateBarRequest[]>([])

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, params),
      component: Preview,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.CHANGELOG, params),
      component: Changelog,
    },
  ]

  return (
    <>
      <PageHeader
        pb="s-8"
        title={categoryName || <Skeleton width={256} height={32} />}
        subtitle={
          <VStack space="s-4" mt="s-4">
            <UserWithAvatar {...employee} asText mb="s-16" />
            <Text color={Token.color.greyTone50}>Time Off Request</Text>
          </VStack>
        }
        backUrl={backUrl}
        renderAboveTitle={() => (
          <Route path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW}>
            <NavigateRequestsBar
              onNavigate={() => setCategoryName('')}
              updatedRequests={updatedRequests}
            />
          </Route>
        )}
      />

      <Form api={employeeTimeOffRequests('requests')} disableLocalStorageCaching>
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component
                setCategoryName={name => setCategoryName(name)}
                setUpdatedRequests={req => setUpdatedRequests(prev => [...prev, req])}
              />
            </Route>
          ))}
        </Switch>
      </Form>
    </>
  )
}

export interface RouteParams {
  id: string
  employeeId: string
}

const EmployeeTimeOffRequest = () => {
  const params = useParams<RouteParams>()
  const stats = useEmployeeTimeOffStats(params.employeeId)

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.REQUESTS, {
    id: params.employeeId,
  })

  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL}>
          <PageHeader
            pb="s-8"
            title={params.id ? 'Edit time off request' : 'Request time off'}
            subtitle={<UserWithAvatar {...stats.data?.employee} asText mb="s-16" />}
            backUrl={
              params.id
                ? pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, params)
                : backUrl
            }
          />
          <Form api={employeeTimeOffRequests('requests')} disableLocalStorageCaching>
            <General />
          </Form>
        </Route>
        <PreviewPages employee={stats.data?.employee} backUrl={backUrl} />
      </Switch>
    </PageWrapper>
  )
}

export default connect(EmployeeTimeOffRequest)
