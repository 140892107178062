import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  bookingLinkDefinitionsFormRequests,
  useGetAvailableSlotsFromRecruiters,
} from '@src/api/bookingLinks'
import Form from '@src/features/Form/Form'
import { BookingLinkDefinitionInterface } from '@src/interfaces/bookingLinks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { Button, Color, InputGroup, Side, Text } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGetSpecialisationHiringStages } from '@src/api/hiringProcess'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { DurationUnitType } from '@src/interfaces/interviewTool'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { useExtensionApiContext } from '@src/utils/extension'
import { PageWrapper } from '@components/Page/Page'
import { Grid } from '@components/CommonSC/Grid'
import JobPostingSelector from '@src/features/BookingLinks/Recruiter/JobPostingSelector'

type BookingLinkSidebarFormProps = {
  id?: number
  jobPosting?: JobPostingInterface
  open: boolean
  onAfterSubmit: () => void
  onClose: () => void
  useSidebar?: boolean
}

const useScreenCallStagesOptions = (
  specialisationId?: number | string,
  hiringProcessId?: number | null,
) => {
  const { data: hiringStages, isLoading: isLoadingHiringStages } =
    useGetSpecialisationHiringStages(specialisationId, hiringProcessId)
  const options = (hiringStages?.results ?? [])
    ?.filter(({ stage_type }) => stage_type.id === 'screen_call')
    .map(hiringStage => ({
      id: hiringStage.id,
      label: hiringStage.title,
      value: hiringStage,
    }))
  return {
    options,
    loading: isLoadingHiringStages,
  }
}

const BookingLinkSidebarForm = ({
  id,
  jobPosting: initialJobPosting,
  open,
  onAfterSubmit,
  onClose,
  useSidebar,
}: BookingLinkSidebarFormProps) => {
  const { values } = useLapeContext<BookingLinkDefinitionInterface>()
  if (initialJobPosting && !values?.job_posting) {
    values.job_posting = initialJobPosting
  }
  const { options, loading } = useScreenCallStagesOptions(
    values?.job_posting?.specialisation?.id,
    values?.job_posting?.hiring_process?.id,
  )
  const { data: availableSlotsData, isLoading: loadingAvailableSlots } =
    useGetAvailableSlotsFromRecruiters(
      values?.recruiters?.map?.(recruiter => recruiter.id),
      values?.specialisation_hiring_stage?.duration,
      values?.specialisation_hiring_stage?.duration_unit,
    )
  const noAvailableSlots = !availableSlotsData?.results?.length
  const hasRecruiters = !!values.recruiters?.length
  const editing = id !== undefined

  const renderButtons = () => (
    <>
      <Button variant="secondary" onClick={() => onClose()}>
        Cancel
      </Button>
      <NewSaveButtonWithPopup<BookingLinkDefinitionInterface>
        successText="New booking link saved successfully"
        onAfterSubmit={() => {
          onAfterSubmit()
          onClose()
        }}
        useValidator
        hideWhenNoChanges
        disabled={!hasRecruiters || noAvailableSlots}
        pending={loadingAvailableSlots}
      />
    </>
  )

  const renderContent = () => {
    return (
      <>
        <InputGroup>
          {editing && (
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Active',
                description: 'You can temporarily disable the link',
              }}
              name="is_enabled"
            />
          )}
          <JobPostingSelector
            disabled={editing || !!initialJobPosting}
            onChange={() => {
              values.specialisation_hiring_stage = undefined
            }}
          />
          <LapeRadioSelectInput
            required
            name="specialisation_hiring_stage"
            label="Interview stage"
            options={options}
            loading={loading}
            onChange={specialisationHiringStageOption => {
              if (specialisationHiringStageOption) {
                values.specialisation_hiring_stage = {
                  id: specialisationHiringStageOption.id,
                  title: specialisationHiringStageOption.title,
                  duration: specialisationHiringStageOption.duration,
                  duration_unit: specialisationHiringStageOption?.duration_unit
                    ?.id as DurationUnitType,
                }
              }
            }}
          />
          <LapeRadioSelectInput
            required
            name="sourcer"
            label="Sourcer"
            selector={selectorKeys.employee}
          />
          <LapeNewMultiSelect
            required
            name="recruiter"
            label="Recruiter"
            placeholder="Select recruiters"
            selector={selectorKeys.employee}
            value={values?.recruiters?.map(recruiter => ({
              id: recruiter.id,
              label: recruiter.full_name || recruiter.name,
              value: recruiter,
            }))}
            onChange={newRecruiters => {
              values.recruiters = newRecruiters.map(({ value }) => value)
            }}
          />
        </InputGroup>
        {!loadingAvailableSlots && hasRecruiters && noAvailableSlots && (
          <ActionWidget
            mt="s-16"
            title="No slots available"
            text={
              <Text>
                Eligible interviewers either have not marked any slots, or no slots are
                available. <br />
                <br /> To mark slots, interviewers need to create a recurring event in
                their calendar with the string “
                <Text color={Color.GREEN}>[interview-slot]</Text>” added either in the
                subject or description of the event.
              </Text>
            }
          />
        )}
      </>
    )
  }

  const title = editing ? 'Edit booking link' : 'Create booking link'
  const subtitle = editing
    ? 'Be aware that all that changes will affect already sent links.'
    : 'Candidates, to whom this link will be sent, will be asked for their email, name and country. After providing that information, they’ll be presented with 4 weeks worth of interview slots to select from to book an interview.'

  if (!useSidebar) {
    return (
      <PageWrapper>
        <PageBody>
          <PageHeader
            title={title}
            subtitle={subtitle}
            backUrl=""
            onClickBack={onClose}
            routerAction={PageHeaderRouterAction.navigate}
            hideGlobalSearch
          />
          {renderContent()}
        </PageBody>
        <Grid mt="s-16" flow="column" gap={16}>
          {renderButtons()}
        </Grid>
      </PageWrapper>
    )
  }

  return (
    <SideBar
      isOpen={open}
      onClose={onClose}
      variant="wide"
      title={title}
      subtitle={subtitle}
    >
      {renderContent()}
      <Side.Actions>{renderButtons()}</Side.Actions>
    </SideBar>
  )
}

export default (props: BookingLinkSidebarFormProps) => {
  const apiHandler = useExtensionApiContext()
  return (
    <Form
      api={bookingLinkDefinitionsFormRequests(apiHandler)}
      forceParams={{ id: props.id ? String(props.id) : undefined }}
      disableLocalStorageCaching
    >
      <BookingLinkSidebarForm {...props} />
    </Form>
  )
}
