import { InterviewScorecardSectionQuestionType } from '@src/interfaces/interviewScorecardTemplates'
import { OptionInterface } from '@src/interfaces/selectors'

export enum ApplicationFormEnumQuestionIds {
  Checkbox = 'checkbox',
  Option = 'option',
  Text = 'text',
  Textarea = 'textarea',
  Dropdown = 'dropdown',
}

export const ApplicationFormEnumQuestionOptions = [
  { id: ApplicationFormEnumQuestionIds.Option, name: 'Option' },
  { id: ApplicationFormEnumQuestionIds.Checkbox, name: 'Checkbox' },
  { id: ApplicationFormEnumQuestionIds.Text, name: 'Text' },
  { id: ApplicationFormEnumQuestionIds.Textarea, name: 'Textarea' },
  { id: ApplicationFormEnumQuestionIds.Dropdown, name: 'Dropdown' },
]

export type ApplicationFormQuestionType = {
  id: ApplicationFormEnumQuestionIds
  name: string
}

export interface ApplicationFormQuestionInterface {
  id?: number
  title?: string
  question_type: ApplicationFormQuestionType
  options?: InterviewScorecardSectionQuestionType<number>[]
  optional?: boolean
  placeholder_text?: string
  subtitle?: string
  locations?: OptionInterface[]
}

export interface ApplicationFormSectionInterface {
  id?: number
  title?: string
  description: string | null
  questions: ApplicationFormQuestionInterface[]
  creation_date_time?: string
  updated_date_time?: string
}
