import React from 'react'
import { CRMReviewLocationState } from '@src/interfaces/CRM'
import { useLocation } from 'react-router-dom'
import { getInterviewRound } from '@src/api/recruitment/interviews'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const useReviewLocationState = () => {
  const locationState = useLocation<CRMReviewLocationState>()
  const { reviewId, interviewRoundId } = locationState?.state || {}
  return { reviewId, interviewRoundId }
}

export const useReviewNavigation = (interviewRounds: number[]) => {
  const { reviewId, interviewRoundId } = useReviewLocationState()
  const currIndex = interviewRounds.findIndex(id => id === interviewRoundId)
  const [loading, setLoading] = React.useState(false)
  const nextIndex = currIndex + 1
  const prevIndex = currIndex - 1
  const next = interviewRounds[nextIndex]
  const prev = interviewRounds[prevIndex]
  const handleNavigateToCandidate = async (newInterviewRoundId: number) => {
    if (newInterviewRoundId) {
      setLoading(true)
      try {
        const res = await getInterviewRound(newInterviewRoundId)
        navigateTo(
          pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: res.data.candidate.id }),
          {
            reviewId,
            interviewRoundId: newInterviewRoundId,
          },
        )
      } finally {
        setLoading(false)
      }
    }
  }
  const handleNavigateToCRM = async () => {
    navigateTo(pathToUrl(ROUTES.FORMS.CRM))
  }

  return {
    next,
    prev,
    loading,
    handleNavigateToCandidate,
    handleNavigateToNext: () => {
      if (next) {
        return handleNavigateToCandidate(next)
      }
      return handleNavigateToCRM()
    },
    handleNavigateToPrev: () => {
      return handleNavigateToCandidate(prev)
    },
  }
}
