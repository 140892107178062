import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import PaygroupPreview from './Preview'
import PaygroupEdit from './General/General'

export const Paygroup = () => (
  <Switch>
    <Route path={ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW}>
      <PaygroupPreview />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT}>
      <PaygroupEdit />
    </Route>
  </Switch>
)
