import React from 'react'
import { ActionButton, Cell, FilterButton, Flex } from '@revolut/ui-kit'

import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  notificationCreationDate,
  notificationDeliverySchedule,
  notificationDeliveryType,
  notificationLastSent,
  notificationName,
  notificationStatus,
  notificationType,
} from '@src/constants/columns/notificationTemplate'
import { getNotificationList } from '@src/api/notificationTemplate'
import { NotificationTemplateInterface } from '@src/interfaces/notificationTemplate'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetCommunicationSettings } from '@src/api/settings'

const Row: RowInterface<NotificationTemplateInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.PREVIEW, { id: data.id })),
  cells: [
    {
      ...notificationName,
      width: 240,
    },
    {
      ...notificationType,
      width: 160,
    },
    {
      ...notificationDeliveryType,
      width: 160,
    },
    {
      ...notificationDeliverySchedule,
      width: 200,
    },
    {
      ...ownerNameColumn,
      width: 200,
    },
    {
      ...notificationCreationDate,
      width: 200,
    },
    {
      ...notificationLastSent,
      width: 200,
    },
    {
      ...notificationStatus,
      width: 160,
    },
  ],
}

const NotificationsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_NOTIFICATIONS_ASSIGNED_TO_ME,
    false,
  )

  const { data: settings } = useGetCommunicationSettings()
  const allNotificationTypesDisabled =
    !settings?.enable_email_notifications &&
    !settings?.enable_slack_notifications &&
    !settings?.enable_system_notifications &&
    !settings?.enable_announcements &&
    !settings?.enable_sms_notifications

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<NotificationTemplateInterface>(
    {
      getItems: getNotificationList,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Cell p="s-16">
      <Flex flexDirection="column" width="100%">
        {allNotificationTypesDisabled ? null : (
          <Flex mb="s-16" justifyContent="space-between">
            <ActionButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.GENERAL)}
              mr="s-16"
              useIcon="Plus"
            >
              Add notification
            </ActionButton>
            <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
              My notifications
            </FilterButton>
          </Flex>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.UserNotifications}
            useWindowScroll
            row={Row}
            {...table}
            noDataMessage="Notifications will appear here."
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default NotificationsTable
