import React from 'react'
import { HStack, Tag, Text, Token, Ellipsis } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  ConnectionInterface,
  ReportInterface,
  ReportColumnsTableInterface,
  ReportParametersTableInterface,
  RunReportResponseInterface,
  RunQueryResponseInterface,
  QueryInterface,
  QueryColumnInterface,
  QueryRunStatuses,
  QueryStatuses,
} from '@src/interfaces/dataAnalytics'
import Icon from '../../components/Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import Table from '@components/TableV2/Table'
import { ColorTagVariant, ColorTag } from '@components/ColorTag/ColorTag'
import { QUERY_DEFAULT_ICON } from '@src/constants/common'

export const queriesNameTableColumn: ColumnInterface<QueryInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.saved_query_names,
  title: 'Query Name',
  insert: ({ data, url }) => (
    <Table.EntityCell
      useIcon={data.icon || QUERY_DEFAULT_ICON}
      avatar={data.avatar}
      url={url}
    >
      {data.name}
    </Table.EntityCell>
  ),
}

export const queriesLastRunTableColumn: ColumnInterface<QueryInterface> = {
  type: CellTypes.date,
  idPoint: 'latest_run_date_time',
  dataPoint: 'latest_run_date_time',
  sortKey: 'latest_run_date_time',
  filterKey: 'latest_run_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Last run',
}

export const queriesCreatedOnTableColumn: ColumnInterface<QueryInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const queriesOwnerColumn: ColumnInterface<QueryInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.query_owner_employees,
  title: 'Owner',
  insert: ({ data }) => {
    if (data.is_system_owned) {
      return <Text color={Token.color.greyTone50}>System Provided</Text>
    }

    return <Table.EmployeeCell employee={data.owner} />
  },
}

const getStatusVariant = (status: QueryStatuses): ColorTagVariant => {
  switch (status) {
    case 'active':
      return 'success'

    default:
      return 'neutral'
  }
}

export const queriesStatusColumn: ColumnInterface<QueryInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.saved_query_statuses,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell status={data.status} variant={getStatusVariant(data.status)} />
  ),
}

export const queriesDescriptionTableColumn: ColumnInterface<QueryInterface> = {
  type: CellTypes.insert,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
  notHoverable: true,
  insert: ({ data }) =>
    data.description ? <Ellipsis tooltip="auto">{data.description}</Ellipsis> : '-',
}

export const queriesTenantDeploymentsColumn: ColumnInterface<QueryInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Tenant Deployments',
  insert: ({ data }) => {
    if (data.tenant_deployment?.all_tenants) {
      return <ColorTag>All</ColorTag>
    }

    if (data.tenant_deployment?.tenants) {
      return (
        <HStack gap="s-4">
          {data.tenant_deployment?.tenants.map(item => (
            <ColorTag key={item.id}>{item.name}</ColorTag>
          ))}
        </HStack>
      )
    }

    return null
  },
}

export const connectionsNameTableColumn: ColumnInterface<ConnectionInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Connection Name',
}

export const connectionsOwnerColumn: ColumnInterface<ConnectionInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner.name',
  filterKey: 'owner.id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
}

const getConnectionStatusVariant = (status: Statuses): ColorTagVariant => {
  switch (status) {
    case Statuses.active:
      return 'success'
    case Statuses.archived:
      return 'neutral'
    default:
      return 'danger'
  }
}

export const connectionsStatusColumn: ColumnInterface<ConnectionInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status}
      variant={getConnectionStatusVariant(data.status)}
    />
  ),
}

export const connectionsTypeTableColumn: ColumnInterface<ConnectionInterface> = {
  type: CellTypes.text,
  idPoint: 'type',
  dataPoint: 'type',
  sortKey: 'type',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
}
export const connectionsServerTableColumn: ColumnInterface<ConnectionInterface> = {
  type: CellTypes.insert,
  idPoint: 'host',
  dataPoint: 'host',
  sortKey: 'host',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <Text>{data.host}</Text>
  },
  title: 'Server',
}

export const connectionsDatabaseTableColumn: ColumnInterface<ConnectionInterface> = {
  type: CellTypes.insert,
  idPoint: 'database',
  dataPoint: 'database',
  sortKey: 'database',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <Text>{data.database || '-'}</Text>
  },
  title: 'Database',
}

export const connectionsDatabaseUserTableColumn: ColumnInterface<ConnectionInterface> = {
  type: CellTypes.insert,
  idPoint: 'username',
  dataPoint: 'username',
  sortKey: 'username',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <Text>{data.username}</Text>
  },
  title: 'Database user',
}

export const queriesDateRunHistoryTableColumn: ColumnInterface<
  RunQueryResponseInterface | RunReportResponseInterface
> = {
  type: CellTypes.dateTime,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: 'update_date_time',
  filterKey: 'update_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Date Run',
}

export const queriesRunByHistoryTableColumn: ColumnInterface<RunQueryResponseInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'triggered_by.id',
    dataPoint: 'triggered_by.name',
    sortKey: 'triggered_by.name',
    filterKey: 'triggered_by.id',
    selectorsKey: selectorKeys.employee,
    title: 'Run By',
    insert: ({ data }) => <Table.EmployeeCell employee={data.triggered_by} />,
  }

export const queriesDurationHistoryTableColumn: ColumnInterface<
  RunQueryResponseInterface | RunReportResponseInterface
> = {
  type: CellTypes.text,
  idPoint: 'execution_duration',
  dataPoint: 'execution_duration',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Execution Duration',
}

export const queriesRowsHistoryTableColumn: ColumnInterface<RunQueryResponseInterface> = {
  type: CellTypes.text,
  idPoint: 'result.row_count',
  dataPoint: 'result.row_count',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Rows Returned',
}

export const queriesParametersHistoryTableColumn: ColumnInterface<RunQueryResponseInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'parameters',
    dataPoint: 'parameters',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Parameters',
    insert: ({ data }) => {
      return (
        <HStack gap="s-4">
          {Object.entries(data.parameters).map(([key, value]) => (
            <Tag key={key} variant="faded">{`${key}=${value}`}</Tag>
          ))}
        </HStack>
      )
    },
  }

export const queriesStatusHistoryTableColumn: ColumnInterface<RunQueryResponseInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: 'status',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => {
      switch (data.status) {
        case QueryRunStatuses.Success:
          return <Icon color={Token.color.green} size="tiny" type="Check" />
        case QueryRunStatuses.Queued:
        case QueryRunStatuses.Running:
          return <Icon size="tiny" type="Time" />
        case QueryRunStatuses.Error:
          return (
            <Tooltip placement="top" text={data.detail}>
              <Icon color={Token.color.red} size="tiny" type="Close" />
            </Tooltip>
          )
        default:
          return ''
      }
    },
  }

export const reportStatusHistoryTableColumn: ColumnInterface<RunReportResponseInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: 'status',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => {
      switch (data.status) {
        case QueryRunStatuses.Success:
          return <Icon color={Token.color.green} size="tiny" type="Check" />
        case QueryRunStatuses.Queued:
        case QueryRunStatuses.Running:
          return <Icon size="tiny" type="Time" />
        case QueryRunStatuses.Error:
          return (
            <Tooltip placement="top" text={data.query_run.detail}>
              <Icon color={Token.color.red} size="tiny" type="Close" />
            </Tooltip>
          )
        default:
          return ''
      }
    },
  }

export const queriesActionsHistoryTableColumn: ColumnInterface<
  RunQueryResponseInterface | RunReportResponseInterface
> = {
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}

export const reportsNameTableColumn: ColumnInterface<ReportInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Report Name',
}

export const reportsDescriptionTableColumn: ColumnInterface<ReportInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const reportsOwnerColumn: ColumnInterface<ReportInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner.name',
  filterKey: 'owner.id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const reportsStatusColumn: ColumnInterface<ReportInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  colors: data => {
    switch (data.status) {
      case Statuses.active:
        return Token.color.green
      case Statuses.archived:
        return Token.color.red
      default:
        return Token.color.greyTone50
    }
  },
  title: 'Status',
}

export const reportsLastRunTableColumn: ColumnInterface<ReportInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'latest_run_date_time',
  dataPoint: 'latest_run_date_time',
  sortKey: 'latest_run_date_time',
  filterKey: 'latest_run_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Last run',
}

export const reportsNextRunTableColumn: ColumnInterface<ReportInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'next_run_date_time',
  dataPoint: 'next_run_date_time',
  sortKey: 'next_run_date_time',
  filterKey: 'next_run_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Next run',
}

export const columnNameTableColumn: ColumnInterface<QueryColumnInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Column Name',
}

export const columnDescriptionTableColumn: ColumnInterface<QueryColumnInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const parameterNameTableColumn: ColumnInterface<ReportParametersTableInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const parameterValueTableColumn: ColumnInterface<ReportParametersTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'value',
    dataPoint: 'value',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
    insert: ({ data }) => {
      return (
        <>
          {data.value ? (
            <Text>
              Value set to: <Text fontWeight={500}>{data.value}</Text>
            </Text>
          ) : (
            <Text>Required at Run time</Text>
          )}
        </>
      )
    },
  }

export const colomnsNameTableColumn: ColumnInterface<ReportColumnsTableInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Column Name',
}

export const colomnsReportNameTableColumn: ColumnInterface<ReportColumnsTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'alias',
    dataPoint: 'alias',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Report Column Name',
    insert: ({ data }) => {
      return <>{data.alias ? <Text>{data.alias}</Text> : <Text>{data.name}</Text>}</>
    },
  }
