import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { EngagementAudienceInterface } from '@src/interfaces/engagement'
import { SidebarContent } from './SidebarContent'

type Props = {
  open: boolean
  onClose: () => void
  audiences: EngagementAudienceInterface[]
  onAddAudiences: (audiences: EngagementAudienceInterface[]) => void
}
export const AddAudienceSidebar = ({
  open,
  onClose,
  audiences,
  onAddAudiences,
}: Props) => {
  return (
    <SideBar variant="wide" isOpen={open} title="Select audience" onClose={onClose}>
      <SidebarContent
        onClose={onClose}
        audiences={audiences}
        onAddAudiences={onAddAudiences}
      />
    </SideBar>
  )
}
