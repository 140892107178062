import { Flex, Token, IconButton, Box } from '@revolut/ui-kit'
import { QueryViewSelect } from '@src/pages/Forms/QueryForm/components/QueryViewSelect'
import React, { Suspense, useContext, useState, useMemo, useEffect } from 'react'
import {
  QueryRunStatuses,
  QueryInterface,
  ChartSettings,
  QueryViewType,
} from '@src/interfaces/dataAnalytics'
import { QueryResult } from '@src/pages/Forms/QueryForm/components/QueryResult'
import { QueryContext } from '@src/pages/Forms/QueryForm/QueryContextProvider'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { VisualisationSidebar } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/VisualisationSidebar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { FormatTableSidebar } from '@src/pages/Forms/DataAnalyticsReportForm/components/FormatTableSidebar'
import { useTableSettings } from '@src/pages/Forms/DataAnalyticsReportForm/hooks/useTableSettings'

const ExportButtonComponent = React.lazy(() => import('./ExportButton'))

interface QueryResultContainerProps {
  height: number
  queryId: number
  pending: boolean
}

export const QueryResultContainer = connect(
  ({ height, queryId, pending }: QueryResultContainerProps) => {
    const { values } = useLapeContext<QueryInterface>()
    const { fetchingError, fetchingErrorDescription, queryRunResponse, queryRunStatus } =
      useContext(QueryContext)
    const featureFlags = useSelector(selectFeatureFlags)
    const [sidebarOpen, setSidebarOpen] = useState<QueryViewType | undefined>(undefined)

    const {
      reorderSettings,
      setReorderSettings,
      sortSettings,
      setSortSettings,
      filterSettings,
      setFilterSettings,
      highlightSettings,
      setHighlightSettings,
      table,
      tableRows,
      tableColumns,
    } = useTableSettings(
      queryRunResponse?.id,
      queryId,
      queryRunStatus === QueryRunStatuses.Success,
    )

    const options = useMemo(
      () =>
        table.columns?.map(column => ({
          label: column.name,
          value: {
            ...column,
            id: column.name,
            description: null,
          },
        })) || [],
      [table.columns],
    )

    useEffect(() => {
      if (sidebarOpen) {
        setSidebarOpen(values.visualisation_type)
      }
    }, [values.visualisation_type])

    const canManageQuery = featureFlags?.includes(FeatureFlags.ReportingQueriesManagement)

    const handleSettingsChange = (settings: ChartSettings) => {
      values.visualisation = settings
    }

    const viewType = values.visualisation_type || 'table'
    const columns = table.columns

    return (
      <>
        <Flex
          backgroundColor={Token.color.widgetBackground}
          borderRadius={12}
          flexDirection="column"
          gap="s-16"
          p="s-16"
          style={{
            height,
            overflow: 'auto',
          }}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            justifyContent={canManageQuery ? 'space-between' : 'flex-end'}
          >
            {canManageQuery && (
              <QueryViewSelect
                onChange={type => {
                  values.visualisation_type = type
                }}
                value={viewType}
              />
            )}
            <Flex alignItems="center" gap="s-16">
              <Suspense fallback={null}>
                <ExportButtonComponent
                  disabled={
                    queryRunStatus !== QueryRunStatuses.Success || !queryRunResponse?.id
                  }
                  fileName={values.name || `${queryId}`}
                  runId={queryRunResponse?.id}
                  queryId={queryId}
                  iconOnly
                />
              </Suspense>
              <IconButton
                aria-label="Visualisation settings"
                disabled={!columns}
                color={!columns ? Token.color.greyTone20 : Token.color.greyTone50}
                onClick={() => setSidebarOpen(viewType)}
                useIcon="Gear"
              />
            </Flex>
          </Flex>
          <Box height={height - 80}>
            <QueryResult
              fetchingError={fetchingError}
              fetchingErrorDescription={fetchingErrorDescription}
              isLoading={pending}
              runStatus={queryRunStatus}
              runResponse={queryRunResponse}
              tab={viewType}
              chartSettings={values.visualisation}
              table={table}
              tableRows={tableRows}
              tableColumns={tableColumns}
            />
          </Box>
          {columns ? (
            <>
              <VisualisationSidebar
                open={sidebarOpen === 'chart'}
                onClose={() => setSidebarOpen(undefined)}
                columns={columns}
                chartSettings={values.visualisation}
                onChange={handleSettingsChange}
              />
              <FormatTableSidebar
                isOpen={sidebarOpen === 'table'}
                handleClose={() => setSidebarOpen(undefined)}
                reorderSettings={reorderSettings}
                setReorderSettings={setReorderSettings}
                sortSettings={sortSettings}
                setSortSettings={setSortSettings}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                highlightSettings={highlightSettings}
                setHighlightSettings={setHighlightSettings}
                options={options}
                runId={queryRunResponse?.id}
                queryId={queryId}
              />
            </>
          ) : null}
        </Flex>
      </>
    )
  },
)
