import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Box } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeeShifts } from '@src/api/attendance'
import { ROUTES } from '@src/constants/routes'
import {
  EmployeeShiftDetailsInterface,
  EmployeeShiftsStatsInterface,
} from '@src/interfaces/attendance'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'
import {
  LargeWeeklyCalendar,
  LargeWeeklyCalendarEventInterface,
} from '@src/components/LargeWeeklyCalendar'
import { useTable } from '@components/Table/hooks'

import {
  END_DATE_FILTER_KEY,
  START_DATE_FILTER_KEY,
} from '@src/features/TimeOffCalendarTable/constants'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import { AddEventPopup } from './components/AddEventPopup'
import {
  useEmployeeShiftsCalendarData,
  useEmployeeTimeOffRequestsCalendarData,
} from './hooks'

interface Props {
  data?: EmployeeTimeOffRequestsCalendarInterface
  employeeId: number
  canViewPolicy?: boolean
  canViewSchedule: boolean
  onWeekSwitch: (startDate: Date, endDate: Date) => void
  side?: React.ReactNode
}

export const TimeOffLargeWeeklyCalendar = ({
  data,
  canViewPolicy,
  canViewSchedule,
  employeeId,
  onWeekSwitch,
  side,
}: Props) => {
  const user = useSelector(selectUser)
  const isThisUser = employeeId === user.id
  const initialFilters = [
    {
      columnName: 'approval',
      filters: isThisUser
        ? []
        : [
            { id: ApprovalStatuses.Pending, name: ApprovalStatuses.Pending },
            { id: ApprovalStatuses.Approved, name: ApprovalStatuses.Approved },
            { id: ApprovalStatuses.NoStatus, name: ApprovalStatuses.NoStatus },
          ],
      nonResettable: true,
    },
  ]

  const [selectedEvent, setSelectedEvent] = useState<LargeWeeklyCalendarEventInterface>()

  const table = useTable<
    EmployeeShiftDetailsInterface,
    undefined,
    EmployeeShiftsStatsInterface
  >({ getItems: getEmployeeShifts(employeeId) }, initialFilters, undefined, {
    disableQuery: true,
    disable: !canViewSchedule,
  })

  const shiftsRequestsCalendarData = useEmployeeShiftsCalendarData(
    table.data,
    table.metadata,
    canViewPolicy,
  )
  const timeOffRequestsCalendarData = useEmployeeTimeOffRequestsCalendarData(
    canViewPolicy,
    data,
  )

  const onScheduleWeekSwitch = (startDate: Date, endDate: Date) => {
    const startDateFilter = format(startDate, 'yyyy-MM-dd')
    const endDateFilter = format(endDate, 'yyyy-MM-dd')

    table.onFilterChange([
      {
        filters: [
          {
            id: startDateFilter,
            name: startDateFilter,
          },
        ],
        columnName: START_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [
          {
            id: endDateFilter,
            name: endDateFilter,
          },
        ],
        columnName: END_DATE_FILTER_KEY,
        nonResettable: true,
      },
    ])
  }

  return (
    <>
      <Box height="90vh" width="100%">
        <LargeWeeklyCalendar
          disabled={!canViewSchedule}
          events={
            canViewSchedule
              ? [
                  ...shiftsRequestsCalendarData.non_working_days,
                  ...shiftsRequestsCalendarData.public_holidays,
                  ...shiftsRequestsCalendarData.shifts,
                ]
              : [
                  ...timeOffRequestsCalendarData.non_working_days,
                  ...timeOffRequestsCalendarData.public_holidays,
                  ...timeOffRequestsCalendarData.time_off_requests,
                ]
          }
          startDayOfWeek={1}
          eventView={['time']}
          onAddEvent={event => {
            setSelectedEvent(event)
          }}
          onChangeEvent={event => {
            setSelectedEvent({
              id: event.event.id,
              calendarId: event.event.calendarId,
              ...event.changes,
            })
          }}
          onClickEvent={({ event }) => {
            if (event.calendarId === 'work' || event.calendarId === 'break') {
              return setSelectedEvent(event)
            }

            if (event.calendarId === 'time_off' && canViewPolicy) {
              return navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                  employeeId,
                  id: event.id,
                }),
              )
            }

            return undefined
          }}
          onSwitchWeek={canViewSchedule ? onScheduleWeekSwitch : onWeekSwitch}
          side={side}
        />
      </Box>
      {!!selectedEvent && (
        <AddEventPopup
          employeeId={employeeId}
          onAfterSubmit={() => {
            setSelectedEvent(undefined)
            table.refresh()
          }}
          onClose={() => setSelectedEvent(undefined)}
          selectedEvent={selectedEvent}
        />
      )}
    </>
  )
}
