import React from 'react'
import { CheckboxGroup } from '@revolut/ui-kit'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export interface NewCheckboxGroupProps<T> {
  value?: T[]
  disabled?: boolean
  onChange?: (value: T[]) => void
  options: RadioSelectOption<T>[]
  renderCheckbox?: (radio: React.ReactNode) => React.ReactNode
  readOnly?: boolean
}

export interface IdInterface {
  id?: number | string
}

const NewCheckboxGroup = <T extends IdInterface>({
  options,
  onChange,
  renderCheckbox = checkbox => checkbox,
  value,
  readOnly,
  ...props
}: NewCheckboxGroupProps<T>) => {
  const genericValue = value?.map(val => val.id)

  const mapValues = (values: any) => {
    if (onChange === undefined) {
      return
    }
    const selected = options.filter(o => values.includes(o.value.id)).map(o => o.value)
    onChange(selected)
  }

  return (
    <CheckboxGroup
      onChange={readOnly ? () => {} : mapValues}
      value={genericValue}
      {...props}
    >
      {group => (
        <>
          {options.map((o, index) => (
            <React.Fragment key={index}>
              {renderCheckbox(
                <NewCheckbox
                  {...group.getInputProps({ value: o.value.id })}
                  label={o.label}
                />,
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </CheckboxGroup>
  )
}

export default NewCheckboxGroup
