import React from 'react'
import { Button, Flex, Header, Link } from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { WrapperCss } from '../components/PageContentWrapper'
import { Steps, StepsData } from '../components/Steps'

interface SummaryProps {
  allStepsCompleted: boolean
  joiningDateTime?: string
  landingPageUrl?: string | null
  steps: StepsData[]
}

export const Summary = ({
  allStepsCompleted,
  joiningDateTime,
  landingPageUrl,
  steps,
}: SummaryProps) => {
  return (
    <Flex
      css={WrapperCss}
      data-testid="summary-tab"
      flexDirection="column"
      flex="1"
      width="100%"
    >
      <PageBody>
        <Header mb="s-8" variant="main">
          <Header.Title>
            {allStepsCompleted
              ? 'Your onboarding is complete 🥳'
              : 'Onboarding progress summary'}
          </Header.Title>
          {allStepsCompleted ? null : (
            <Header.Subtitle>
              Before finalising your onboarding, please check if all the steps were
              completed. If not, go back to these were some action is pending completion.
            </Header.Subtitle>
          )}
          {joiningDateTime && (
            <Header.Description>
              Expected joining date: {joiningDateTime}
            </Header.Description>
          )}
        </Header>
        <Steps steps={steps} />
      </PageBody>
      {allStepsCompleted && landingPageUrl && (
        <PageActions>
          <Button target="_blank" href={landingPageUrl} use={Link}>
            Finalise onboarding
          </Button>
        </PageActions>
      )}
    </Flex>
  )
}
