import React from 'react'
import { Flex, Link } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'

export const extractHostname = (url: string) => {
  try {
    return new URL(url).hostname
  } catch (e) {
    return url
  }
}

type Props = {
  label?: string
  link: string
}

const OpenInNewTab = ({ label = 'Open in new tab', link }: Props) => {
  return (
    <Link href={link} target="_blank" rel="noopener noreferrer">
      <Flex alignItems="center">
        <LinkExternal size={14} />
        &nbsp;{label}
      </Flex>
    </Link>
  )
}

export default OpenInNewTab
