import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { PaygroupTable } from '@src/apps/People/Payroll/PaygroupTable/PaygroupTable'

export const Configuration = () => {
  return (
    <PageBody maxWidth="100%" mt="s-32">
      <PaygroupTable />
    </PageBody>
  )
}
