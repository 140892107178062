import { upperFirst } from 'lodash'

export const removeUnderscore = (str: string) => str.replace(/_/g, ' ')

export const decl = (count: number, single: string, multiple: string) =>
  count === 1 ? single : multiple

export const isInt = (value: string) => {
  const intRegex = new RegExp(/^-?\d+$/)
  return intRegex.test(value)
}

export const isPositiveInt = (value: string) => {
  const intRegex = new RegExp(/^\d+$/)
  return intRegex.test(value)
}

export const isFloat = (
  value: string,
  maxNumDecimalPlaces?: number,
  onlyPositive?: boolean,
) => {
  const sign = onlyPositive ? '' : '-?'
  const amountOfDecimals = maxNumDecimalPlaces ? `{0,${maxNumDecimalPlaces}}` : '*'

  const floatRegex = new RegExp(`^${sign}\\d+(\\.)?\\d${amountOfDecimals}$`)
  return floatRegex.test(value)
}

// https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
export const isEmail = (str: string) =>
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
    str,
  )

// https://stackoverflow.com/a/8234912/2013580
export const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
)

export const formatSnakeCase = (status?: string | null, fallback = '-') => {
  if (!status) {
    return fallback
  }

  return upperFirst(status).replaceAll('_', ' ')
}

export const isHTMLFieldEmpty = (html?: string | null) => !html || html === '<p><br></p>'

// we cannot remove all non ASCII characters, so removing just directional characters which breaks our BE validation and not visible
export const obfuscateNonPrintableCharacters = (str: string) =>
  str
    .replace(/\u202d/g, '')
    .replace(/\u202c/g, '')
    .replace(/\u2212/g, '-')
