import React, { useState } from 'react'
import isArray from 'lodash/isArray'
import { VStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  getCompensationBandsTemplateUrl,
  importCompensationBands,
} from '@src/api/importData'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { HowToUploadGuide } from '@src/features/BulkDataImport/BulkDataImportUploadFile'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import { pathToUrl } from '@src/utils/router'
import { saveFile } from '@src/utils'

export const Upload = () => {
  const [importPending, setImportPending] = useState(false)
  const [downloadTemplatePending, setDownloadTemplatePending] = useState(false)

  const onFileUpload = (file: File) => {
    setImportPending(true)

    importCompensationBands(file)
      .then(response => {
        navigateTo(
          pathToUrl(ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.REVIEW_DATA, {
            id: response.data.id,
          }),
        )
      })
      .catch(() => setImportPending(false))
  }

  const onDownloadTemplate = () => {
    setDownloadTemplatePending(true)
    getCompensationBandsTemplateUrl()
      .then(details => saveFile(details, 'bands'))
      .finally(() => setDownloadTemplatePending(false))
  }

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.APPS.COMPENSATION.BANDS}
        title="Import data"
        subtitle="Bands"
        mb="28px"
      />
      <PageBody>
        <VStack space="s-16">
          <HowToUploadGuide importName="bands" />

          <ImportFile
            downloadTemplatePending={downloadTemplatePending}
            importPending={importPending}
            onDownloadTemplate={onDownloadTemplate}
            onFileUpload={file => {
              if (isArray(file)) {
                onFileUpload(file[0])
              } else if (file) {
                onFileUpload(file)
              }
            }}
            supportedFormats={['CSV', 'XLS']}
          />
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}
