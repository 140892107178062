import React, { useState } from 'react'
import { TextButton } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { deleteSlackBot } from '@src/api/integrations'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

interface SlackBotActionsProps {
  id: number
  onDelete: () => void
}

const SlackBotActions = ({ id, onDelete }: SlackBotActionsProps) => {
  const [isDeleteSubmitOpen, setIsDeleteSubmitOpen] = useState(false)
  const [isDeletePending, setIsDeletePending] = useState(false)

  return (
    <>
      <TextButton
        color="blue"
        onClick={e => {
          e.stopPropagation()
          navigateTo(pathToUrl(ROUTES.FEATURES.INTEGRATION.SLACK.BOT, { id }))
        }}
        mr="s-4"
      >
        Edit
      </TextButton>
      <TextButton
        color="red"
        onClick={async e => {
          e.stopPropagation()
          setIsDeleteSubmitOpen(true)
        }}
      >
        Remove
      </TextButton>
      <ConfirmationDialog
        open={isDeleteSubmitOpen}
        loading={isDeletePending}
        body={''}
        label="Are you sure you want to unregister slack bot?"
        onClose={() => setIsDeleteSubmitOpen(false)}
        onReject={() => setIsDeleteSubmitOpen(false)}
        onConfirm={async () => {
          setIsDeletePending(true)

          try {
            await deleteSlackBot(id)
            onDelete()
          } finally {
            setIsDeleteSubmitOpen(false)
            setIsDeletePending(false)
          }
        }}
      />
    </>
  )
}

export default SlackBotActions
