import React, { useState, useEffect } from 'react'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { $insertNodes } from 'lexical'

interface Props {
  initialHtml?: string
  onHtmlChanged: (html: string) => void
}

export const HtmlPlugin = ({ initialHtml, onHtmlChanged }: Props) => {
  const [editor] = useLexicalComposerContext()

  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (!initialHtml || !isFirstRender) {
      return
    }

    setIsFirstRender(false)

    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(initialHtml, 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      $insertNodes(nodes)
    })
  }, [])

  return (
    <OnChangePlugin
      onChange={editorState => {
        editorState.read(() => {
          onHtmlChanged($generateHtmlFromNodes(editor))
        })
      }}
    />
  )
}
