import React, { useState } from 'react'
import {
  HStack,
  RadioGroup,
  Subheader,
  VStack,
  Widget,
  Image,
  Text,
  Radio,
  Tag,
  Avatar,
} from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { LocalStorageKeys } from '@src/store/auth/types'
import { RowHeight } from '@src/interfaces/table'

export const Appearance = () => {
  const { themeSettingValue, setTheme } = useAppTheme()

  const [rowHeight, setRowHeight] = useState<RowHeight>(() => {
    const localStorageRowHeight = localStorage.getItem(LocalStorageKeys.TABLE_ROW_HEIGHT)

    if (localStorageRowHeight === 'large') {
      return 'large'
    }
    return 'medium'
  })

  return (
    <PageWrapper>
      <PageHeader title="Appearance" backUrl={ROUTES.MAIN} />

      <PageBody>
        <Subheader>
          <Subheader.Title>Theme</Subheader.Title>
        </Subheader>

        <Widget p="s-16">
          <RadioGroup
            value={themeSettingValue}
            onChange={val => {
              if (val === 'dark' || val === 'light') {
                setTheme(val)
              }
            }}
          >
            {group => (
              <HStack space="s-32" css={{ justifyContent: 'center', userSelect: 'none' }}>
                <VStack
                  use="label"
                  space="s-12"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Image
                    variant="round"
                    width={129}
                    src="https://assets.revolut.com/revoluters/assets/theme-dark.png"
                    alt="Dark"
                  />
                  <HStack space="s-8">
                    <Text>Transparent</Text>
                    <Tag variant="outlined">BETA</Tag>
                  </HStack>
                  <Radio
                    aria-label="Transparent"
                    {...group.getInputProps({ value: 'dark' })}
                  />
                </VStack>

                <VStack
                  use="label"
                  space="s-12"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Image
                    variant="round"
                    width={129}
                    src="https://assets.revolut.com/revoluters/assets/theme-light.png"
                    alt="Light"
                  />
                  <Text>Light</Text>
                  <Radio
                    aria-label="Light"
                    {...group.getInputProps({ value: 'light' })}
                  />
                </VStack>
              </HStack>
            )}
          </RadioGroup>
        </Widget>

        <Subheader>
          <Subheader.Title>Table density</Subheader.Title>
        </Subheader>

        <Widget p="s-16">
          <RadioGroup
            value={rowHeight}
            onChange={val => {
              if (val) {
                setRowHeight(val)
                localStorage.setItem(LocalStorageKeys.TABLE_ROW_HEIGHT, val)
              }
            }}
          >
            {group => (
              <HStack space="s-32" css={{ justifyContent: 'center', userSelect: 'none' }}>
                <VStack
                  use="label"
                  space="s-12"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Avatar useIcon="ViewListSmall" />
                  <Text>Condensed cells</Text>
                  <Radio
                    aria-label="Condensed cells"
                    {...group.getInputProps({ value: 'medium' })}
                  />
                </VStack>

                <VStack
                  use="label"
                  space="s-12"
                  align="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Avatar useIcon="ViewListLarge" />
                  <Text>Large cells</Text>
                  <Radio
                    aria-label="Large cells"
                    {...group.getInputProps({ value: 'large' })}
                  />
                </VStack>
              </HStack>
            )}
          </RadioGroup>
        </Widget>
      </PageBody>
    </PageWrapper>
  )
}
