import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { Virtuoso } from 'react-virtuoso'
import React, { RefObject } from 'react'

import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import {
  EligibleGroupItemContext,
  renderEligibilityGroupItem,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/components/EligibilityList/ListItems'
import { useScrollParent } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useScrollParent'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  scrollRef?: RefObject<HTMLDivElement>
}

export const PopupBody = ({ cycle, eligibleGroups, scrollRef }: Props) => {
  const { toEditEligibleEmployees } = useNavigation()
  const scrollParent = useScrollParent<HTMLDivElement>(scrollRef)

  return (
    <Virtuoso<EligibleGroupInterface, EligibleGroupItemContext>
      overscan={10}
      height={500}
      data={eligibleGroups}
      itemContent={(index, props) =>
        renderEligibilityGroupItem(index, props, {
          onItemActionClick: () => toEditEligibleEmployees(cycle)(),
        })
      }
      customScrollParent={scrollParent}
    />
  )
}
