import React from 'react'
import { MoreBar, Link as UILink } from '@revolut/ui-kit'
import Icon from '@src/components/Icon/Icon'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import { slackChannelNameToUrl } from './helpers'

type Props = {
  group?: CommunicationGroupInterface | null
}
export const CommunicationActions = ({ group }: Props) => {
  // until any more channels are not available we hide actions for enitites without
  // Slack channel
  if (!group?.slack_channel_name) {
    return null
  }
  return (
    <>
      <MoreBar.Action
        useIcon={<Icon type="SlackMono" size="tiny" />}
        use={UILink}
        href={slackChannelNameToUrl(group.slack_channel_name)}
        target="_blank"
      >
        Slack
      </MoreBar.Action>
      {/* TODO: uncomment when supported by BE
      <MoreBar.Action disabled useIcon={<Envelope color={Color.BLUE_20} size={16} />}>
        Email
      </MoreBar.Action>
      <MoreBar.Action disabled useIcon={<Megaphone color={Color.BLUE_20} size={16} />}>
        News
      </MoreBar.Action> */}
    </>
  )
}
