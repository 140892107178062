import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { navigateTo, useOpenNewTab } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

interface NavigationOptions {
  blank?: boolean
}

type NavigationFunction = (
  cycle: ReviewCyclesInterface,
  params?: Record<string, unknown>,
  options?: NavigationOptions,
) => () => void

type LinkCreatorFunction<T> = (params: T) => string

interface Navigation {
  toPerformanceSummary: NavigationFunction
  toPerformanceDepartmentGoals: NavigationFunction
  toPerformanceTeamGoals: NavigationFunction
  toDepartmentGoals: NavigationFunction
  toTeamGoals: NavigationFunction
  toEditEligibleEmployees: NavigationFunction
  toEligibleEmployeesList: NavigationFunction
  makeDepartmentLink: LinkCreatorFunction<{ id: number }>
  makeTeamLink: LinkCreatorFunction<{ id: number }>
}

const performanceSummaryPath = ROUTES.PERFORMANCE.REVIEWS_CALIBRATION
const performanceGoalsPath = ROUTES.PERFORMANCE.GOALS.GENERAL
const departmentGoalsPath = ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL
const teamGoalsPath = ROUTES.FORMS.TEAM.GOALS.GENERAL
const editEligibleEmployeesPath = ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES
const eligibleEmployeesListPath = ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES_LIST
const departmentPath = ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL
const teamPath = ROUTES.FORMS.TEAM.GOALS.GENERAL

const selectorsStaleTimeMS = 1000 * 60 * 3

export const useNavigation = (): Navigation => {
  const openInNewTab = useOpenNewTab()
  const { data: goalOwnersTypes = [] } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    'v1',
    { staleTime: selectorsStaleTimeMS },
  )

  const toPerformanceSummary: NavigationFunction =
    ({ offset }) =>
    () => {
      navigateTo(
        pathToUrl(
          performanceSummaryPath,
          {},
          offset !== undefined ? { cycle__offset: offset.toString() } : {},
        ),
      )
    }

  const toPerformanceDepartmentGoals: NavigationFunction = cycle => () => {
    const goalsSourceId = cycleModel.isTest(cycle)
      ? cycleModel.getParentCycleId(cycle)
      : cycle.id
    const goalOwnerIdParam = goalOwnersTypes.find(
      ({ model }) => model === 'department',
    )?.id
    const queryParams = {
      cycle__id: String(goalsSourceId),
      ...(goalOwnerIdParam ? { content_type_id: goalOwnerIdParam.toString() } : {}),
    }

    navigateTo(pathToUrl(performanceGoalsPath, {}, queryParams))
  }

  const toPerformanceTeamGoals: NavigationFunction = cycle => () => {
    const goalsSourceId = cycleModel.isTest(cycle)
      ? cycleModel.getParentCycleId(cycle)
      : cycle.id
    const goalOwnerIdParam = goalOwnersTypes.find(({ model }) => model === 'teams')?.id
    const queryParams = {
      cycle__id: String(goalsSourceId),
      ...(goalOwnerIdParam ? { content_type_id: goalOwnerIdParam.toString() } : {}),
    }

    navigateTo(pathToUrl(performanceGoalsPath, {}, queryParams))
  }

  const toDepartmentGoals: NavigationFunction = (cycle, params) => () => {
    const goalsSourceId = cycleModel.isTest(cycle)
      ? cycleModel.getParentCycleId(cycle)
      : cycle.id

    navigateTo(
      pathToUrl(departmentGoalsPath, params ?? {}, { cycle__id: String(goalsSourceId) }),
    )
  }

  const toTeamGoals: NavigationFunction = (cycle, params) => () => {
    const goalsSourceId = cycleModel.isTest(cycle)
      ? cycleModel.getParentCycleId(cycle)
      : cycle.id

    navigateTo(
      pathToUrl(teamGoalsPath, params ?? {}, { cycle__id: String(goalsSourceId) }),
    )
  }

  const toEditEligibleEmployees: NavigationFunction =
    ({ id }, _params, options) =>
    () =>
      options?.blank
        ? openInNewTab(pathToUrl(editEligibleEmployeesPath, { id }))
        : navigateTo(pathToUrl(editEligibleEmployeesPath, { id }))

  const toEligibleEmployeesList: NavigationFunction =
    ({ id }) =>
    () =>
      navigateTo(pathToUrl(eligibleEmployeesListPath, {}, { cycle__id: String(id) }))

  const makeDepartmentLink: LinkCreatorFunction<{ id: number }> = (params): string =>
    pathToUrl(departmentPath, params)

  const makeTeamLink: LinkCreatorFunction<{ id: number }> = (params): string =>
    pathToUrl(teamPath, params)

  return {
    toPerformanceSummary,
    toPerformanceDepartmentGoals,
    toPerformanceTeamGoals,
    toDepartmentGoals,
    toTeamGoals,
    toEditEligibleEmployees,
    toEligibleEmployeesList,
    makeDepartmentLink,
    makeTeamLink,
  }
}
