import React from 'react'
import { startCase } from 'lodash'
import { Text, Flex, Token } from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportBandsInterface } from '@src/interfaces/importCompensationBands'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { formatMoneyWithCode } from '@src/utils/format'

interface CellWithErrorProps {
  data: ImportBandsInterface
  prop: keyof Omit<ImportBandsInterface, 'errors'>
}

const CellWithError: React.FC<CellWithErrorProps> = ({ prop, data, children }) => {
  const errors = data.errors?.[prop]
  const fallbackValue = data[prop]
  const hasError = !!errors?.length

  return (
    <Flex>
      <Tooltip hide={!hasError} noArrow placement="top" text={errors?.join('\n')}>
        <Flex gap="s-8" justifyContent="flex-start">
          <Text color={hasError ? Token.color.red : Token.color.foreground}>
            {children || fallbackValue}
          </Text>
          {hasError ? <ExclamationMarkOutline color={Token.color.red} size={16} /> : null}
        </Flex>
      </Tooltip>
    </Flex>
  )
}

export const importBandsSpecialisationColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Specialisation',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="specialisation">
        {data.specialisation}
      </CellWithError>
    )
  },
}

export const importBandsSeniorityColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="seniority">
        {data.seniority}
      </CellWithError>
    )
  },
}

export const importBandsSeniorityLevelColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority_level',
  dataPoint: 'seniority_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority Level',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="seniority_level">
        {data.seniority_level}
      </CellWithError>
    )
  },
}

export const importBandsLocationColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Location',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="location">
        {data.location}
      </CellWithError>
    )
  },
}

export const importBandsBenchmarkTypeColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'benchmark_type',
  dataPoint: 'benchmark_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Benchmark Type',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="benchmark_type">
        {data.benchmark_type}
      </CellWithError>
    )
  },
}

export const importBandsLowerBandColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'lower_band',
  dataPoint: 'lower_band',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Lower Band',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="lower_band">
        {data.lower_band != null && data.currency_iso_code != null
          ? formatMoneyWithCode(data.lower_band, data.currency_iso_code)
          : null}
      </CellWithError>
    )
  },
}

export const importBandsUpperBandColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'upper_band',
  dataPoint: 'upper_band',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Upper Band',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="upper_band">
        {data.upper_band != null && data.currency_iso_code != null
          ? formatMoneyWithCode(data.upper_band, data.currency_iso_code)
          : null}
      </CellWithError>
    )
  },
}

export const importBandsActionColumn: ColumnInterface<ImportBandsInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <CellWithError data={data} prop="action">
        {startCase(data.action)}
      </CellWithError>
    )
  },
}
