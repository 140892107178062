import React, { useMemo } from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import {
  Box,
  chain,
  Flex,
  HStack,
  Icon,
  Item,
  ItemSkeleton,
  Text,
  Token,
} from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { HiringStageJobPostings } from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/HiringStageJobPostings'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import {
  isArchivedOpportunityOption,
  isInterviewRound,
  isInterviewRoundSelectOption,
  isNewOption,
  OptionType,
} from '@src/pages/Forms/Candidate/StagesWidget/utils'
import pluralize from 'pluralize'
import { ColorTag } from '@src/components/ColorTag/ColorTag'

type SpecialisationHiringStageProps = {
  item: InterviewRoundInterface
  isMainRound: boolean
}

const SpecialisationHiringStage = ({
  item,
  isMainRound,
}: SpecialisationHiringStageProps) => {
  const mainJobPosting = item?.application
  const specialisationName = item?.specialisation?.name
  const jobPostingName = mainJobPosting?.job_posting?.name
  const opportunityTitle = jobPostingName || specialisationName
  return (
    <>
      <Text
        variant="h6"
        color={item.archived_reason ? Token.color.greyTone50 : undefined}
      >
        {chain(
          opportunityTitle,
          isMainRound && <Text color={Token.color.success}>Main</Text>,
          !!item.archived_reason && <Text>Archived</Text>,
        )}
      </Text>
      {item.archived_reason && (
        <Box>
          <Text variant="small" color={Token.color.greyTone50}>
            {chain(
              item.latest_interview_stage_updated_date_time &&
                formatDate(item.latest_interview_stage_updated_date_time),
              item.archived_reason.name,
            )}
          </Text>
        </Box>
      )}
      {item.latest_interview_stage && (
        <Box>
          <Text variant="small" color={Token.color.greyTone50}>
            {item.latest_interview_stage.interview_type_display}&nbsp;
            {!!item.stage_count &&
              Number.isFinite(item.latest_interview_stage.interview_number) &&
              `(${item.latest_interview_stage.interview_number}/${item.stage_count})`}
          </Text>
        </Box>
      )}
      {jobPostingName ? (
        <Box>
          <Text variant="small" color={Token.color.greyTone50}>
            Specialisation: {specialisationName}
          </Text>
        </Box>
      ) : (
        <HiringStageJobPostings
          round={item}
          textVariant="small"
          labelVariant="small"
          textColor={Token.color.greyTone50}
        />
      )}
    </>
  )
}

const ConsiderNewOpportunity = () => {
  return (
    <Flex alignItems="center" color={Token.color.blue} wrap="noWrap" gap="s-4">
      <Icon name="Plus" size={16} color={Token.color.blue} />
      Consider for another opportunity
    </Flex>
  )
}

type HiringStageSwitcherProps = {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
  onChange: (roundId: number) => void
}

const mapRoundToOption = (
  item: InterviewRoundInterface,
): RadioSelectOption<OptionType> => {
  const mainJobPosting = item?.application
  const jobPostingName = mainJobPosting?.job_posting?.name
  const label = jobPostingName || item?.specialisation?.name
  return {
    label,
    value: {
      ...item,
    },
  }
}

const getReferenceUrl = (option: OptionType) => {
  if (option?.id === 'new') {
    return undefined
  }
  const mainJobPosting = option?.application
  const jobPosting = mainJobPosting?.job_posting
  if (jobPosting) {
    return option?.specialisation
      ? pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
          specId: option.specialisation.id,
          id: jobPosting?.id,
        })
      : undefined
  }
  return option?.specialisation
    ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.CANDIDATES, {
        id: option.specialisation.id,
      })
    : undefined
}

type OpportunityCountDescriptionProps = {
  activeCount: number
  archivedCount: number
  currentIsArchived: boolean
}

export const OpportunityCountDescription = ({
  activeCount,
  archivedCount,
  currentIsArchived,
}: OpportunityCountDescriptionProps) => {
  let active = activeCount
  let archived = archivedCount
  if (currentIsArchived) {
    archived -= 1
  } else {
    active -= 1
  }
  if (active && archived) {
    return (
      <>
        Also in {active} active and {archived} archived opportunities
      </>
    )
  }
  if (active) {
    return (
      <>
        Also in {active} active {pluralize('opportunity', active)}
      </>
    )
  }
  if (archived) {
    return (
      <>
        Also in {archived} archived {pluralize('opportunity', archived)}
      </>
    )
  }
  return <>Opportunity</>
}

export const HiringStageSwitcher = ({
  candidate,
  round,
  onChange,
}: HiringStageSwitcherProps) => {
  const candidateId = candidate?.id
  const mainRoundId = candidate?.active_interview_round?.id
  const roundId = round?.id

  const { data, isLoading } = useGetFullInterviewRounds(candidateId || null)

  const { activeCount, archivedCount, options } = useMemo<{
    activeCount: number
    archivedCount: number
    options: RadioSelectOption<OptionType>[]
  }>(() => {
    const list = (data || []).reduce<{
      active: RadioSelectOption<OptionType>[]
      archived: RadioSelectOption<OptionType>[]
    }>(
      (acc, item) => {
        const opt = mapRoundToOption(item)
        if (item.archived_reason) {
          acc.archived.push(opt)
        } else {
          acc.active.push(opt)
        }

        return acc
      },
      { active: [], archived: [] },
    )
    return {
      options: [
        ...list.active,
        { key: 'new', id: 'new', label: 'new', value: { id: 'new' } },
        ...list.archived,
      ],
      activeCount: list.active.length,
      archivedCount: list.archived.length,
    }
  }, [data])

  const opt = options?.find(item => item.value.id === roundId)
  const optionValue = opt?.value

  if (isLoading) {
    return <ItemSkeleton />
  }

  return (
    <RadioSelectInput<OptionType>
      label="Opportunity"
      labelPath="label"
      value={optionValue}
      searchable={false}
      onChange={item => {
        if (item && isInterviewRound(item)) {
          onChange(item.id)
        } else {
          navigateTo(
            pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
              candidateId,
            }),
          )
        }
      }}
      loading={isLoading}
      disabled={isLoading}
      options={options}
      allowCreateNewOption
      referenceUrl={optionValue ? getReferenceUrl(optionValue) : undefined}
      renderInput={(open, setOpen, ref) => (
        <Item
          aria-label="Opportunity selector"
          ref={ref}
          use="label"
          onClick={() => {
            setOpen(!open)
          }}
        >
          <Item.Content>
            <Item.Title>
              <HStack align="center" gap="s-4">
                <Text>{opt?.label || 'Select opportunity'}</Text>
                {!!isArchivedOpportunityOption(optionValue) && (
                  <ColorTag icon="Archive" variant="neutral">
                    Archived
                  </ColorTag>
                )}
                <Icon use="span" name={open ? 'ChevronUp' : 'ChevronDown'} />
              </HStack>
            </Item.Title>
            <Item.Description>
              <OpportunityCountDescription
                activeCount={activeCount}
                archivedCount={archivedCount}
                currentIsArchived={!!isArchivedOpportunityOption(optionValue)}
              />
            </Item.Description>
          </Item.Content>
        </Item>
      )}
    >
      {option => {
        if (isNewOption(option)) {
          return <ConsiderNewOpportunity />
        }
        if (isInterviewRoundSelectOption(option)) {
          return (
            <SpecialisationHiringStage
              item={option.value}
              isMainRound={mainRoundId === option.value.id}
            />
          )
        }
        return null
      }}
    </RadioSelectInput>
  )
}
