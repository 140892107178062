import React from 'react'
import {
  Avatar,
  Copyable,
  Group,
  HStack,
  Icon,
  IconButton,
  Item,
  Separator,
  Text,
  Token,
  Tooltip,
  VStack,
  useTooltip,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { usePreviewPendingChanges } from '@src/components/FormPreview/changelog'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { changelogApiRequisition } from '../General/General'
import { Statuses } from '@src/interfaces'
import { getPendingRequisitionChangesRequest } from '@src/api/changelog'
import { useGetHiringRiskLocations } from '../utils'
import PreviewLocations from './PreviewLocations'
import { SectionOptions } from '@src/interfaces/customFields'
import { PermissionTypes } from '@src/store/auth/types'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'

type HiringProgressDetailsProps = {
  candidatesHired: number
  approvedHeadcount: number
}

const HiringProgressDetails = ({
  candidatesHired,
  approvedHeadcount,
}: HiringProgressDetailsProps) => {
  const tooltip = useTooltip()
  return (
    <HStack align="center" gap="s-8">
      <Text>
        {candidatesHired ?? 0}/{approvedHeadcount ?? 0}
      </Text>
      <IconButton size={16} useIcon="InfoOutline" {...tooltip.getAnchorProps()} />
      <Tooltip {...tooltip.getTargetProps()}>
        Candidates hired / Approved headcount
      </Tooltip>
    </HStack>
  )
}

type RequisitionDetailsProps = {
  showPendingChanges: boolean
}

const RequisitionDetails = ({ showPendingChanges }: RequisitionDetailsProps) => {
  const { values } = useLapeContext<RequisitionInterface>()
  const pendingChangelog = usePreviewPendingChanges<RequisitionInterface>({
    api: changelogApiRequisition,
    data: values,
    showPendingChanges,
    disabled: values.status !== Statuses.pending,
    pendingChangesRequest: getPendingRequisitionChangesRequest,
  })
  const { highRiskLocations, mediumRiskLocations } = useGetHiringRiskLocations()
  const canViewBackfilledEmployees = values.field_options?.permissions?.includes(
    PermissionTypes.ViewBackfillsRequisitions,
  )
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="InfoOutline" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Requisition details</Item.Title>
        </Item.Content>
      </Item>
      <FormPreview<RequisitionInterface> data={values} changelog={pendingChangelog}>
        <FormPreview.Item<RequisitionInterface>
          field=""
          title="Hired headcount"
          insert={d => (
            <HiringProgressDetails
              candidatesHired={d.hired_headcount}
              approvedHeadcount={d.headcount}
            />
          )}
        />
        <FormPreview.Item<RequisitionInterface>
          to={data =>
            data.team?.id
              ? pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.team?.id })
              : undefined
          }
          title="Team"
          field="team.name"
        />
        <FormPreview.Item<RequisitionInterface>
          title="Specialisation"
          to={data =>
            data.specialisation?.id
              ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                  id: data.specialisation?.id,
                })
              : undefined
          }
          field="specialisation.name"
        />
        <FormPreview.Item<RequisitionInterface>
          title="Seniority"
          field="seniority_min.name"
          insert={data =>
            data.seniority_min?.id !== data.seniority_max?.id
              ? `${data.seniority_min?.name} - ${data.seniority_max?.name}`
              : data.seniority_min?.name ?? '-'
          }
        />
        <FormPreview.Item type="employee" title="Hiring manager" field="line_manager" />
        <FormPreview.Item<RequisitionInterface>
          title="Locations"
          field="locations"
          insert={data => (
            <PreviewLocations
              specialisationId={values.specialisation.id}
              selectedLocations={data.locations || []}
              highRiskLocations={highRiskLocations}
              mediumRiskLocations={mediumRiskLocations}
            />
          )}
        />
        <FormPreview.Item<RequisitionInterface>
          field=""
          title="Position in queue"
          insert={d =>
            d.pipeline_queue_position && d.pipeline_queue_total
              ? `${d.pipeline_queue_position}/${d.pipeline_queue_total}`
              : '-'
          }
        />
        {values.is_regulated && (
          <FormPreview.Item title="Regulated role" field="is_regulated" type="boolean" />
        )}
        <Separator />
        <FormPreview.Item<RequisitionInterface>
          title="ID"
          field="id"
          insert={data => (
            <Copyable
              value={String(data.id)}
              labelButtonCopy="Copy"
              onCopy={() => {
                pushNotification({
                  value: 'Id copied to clipboard',
                  duration: SUCCESS_DEFAULT_DURATION,
                  type: NotificationTypes.success,
                })
              }}
            >
              {data?.id}
            </Copyable>
          )}
        />
        <FormPreview.Item type="employee" title="Recruiter" field="recruiter" />
        <FormPreview.Item<RequisitionInterface>
          title="Type"
          field="backfill"
          insert={data => (data.backfill ? 'Backfill (Replacement)' : 'New hire')}
        />
        {values.backfill && (
          <FormPreview.Details<RequisitionInterface>
            title="Employee(s) being backfilled"
            insert={data =>
              canViewBackfilledEmployees ? (
                <VStack space="s-8">
                  {data.backfill_employees?.length
                    ? data.backfill_employees?.map(employee => (
                        <UserWithAvatar {...employee} key={employee.id} />
                      ))
                    : '-'}
                </VStack>
              ) : (
                <Icon name="16/LockOutline" color={Token.color.greyTone50} />
              )
            }
          />
        )}
        <FormPreview.Item
          type="date"
          title="Ideal start date"
          field="potential_start_date"
        />
        <FormPreview.Details title="Creation reason" field="description" />
        <FormPreview.CustomFields
          sectionId={SectionOptions.Requisitions}
          requisitionId={values.id}
        />
      </FormPreview>
    </Group>
  )
}

export default RequisitionDetails
