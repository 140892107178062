import React, { useState } from 'react'
import isArray from 'lodash/isArray'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import { bonusesUploadTemplateURL, importBonuses } from '@src/api/importData'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

export const Upload = () => {
  const [importPending, setImportPending] = useState(false)

  const onFileUpload = (file: File) => {
    setImportPending(true)

    importBonuses(file)
      .then(response => {
        navigateTo(
          pathToUrl(ROUTES.FORMS.IMPORT_DATA.BONUSES.REVIEW_DATA, {
            id: response.data.id,
          }),
        )
      })
      .catch(() => setImportPending(false))
  }

  const onDownloadTemplate = () => {
    window.open(bonusesUploadTemplateURL, '_blank')
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Import data"
        subtitle="Bonuses"
        backUrl={ROUTES.APPS.COMPENSATION.BONUSES}
        mb="28px"
      />
      <PageBody>
        <ImportFile
          importPending={importPending}
          onDownloadTemplate={onDownloadTemplate}
          supportedFormats={['CSV', 'XLS']}
          onFileUpload={file => {
            if (isArray(file)) {
              onFileUpload(file[0])
            } else if (file) {
              onFileUpload(file)
            }
          }}
        />
      </PageBody>
    </PageWrapper>
  )
}
