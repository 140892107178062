import React from 'react'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { subscriptionInvoicesRequests, useTestCreateOpenInvoice } from '@src/api/plans'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { TableNames } from '@src/constants/table'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  subscriptionInvoicesDateColumn,
  subscriptionInvoicesOrderNumberColumn,
  subscriptionInvoicesTotalPriceColumn,
  subscriptionInvoicesActionColumn,
  subscriptionInvoicesStatusColumn,
  subscriptionInvoicesPeriodColumn,
} from '@src/constants/columns/plans'
import { FeatureFlags } from '@src/store/auth/types'
import { selectFeatureFlags } from '@src/store/auth/selectors'

const row: RowInterface<SubscriptionInvoiceInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.PLANS.INVOICE_DETAILS, { id: data.id })),
  cells: [
    {
      ...subscriptionInvoicesPeriodColumn,
      width: 150,
    },
    {
      ...subscriptionInvoicesDateColumn,
      width: 100,
    },
    {
      ...subscriptionInvoicesOrderNumberColumn,
      width: 400,
    },
    {
      ...subscriptionInvoicesStatusColumn,
      width: 100,
    },
    {
      ...subscriptionInvoicesTotalPriceColumn,
      width: 100,
    },
    {
      ...subscriptionInvoicesActionColumn,
      width: 100,
    },
  ],
}

export const Invoices = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const subscriptionPlansDevtoolsEnabled = featureFlags.includes(
    FeatureFlags.SubscriptionPlansDevtools,
  )

  const { isFetching, refetch } = useTestCreateOpenInvoice()

  const table = useTable(
    subscriptionInvoicesRequests,
    undefined,
    subscriptionInvoicesDateColumn.sortKey
      ? [
          {
            sortBy: subscriptionInvoicesDateColumn.sortKey,
            direction: SORT_DIRECTION.ASC,
          },
        ]
      : undefined,
  )

  return (
    <TableWidget>
      {subscriptionPlansDevtoolsEnabled && (
        <TableWidget.Actions>
          <MoreBar>
            <MoreBar.Action
              onClick={() => refetch().then(() => table.refresh())}
              pending={isFetching}
            >
              Create invoice
            </MoreBar.Action>
          </MoreBar>
        </TableWidget.Actions>
      )}
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.SubscriptionInvoices}
          {...table}
          row={row}
          useWindowScroll
          dataType="Invoice"
          noDataMessage="All invoices will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
