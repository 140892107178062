import React from 'react'
import { ActionButton, Box, Caption, H1, Paragraph, Token } from '@revolut/ui-kit'

type ExpiredLinkProps = {
  recruiterEmail?: string
}

const ExpiredLink = ({ recruiterEmail }: ExpiredLinkProps) => {
  return (
    <Box mt="s-40" color={Token.color.foreground} px="s-16">
      <H1 mb="s-8">This link has expired </H1>
      <Paragraph mb="s-4">
        Please contact the recruiter to receive a new link and book a call
      </Paragraph>
      {recruiterEmail && (
        <>
          <Caption mb="s-16">{recruiterEmail}</Caption>
          <ActionButton use="a" href={`mailto:${recruiterEmail}`}>
            Contact recruiter
          </ActionButton>
        </>
      )}
    </Box>
  )
}

export default ExpiredLink
