import React from 'react'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import AutoStepper from '@components/Stepper/AutoStepper'
import { Box, InputGroup } from '@revolut/ui-kit'
import { API, selectorKeys } from '@src/constants/api'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompanySettingsInterface } from '@src/interfaces/settings'
import { useQueryClient } from 'react-query'
import { isWorkspacesEnabled } from '@src/utils'

const Edit = () => {
  const { values } = useLapeContext<CompanySettingsInterface>()
  const queryClient = useQueryClient()

  const onAfterSubmit = (res: CompanySettingsInterface) => {
    queryClient.setQueryData([API.COMPANY_SETTINGS, 'v1', null], res)
  }

  const getWorkspaceMessage = () => {
    const workspace = values.company_subdomain_key || 'your-domain'

    if (isWorkspacesEnabled()) {
      return `${document.location.origin}/${workspace}`
    }

    return values.subdomain_template?.replace('{}', workspace)
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Admin info" />
          <LapeRadioSelectInput
            name="point_of_contact"
            label="Owner"
            selector={selectorKeys.employee}
          />
          <NewStepperTitle title="Company info" />
          <InputGroup>
            <LapeNewInput name="company_name" label="Company name" required />
            <LapeRadioSelectInput
              name="company_size"
              label="Number of employees"
              selector={selectorKeys.company_sizes}
            />
            <LapeRadioSelectInput
              name="industry"
              label="Company industry"
              selector={selectorKeys.company_industries}
            />
            <LapeNewInput
              name="company_subdomain_key"
              label="Your company workspace"
              message={getWorkspaceMessage()}
              type="url"
              required
              disabled
            />
            <LapeNewInput name="vat_number" label="VAT number" required />
          </InputGroup>

          <NewStepperTitle title="Address of your company" />
          <InputGroup>
            <LapeRadioSelectInput
              name="country"
              label="Country of incorporation"
              selector={selectorKeys.countries}
            />
            <LapeNewInput name="post_code" label="Postal code" required />
            <InputGroup variant="horizontal">
              <Box flex={1}>
                <LapeNewInput name="address_line_1" label="Address line 1" required />
              </Box>
              <Box flex={1}>
                <LapeNewInput name="address_line_2" label="Address line 2" />
              </Box>
            </InputGroup>
            <LapeNewInput name="city" label="City" required />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          previewUrl={ROUTES.SETTINGS.GENERAL.PREVIEW}
          onAfterSubmit={onAfterSubmit}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default Edit
