import React from 'react'
import {
  Button,
  Checkbox,
  HStack,
  StatusPopup,
  Subheader,
  Text,
  Tooltip,
  VStack,
  Widget,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import { LocationInterface } from '@src/interfaces/requisitions'
import { PageBody } from '@src/components/Page/PageBody'
import { selectorKeys } from '@src/constants/api'
import {
  patchJobDescriptionOfRequisition,
  useGetHiringEnabledLocations,
  useGetRequisition,
} from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useLocation, useParams } from 'react-router-dom'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import { PageActions } from '@src/components/Page/PageActions'
import { getBackUrl } from '@src/features/JobPostingFlow/utils'
import { navigateTo } from '@src/actions/RouterActions'
import PostingCompensationBand from '@src/components/LocationCompensationBand/PostingCompensationBand'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useGetPostingCompensationBands } from '@src/api/benchmarks'
import ManualCompensationBandForm from '@src/pages/Forms/JobPosting/Components/ManualCompensationBandForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Description } from '@src/features/JobPostingFlow/Description'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { getErrorMessageFromError } from '@src/store/notifications/actions'
import ActionWidget from '@components/ActionWidget/ActionWidget'

const locationsToLocationOptions = (locations?: LocationInterface[]) => {
  return (locations || []).map(location => ({
    label: location.name,
    value: location,
  }))
}

type HiringLocationsProps = {
  requisitionLocations?: LocationInterface[]
}

const HiringLocations = ({ requisitionLocations }: HiringLocationsProps) => {
  const { values, errors } = useLapeContext<JobPostingInterface>()
  const { data: enabledLocationsData } = useGetHiringEnabledLocations()
  const locationOptions = locationsToLocationOptions(enabledLocationsData)
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableAutomaticCompensationBands =
    jobPostingSettings?.enable_automatic_compensation_ranges
  const selectedLocationIds = (values?.locations || []).map(
    ({ id: locationId }) => locationId,
  )
  const mandatoryCompensationLocations = (
    jobPostingSettings?.mandatory_compensation_locations || []
  )
    .map(({ id: locationId }) => locationId)
    .filter(locationId => selectedLocationIds?.includes(locationId))
  const { data: compensationBands, error: compensationBandsError } =
    useGetPostingCompensationBands(
      values.id,
      values.locations
        ?.filter(
          item =>
            item.posting_compensation_enabled ||
            mandatoryCompensationLocations.includes(item.id),
        )
        ?.map(item => item.id),
    )

  return (
    <Widget px="s-16" pb="s-16">
      <VStack gap="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Hiring locations</Subheader.Title>
        </Subheader>
        <Checkbox
          checked={values.location_source === LocationSource.requisition}
          onChange={event => {
            const fromRequisitions = event.currentTarget.checked
            if (fromRequisitions) {
              values.location_source = LocationSource.requisition
              values.locations = undefined
            } else {
              values.location_source = LocationSource.manual
            }
          }}
        >
          <Text>Autofill locations from Requisitions</Text>
        </Checkbox>
        {values.location_source === LocationSource.requisition ? (
          <NewMultiSelect
            name="locations"
            placeholder="Locations"
            required
            options={locationOptions}
            value={locationsToLocationOptions(requisitionLocations)}
            disabled
          />
        ) : (
          <>
            <LapeNewMultiSelect<LocationInterface>
              name="locations"
              placeholder="Locations"
              required
              options={locationOptions}
              onAfterChange={locationValues => {
                const entries = Object.entries(values.salary_bands_by_location || {})
                const locationKeys = (locationValues || []).map(({ label }) => label)
                const keepEntries = entries.filter(([key]) => locationKeys.includes(key))
                if (keepEntries.length) {
                  values.salary_bands_by_location = Object.fromEntries(keepEntries)
                }
              }}
            />
            <PostingCompensationBand bands={compensationBands} />
          </>
        )}
        {!enableAutomaticCompensationBands && !!mandatoryCompensationLocations.length && (
          <>
            {values.location_source !== LocationSource.manual && (
              <PostingCompensationBand bands={compensationBands} />
            )}
            <ManualCompensationBandForm
              locationIds={mandatoryCompensationLocations}
              values={values.salary_bands_by_location}
              errors={errors.salary_bands_by_location}
              onChange={salaryBandsByLocation => {
                values.salary_bands_by_location = values.salary_bands_by_location
                  ? {
                      ...values.salary_bands_by_location,
                      ...salaryBandsByLocation,
                    }
                  : salaryBandsByLocation
              }}
            />
          </>
        )}
        {compensationBandsError && (
          <ActionWidget
            title="Could not show compensation bands"
            text={getErrorMessageFromError(compensationBandsError)}
          />
        )}
      </VStack>
    </Widget>
  )
}

type DetailsProps = {
  onAfterSubmit: () => void
}

export const Details = ({ onAfterSubmit }: DetailsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { data: requisition } = useGetRequisition(locationState?.requisitionId)
  const tooltip = useTooltip()
  const statusPopup = useStatusPopup()
  const handleRequisitionConnectionError = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Cannot connect job posting to requisition(s)
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button elevated onClick={statusPopup.hide}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  return (
    <>
      <PageBody>
        <Subheader>
          <Subheader.Title>Job Details</Subheader.Title>
        </Subheader>
        <HStack {...tooltip.getAnchorProps()}>
          <LapeRadioSelectInput
            name="specialisation"
            label="Specialisation"
            selector={selectorKeys.specialisations}
            onAfterChange={() => {
              if (values.specialisation?.name) {
                values.name = values.specialisation.name
              }
              if (values.hiring_process) {
                values.hiring_process = undefined
              }
            }}
            // Once a Job Posting is created, the “Specialisation” field
            // should not be editable in order to prevent situations
            // when candidate’s job posting and candidate specialisation’s
            // hiring process are different from each other.
            disabled={!!values.id}
          />
          {!!values.id && (
            <Tooltip {...tooltip.getTargetProps()}>
              It's not possible to change job posting's specialisation after the job
              posting is created
            </Tooltip>
          )}
        </HStack>
        <VStack gap="s-16" mt="s-16">
          <HiringLocations requisitionLocations={requisition?.locations} />
          <LapeRadioSelectInput
            name="recruiter"
            label="Recruiter"
            selector={selectorKeys.employee}
          />
          <HideIfCommercial>
            <LapeRadioSelectInput
              name="coordinator"
              label="Coordinator"
              required={false}
              selector={selectorKeys.employee}
              clearable
            />
          </HideIfCommercial>
        </VStack>
        <Description requisitionLocations={requisition?.locations} />
      </PageBody>
      <PageActions>
        <Button
          onClick={() => {
            navigateTo(getBackUrl(params, locationState), locationState)
          }}
          variant="secondary"
          elevated
        >
          Cancel
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          noPopup
          hideWhenNoChanges={false}
          onAfterSubmit={async resp => {
            onAfterSubmit()
            let hasError = false
            if (locationState?.requisitionId) {
              try {
                await patchJobDescriptionOfRequisition(locationState?.requisitionId, {
                  job_posting: {
                    id: resp.id,
                  },
                })
              } catch {
                hasError = true
                handleRequisitionConnectionError()
              }
            }
            if (!hasError) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
                {
                  ...locationState,
                  id: resp.id,
                },
              )
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
