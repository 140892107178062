import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { GetRequestInterface, IdAndName, RequestInterfaceNew } from '@src/interfaces'
import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '.'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  RewardsSummary,
  RewardsSalary,
  SalarySacrificeInterface,
  RewardsSalaryStats,
  BenefitsInterface,
  BenefitsStats,
  BonusesInterface,
  BonusesStats,
  BenefitsInterfaceLegacy,
  BenefitsStatsLegacy,
  LastContractValueInterface,
} from '@src/interfaces/rewards'
import { useFetch } from '@src/utils/reactQuery'

export const getEmployeeRewardsSummary =
  (employeeId: number, year: string, target_currency?: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RewardsSummary>> =>
    api.get(`${API.EMPLOYEES}/${employeeId}/totalRewardsSummary`, {
      params: {
        target_currency,
        year,
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

export const useGetEmployeeRewardsSummary = (
  employeeId: number | undefined,
  year: string,
  currency?: string,
  filters?: FilterByInterface[],
) =>
  useFetch<GetRequestInterface<RewardsSummary>>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/totalRewardsSummary` : null,
    undefined,
    {
      params: {
        target_currency: currency,
        year,
        ...(filters?.length ? filterSortPageIntoQuery(undefined, filters) : null),
      },
    },
  )

export const useGetEmployeeLastContractValue = (
  employeeId: string | number,
  year: string | number | undefined,
) =>
  useFetch<LastContractValueInterface>(
    year ? `${API.EMPLOYEES}/${employeeId}/salarySummary/lastContractValue` : null,
  )

export const getEmployeeRewardsSalary =
  (employeeId: number, target_currency?: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RewardsSalary>> =>
    api.get(`${API.EMPLOYEES}/${employeeId}/salarySummary`, {
      params: {
        target_currency,
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

// TODO: move to correct location
export interface SalaryHistoryresponse {
  currency: IdAndName & { iso_code: string }
  period_amount: number | null
  period_end_date: string | null
  period_start_date: string | null
}

// TODO: move to correct location
export const getEmployeeSalaryHistory = (employeeId: number, target_currency?: string) =>
  api.get<GetRequestInterface<SalaryHistoryresponse>>(
    `${API.COMPENSATION_REVIEW}/employees/${employeeId}/salaryHistory`,
    {
      params: {
        target_currency,
      },
    },
  )

export const getEmployeeRewardsSalaryStats =
  (employeeId: number, target_currency?: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<RewardsSalaryStats> =>
    api.get(`${API.EMPLOYEES}/${employeeId}/salarySummary/stats`, {
      params: {
        target_currency,
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

export const getEmployeeRewardsSalarySacrifice =
  (employeeId: number, year: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<SalarySacrificeInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}/salarySacrificeSummary`, {
      params: {
        year,
        ...filterSortPageIntoQuery(sortBy, filters, page),
      },
    })

export const getEmployeeRewardsBenefitsLegacy =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<BenefitsInterfaceLegacy>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}/benefitsSummary`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getEmployeeRewardsBenefitsStatsLegacy =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<BenefitsStatsLegacy> =>
    api.get(`${API.EMPLOYEES}/${employeeId}/benefitsSummary/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getEmployeeRewardsBenefits =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<BenefitsInterface>> =>
    api.get(`${API.EMPLOYEES}/${employeeId}/benefits`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getEmployeeRewardsBenefitsStats =
  (employeeId: number) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface): AxiosPromise<BenefitsStats> =>
    api.get(`${API.EMPLOYEES}/${employeeId}/benefits/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getEmployeeRewardsBonuses =
  (employeeId: number, target_currency?: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<BonusesInterface>> =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}/bonuses`,
      {
        params: {
          target_currency,
          ...filterSortPageIntoQuery(sortBy, filters, page),
        },
      },
      'v2',
    )

export const getEmployeeRewardsBonusesStats =
  (employeeId: number, target_currency?: string) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface): AxiosPromise<BonusesStats> =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}/bonuses/stats`,
      {
        params: {
          target_currency,
          ...filterSortPageIntoQuery(sortBy, filters, page),
        },
      },
      'v2',
    )

export const salarySacrificeRequests: RequestInterfaceNew<SalarySacrificeInterface> = {
  get: async ({ employeeId, id }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/salarySacrificeSummary/${id}`),
  update: async (data, { employeeId, id }) =>
    apiWithoutHandling.patch(
      `${API.EMPLOYEES}/${employeeId}/salarySacrificeSummary/${id}`,
      data,
    ),
  submit: async (data, { employeeId }) =>
    apiWithoutHandling.post(
      `${API.EMPLOYEES}/${employeeId}/salarySacrificeSummary`,
      data,
    ),
  delete: async (_, { employeeId, id }) =>
    api.delete(`${API.EMPLOYEES}/${employeeId}/salarySacrificeSummary/${id}`),
}
