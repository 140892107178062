import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Upload } from '@src/pages/Forms/ImportData/Bonuses/Upload'
import { ReviewData } from '@src/pages/Forms/ImportData/Bonuses/ReviewData'
import { ROUTES } from '@src/constants/routes'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const ImportBonuses = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.BONUSES.UPLOAD_FILE}>
        <Upload />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.BONUSES.REVIEW_DATA}>
        <ReviewData />
      </Route>
      <InternalRedirect to={ROUTES.FORMS.IMPORT_DATA.BONUSES.UPLOAD_FILE} />
    </Switch>
  )
}

export default ImportBonuses
