import { AxiosPromise, AxiosResponse } from 'axios'
import set from 'lodash/set'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import {
  BenefitsInterface,
  BenefitsTableInterface,
  BenfitAttachment,
  EmployeeBenefitInterface,
  BenefitEnrolmentTableInterface,
  BenefitEnrolmentStats,
} from '@src/interfaces/benefits'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { api, apiWithoutHandling } from '.'
import { uploadFile } from './files'
import { FileInterface } from '@src/interfaces/files'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { getCommentsAPI } from './comments'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { useFetch } from '@src/utils/reactQuery'

export const getBenefits = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<BenefitsTableInterface>> =>
  api.get(API.BENEFITS_TEMPLATES, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getBenefitEnrolments = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<BenefitEnrolmentTableInterface>
> =>
  api.get(API.BENEFITS_ENROLMENTS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getBenefitEnrolmentsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<BenefitEnrolmentStats> =>
  api.get(`${API.BENEFITS_ENROLMENTS}/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

const createFiles = (files: File[]) => {
  return Promise.allSettled(files.map(file => uploadFile(file, 'benefits', true)))
}

export const benefitsRequests: RequestInterfaceNew<BenefitsInterface> = {
  get: async ({ id }) => api.get(`${API.BENEFITS_TEMPLATES}/${id}`),
  update: async (_, { id }, data) => {
    let filesResults: PromiseSettledResult<AxiosResponse<FileInterface, any>>[] = []

    if (data?.files && data.files.length > 0) {
      filesResults = await createFiles(data.files)
    }

    if (filesResults.some(file => file.status === 'rejected')) {
      throw new Error('Failed to attach documents')
    }

    data!.files = []
    data!.enrolment_documents = [
      ...(data!.enrolment_documents || []),
      ...(filesResults
        .map(file => (file.status === 'fulfilled' ? file.value.data : null))
        .filter(Boolean) as BenfitAttachment[]),
    ]

    const { files, ...formData } = data!

    return apiWithoutHandling.patch(`${API.BENEFITS_TEMPLATES}/${id}`, formData)
  },
  submit: async data => {
    const filesToCreate: { resultPath: string; file: File }[] = []

    data?.files?.forEach((file, fileIndex) => {
      filesToCreate.push({ resultPath: `enrolment_documents[${fileIndex}]`, file })
    })

    data?.packages?.forEach((benefitPackage, packageIndex) => {
      benefitPackage.files?.forEach((file, fileIndex) => {
        filesToCreate.push({
          resultPath: `packages[${packageIndex}].additional_information[${fileIndex}]`,
          file,
        })
      })
    })

    if (filesToCreate.length) {
      const filesResults = await Promise.allSettled(
        filesToCreate.map(({ file }) => uploadFile(file, 'benefits', true)),
      )

      if (filesResults.some(file => file.status === 'rejected')) {
        throw new Error('Failed to attach documents')
      }

      filesToCreate.forEach(({ resultPath }, index) => {
        const file = filesResults[index]
        if (file.status === 'fulfilled') {
          set(data, resultPath, file.value.data)
        }
      })

      data!.files = []
      data!.packages?.forEach(benefitPackage => {
        benefitPackage.files = []
      })
    }

    const { files, ...formData } = data!

    return apiWithoutHandling.post(`${API.BENEFITS_TEMPLATES}`, formData)
  },
  delete: async ({ id }) => api.delete(`${API.BENEFITS_TEMPLATES}/${id}`),
}

export const deleteBenefitPackage = (
  packageId: number,
  data: Partial<BenefitsInterface>,
) =>
  api.patch<BenefitsInterface>(`${API.BENEFITS_TEMPLATES}/${data.id}`, {
    ...data,
    packages: data.packages?.filter(p => p.id !== packageId),
  })

export const updateBenefitPackage = async (data: BenefitsInterface) => {
  const modifiedPackage = data.packages.find(p => p.files?.length > 0)

  let filesResults: PromiseSettledResult<AxiosResponse<FileInterface>>[] = []

  if (modifiedPackage) {
    filesResults = await createFiles(modifiedPackage.files)

    if (filesResults.some(file => file.status === 'rejected')) {
      throw new Error('Failed to attach documents')
    }

    modifiedPackage.additional_information = [
      ...modifiedPackage.additional_information,
      ...(filesResults
        .map(file => (file.status === 'fulfilled' ? file.value.data : null))
        .filter(Boolean) as BenfitAttachment[]),
    ]
    modifiedPackage.files = []
  }

  return api.patch<BenefitsInterface>(`${API.BENEFITS_TEMPLATES}/${data.id}`, data)
}

export const archiveBenefit = (id: number, action: 'archive' | 'unarchive') =>
  api.post<BenefitsInterface>(`${API.BENEFITS_TEMPLATES}/${id}/${action}`)

export const updateEmployeeBenefit = async (
  data: Partial<EmployeeBenefitInterface>,
  id: string,
  employeeId: string,
) => {
  return api.patch<EmployeeBenefitInterface>(
    `${API.EMPLOYEES}/${employeeId}/benefits/${id}`,
    data,
  )
}

export const deleteDependant = (
  data: Partial<EmployeeBenefitInterface>,
  id: string,
  employeeId: string,
  dependantId: number,
) =>
  api.patch<EmployeeBenefitInterface>(`${API.EMPLOYEES}/${employeeId}/benefits/${id}`, {
    ...data,
    dependants: data.enrolment?.dependants.filter(d => d.id !== dependantId),
  })

export const employeeBenefitRequests: RequestInterfaceNew<EmployeeBenefitInterface> = {
  get: async ({ id, employeeId }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/benefits/${id}`),
  update: async (_, { id, employeeId }, data) => {
    let filesResults: PromiseSettledResult<AxiosResponse<FileInterface, any>>[] = []

    if (data?.files && data.files.length > 0) {
      filesResults = await createFiles(data.files)
    }

    if (filesResults.some(file => file.status === 'rejected')) {
      throw new Error('Failed to attach documents')
    }

    const documents = [
      ...(data!.enrolment!.documents || []),
      ...(filesResults
        .map(file => (file.status === 'fulfilled' ? file.value.data : null))
        .filter(Boolean) as BenfitAttachment[]),
    ]
    data!.files = []
    /** Need to keep it in 2 places: one used for PATCH, other to show on the FE, for convenience */
    data!.enrolment!.documents = documents
    data!.documents = documents

    const { files, initialDependants, ...formData } = data!
    const dependants = [
      ...(formData.enrolment?.dependants || []),
      ...(formData.dependants || []),
    ]

    return apiWithoutHandling.patch(`${API.EMPLOYEES}/${employeeId}/benefits/${id}`, {
      ...formData,
      ...(dependants.length && { dependants }),
    })
  },
  submit: async (data, { id, employeeId }) => {
    return apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}/benefits/${id}`, data)
  },
  delete: async (_, { id, employeeId }) =>
    api.delete(`${API.EMPLOYEES}/${employeeId}/benefits/${id}`),
}

interface EnrolToBenefit {
  id: string
  employeeId: string
  data: EmployeeBenefitInterface
}

export const enrolToBenefit = async ({ id, employeeId, data }: EnrolToBenefit) => {
  let filesResults: PromiseSettledResult<AxiosResponse<FileInterface, any>>[] = []

  if (data?.files && data.files.length > 0) {
    filesResults = await createFiles(data.files)
  }

  if (filesResults.some(file => file.status === 'rejected')) {
    throw new Error('Failed to attach documents')
  }

  const documents = [
    ...(data!.documents || []),
    ...(filesResults
      .map(file => (file.status === 'fulfilled' ? file.value.data : null))
      .filter(Boolean) as BenfitAttachment[]),
  ]
  data!.files = []
  data!.documents = documents

  const { files, initialDependants, ...formData } = data!
  const dependants = data.enrolment?.dependants
    ? { dependants: data.enrolment.dependants }
    : {}

  return apiWithoutHandling.post<EmployeeBenefitInterface>(
    `${API.EMPLOYEES}/${employeeId}/benefits/${id}/enrol`,
    { ...formData, ...dependants },
  )
}

interface OptOutOfBenefit {
  id: string
  employeeId: string
}

export const optOutOfBenefit = ({ id, employeeId }: OptOutOfBenefit) => {
  return api.post<EmployeeBenefitInterface>(
    `${API.EMPLOYEES}/${employeeId}/benefits/${id}/optOut`,
  )
}

export const getBenefitChangelog =
  (id: string, employeeId: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ChangelogInterface<BenefitsInterface>>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}/benefits/${id}/changelogFields`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getEmployeeBenefitsCommentsAPI = (id: string, employeeId: string) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/benefits/${id}/comments`,
  })

export const useEmployeeBenefitsApprovals = (
  id: string,
  employeeId: string,
  disabled: boolean,
) =>
  useFetch<ApprovalFlowResponse>(
    disabled ? null : `${API.EMPLOYEES}/${employeeId}/benefits/${id}/approvals`,
  )

export const approveBenefit = (id: string, employeeId: string) =>
  api.post<EmployeeBenefitInterface>(
    `${API.EMPLOYEES}/${employeeId}/benefits/${id}/approve`,
  )

export const rejectBenefit = (
  id: string,
  employeeId: string,
  rejection_comment: string,
) =>
  api.post(`${API.EMPLOYEES}/${employeeId}/benefits/${id}/reject`, {
    rejection_comment,
  })
