import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { EmployeeEmailTemplates } from '@src/pages/People/PeopleSubTabs/EmployeeEmailTemplates/EmployeeEmailTemplates'

export const EmailTemplates = () => {
  return (
    <PageBody maxWidth="100%" mt="s-32">
      <EmployeeEmailTemplates />
    </PageBody>
  )
}
