import { EmployeeOptionInterface, NameIdInterface } from '@src/interfaces/employees'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Statuses } from '@src/interfaces'
import { OptionInterface } from '@src/interfaces/selectors'
import { ContributorType } from '../talent/performance'

export interface EligibleEmployeesInterface {
  id: number
  employee: {
    id: number
    name: string
    full_name?: string
    display_name?: string
    line_manager?: EmployeeOptionInterface
    quality_control?: EmployeeOptionInterface | null
    team?: NameIdInterface
    status?: {
      name: string
    }
  }
  cycle: ReviewCyclesInterface
  functional_manager: {
    id: number
    name: string
    full_name?: string
    display_name?: string
    status?: Statuses
  } | null
  is_disabled: boolean
  line_manager: {
    id: number
    name: string
    full_name?: string
    display_name?: string
    status?: Statuses
  } | null
  reason_for_disabling: string | null
  seniority: OptionInterface | null
  specialisation: OptionInterface | null
  team: OptionInterface | null
  reviewed_employee_type: ContributorType
  validation_errors: EligibilityValidationError[] | null
}

export interface EligibleEmployeesStats {
  total: number
  in_progress: number
  valid: number
  invalid: number
}

export enum ValidationErrorType {
  ManagerDeactivated = 'manager_deactivated',
  NoSeniority = 'no_seniority',
  NoSpecialisation = 'no_specialisation',
  NoSkills = 'no_skills_defined',
  NoSkillsDistribution = 'no_skills_distribution',
}

export interface EligibilityValidationError {
  error: string
  error_type: ValidationErrorType
  object_id: number | null
  object_title: string | null
}
