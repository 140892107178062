import Form from '@src/features/Form/Form'
import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import { reviewCyclesEligibleEmployees } from '@src/api/reviewCycles'
import { EligibleGroupsFiltersSidebarForm } from '@src/pages/Forms/ReviewCycle/components/EligibleGroupsFiltersSidebarForm'
import { RequestInterfaceNew } from '@src/interfaces'

export type Props = {
  isOpen: boolean
  handleClose: () => void
  groupData?: EligibleGroupInterface
  refetchGroups: () => void
  api?: RequestInterfaceNew<EligibleGroupInterface>
  submit?: (group: EligibleGroupInterface) => Promise<void>
}

export const EligibleGroupsFiltersSidebar = (props: Props) => {
  const { api = reviewCyclesEligibleEmployees } = props
  const idValue = props.groupData?.id ? String(props.groupData.id) : undefined

  return (
    <SideBar
      variant="wide"
      title="Eligible employee group"
      subtitle="Filter for eligible employees"
      onClose={props.handleClose}
      isOpen={props.isOpen}
    >
      <Form
        api={api}
        forceParams={{ id: idValue }}
        disableLocalStorageCaching
        disableDataCleanup
      >
        <EligibleGroupsFiltersSidebarForm {...props} />
      </Form>
    </SideBar>
  )
}
