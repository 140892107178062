import React from 'react'
import {
  Avatar,
  Item,
  Box,
  Token,
  Widget,
  VStack,
  Flex,
  Text,
  Group,
} from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import { ChevronRight, IdBackSide } from '@revolut/icons'
import {
  checkCanViewPIPv2Settings,
  checkIsProbationPeriod,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { useGetPeriodTypes } from '@src/utils/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { getAvatarUrl } from '@src/utils/employees'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { pipCommentsApi } from '@src/api/pip'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { probationCommentsApi } from '@src/api/probationReview'

type Props = {
  selectedPeriod: PerformanceSelector
  data: EmployeeInterface
}
export const ProbationPipAdditionalInfo = ({ selectedPeriod, data }: Props) => {
  const user = useSelector(selectUser)
  const { isPIPv2 } = useGetPeriodTypes(selectedPeriod)
  const isProbationPeriod = checkIsProbationPeriod(data)
  const canViewPIPv2Settings = checkCanViewPIPv2Settings(selectedPeriod)
  const permissions = useSelector(selectPermissions)
  const canRegenerateScorecards = data.field_options.permissions?.includes(
    PermissionTypes.ProbationCommitteeHRPermissions,
  )
  const isLineManager = user.id === data.line_manager?.id
  const isFunctionalManager = user.id === data.quality_control?.id

  const showButtons = isProbationPeriod || canViewPIPv2Settings
  const canViewProbationComments =
    permissions.includes(PermissionTypes.ProbationCommitteeHRPermissions) ||
    canRegenerateScorecards ||
    isLineManager ||
    isFunctionalManager

  const hr = selectedPeriod.hr_manager
  const additionalSettings = [
    {
      title: 'HR manager',
      value: hr ? (
        <UserWithAvatar id={hr.id} name={hr.full_name} avatar={getAvatarUrl(hr.avatar)} />
      ) : (
        '-'
      ),
    },
    {
      title: 'Probation template',
      value: data.probation_template?.name || '-',
    },
  ]

  const handleOpenSettings = () => {
    navigateTo(
      pathToUrl(
        isPIPv2
          ? ROUTES.FORMS.PIP.SETTINGS
          : ROUTES.FORMS.PROBATION_EXTRA_SETTINGS.GENERAL,
        {
          employeeId: data.id,
          id: selectedPeriod.id,
        },
      ),
    )
  }

  return (
    <Group>
      <Item
        use={showButtons ? 'button' : undefined}
        onClick={() => showButtons && handleOpenSettings()}
      >
        <Item.Avatar>
          <Avatar useIcon={IdBackSide} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Cycle info</Item.Title>
          <Item.Description>Settings and comments</Item.Description>
        </Item.Content>
        {showButtons && (
          <Item.Side>
            <ChevronRight color={Token.color.greyTone50} />
          </Item.Side>
        )}
      </Item>
      <Widget p="12px 16px">
        {selectedPeriod.category === ReviewCycleCategory.Probation && (
          <>
            <VStack space="s-16">
              {additionalSettings.map((setting, ind) => (
                <Flex justifyContent="space-between" key={ind}>
                  <Text variant="caption" fontWeight={500} color={Token.color.greyTone50}>
                    {setting.title}
                  </Text>
                  {setting.value}
                </Flex>
              ))}
            </VStack>
            {canViewProbationComments && (
              <Box mt="s-4">
                <CommentsSection
                  api={probationCommentsApi(selectedPeriod.id)}
                  disableTodolistFeature
                  messageBorder
                />
              </Box>
            )}
          </>
        )}
        {isPIPv2 && (
          <>
            <Flex justifyContent="space-between">
              <Text variant="caption" fontWeight={500} color={Token.color.greyTone50}>
                HR manager
              </Text>
              {selectedPeriod.hr_manager?.full_name || '-'}
            </Flex>
            {canViewPIPv2Settings && (
              <Box mt="s-4">
                <CommentsSection
                  api={pipCommentsApi(selectedPeriod.id)}
                  disableTodolistFeature
                  messageBorder
                />
              </Box>
            )}
          </>
        )}
      </Widget>
    </Group>
  )
}
