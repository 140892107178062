import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { useSelector } from 'react-redux'
import { RadioOptionInterface } from '@components/Inputs/RadioButtons/RadioButtons'
import {
  Box,
  Button,
  FilterButton,
  Flex,
  MoreBar,
  StatusPopup,
  TableWidget,
  TextButton,
  useStatusPopup,
} from '@revolut/ui-kit'
import produce from 'immer'
import { Retry } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import Stat from '@components/Stat/Stat'
import {
  getUserMeetings,
  refreshMeetings,
  updateMeetingCategory,
} from '@src/api/meetingsTracker'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  MeetingTrackerCategory,
  MeetingTrackerEntity,
  MeetingTrackerInterface,
} from '@src/interfaces/meetingsTracker'
import {
  meetingsTrackerActionColumn,
  meetingsTrackerQualityColumn,
  meetingsTrackerDateColumn,
  meetingsTrackerNameColumn,
  meetingsTrackerOwnerColumn,
  meetingsTrackerParticipantsColumn,
  meetingsTrackerRatingColumn,
  meetingsTrackerRecurringTypeColumn,
  meetingsTrackerTotalTimeColumn,
  meetingsTrackerTypeColumn,
} from '@src/constants/columns/meetingsTracker'
import { getTotalTimeFromMinutes } from '@src/utils/format'
import { MeetingCategorySelect } from './MeetingCategorySelect'
import { selectUser } from '@src/store/auth/selectors'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { MeetingSidebar } from '@src/pages/Forms/MeetingsTracker/MeetingSidebar'
import {
  getCurrentWeek,
  NavigateWeek,
  NavigationWeek,
} from '@src/components/NavigateWeek/NavigateWeek'

const meetingEntityFilters = {
  [MeetingTrackerEntity.employee]: 'employee_id',
  [MeetingTrackerEntity.team]: 'team_id',
  [MeetingTrackerEntity.department]: 'department_id',
}

const ROW = (
  categories: RadioOptionInterface[],
  onChangeField: (
    object_id: number,
    id?: number,
    parentId?: string,
    value?: MeetingTrackerCategory,
  ) => void,
  onActionClick: (data: MeetingTrackerInterface) => void,
): RowInterface<MeetingTrackerInterface> => ({
  cells: [
    {
      ...meetingsTrackerNameColumn,
      width: 200,
    },
    {
      ...meetingsTrackerOwnerColumn,
      width: 50,
    },
    {
      ...meetingsTrackerParticipantsColumn,
      width: 50,
    },
    {
      ...meetingsTrackerDateColumn,
      width: 200,
    },
    {
      ...meetingsTrackerRecurringTypeColumn,
      width: 100,
    },
    {
      ...meetingsTrackerTypeColumn,
      width: 120,
      insert: ({ data }) => {
        return (
          <Box onClick={e => e.stopPropagation()}>
            <MeetingCategorySelect
              value={data.category}
              categories={[...categories, { id: null, name: '-' }]}
              onChange={value => {
                onChangeField(data.object_id, data.id, data.parent_event_id, value)
              }}
              readOnly={!data.can_edit_category}
            />
          </Box>
        )
      },
    },
    {
      ...meetingsTrackerTotalTimeColumn,
      width: 100,
    },
    {
      ...meetingsTrackerQualityColumn,
      width: 100,
    },
    {
      ...meetingsTrackerRatingColumn,
      width: 80,
    },
    {
      ...meetingsTrackerActionColumn,
      width: 80,
      insert: ({ data }) => (
        <TextButton
          onClick={() => {
            onActionClick(data)
          }}
          fontWeight={500}
        >
          View
        </TextButton>
      ),
    },
  ],
})

export const MeetingsTracker = ({
  data,
  meetingEntityType,
}: {
  data: EmployeeInterface | DepartmentInterface | TeamInterface
  meetingEntityType: MeetingTrackerEntity
}) => {
  const [sidebarData, setSidebarData] = useState<MeetingTrackerInterface>()
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [week, setWeek] = useState<NavigationWeek>(getCurrentWeek())
  const [showMyMeetings, setShowMyMeetings] = useState(false)
  const user = useSelector(selectUser)
  const statusPopup = useStatusPopup()
  const { data: categories } = useGetSelectors(selectorKeys.meeting_categories)

  const initialFilterBy = [
    {
      filters: [{ id: data.id, name: `${data.id}` }],
      columnName: meetingEntityFilters[meetingEntityType],
      nonResettable: true,
    },
    {
      filters: [
        { id: week.start, name: `${week.start}` },
        { id: week.end, name: `${week.end}` },
      ],
      columnName: 'start',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'start',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const onToggleMyMeetings = () => {
    const filter = {
      filters: !showMyMeetings ? [{ id: user.id, name: String(user.id) }] : [],
      columnName: 'organizer_id',
      nonResettable: true,
    }
    setShowMyMeetings(!showMyMeetings)
    table.onFilterChange(filter)
  }

  const table = useTable<MeetingTrackerInterface>(
    { getItems: params => getUserMeetings(params) },
    initialFilterBy,
    initialSortBy,
  )

  const getTotalMinutes = (list: MeetingTrackerInterface[]) => {
    return list
      .map(meeting => meeting.duration)
      .filter(duration => !!duration)
      .reduce((acc, val) => acc + val, 0)
  }

  const onClosePopup = () => {
    statusPopup.hide()
    table.refresh()
  }

  const onRefreshClick = async () => {
    statusPopup.show(<StatusPopup variant="loading" preventUserClose />)
    try {
      await refreshMeetings()
      statusPopup.show(
        <StatusPopup variant="success-result" onClose={onClosePopup}>
          <StatusPopup.Title>
            Successfully queued the task. Refreshing meetings list will be processed in
            the background.
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button elevated onClick={onClosePopup}>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup variant="error" onClose={onClosePopup}>
          <StatusPopup.Title>
            Something went wrong. Please try again later
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button elevated onClick={onClosePopup}>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }

  const updateAfterChangingField = (
    responseData: MeetingTrackerInterface[],
    object_id: number,
  ) => {
    const response = responseData.find(el => el.object_id === object_id)
    if (response) {
      const updatedData = produce(table.data, draft => {
        const idx = draft.findIndex(item => item.object_id === object_id)

        if (idx !== -1) {
          draft[idx] = response
          setSidebarData(response)
        }
      })

      table.setData(updatedData)
    }
  }

  const onChangeField = async (
    object_id: number,
    id?: number,
    parentId?: string,
    value?: MeetingTrackerCategory,
  ) => {
    const payload = parentId
      ? { category: value || null, parent_event_id: parentId }
      : { category: value || null, id }

    const upd = produce(table.data, draft => {
      const row = draft.find(item => item.object_id === object_id)
      if (row) {
        row.category = payload.category
      }
    })
    table.setData(upd)

    try {
      const response = await updateMeetingCategory(payload)
      updateAfterChangingField(response.data.results, object_id)
    } catch (e) {
      table.refresh()
    }
  }

  const handleChangeWeek = (newWeek: NavigationWeek) => {
    const filters = [
      {
        filters: [
          { id: newWeek.start, name: `${newWeek.start}` },
          { id: newWeek.end, name: `${newWeek.end}` },
        ],
        columnName: 'start',
        nonResettable: true,
      },
    ]
    setWeek(newWeek)
    table.onFilterChange(filters)
  }

  const onActionClick = (meeting: MeetingTrackerInterface) => {
    setSidebarData(meeting)
    setSidebarOpened(true)
  }

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <Stat
            label={`Time in meetings${
              meetingEntityType !== MeetingTrackerEntity.employee ? ' (week)' : ''
            }`}
            val={getTotalTimeFromMinutes(getTotalMinutes(table.data))}
          />
        </TableWidget.Info>

        <TableWidget.Actions>
          <Flex flexDirection="column" gap="s-20">
            <NavigateWeek
              start={week.start}
              end={week.end}
              onNavigate={handleChangeWeek}
            />
            <MoreBar>
              <MoreBar.Action useIcon={Retry} onClick={onRefreshClick}>
                Refresh list
              </MoreBar.Action>
            </MoreBar>
          </Flex>
        </TableWidget.Actions>
        {meetingEntityType === MeetingTrackerEntity.employee && (
          <TableWidget.Filters height="100%">
            <Flex flexDirection="column" justifyContent="flex-end" height="100%">
              <FilterButton onClick={onToggleMyMeetings} active={showMyMeetings}>
                Meetings I own
              </FilterButton>
            </Flex>
          </TableWidget.Filters>
        )}

        <TableWidget.Table>
          <AdjustableTable<MeetingTrackerInterface>
            name={TableNames.MeetingsTracker}
            useWindowScroll
            row={ROW(categories || [], onChangeField, onActionClick)}
            {...table}
            noDataMessage="No meetings scheduled"
            dataType="meeting"
            count={table.data.length}
            onRowClick={meeting => onActionClick(meeting)}
          />
        </TableWidget.Table>
      </TableWidget>
      {sidebarData && (
        <MeetingSidebar
          data={sidebarData}
          open={sidebarOpened}
          onClose={() => setSidebarOpened(false)}
          onAfterRatingChange={updateAfterChangingField}
          onChangeRatingError={() => table.refresh()}
        />
      )}
    </>
  )
}
