import React from 'react'
import styled from 'styled-components'
import { ButtonBase, ButtonBaseProps, Color } from '@revolut/ui-kit'
import { CrossCircle } from '@revolut/icons'

export interface ClearButtonProps extends ButtonBaseProps {}

const ClearButtonBase = (props: ClearButtonProps) => (
  <ButtonBase tabIndex={-1} {...props}>
    <CrossCircle size={24} color={Color.GREY_TONE_20} />
  </ButtonBase>
)

export const ClearButton = styled(ClearButtonBase)``
