import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Header,
  Popup,
  StatusPopup,
  Tooltip,
  VStack,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { HiringStagesList } from './components/HiringStagesList'
import { bulkCreateHiringProcess, useGetHiringStages } from '@src/api/hiringProcess'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { ROUTES } from '@src/constants/routes'
import {
  completeOnboardingCheckpoint,
  updateOnboardingCheckpointCategoryCurrentStep,
  useGetOnboardingCheckpointCategory,
} from '@src/api/onboardingChecklistV2'
import { StepCompletedWidget } from '@src/pages/OnboardingChecklist/components/StepCompletedWidget'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateTo } from '@src/actions/RouterActions'
import { interviewsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'

type SubmitDefaultHiringProcessProps = {
  hiringProcessRounds: HiringProcessInterface[]
  stepIsCompleted: boolean
}

const SubmitDefaultHiringProcess = ({
  hiringProcessRounds,
  stepIsCompleted,
}: SubmitDefaultHiringProcessProps) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const statusPopup = useStatusPopup()
  const showLoadingPopup = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>
          Applying hiring process rounds to specialisations
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const navigateToNextStep = () => {
    navigateTo(ROUTES.MAIN)
  }
  const showSuccessPopup = () => {
    statusPopup.show(
      <StatusPopup
        variant="success"
        onClose={async () => {
          await updateOnboardingCheckpointCategoryCurrentStep(
            interviewsConfig.category,
            'Interviews',
          )
          await completeOnboardingCheckpoint(interviewsConfig.category)
          navigateToNextStep()
        }}
      >
        <StatusPopup.Title>
          Successfully applied hiring process rounds to specialisations
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showErrorPopup = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Error applying hiring process rounds to specialisations
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleSubmit = async () => {
    setLoadingSubmit(true)
    setOpenConfirmation(false)
    showLoadingPopup()
    try {
      await bulkCreateHiringProcess(hiringProcessRounds.map(({ id }) => id))
      showSuccessPopup()
    } catch (e) {
      showErrorPopup()
    } finally {
      setLoadingSubmit(false)
    }
  }
  const disabled = loadingSubmit || hiringProcessRounds.length < 1
  const tooltip = useTooltip()
  return (
    <>
      <Box {...tooltip.getAnchorProps()}>
        <Button
          onClick={() => {
            if (stepIsCompleted) {
              navigateToNextStep()
            } else {
              setOpenConfirmation(true)
            }
          }}
          disabled={stepIsCompleted ? false : disabled}
        >
          Next
        </Button>
      </Box>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>Must have at least one stage</Tooltip>
      )}
      {openConfirmation && (
        <Popup
          open
          variant="bottom-sheet"
          onClose={() => {
            setOpenConfirmation(false)
          }}
        >
          <Header>
            <Header.Title>Are you sure?</Header.Title>
            <Header.Description>
              Default hiring process can only be applied once.
            </Header.Description>
          </Header>
          <Popup.Actions horizontal>
            <Button
              variant="secondary"
              onClick={() => {
                setOpenConfirmation(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
            </Button>
          </Popup.Actions>
        </Popup>
      )}
    </>
  )
}

export const SetupInterviews = () => {
  const {
    data: stagesData,
    isLoading: isLoadingHiringStages,
    refetch,
  } = useGetHiringStages([
    {
      columnName: 'status',
      filters: [
        {
          id: 'active',
          name: 'active',
        },
      ],
    },
  ])

  const [stages, setStages] = useState<HiringProcessInterface[]>([])

  useEffect(() => {
    if (stagesData?.results) {
      setStages(stagesData.results)
    }
  }, [stagesData])

  const { data: checkpoint } = useGetOnboardingCheckpointCategory(
    interviewsConfig.category,
  )

  const stepIsCompleted =
    checkpoint?.current_step !== null && checkpoint?.current_step !== 'Intro'

  if (isLoadingHiringStages) {
    return <PageLoading />
  }
  return (
    <>
      <PageBody>
        {stepIsCompleted ? (
          <StepCompletedWidget />
        ) : (
          <VStack gap="s-16">
            <HiringStagesList
              isSpecialisation={false}
              hiringProcessRounds={stages}
              onOrderChange={setStages}
              onRefresh={refetch}
            />
          </VStack>
        )}
      </PageBody>
      <PageActions>
        <SubmitDefaultHiringProcess
          hiringProcessRounds={stages}
          stepIsCompleted={stepIsCompleted}
        />
      </PageActions>
    </>
  )
}
