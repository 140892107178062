import React, { useEffect, useRef } from 'react'
import {
  ChangeScorecardInterface,
  CriteriaAssessment,
  PerformanceReviewTypes,
  PreviousReviewInterface,
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ScorecardError,
  ScorecardSections,
  SectionType,
  SingleChoice,
  SkillCardInterface,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import { ScorecardAssessLevel } from '@components/ScorecardGeneral/ScorecardAssessLevel'
import ScorecardRadioSection from '@components/ScorecardGeneral/ScorecardRadioSection'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import { useLocation } from 'react-router-dom'
import CardTitle from '@components/ScorecardGeneral/CardTitle'
import CardResult from '@components/ScorecardGeneral/CardResult'
import CardJustification from '@components/ScorecardGeneral/CardJustification'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, BoxProps, Link, Text, Widget } from '@revolut/ui-kit'
import { connect } from 'lape'
import { OptionInterface } from '@src/interfaces/selectors'
import { PreviousCycleCoverCss } from '@components/ScorecardGeneral/Common'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import { isInternalEnv } from '@src/utils'

interface ScorecardSectionProps extends Omit<BoxProps, 'onChange'> {
  skillId: string
  disabled?: boolean
  cardIndex: number
  cardTitleNumber?: number
  type: PerformanceReviewTypes
  category: ReviewCategory
  onChange?: (params: ChangeScorecardInterface) => void
  viewMode?: boolean
  errors?: ScorecardError
  sectionErrors?: ScorecardError[] | null
  touched?: boolean
  card: SkillCardInterface | SummarySkillCardInterface
  previousReviews?: PreviousReviewInterface[]
  hideCardTitle?: boolean
  hideJustification?: boolean
  hideResult?: boolean
  resultLoading?: boolean
  onSetResultLoading: (ids: (number | string)[]) => void
  cardTitleTags?: OptionInterface[]
  titleTooltip?: string
  generateOptionDataName?: (
    cardIndex: number,
    sectionIndex: number,
    optionIndex: number,
  ) => string
  showErrorLabel?: boolean
  hidePlaybookDescription?: boolean
  hideCountAndPrefill?: boolean
  showExceeding?: boolean
  noWidgetMargin?: boolean
  hideCounts?: boolean
}

const ScorecardSection = ({
  skillId,
  type,
  disabled,
  cardIndex,
  cardTitleNumber,
  onChange,
  viewMode,
  errors,
  touched,
  sectionErrors,
  previousReviews,
  card,
  hideCardTitle,
  hideJustification,
  hideResult,
  resultLoading,
  onSetResultLoading,
  category,
  cardTitleTags,
  titleTooltip,
  generateOptionDataName,
  showErrorLabel,
  hidePlaybookDescription = false,
  hideCountAndPrefill = false,
  showExceeding = false,
  noWidgetMargin = false,
  hideCounts,
  ...boxProps
}: ScorecardSectionProps) => {
  const { values } = useLapeContext<
    ReviewScorecardInterface | ReviewScorecardViewInterface
  >()
  const { hash } = useLocation()
  const ref = useRef<HTMLDivElement>(null)
  const justificationRef = useRef<HTMLDivElement>(null)
  // TODO: this is a hack around using both old and new scorecards UI in one review cycle
  //  will be removed once new scorecards are released for everyone
  // in the new scorecards UI we won't have Complexity/Speed/Quality cards, they will be replaced with setting rating directly
  // for Q4 2023 we're removing limiting complexity setting and hide extra options manually on FE
  // to allow sending values in a certain way for users who will be using new UI in Q4
  const showLimitedComplexity =
    type === PerformanceReviewTypes.deliverables &&
    isInternalEnv() &&
    card.criteria_key === 'complexity'

  useEffect(() => {
    if (`#${skillId}` === hash) {
      ref.current?.scrollIntoView()
    }
  }, [skillId, hash])

  useEffect(() => {
    const alreadyScrolled =
      sectionErrors && sectionErrors.findIndex(section => section) < cardIndex

    if (hash === SCROLL_ERROR_HASH && !alreadyScrolled && errors) {
      if (errors.sections) {
        ref.current?.scrollIntoView()
      } else if (errors.justification) {
        justificationRef.current?.scrollIntoView()
      } else {
        ref.current?.scrollIntoView()
      }
    }
  }, [hash])

  const renderContent = (section: ScorecardSections, sectionIndex: number) => {
    switch (section.type) {
      case SectionType.SingleChoice:
        return (
          <ScorecardRadioSection
            content={section as SingleChoice}
            cardIndex={cardIndex}
            sectionIndex={sectionIndex}
            onChange={onChange}
            viewMode={viewMode}
            key={sectionIndex}
            showLimitedOptions={showLimitedComplexity}
          />
        )

      case SectionType.CriteriasAssessment:
        return (
          <ScorecardAssessLevel
            content={section as CriteriaAssessment}
            cardIndex={cardIndex}
            sectionIndex={sectionIndex}
            onChange={onChange}
            viewMode={viewMode}
            error={errors?.sections?.[sectionIndex]}
            touched={touched}
            key={sectionIndex}
            generateOptionDataName={generateOptionDataName}
            ratingExpectation={null}
            hideCounts={hideCounts}
          />
        )
      default:
        return null
    }
  }

  const firstError = errors?.sections?.find(Boolean)

  return (
    <Box ref={ref} mt="s-32" mb="s-48" data-testid={`card-${skillId}`} {...boxProps}>
      <Box>
        <Widget
          p="s-16"
          mt={noWidgetMargin ? 0 : 's-16'}
          css={PreviousCycleCoverCss(previousReviews?.length || 0)}
        >
          {!hideCardTitle && (
            <CardTitle
              card={card as SkillCardInterface}
              type={type}
              category={category}
              cardIndex={cardIndex}
              cardTitleNumber={cardTitleNumber}
              viewMode={viewMode}
              previousReviews={previousReviews}
              onSetResultLoading={onSetResultLoading}
              tags={cardTitleTags}
              tooltip={titleTooltip}
              hideCountAndPrefill={hideCountAndPrefill}
            />
          )}
          {type === PerformanceReviewTypes.skills && !hidePlaybookDescription && (
            <Text variant="primary" color="grey-tone-50">
              Filled from the description of the skill.{' '}
              {(card as SkillCardInterface).playbook_url && (
                <Link target="_blank" href={(card as SkillCardInterface).playbook_url}>
                  View playbook
                </Link>
              )}
            </Text>
          )}
          {card.sections.map((section, sectionIndex) => {
            return renderContent(section, sectionIndex)
          })}
          {showErrorLabel && touched && firstError && (
            <InputErrorMessage message="This question is required" />
          )}
          {!viewMode && !hideResult && (
            <Box mb="s-8">
              <CardResult
                card={card as SkillCardInterface}
                type={type}
                disabled={disabled}
                loading={resultLoading}
              />
            </Box>
          )}
          {!hideJustification && (
            <CardJustification
              reviews={(values as ReviewScorecardViewInterface)?.reviews}
              justificationRef={justificationRef}
              card={card}
              cardIndex={cardIndex}
              previousReviews={previousReviews}
              type={type}
              disabled={disabled}
              viewMode={viewMode}
              css={PreviousCycleCoverCss(previousReviews?.length || 0)}
            />
          )}
        </Widget>
      </Box>
    </Box>
  )
}

export default connect(ScorecardSection)
