import { RequestInterface } from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  MeetingCompanionInterface,
  MeetingInterface,
  MeetingWithCompanionDetail,
  MeetingWithManagersStats,
} from '@src/interfaces/meetings'
import { useFetch } from '@src/utils/reactQuery'
import { getCommentsAPI } from '@src/api/comments'

export const getMeetingCompanionsRequests = (
  employeeId: number,
): RequestInterface<MeetingCompanionInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id =>
    api.get(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`, data),
  deleteItem: async id =>
    api.delete(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`),
  postItem: async data =>
    api.post(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings`, data),
})

export const getMeetingsWithCompanionRequests = (
  employeeId: number | string, // employee at whose page the data is requested
  companionId: number | string, // employee with whom the meetings are
): RequestInterface<MeetingInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
  getItem: async id =>
    api.get(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
    ),
  patchItem: async (data, id) =>
    api.patch(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
      data,
    ),
  deleteItem: async id =>
    api.delete(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
    ),
  postItem: async data =>
    api.post(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee`,
      data,
    ),
})

export const useGetManagerMeetingsStats = (employeeId: number) => {
  return useFetch<MeetingWithManagersStats>(
    `${API.ONE_TO_ONES}/${employeeId}/meetingsWithManagers/stats`,
  )
}

export const useGetCompanionMeetingsDetails = (
  employeeId: number | string,
  companionId: number | string,
) => {
  return useFetch<MeetingWithCompanionDetail>(
    `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithDetailEmployee/detail`,
  )
}

export const submitCalendarTracking = (syncNow = false) => {
  return api.post(
    `${API.ONE_TO_ONES}/newMeetingsForEmployee/fetch`,
    syncNow ? { sync_now: true } : {},
  )
}

export const getOneToOneCommentsApi = (meetingId: number) =>
  getCommentsAPI({ baseUrl: `${API.ONE_TO_ONES}/${meetingId}/comments` })
