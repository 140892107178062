import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import ApplicationFormWidgetSection, {
  ApplicationFormWidgetSectionInterface,
} from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormWidgetSection'
import { OptionInterface } from '@src/interfaces/selectors'
import { FormError } from '@src/features/Form/LapeForm'

type FieldNamesType<T> = {
  country?: keyof T
  location?: keyof T
}

interface Props<T> extends Partial<ApplicationFormWidgetSectionInterface> {
  optional?: boolean
  onChangeCountry?: (val: OptionInterface | null) => void
  onChangeLocation?: (val: OptionInterface | null) => void
  country?: OptionInterface | null
  location?: OptionInterface | null
  names?: FieldNamesType<T>
  errors?: FormError<T>
  disabled?: boolean
}

const LocationsQuestionSection = <T,>({
  optional,
  onChangeCountry,
  onChangeLocation,
  country,
  location,
  errors,
  names,
  locked,
  disabled,
  ...props
}: Props<T>) => {
  const getCommonProps = (field: keyof FieldNamesType<T>) => {
    const name = names?.[field]
    return {
      'data-name': name,
      'aria-invalid': name ? !!errors?.[name] : false,
      hasError: name ? !!errors?.[name] : false,
      message: name ? errors?.[name] : undefined,
      clearable: true,
      disabled: locked || disabled,
    }
  }
  const optionalPostfix = optional ? ' (optional)' : ''

  const countryProps = getCommonProps('country')
  const locationProps = getCommonProps('location')

  return (
    <ApplicationFormWidgetSection
      titleIndexNumber={1}
      title="Location"
      description="Current and preferred locations of the candidate"
      locked={locked}
      {...props}
    >
      <InputGroup>
        <RadioSelectInput<OptionInterface>
          selector={selectorKeys.countries}
          label={`Current country${optionalPostfix}`}
          value={country}
          onChange={val => {
            onChangeCountry?.(val)
          }}
          inputProps={{
            'data-name': countryProps['data-name'] as string,
          }}
          {...countryProps}
        />
        <RadioSelectInput<OptionInterface>
          selector={selectorKeys.location}
          label={`Preferred location${optionalPostfix}`}
          value={location}
          onChange={val => {
            onChangeLocation?.(val)
          }}
          inputProps={{
            'data-name': locationProps['data-name'] as string,
          }}
          {...locationProps}
        />
      </InputGroup>
    </ApplicationFormWidgetSection>
  )
}

export default LocationsQuestionSection
