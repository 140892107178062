import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import pluralize from 'pluralize'
import { Box, StatusWidget, Token, useMoneyFormat, Text, Color } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import { getPayCycleEmployeeReportsTableRequests } from '@src/api/payrollV2'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import {
  payCycleReportsChangesColumn,
  payCycleReportsDepartmentColumn,
  payCycleReportsEmployeeNameColumn,
  payCycleReportsGrossSalaryColumn,
  payCycleReportsRoleColumn,
  payCycleReportsStatusColumn,
} from '@src/constants/columns/payCycleReportsV2'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  PayCycleReportStatsInterface,
  PayCycleReportTableItemInterface,
} from '@src/interfaces/payrollV2'

import { CommonTabProps } from '../common'

const getRow = (
  onRowClick: (id: number) => void,
  moneyFormat: ReturnType<typeof useMoneyFormat>,
): RowInterface<PayCycleReportTableItemInterface> => ({
  linkToForm: data => {
    onRowClick(data.id)
  },
  cells: [
    {
      ...payCycleReportsEmployeeNameColumn,
      width: 200,
    },
    {
      ...payCycleReportsGrossSalaryColumn,
      width: 100,
      insert: ({ data }) =>
        data.salary_amount ? (
          <Text>
            {moneyFormat({ amount: data.salary_amount, currency: data.salary_currency })}
          </Text>
        ) : (
          '-'
        ),
    },
    {
      ...payCycleReportsRoleColumn,
      width: 150,
    },
    {
      ...payCycleReportsDepartmentColumn,
      width: 150,
    },
    {
      ...payCycleReportsChangesColumn,
      width: 100,
    },
    {
      ...payCycleReportsStatusColumn,
      width: 100,
    },
  ],
})

type Props = CommonTabProps & {
  onClickReport: (id: number) => void
}
export const PayCycleEmployeeReportsTable = ({ selectedCycle, onClickReport }: Props) => {
  const params = useParams<{ id: string }>()
  const cycleId = selectedCycle?.id || params.id

  const moneyFormat = useMoneyFormat()
  const table = useTable<PayCycleReportTableItemInterface, PayCycleReportStatsInterface>(
    getPayCycleEmployeeReportsTableRequests(cycleId),
  )

  useEffect(() => {
    table.refresh()
  }, [cycleId])

  const successOnZero = (
    count: number | undefined,
    defaultColor: Color = Token.color.greyTone50,
  ) => (count === 0 ? Token.color.success : defaultColor)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="Total"
          color={Token.color.greyTone50}
          val={table.stats?.total_reports}
        />
        <Stat
          label={pluralize('Change', table.stats?.total_changes)}
          color={Token.color.greyTone50}
          val={table.stats?.total_changes}
        />
        <Stat
          label={pluralize('Issue', table.stats?.total_issues)}
          color={successOnZero(table.stats?.total_issues)}
          val={table.stats?.total_issues}
        />
        <Stat
          label={pluralize('Critical', table.stats?.total_criticals)}
          color={successOnZero(table.stats?.total_criticals, Token.color.danger)}
          val={table.stats?.total_criticals}
        />
        <Stat
          label={pluralize('Warning', table.stats?.total_warnings)}
          color={successOnZero(table.stats?.total_criticals, Token.color.warning)}
          val={table.stats?.total_warnings}
        />
        <Stat
          label="Approved"
          color={
            table.stats?.total_approved === 0
              ? Token.color.greyTone50
              : Token.color.success
          }
          val={table.stats?.total_approved}
        />
      </Table.Widget.Info>
      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.PayCycleEmployeeReports}
          row={getRow(onClickReport, moneyFormat)}
          useWindowScroll
          emptyState={
            <Box py="s-32">
              <StatusWidget>
                <StatusWidget.Image
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images-v2/3D028.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@3x.png',
                  }}
                />
                <StatusWidget.Title>Nothing found</StatusWidget.Title>
                <StatusWidget.Description>
                  No employee reports for this cycle
                </StatusWidget.Description>
              </StatusWidget>
            </Box>
          }
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
