import React from 'react'
import { Box, Flex, Text, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ApplicationFormSettingsInterface } from '@src/interfaces/settings'
import ScorecardSectionControls from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/ScorecardSectionControls'
import ApplicationFormPreviewQuestion from '@src/pages/Settings/JobPosting/ApplicationForm/ApplicationFormPreviewQuestion'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

type Props = {
  idx: number
  onClickEdit: (sectionIdx: number) => void
}

const ApplicationFormPreviewSection = ({ idx, onClickEdit }: Props) => {
  const { values } = useLapeContext<ApplicationFormSettingsInterface>()
  const section = values.global_sections[idx]
  const permissions = useSelector(selectPermissions)
  const disableAdd = !permissions.includes(PermissionTypes.AddApplicationFormPreferences)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeApplicationFormPreferences,
  )
  const disableDelete = !permissions.includes(
    PermissionTypes.DeleteApplicationFormPreferences,
  )

  const handleDelete = () => {
    values.global_sections = values.global_sections?.filter((_, i) => i !== idx)
  }

  const handleCopy = () => {
    values.global_sections?.splice(idx + 1, 0, {
      ...values.global_sections[idx],
      questions: values.global_sections[idx]?.questions?.map(question => ({
        ...question,
        id: undefined,
        options: question.options?.map(option => ({
          ...option,
          id: undefined,
        })),
      })),
      id: undefined,
    })
  }

  return (
    <Widget
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        if (!disableEdit) {
          onClickEdit(idx)
        }
      }}
      width="100%"
      display="block"
      p="s-24"
      data-testid={`preview_section_${idx}`}
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="start">
          <Text fontWeight={500} fontSize="h5" use="div">
            {section.title || 'Section'}
          </Text>
          <Box
            style={{
              flexShrink: 0,
            }}
          >
            <ScorecardSectionControls
              id={idx}
              onDelete={handleDelete}
              onCopy={handleCopy}
              onEdit={() => onClickEdit(idx)}
              sections={values.global_sections}
              isView
              disableEdit={disableEdit}
              disableDelete={disableDelete}
              disableCopy={disableAdd}
            />
          </Box>
        </Flex>
        {section?.description && (
          <Box my="s-8">
            <Text variant="secondary">{section?.description}</Text>
          </Box>
        )}
      </Box>
      {section.questions.map((_, questionIdx) => (
        <ApplicationFormPreviewQuestion
          key={questionIdx}
          sectionIdx={idx}
          questionIdx={questionIdx}
        />
      ))}
    </Widget>
  )
}

export default ApplicationFormPreviewSection
