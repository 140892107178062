import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { FilterByInterface } from '@src/interfaces/data'
import { ParentGoalSelector } from '@src/interfaces/goals'
import { useGetGoalParentSelector } from '@src/api/goals'

const selectorTypeMap = {
  employees: 'employee_goal' as const,
  teams: 'team_goal' as const,
  department: 'department_goal' as const,
}

export const useGoalParentSelector = (
  contentType?: 'employees' | 'teams' | 'department',
  preselectedId?: number | string,
) => {
  const [isSearchPending, setIsSearchPending] = useState(false)
  const [nextPage, setNextPage] = useState<number>()
  const [filters, setFilters] = useState<FilterByInterface[]>(
    preselectedId
      ? [
          {
            columnName: 'id',
            filters: [{ id: String(preselectedId), name: String(preselectedId) }],
          },
        ]
      : [],
  )
  const [preselectedOption, setPreselectedOption] = useState<
    { value: ParentGoalSelector; label: string; key: number }[]
  >([])
  const [options, setOptions] = useState<
    { value: ParentGoalSelector; label: string; key: number }[]
  >([])
  const { data, isLoading } = useGetGoalParentSelector(
    contentType ? selectorTypeMap[contentType] : undefined,
    {
      page: nextPage,
      filters,
    },
  )

  const search = (query?: string) => {
    setNextPage(undefined)
    setOptions([])
    setFilters(
      query
        ? [
            {
              columnName: 'search',
              filters: [{ id: String(query), name: String(query) }],
            },
          ]
        : [],
    )
  }

  const debouncedSearch = useCallback(
    debounce(search, 1000, {
      leading: false,
      trailing: true,
    }),
    [],
  )

  const getNextPage = () => {
    if (data?.pages.next) {
      setNextPage(data.pages.next)
    }
  }

  useEffect(() => {
    // if we had chached goal or existing form with goal that is not on the first page we want to prefetch it
    if (preselectedId && !preselectedOption.length && data?.results.length) {
      const preselectedResult = data.results.at(0)
      if (preselectedResult) {
        setPreselectedOption([
          {
            value: preselectedResult,
            label: preselectedResult.name,
            key: preselectedResult.id,
          },
        ])
        // then on results remove the ID of the filters so that first page can be prefetched
        setFilters([])
      }
    } else if (data?.results.length) {
      setOptions(prev => {
        const nextValues =
          data?.results
            .filter(result => result.id !== preselectedId)
            .map(result => ({
              value: result,
              label: result.name,
              key: result.id,
            })) || []

        return [...(prev || []), ...nextValues]
      })
      setIsSearchPending(false)
    } else if (data?.count === 0) {
      setOptions([])
      setIsSearchPending(false)
    }
  }, [data])

  return {
    isSearchPending,
    isLoading,
    getNextPage,
    search: (query?: string) => {
      setIsSearchPending(true)
      debouncedSearch(query)
    },
    options: [...preselectedOption, ...options],
    // if search pending ignore current stage of next page so that we're not refetching when list shrinks due to FE filtering
    hasMore: isSearchPending ? false : !!data?.pages.next,
  }
}
