import React from 'react'
import { IconButton, MoreBar, Token } from '@revolut/ui-kit'
import {
  onboardingTimeManagementDynamicGroupsActionsColumn,
  onboardingTimeManagementDynamicGroupsEmployeesCountColumn,
  onboardingTimeManagementDynamicGroupsGroupNameColumn,
} from '@src/constants/columns/onboardingTimeManagement'
import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { pathToUrl } from '@src/utils/router'

const getRow = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<DynamicGroupInerface>> => ({
  cells: [
    {
      ...onboardingTimeManagementDynamicGroupsGroupNameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementDynamicGroupsEmployeesCountColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementDynamicGroupsActionsColumn(options.onChange),
      width: 100,
      insert: ({ data }) => {
        return (
          <IconButton
            color={Token.color.greyTone50}
            size={16}
            to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id: String(data.id) })}
            use={InternalLink}
            useIcon="Pencil"
          />
        )
      },
    },
  ],
})

export const ManageDynamicGroups = () => {
  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CONFIGURATION}
        title="Manage groups"
        subtitle="Define pre configured groups to be re-used across the product"
      />
      <PageBody>
        <GenericEditableTable
          apiEndpoint={API.DYNAMIC_GROUPS}
          entity="group"
          hiddenColumns={{ select: true, action: true }}
          row={getRow}
          tableActions={() => {
            return (
              <MoreBar>
                <MoreBar.Action
                  to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL)}
                  use={InternalLink}
                  useIcon="Plus"
                >
                  Add eligibility group
                </MoreBar.Action>
              </MoreBar>
            )
          }}
          tableName={TableNames.ManageDynamicGroups}
          variant="existingEntities"
        />
      </PageBody>
    </PageWrapper>
  )
}
