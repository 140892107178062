import React, { useState } from 'react'
import { AxiosResponse } from 'axios'
import pluralize from 'pluralize'
import { MoreBar, Spinner } from '@revolut/ui-kit'

import { getPayCycleExport } from '@src/api/payroll'
import { ExportTypes } from '@src/constants/export'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { saveFileByBlob } from '@src/utils'

export const ChangeReportDownload = ({
  id,
  issuesCount,
  payrollProvider,
}: {
  id: string
  issuesCount: number | undefined
  payrollProvider: string
}) => {
  const [loading, setLoading] = useState(false)
  const [openSubmit, setOpenSubmit] = useState(false)

  const onExportItem = () => {
    setOpenSubmit(false)
    if (!loading) {
      setLoading(true)
      getPayCycleExport(id)(ExportTypes.xlsx, { report_format: payrollProvider })
        .then((response: AxiosResponse<string>) => {
          saveFileByBlob(response.data, `Changes.xlsx`, response.headers['content-type'])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const onClick = () => {
    if (issuesCount) {
      setOpenSubmit(true)
      return
    }
    onExportItem()
  }

  return (
    <>
      <MoreBar.Action
        useIcon={loading ? <Spinner size={16} /> : 'Download'}
        onClick={onClick}
        aria-disabled={loading}
      >
        {loading
          ? 'Fetching report... This might take a few minutes'
          : 'Download change report'}
      </MoreBar.Action>
      <ConfirmationDialog
        open={openSubmit}
        onClose={() => setOpenSubmit(false)}
        onConfirm={onExportItem}
        onReject={() => setOpenSubmit(false)}
        label="Warning"
        body={`There are ${pluralize(
          'issue',
          issuesCount,
          true,
        )} in this pay cycle, do you want to continue?`}
        yesMessage="Yes, download"
        noMessage="Go back"
      />
    </>
  )
}
