import React from 'react'

import { PermissionTypes } from '@src/store/auth/types'

import { GoalsSettingsProps } from './Goals'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Rules from '@src/pages/Forms/KpiTemplates/Rules'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import Loader from '@src/components/CommonSC/Loader'
import { Redirect } from 'react-router-dom'

export const GoalsSettingsRules = ({ rulesRoute }: GoalsSettingsProps) => {
  const isOnboarding = isOnboardingPath()
  const permissions = useSelector(selectPermissions)
  const { data: performanceSettings, isLoading } = useGetPerformanceSettings()
  const hasTemplatesAndRulesEnabled = performanceSettings?.enable_kpi_templates

  const hasPermission = permissions.includes(PermissionTypes.ViewKpiTemplate)

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (!isLoading && !hasTemplatesAndRulesEnabled) {
    return (
      <Redirect
        to={pathToUrl(
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.ALL
            : ROUTES.SETTINGS.ALL,
        )}
      />
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        withVerticalSpacing={isOnboarding}
        title="Rules"
        subtitle="Manage goal rules"
        backUrl={pathToUrl(ROUTES.SETTINGS.ALL)}
        key={rulesRoute}
        hideGlobalSearch
      />

      {hasPermission ? <Rules variant="goal" /> : <NoAccessError />}
    </PageWrapper>
  )
}
