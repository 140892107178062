import { createBrowserHistory, History } from 'history'
import { compile } from 'path-to-regexp'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'

// There is no DOM in jest node environment, only for jest testing
// eslint-disable-next-line import/no-mutable-exports
export let history = typeof window === 'undefined' ? ({} as any) : createBrowserHistory()

export const updateHistory = (newHistory: History) => {
  history = newHistory
}

/*
 * Extension: returns a relative path
 * Main app: returns a relative path
 * Usage: in main app or extension where navigation within app
 * */
export const pathToUrl = (
  path: string,
  params: object = {},
  queryParams: Record<string, string> = {},
) => {
  let queryString = ''
  const filteredQueryParams = omitBy(queryParams, param => param == null)

  if (!isEmpty(filteredQueryParams)) {
    queryString = new URLSearchParams(filteredQueryParams).toString()
  }
  return `${compile(path)(params)}${queryString ? `?${queryString}` : ''}`
}

/*
 * Extension: returns a full path with hardcoded domain to the main app
 * Main app: as usual, returns a relative path
 * Usage: in places where main app and extension share code and should navigate to the main app
 * */
export const pathToUrlWithBaseUrl = (path: string, params: object = {}) =>
  `${process.env.BASE_URL || ''}${pathToUrl(path, params)}`

/*
 * Extension: returns a full path with hardcoded domain to the main app
 * Main app: returns a full path with current domain
 * Usage: in places where main app and extension share code and should navigate to the main app with current domain
 * */
export const pathToFullUrlWithBaseUrl = (
  path: string,
  params: object = {},
  queryParams?: Record<string, string>,
) =>
  `${process.env.BASE_URL || document.location.origin}${pathToUrl(
    path,
    params,
    queryParams,
  )}`

export const newTabTo = (path: string, params: object = {}) => {
  if (window) {
    const tab = window.open(pathToUrl(path, params), '_blank')
    tab?.focus()
  }
}

export const newTabToWithBaseUrl = (path: string, params: object = {}) => {
  if (window) {
    const tab = window.open(pathToUrlWithBaseUrl(path, params), '_blank')
    tab?.focus()
  }
}
