import React from 'react'
import { Avatar, Icon, Item, ItemSkeleton, Text, Token } from '@revolut/ui-kit'
import { useGetPerformanceRecruitmentCycleData } from '@src/api/performance'
import { PerformanceSelector } from '@src/interfaces/performance'
import { useQuery } from '@src/utils/queryParamsHooks'
import { SELECTED_CYCLE_ID_PARAM } from './NewLayoutTab'
import {
  PerformanceRatingTitle,
  PerformanceSenioritySublevel,
} from '@src/constants/performance'
import { getCellBackground } from '@src/constants/columns/performanceSummary'

interface Props {
  empoyeeId: number
  isRecruitmentCycle: boolean
  setRecruitmentCycle: (flag: boolean) => void
  setSelectedPeriod: (target: PerformanceSelector | undefined) => void
}

export const RecruitmentCycleItem = ({
  setRecruitmentCycle,
  setSelectedPeriod,
  isRecruitmentCycle,
  empoyeeId,
}: Props) => {
  const { changeQueryParam } = useQuery(true)

  const { data: recruitmentData, isLoading } =
    useGetPerformanceRecruitmentCycleData(empoyeeId)
  const rating = recruitmentData?.summary_data?.skill_ratings?.average_rating

  if (isLoading) {
    return <ItemSkeleton />
  }

  if (!recruitmentData?.candidate_id) {
    return null
  }

  return (
    <Item
      aria-label="Recruitment Cycle"
      use="button"
      variant="compact"
      aria-pressed={isRecruitmentCycle}
      onClick={() => {
        setRecruitmentCycle(true)
        setSelectedPeriod(undefined)
        changeQueryParam(SELECTED_CYCLE_ID_PARAM, 'recruitment')
      }}
    >
      <Item.Avatar>
        <Avatar color={Token.color.foreground} size={40} textStyle="emphasis1">
          {isRecruitmentCycle && (
            <Avatar.Badge
              bg={Token.color.foreground}
              position="bottom-right"
              useIcon={<Icon name="Check" size={12} />}
              size={16}
            />
          )}
          R
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        {recruitmentData?.seniority?.name && recruitmentData?.seniority?.level && (
          <Item.Title>
            {recruitmentData.seniority.name}{' '}
            {PerformanceSenioritySublevel[recruitmentData.seniority.level] ||
              recruitmentData.seniority.level}
          </Item.Title>
        )}
        <Item.Description>Recruitment</Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <Text color={getCellBackground(rating, true)} variant="body1">
            {rating ? PerformanceRatingTitle[rating] : ''}
          </Text>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
