import { ReactNode } from 'react'
import toString from 'lodash/toString'
import upperFirst from 'lodash/upperFirst'
import { css } from 'styled-components'
import { useLapeField } from '@src/features/Form/LapeForm'

export const getCommonInputProps = (
  inputProps: {
    name: string
    hasError?: boolean
    disabled?: boolean
    message?: ReactNode
    required?: boolean
    hideOptional?: boolean
    label?: string
    placeholder?: string
  },
  lapeProps: ReturnType<typeof useLapeField>,
  validated: boolean,
) => {
  const lapeError = lapeProps.touched ? lapeProps.error : undefined
  const error = lapeProps.error || lapeProps.apiError
  const lapeMessage = lapeError ? toString(lapeError) : undefined
  const errorMessage = error ? toString(error) : undefined
  const {
    disabled,
    message,
    hasError,
    label,
    placeholder,
    required,
    hideOptional,
    name,
  } = inputProps
  const optionalSuffix = !required && !hideOptional ? ' (optional)' : ''
  const msg = errorMessage && validated ? errorMessage : lapeMessage || message

  return {
    disabled: lapeProps.disabled || disabled,
    /** @deprecated use aria-invalid */
    hasError: (!!error && validated) || !!lapeError || hasError,
    'aria-invalid': (!!error && validated) || !!lapeError || hasError,
    message: typeof msg === 'string' ? upperFirst(msg) : msg,
    label: label ? label + optionalSuffix : undefined,
    // TODO: keeping optionalSuffix (when label is not set) due to legacy support (remove when possible)
    placeholder: placeholder ? placeholder + (label ? '' : optionalSuffix) : undefined,
    'data-name': name,
  }
}

export const HideInputCalendarIndicatorCss = css`
  input::-webkit-calendar-picker-indicator {
    background: none;
  }
`
