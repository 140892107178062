import { IconName } from '@revolut/ui-kit'
import { ReviewCycleStage } from '@src/interfaces/reviewCycles'

interface Props {
  label: string
  iconName: IconName
  disabled?: boolean
}

const goalsStageProps: Props = {
  label: 'Finish stage',
  iconName: 'ArrowThinRight',
}

const reviewStageProps: Props = {
  label: 'End performance review',
  iconName: 'ArrowThinRight',
}

const calibrationStageProps: Props = {
  label: 'Finish calibration period',
  iconName: 'ArrowThinRight',
}

const publishStageProps: Props = {
  label: 'Publish results',
  iconName: 'Megaphone',
}

const completedStageProps: Props = {
  label: 'Results published',
  iconName: 'Check',
  disabled: true,
}

export const stageToButtonPropsMap: Record<ReviewCycleStage, Props> = {
  [ReviewCycleStage.DepartmentGoals]: goalsStageProps,
  [ReviewCycleStage.TeamGoals]: goalsStageProps,
  [ReviewCycleStage.Review]: reviewStageProps,
  [ReviewCycleStage.Calibration]: calibrationStageProps,
  [ReviewCycleStage.ManagersPublish]: publishStageProps,
  [ReviewCycleStage.EmployeesPublish]: publishStageProps,
  [ReviewCycleStage.Completed]: completedStageProps,
}
