import React from 'react'
import { AccountSwitcher } from '@revolut/ui-kit'

import { useGetCompanyLogo, useGetCompanyPreferences } from '@src/api/settings'

export const TopNavAccountSwitcher = () => {
  const { data: companyLogoData, isLoading: isCompanyLogoLoading } = useGetCompanyLogo()
  const { data: companyPreferencesData, isLoading: isCompanyPreferencesLoading } =
    useGetCompanyPreferences()

  if (isCompanyLogoLoading || isCompanyPreferencesLoading) {
    return <AccountSwitcher pending />
  }

  return (
    <AccountSwitcher image={companyLogoData?.url || undefined}>
      {companyPreferencesData?.brand_name}
    </AccountSwitcher>
  )
}
