import { z } from 'zod'

export interface ValidationResult {
  isValid: boolean
  messages: string[]
}

export const gradeDistributionSchema = z
  .record(z.string(), z.number())
  .refine(
    obj =>
      Object.values(obj).reduce<number>(
        (sum, current) => Number((sum + current).toFixed(2)),
        0,
      ) === 1,
    {
      message: 'The sum of all numbers needs to be 100%',
    },
  )

export const validateGradesDistribution = (
  values: Record<string, number>,
): ValidationResult => {
  const validationResult = gradeDistributionSchema.safeParse(values)

  return {
    isValid: validationResult.success,
    messages: !validationResult.success
      ? validationResult.error.issues.map(({ message }) => message)
      : [],
  }
}
