import React, { useState } from 'react'
import {
  ActionMenu,
  Button,
  Cell,
  DateInput,
  InputGroup,
  Header,
  MoreBar,
  Popup,
  Radio,
  RadioGroup,
  StatusPopup,
  useDropdown,
  useStatusPopup,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { createAdpReportFtpUpload, createAdpReportExport } from '@src/api/payroll'
import { ROUTES } from '@src/constants/routes'
import { AdpReportTypes, AdpReportRequestInterface } from '@src/interfaces/payroll'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { getDate } from '@src/utils/timezones'

type ReportActions = 'download' | 'upload'
type Props = {
  id: string
  payrollProvider: string
}

export const AdpDownloadReport = ({ id, payrollProvider }: Props) => {
  const dropdown = useDropdown()
  const statusPopup = useStatusPopup()
  const [isPending, setIsPending] = useState(false)
  const [periodPopupOpen, setPeriodPopupOpen] = useState(false)
  const [selectDates, setSelectDates] = useState(false)
  const [reportAction, setReportAction] = useState<ReportActions>()
  const [reportType, setReportType] = useState<AdpReportTypes>()
  const [endDate, setEndDate] = useState<Date | null>()
  const [startDate, setStartDate] = useState<Date | null>()

  const handleCreateAdpReport = async (
    cb: (data: AdpReportRequestInterface) => ReturnType<typeof createAdpReportExport>,
  ) => {
    setIsPending(true)
    const reportData = {
      export_provider: payrollProvider,
      report_type: reportType,
      ...(selectDates && {
        from_date: startDate ? getDate(startDate) : undefined,
        to_date: endDate ? getDate(endDate) : undefined,
      }),
    }

    try {
      const result = await cb(reportData)
      navigateTo(
        pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORT, {
          id,
          requestId: result.data.id,
          action: reportAction,
        }),
      )
    } catch (e) {
      const errorMsg = getStringMessageFromError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to create report</StatusPopup.Title>
          {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
          <StatusPopup.Actions>
            <Button elevated onClick={() => statusPopup.hide()}>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  const createAdpReport = () => {
    if (reportAction === 'download') {
      handleCreateAdpReport(data => createAdpReportExport(id, data))
    } else {
      handleCreateAdpReport(data => createAdpReportFtpUpload(id, data))
    }
  }

  return (
    <>
      <MoreBar.Action {...dropdown.getAnchorProps()} useIcon="Download">
        Download report
      </MoreBar.Action>

      <ActionMenu {...dropdown.getTargetProps()}>
        <ActionMenu.Group>
          <ActionMenu.Item
            onClick={() => setReportType('base_report')}
            useIcon="BalanceSheet"
          >
            Base Report
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => setReportType('change_report')}
            useIcon="ArrowExchange"
          >
            Change Report
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>

      <Popup
        open={!!reportType}
        onClose={() => setReportType(undefined)}
        variant="bottom-sheet"
      >
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>How would you like to send the file?</Header.Title>
        </Header>
        <InputGroup>
          <RadioGroup<ReportActions>
            onChange={value => {
              if (value) {
                setReportAction(value)
              }
            }}
            value={reportAction}
          >
            {group => (
              <>
                <Cell>
                  <Radio {...group.getInputProps({ value: 'download' })} defaultChecked>
                    <Radio.Label>Download the file</Radio.Label>
                    <Radio.Description>
                      File will be downloaded manually.
                    </Radio.Description>
                  </Radio>
                </Cell>
                <Cell>
                  <Radio
                    {...group.getInputProps({ value: 'upload' })}
                    disabled={payrollProvider === 'adp_g2'}
                  >
                    <Radio.Label>Send file to provider</Radio.Label>
                    <Radio.Description>
                      File will automatically be sent to provider
                    </Radio.Description>
                  </Radio>
                </Cell>
              </>
            )}
          </RadioGroup>
        </InputGroup>
        <Popup.Actions horizontal>
          <Button onClick={() => setReportType(undefined)} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={!reportAction}
            elevated
            onClick={() => setPeriodPopupOpen(true)}
            pending={isPending}
          >
            Next
          </Button>
        </Popup.Actions>
      </Popup>

      <Popup
        data-testid="select_report_period_popup"
        open={periodPopupOpen}
        onClose={() => setPeriodPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Which period should be sent?</Header.Title>
        </Header>
        <InputGroup>
          <RadioGroup
            onChange={value => {
              if (value !== null) {
                setSelectDates(value)
              }
            }}
            value={selectDates}
          >
            {group => (
              <>
                <Cell>
                  <Radio {...group.getInputProps({ value: false })} defaultChecked>
                    <Radio.Label>Whole paycycle</Radio.Label>
                    <Radio.Description>
                      Dates from pay cycle will be sent
                    </Radio.Description>
                  </Radio>
                </Cell>
                <Cell>
                  <Radio {...group.getInputProps({ value: true })}>
                    <Radio.Label>Specific dates within paycycle</Radio.Label>
                    <Radio.Description>
                      Send specific dates from within paycycle
                    </Radio.Description>
                  </Radio>
                </Cell>
              </>
            )}
          </RadioGroup>
          {selectDates && (
            <InputGroup variant="horizontal">
              <DateInput
                clearable
                label="Start date"
                onChange={value => setStartDate(value)}
                value={startDate}
              />
              <DateInput
                clearable
                label="End date"
                onChange={value => setEndDate(value)}
                value={endDate}
              />
            </InputGroup>
          )}
        </InputGroup>
        <Popup.Actions horizontal>
          <Button onClick={() => setPeriodPopupOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            elevated
            disabled={selectDates && !(startDate && endDate)}
            onClick={createAdpReport}
            pending={isPending}
          >
            Next
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
