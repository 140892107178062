import React, { useEffect, useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  CareersPositionContent,
  CareersPositionContentProps,
} from '@src/pages/Careers/CareersPosition/CareersPositionContent'
import { locationsRequests } from '@src/api/locations'
import { CareersPositionLocation } from '@src/interfaces/careers'
import { LocationInterface } from '@src/interfaces/requisitions'
import { useCareersSettings } from '@src/api/recruitment/careers'
import { JobPostingLocationInterface } from '@src/interfaces/jobPosting'
import { isHTMLFieldEmpty } from '@src/utils/string'

interface Props extends Omit<CareersPositionContentProps, 'locations' | 'id'> {
  open: boolean
  onClose: () => void
  locations: LocationInterface[] | JobPostingLocationInterface[]
}

export const CareersPositionPreviewSidebar = ({
  open,
  onClose,
  text,
  locations,
  description,
}: Props) => {
  const { data: careersSettings } = useCareersSettings()
  const [locationsWithType, setLocationsWithType] = useState<CareersPositionLocation[]>(
    [],
  )

  useEffect(() => {
    const fetchLocations = async () => {
      const resp = await locationsRequests.getItems({
        filters: [
          {
            columnName: 'id',
            filters: locations.map(item => ({
              id: item.id,
              name: String(item.id),
            })),
          },
        ],
      })

      setLocationsWithType(
        resp.data.results.map(item => ({
          name: item.location_name,
          country: item.country?.name || '',
          type: item.type?.id || 'office',
        })),
      )
    }

    if (locations.length) {
      fetchLocations()
    } else {
      setLocationsWithType([])
    }
  }, [locations])

  return (
    <SideBar
      isOpen={open}
      onClose={onClose}
      useLayout
      title="Preview position"
      sideProps={{ resizable: true }}
    >
      <CareersPositionContent
        text={text || '<Position title>'}
        locations={locationsWithType}
        id=""
        description={
          !isHTMLFieldEmpty(careersSettings?.about_the_company)
            ? `<b>About the company</b><br />${careersSettings?.about_the_company} ${description}`
            : description
        }
        disabledApplyButton
      />
    </SideBar>
  )
}
