import React, { useMemo, useState } from 'react'
import {
  Search,
  VStack,
  ItemSkeleton,
  Item,
  Avatar,
  Bar,
  Cell,
  Text,
  Checkbox,
  ActionButton,
  Side,
  chain,
  Button,
  StatusWidget,
} from '@revolut/ui-kit'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { getQuestionDescription } from '@src/apps/People/Engagement/helpers'
import {
  useInfiniteQuestionsLoading,
  useQuestionsFilters,
} from '@src/apps/People/Engagement/Survey/components/AddQuestionSidebar/hooks'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { differenceBy, has } from 'lodash'

type Props = {
  onAddQuestion: (
    q: EngagementQuestionInterface | EngagementQuestionInterface[],
    closeSidebar?: boolean,
  ) => void
  addedQuestionsIds: number[]
}
export const QuestionsTab = ({ onAddQuestion, addedQuestionsIds }: Props) => {
  const { data: driverOptions } = useGetSelectors<IdAndName>(
    selectorKeys.engagement_drivers,
  )
  const [selectedQuestions, setSelectedQuestions] = useState<
    EngagementQuestionInterface[]
  >([])

  const { search, filters, filtersParams } = useQuestionsFilters()
  const { questions, isLoadingMore, isReloadingFilters, ref, hasMoreData } =
    useInfiniteQuestionsLoading({ filtersParams })

  const filteredQuestions = useMemo(() => {
    return (
      questions.filter(({ id: questionId }) => !addedQuestionsIds.includes(questionId)) ||
      []
    )
  }, [questions, addedQuestionsIds])

  const toggleQuestion = (question: EngagementQuestionInterface) => {
    setSelectedQuestions(prev => {
      const hasQuestion = prev.some(({ id }) => id === question.id)
      if (hasQuestion) {
        return prev.filter(({ id }) => id !== question.id)
      }
      return [...prev, question]
    })
  }

  return (
    <>
      <VStack space="s-16">
        <Search
          value={search.searchValue}
          placeholder="Search"
          onChange={newValue => {
            search.setSearchValue(newValue)
            filters.setTextFilter(newValue)
          }}
        />
        <Bar pb="s-12">
          <FilterButtonCheckboxSelect
            searchable
            width={300}
            options={driverOptions || []}
            label="Category"
            onChange={value => {
              if (value) {
                filters.setDriversFilter(value)
              }
            }}
          />
        </Bar>
        {!isLoadingMore && isReloadingFilters ? (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        ) : (
          <>
            {filteredQuestions.length > 1 && (
              <Cell use="label">
                <Checkbox
                  checked={
                    !differenceBy(filteredQuestions, selectedQuestions, 'id').length
                  }
                  onChange={() => {
                    if (
                      !differenceBy(filteredQuestions, selectedQuestions, 'id').length
                    ) {
                      setSelectedQuestions([])
                    } else {
                      setSelectedQuestions(filteredQuestions)
                    }
                  }}
                >
                  <Text variant="secondary">Select all</Text>
                </Checkbox>
              </Cell>
            )}
            {!filteredQuestions.length && (
              <StatusWidget>
                <StatusWidget.Image
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
                  }}
                />
                <StatusWidget.Title>No questions found</StatusWidget.Title>
                <StatusWidget.Description>
                  {search.searchValue
                    ? `No results found for “${search.searchValue}"`
                    : has(filtersParams, 'driver__id')
                    ? 'No results found for selected categories'
                    : 'All questions in library are added to the survey. Try creating a new one'}
                </StatusWidget.Description>
              </StatusWidget>
            )}
            {filteredQuestions.map(question => (
              <Item key={question.id}>
                <Item.Prefix>
                  <Checkbox
                    checked={selectedQuestions.some(({ id }) => id === question.id)}
                    onChange={() => toggleQuestion(question)}
                  />
                </Item.Prefix>
                <Item.Avatar>
                  <Avatar
                    useIcon={
                      question.type?.id === 'open_ended' ? 'Paragraph' : 'LikeDislike'
                    }
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{question.question_text}</Item.Title>
                  <Item.Description>{getQuestionDescription(question)}</Item.Description>
                </Item.Content>
                <Item.Side>
                  <Item.Value>
                    <ActionButton
                      onClick={() => {
                        // remove question from selected list as instantly added to the survey
                        if (selectedQuestions.some(({ id }) => id === question.id)) {
                          toggleQuestion(question)
                        }
                        onAddQuestion(question)
                      }}
                    >
                      Add
                    </ActionButton>
                  </Item.Value>
                </Item.Side>
              </Item>
            ))}
          </>
        )}
        <VStack ref={ref} space="s-12">
          {isLoadingMore && hasMoreData && (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          )}
        </VStack>
      </VStack>
      {!!selectedQuestions.length && (
        <Side.Actions>
          <Button
            elevated
            onClick={() => {
              onAddQuestion(selectedQuestions, true)
            }}
          >
            {chain('Add', selectedQuestions.length)}
          </Button>
        </Side.Actions>
      )}
    </>
  )
}
