import React, { useEffect, useRef, useState } from 'react'
import { Cell, Token, VStack, Avatar, StatusWidget } from '@revolut/ui-kit'

import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { WorkScheduleEligibilityStepInterface } from '@src/interfaces/workSchedule'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import {
  useGetWorkScheduleEligibility,
  workScheduleEligibilityCustomFiltersRequests,
} from '@src/api/workSchedule'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@components/Page/PageActions'
import { CommonTabsProps } from '@src/apps/People/TimeManagement/WorkSchedule/Edit/common'
import { useQuery } from '@src/utils/queryParamsHooks'
import Loader from '@components/CommonSC/Loader'

import { EligibilityConfig } from '../../common/EligibilityConfig'
import { useHandleEligibilityError } from '../../common/useHandleEligibilityError'
import { useHandleNonFieldError } from '../../common/useHandleNonFieldError'

type Props = CommonTabsProps
export const WorkScheduleEligibility = ({ nextTabPath }: Props) => {
  const { query } = useQuery()
  const { values, submit } = useLapeContext<WorkScheduleEligibilityStepInterface>()

  const [customFilters, setCustomFilters] = useState<TableFilter>({})

  const { data: copySource, isLoading: isCopySourceLoading } =
    useGetWorkScheduleEligibility(query.copyFrom)

  useEffect(() => {
    if (values.is_default_schedule) {
      values.auto_assign_to_employees = false
    }
  }, [values.is_default_schedule])

  useEffect(() => {
    if (query.copyFrom && copySource) {
      values.auto_assign_to_employees = copySource.auto_assign_to_employees
      values.custom_filters_eligibility_group =
        copySource.custom_filters_eligibility_group
      values.eligibility_group_type = copySource.eligibility_group_type
      values.eligibility_groups = copySource.eligibility_groups
    }
  }, [query.copyFrom, copySource])

  const groupsPlaceholderRef = useRef<HTMLDivElement>(null)

  const {
    error: eligibilityGroupError,
    setError: setEligibilityGroupError,
    errorHandler: eligibilityErrorHandler,
  } = useHandleEligibilityError(groupsPlaceholderRef)

  const nonFieldErrorHandler = useHandleNonFieldError([
    'auto_assign_to_employees',
    'custom_filters_eligibility_group',
    'eligibility_group_type',
    'eligibility_groups',
  ])

  if (isCopySourceLoading) {
    return <Loader />
  }
  return (
    <>
      <VStack space="s-16">
        {values.is_default_schedule ? (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D211.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D211@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D211@3x.png',
              }}
            />
            <StatusWidget.Title>This is a default work schedule</StatusWidget.Title>
            <StatusWidget.Description>
              It applies to everyone who is not assigned to another work schedule. For
              this work schedule, there is no assignment group.
            </StatusWidget.Description>
          </StatusWidget>
        ) : (
          <>
            <SectionTitle
              title="Who should be assigned to this schedule?"
              subtitle="Auto assign this work scheduled to any employee who meets the criterias set below"
            />
            <VStack width="100%" space="s-16">
              <Cell p={0}>
                <LapeNewSwitch
                  disabled={values.is_default_schedule}
                  name="auto_assign_to_employees"
                  itemTypeProps={{
                    title: 'Auto assign schedule to a group of employees',
                    description:
                      'Auto assign this work scheduled to any employee who meets the criteria set below',
                    avatar: (
                      <Avatar size={40} color={Token.color.blue} useIcon="People" />
                    ),
                    transparent: true,
                  }}
                />
              </Cell>
            </VStack>
          </>
        )}
        {values.auto_assign_to_employees && (
          <EligibilityConfig
            isSchedule
            values={values}
            eligibilityGroupError={eligibilityGroupError}
            setEligibilityGroupError={setEligibilityGroupError}
            customFilters={customFilters}
            setCustomFilters={setCustomFilters}
            placeholderRef={groupsPlaceholderRef}
            entity="workSchedule"
          />
        )}
      </VStack>

      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          hideWhenNoChanges={false}
          onClick={async () => {
            if (
              values.eligibility_group_type?.id !== 'employee_filters' ||
              !customFilters
            ) {
              return submit()
            }
            let groupId

            if (!values.custom_filters_eligibility_group?.id) {
              const { data: newFilter } =
                await workScheduleEligibilityCustomFiltersRequests.submit(
                  { table_filters: customFilters },
                  values.id,
                )
              groupId = newFilter.id
            } else {
              await workScheduleEligibilityCustomFiltersRequests.update(
                { table_filters: customFilters },
                values.id,
                values.custom_filters_eligibility_group.id,
              )
              groupId = values.custom_filters_eligibility_group.id
            }
            // On the final step the eligible employees are fetched by the group.id,
            // even if the source is custom filter, so to make it display correct results,
            // we need to replace the value here before proceeding to the next step
            values.custom_filters_eligibility_group = {
              id: groupId,
              name: `Custom filters eligibility group ${groupId}`,
            }
            return submit()
          }}
          onAfterSubmit={res => {
            navigateReplace(pathToUrl(nextTabPath, { id: res.id }, query))
          }}
          errorHandler={e => {
            try {
              eligibilityErrorHandler(e)
            } catch (unhandledErr) {
              nonFieldErrorHandler(unhandledErr)
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
