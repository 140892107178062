import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetReviewGradesMap } from '@src/utils/grades'

export interface Grade {
  id: string
  name: string
  value: number
}

interface GradesDistribution {
  grades: Grade[]
  isLoading: boolean
}

export const useGradesDistribution = (
  cycle: ReviewCyclesInterface,
): GradesDistribution => {
  const { grade_distribution: gradeDistribution } = cycle
  const { gradesMap, isLoading } = useGetReviewGradesMap()

  if (!gradeDistribution || !Object.keys(gradesMap).length) {
    return {
      grades: [],
      isLoading,
    }
  }

  const grades = Object.entries(gradesMap).reduce<Grade[]>((distribution, [id, name]) => {
    if (Object.hasOwn(gradeDistribution, id)) {
      distribution.push({
        id,
        name,
        value: gradeDistribution[id],
      })
    }

    return distribution
  }, [])

  return {
    grades,
    isLoading,
  }
}
