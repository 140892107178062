import { Reducer } from 'redux'
import { produce } from 'immer'
import Cookies from 'js-cookie'
import { AuthActionTypes, AuthState } from './types'
import { COOKIE } from '@src/constants/api'
import { Authenticated } from '@src/store/auth/constants'

const initialAuthState: AuthState = {
  authenticated: Cookies.get(COOKIE.AUTHENTICATED) === Authenticated.authenticated,
  permissions: [],
  featureFlags: [],
  // @ts-expect-error hack: user should be set here, but due to security reasons is not
  user: {},
  loginUrl: undefined,
  subscriptionState: undefined,
}

const reducer: Reducer<AuthState> = (state = initialAuthState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case AuthActionTypes.SET_AUTHENTICATED:
        draft.authenticated = action.payload
        break
      case AuthActionTypes.SET_PERMISSIONS:
        draft.permissions = action.payload
        break
      case AuthActionTypes.SET_FEATURE_FLAGS:
        draft.featureFlags = action.payload
        break
      case AuthActionTypes.SET_USER:
        draft.user = action.payload
        break
      case AuthActionTypes.SET_SUBSCRIPTION_STATE:
        draft.subscriptionState = action.payload
        break
      case AuthActionTypes.SET_LOGIN_URL:
        draft.loginUrl = action.payload
        break
      default:
        break
    }
  })
}

export { reducer as AuthReducer }
