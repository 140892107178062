import { AxiosPromise } from 'axios'
import { api } from '@src/api'
import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import {
  EmployeeTotalCompensationEquityTimeSeriesInterface,
  EmployeeTotalCompensationStatsInterface,
  EmployeeTotalCompensationTimeSeriesInterface,
  EmployeeTotalCompensationGrantInterface,
} from '@src/interfaces/totalCompensation'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const useGetEmployeeTotalCompensationTimeSeries = (
  employeeId: number | string,
  filtersParams: FilterByInterface[],
) =>
  useFetch<EmployeeTotalCompensationTimeSeriesInterface[]>(
    `${API.TOTAL_COMPENSATION}/${employeeId}/timeSeries`,
    'v1',
    {
      params: filterSortPageIntoQuery(undefined, filtersParams),
    },
  )

export const useGetEmployeeEquityTimeSeries = (
  employeeId: string | number,
  filtersParams: FilterByInterface[],
) =>
  useFetch<EmployeeTotalCompensationEquityTimeSeriesInterface[]>(
    `${API.TOTAL_COMPENSATION}/${employeeId}/equityTimeSeries`,
    'v1',
    {
      params: filterSortPageIntoQuery(undefined, filtersParams),
    },
  )

export const useGetEmployeeTotalCompensationStats = (
  employeeId: string | number,
  filtersParams: FilterByInterface[],
) =>
  useFetch<EmployeeTotalCompensationStatsInterface>(
    `${API.TOTAL_COMPENSATION}/${employeeId}/stats`,
    'v1',
    {
      params: filterSortPageIntoQuery(undefined, filtersParams),
    },
  )

export const getGrants = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeTotalCompensationGrantInterface>
> => {
  return api.get(`${API.TOTAL_COMPENSATION}/grants`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
}

export const deleteGrant = (id: number) =>
  api.delete(`${API.TOTAL_COMPENSATION}/grants/${id}`)

export const getEmployeeGrants =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTotalCompensationGrantInterface>
  > =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}/grants`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    )
