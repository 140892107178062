import React, { useEffect, useRef, useState } from 'react'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ActionButton, Dropdown, Text, Token } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { API, selectorKeys } from '@src/constants/api'
import HiringStagePreviewForm from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringStagePreviewForm'
import { getSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { history, pathToUrl } from '@src/utils/router'
import { formHiringProcessStagesSpecialisationRequest } from '@src/api/hiringProcess'
import { rolesRequests } from '@src/api/roles'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { Link, useLocation } from 'react-router-dom'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { updateStagesAfterDelete } from '@src/utils/hiring'
import { handleError } from '@src/api'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { HiringProcessTable } from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcessTable'
import Table from '@src/components/TableV2/Table'

const CURRENT_STEP = 'hiring_process'

const HiringProcess = () => {
  const location = useLocation<{ stageId?: number } | undefined>()
  const [loading, setLoading] = useState(false)
  const [openDropdown, setDropdown] = useState(false)
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>(
    location.state?.stageId,
  )
  const [hiringStages, setHiringStages] = useState<OptionInterface[]>([])
  const [role, setRole] = useState<RoleInterface>()
  const ref = useRef<HTMLButtonElement | null>(null)
  const context = useLapeContext<SpecialisationInterface>()
  const { values, errors } = context
  const changeOrder = (d: HiringProcessInterface[]) => {
    values.hiring_process_rounds = d
  }
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)

  useEffect(() => {
    fetchHiringStages()
    history.replace({ state: { ...location.state, stageId: undefined } })
  }, [])

  const fetchHiringStages = async () => {
    setLoading(true)
    try {
      const roleResult = values?.role?.id
        ? await rolesRequests.getItem(values?.role?.id)
        : undefined
      const result = await getSelectors(selectorKeys.hiring_stages)
      if (result?.data?.options) {
        setHiringStages(result?.data?.options)
      }

      if (roleResult?.data) {
        setRole(roleResult.data)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (stage: HiringProcessInterface) => {
    try {
      await formHiringProcessStagesSpecialisationRequest.delete!(stage, {
        id: String(stage.id),
      })
      updateStagesAfterDelete(context, stage.id)
    } catch (e) {
      handleError(e)
    }
  }

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      setSelectedStageId(data.id)
    }
  }

  return (
    <>
      <PageBody maxWidth="100%">
        <Table.Widget>
          <HiringStagePreviewForm
            id={selectedStageId}
            type="specialisation"
            onClose={() => setSelectedStageId(undefined)}
            onSuccessDelete={() => {
              updateStagesAfterDelete(context, selectedStageId!)
            }}
          />
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <Dropdown
                open={openDropdown}
                anchorRef={ref}
                onClose={() => setDropdown(false)}
                autoClose
              >
                <Dropdown.Group>
                  <Dropdown.Item
                    color={Token.color.accent}
                    // @ts-expect-error object works fine here, but UI kit expects string
                    to={getLocationDescriptor(
                      pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                      {
                        specialisation: { id: values.id, name: values.name },
                      },
                    )}
                    use={Link}
                  >
                    <Text>+ Add new</Text>
                  </Dropdown.Item>
                </Dropdown.Group>
                {!!role?.hiring_process_rounds?.length && (
                  <Dropdown.Group>
                    <Text ml="s-16" color="grey-tone-50" fontSize="small">
                      {values.role?.name ? `${values.role?.name} stages` : 'Role stages'}
                    </Text>
                    {role.hiring_process_rounds.map(stage => {
                      return (
                        <Dropdown.Item
                          key={stage.id}
                          // @ts-expect-error object works fine here, but UI kit expects string
                          to={getLocationDescriptor(
                            pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                            {
                              specialisation: { id: values.id, name: values.name },
                              roleHiringStageId: stage.id,
                            },
                          )}
                          use={Link}
                        >
                          {stage.title}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Group>
                )}

                <Dropdown.Group>
                  {!!role?.hiring_process_rounds?.length && (
                    <Text ml="s-16" color="grey-tone-50" fontSize="small">
                      Other stages
                    </Text>
                  )}
                  {hiringStages.map(stage => {
                    return (
                      <Dropdown.Item
                        key={stage.id}
                        // @ts-expect-error object works fine here, but UI kit expects string
                        to={getLocationDescriptor(
                          pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION),
                          {
                            specialisation: { id: values.id, name: values.name },
                            companyHiringStageId: stage.id,
                          },
                        )}
                        use={Link}
                      >
                        {stage.name}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Group>
              </Dropdown>
              <ActionButton
                onClick={() => setDropdown(!openDropdown)}
                ref={ref}
                useIcon="ChevronDown"
              >
                Add a round
              </ActionButton>
              <HideIfCommercial>
                {values.hiring_playbook_url && (
                  <ActionButton
                    use="a"
                    target="_blank"
                    href={values.hiring_playbook_url}
                    useIcon="16/LinkExternal"
                  >
                    Hiring Playbook
                  </ActionButton>
                )}
              </HideIfCommercial>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <HiringProcessTable
              hiringProcessRounds={values.hiring_process_rounds}
              hiringProcessErrors={errors?.hiring_process_rounds}
              loading={loading}
              onDelete={handleDelete}
              onOrderChange={changeOrder}
              onRowClick={handleRowEdit}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
      <PageActions>
        {values.status === Statuses.draft && (
          <RoleSaveDraftButton
            title="specialisation"
            pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
            pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS}
            isNew
            notification={{
              updateMsg: 'Specialisation draft successfully updated.',
              createMsg: 'Specialisation draft successfully created.',
            }}
          />
        )}
        <ContinueRoleButton
          api={API.SPECIALISATIONS}
          type="specialisation"
          step={CURRENT_STEP}
          to={nextLink}
          disabled={!values?.hiring_process_rounds?.length}
        />
      </PageActions>
    </>
  )
}

export default HiringProcess
