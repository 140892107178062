import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  Box,
  Flex,
  Token,
  VStack,
  Group,
  Text,
  Cell,
  TabBar,
  TextSkeleton,
} from '@revolut/ui-kit'
import React, { useEffect, useMemo, useState } from 'react'
import { format, isPast } from 'date-fns'

import { BarChart } from '@revolut/icons'
import {
  PerformanceSelector,
  PerformanceTimelineInterface,
  PerformanceTimelineStats,
  RequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import {
  getPerformanceTimelineStats,
  useGetPerformanceSelector,
} from '@src/api/performance'
import { LayoutTabProps } from '@src/pages/EmployeeProfile/Layout/common/types'
import { ReviewCycleCategory, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { isBefore, subDays } from 'date-fns'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  checkIsPipPeriod,
  checkIsProbationPeriod,
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetPeriodTypes } from '@src/utils/performance'
import { HeaderActions } from '@src/pages/EmployeeProfile/Layout/Performance/HeaderActions'
import { usePerformanceChartData } from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceHistory/hooks'
import { useGetPerformanceCycleTimelineEvents } from '@src/api/performance'

import {
  canViewProgressionComponent,
  getCategory,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { PerformanceSummaryHeader } from './PerfomanceSummaryHeader'
import PopupChart from '@src/components/Charts/BaseChart/PopupChart'
import { Chart } from '@src/components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { PerformanceLayoutKeyDates } from '../../Preview/Performance/PerformanceReview/PerformanceLayoutKeyDates'
import { ProbationPipTimeline } from '../../Preview/PerformanceSummary/ProbationPipTimeline'
import { PerformanceTimeline } from './PerformanceTimeline'
import { useGetPipCheckpoints } from '@src/api/pip'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { PerformanceHistorySection } from './PerformanceHistorySection'
import { PerformanceProvider } from '@src/pages/Forms/PromotionNominationForm/common/EligibilityAndPerformance/PerformanceContext'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { IdStatuses } from '@src/interfaces/employees'
import { PerformanceHistoryNavigation } from './PerformanceHistoryNavigation'
import { PerformanceCyclesNavigation } from './PerformanceCyclesNavigation'
import { useIsTestPerformanceCycleEnabled } from '@src/pages/Forms/ReviewCycle/ReviewCycle/useIsTestPerformanceCycleEnabled'
import { PerformanceStats } from './PerformanceStats'
import { PerformanceSummary } from './PerformanceSummary'
import { RecruitmentPerformanceStats } from './RecruitmentPerformanceStats'
import { RecruitmentPerformanceSummary } from './RecruitmentPerformanceSummary'

enum Tabs {
  Cycles = 'cycles',
  Analytics = 'analytics',
}

export const SELECTED_CYCLE_ID_PARAM = 'selected_cycle_id'

export const NewPerformanceLayoutTab = ({ data, stats }: LayoutTabProps) => {
  const { query, changeQueryParam } = useQuery(true)
  const [timelineStats, setTimelineStats] = useState<PerformanceTimelineStats | null>()
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceSelector>()
  const [initialPeriod, setInitialPeriod] = useState<PerformanceSelector>()
  const [performanceSelector, setPerformanceSelector] = useState<PerformanceSelector[]>()
  const [performanceLink, setPerformanceLink] = useState<string>()
  const isTestCycleEnabled = useIsTestPerformanceCycleEnabled()
  const [isRecruitmentCycle, setRecruitmentCycle] = useState(false)

  const [navigationTabValue, setNavigationTabValue] = useState<Tabs | null>(Tabs.Cycles)
  const [selectedCycle, setSelectedCycle] = useState<PerformanceTimelineInterface>()

  const periodBeforeOngoing =
    selectedPeriod?.offset === 0
      ? performanceSelector?.find(el => el.offset === 1)
      : undefined

  const { data: performanceSelectorData, isLoading: isLoadingPerformanceSelector } =
    useGetPerformanceSelector(data.id)
  const user = useSelector(selectUser)
  const isCurrentUserProfile = user.id === data.id

  const isProbationPeriod = checkIsProbationPeriod(data)

  const isPIPv2 = checkIsPipPeriod(initialPeriod)

  const currentCycle = performanceSelectorData?.find(
    ({ status }) => status === ReviewCycleStatus.ongoing,
  )

  const canViewAllPerformanceStats = !!data?.field_options?.permissions?.includes(
    PermissionTypes.CanViewAllPerformanceStats,
  )

  const canViewPerformanceHistoryTab = !!data?.field_options?.permissions?.includes(
    PermissionTypes.ViewPerformanceHistoryTab,
  )

  const { data: eventsData, isLoading } = useGetPerformanceCycleTimelineEvents(
    data.id,
    selectedPeriod?.id,
    selectedPeriod?.category === ReviewCycleCategory.Performance,
  )

  const { mappedChartData } = usePerformanceChartData(data, true)

  const { isNewProbation } = useGetPeriodTypes(initialPeriod)

  const { data: checkpointsData } = useGetProbationCheckpoints(
    isNewProbation ? data.id : null,
    initialPeriod?.id !== undefined ? String(initialPeriod?.id) : null,
  )
  const { data: pipCheckpointsData } = useGetPipCheckpoints(
    isPIPv2 ? data.id : null,
    initialPeriod?.id !== undefined ? String(initialPeriod?.id) : null,
  )
  const isCommercial = useIsCommercial()

  const canViewProgression = canViewProgressionComponent(data)

  const { data: feedback, refetch: refetchFeedbackList } = useGetAllFeedbackList(data.id)

  const checkpoints = useMemo(() => {
    if (isNewProbation && checkpointsData) {
      return checkpointsData
    }
    return pipCheckpointsData
  }, [checkpointsData, pipCheckpointsData])

  const isManager =
    data.line_manager?.id === user.id || data.quality_control?.id === user.id

  const initialCategory = useMemo(() => {
    if (isProbationPeriod) {
      return ReviewCategory.Probation
    }

    if (isPIPv2) {
      return ReviewCategory.PIP_V2
    }

    return ReviewCategory.Performance
  }, [isProbationPeriod, isPIPv2])

  const category = useMemo(() => {
    return getCategory(selectedPeriod?.category)
  }, [selectedPeriod?.category])

  const canViewProgressionSummary =
    data.status.id === IdStatuses.active &&
    data.specialisation_seniority_sublevel?.id !== undefined

  const isProbationOrPip = category !== ReviewCategory.Performance

  const onSetSelectedPeriod = (period: PerformanceSelector | undefined) => {
    period && changeQueryParam(SELECTED_CYCLE_ID_PARAM, period.id.toString())
    setSelectedPeriod(period)
  }

  const fetchPerformanceSelector = () => {
    let targetPeriod
    if (performanceSelectorData) {
      const selectors = performanceSelectorData.filter(selector => {
        if (isTestCycleEnabled && selector.is_manual_stage_switch_enabled) {
          return true
        }
        if (selector.category === ReviewCycleCategory.PIP) {
          return true
        }
        if (selector.category === ReviewCycleCategory.Probation) {
          // if Probation is going to start in a week or less, we want to pre-fill cycle selector with it
          const startDateAdjusted = subDays(new Date(selector.start_date_time), 7)
          return isBefore(startDateAdjusted, new Date())
        }
        return isBefore(new Date(selector.start_date_time), new Date())
      })

      const changeSelectedPeriod =
        !!selectedPeriod && !selectors.find(selector => selector.id === selectedPeriod.id)

      const currentPIP = selectors.filter(selector => checkIsPipPeriod(selector))?.[0]

      if (currentPIP) {
        targetPeriod = currentPIP
      }

      if ((isProbationPeriod || query.cycle_id === 'null') && !currentPIP) {
        targetPeriod = selectors.find(
          selector => selector.category === ReviewCycleCategory.Probation,
        )
      } else if ((!selectedPeriod || changeSelectedPeriod) && !isProbationPeriod) {
        if (query.cycle_id) {
          targetPeriod = selectors.find(selector => {
            return `${selector.id}` === query.cycle_id
          })
        } else {
          targetPeriod = selectors.find(
            selector => selector.performance_reviews_selected_cycle,
          )
        }
      }

      setInitialPeriod(targetPeriod || currentCycle)
      return selectors as OptionInterface[]
    }

    return []
  }

  useEffect(() => {
    const fetchSelector = () => {
      const result = fetchPerformanceSelector()
      setPerformanceSelector(result as PerformanceSelector[])
    }
    fetchSelector()
  }, [initialCategory, performanceSelectorData])

  useEffect(() => {
    if (isManager || canViewAllPerformanceStats) {
      getPerformanceTimelineStats(data.id)
        .then(response => setTimelineStats(response.data))
        .catch(() => setTimelineStats(null))
    }
  }, [isManager, canViewAllPerformanceStats])

  useEffect(() => {
    if (query.selected_cycle_id === 'recruitment' && isManager) {
      setRecruitmentCycle(true)
      return
    }

    if (query.selected_cycle_id && query.selected_cycle_id !== 'recruitment') {
      const target = performanceSelectorData?.find(
        selector => selector.id.toString() === query.selected_cycle_id,
      )
      setSelectedPeriod(target)
      return
    }
    const shouldHideCurrentCycleForTerminatedUser =
      currentCycle && data.status.id !== IdStatuses.terminated

    if (isPIPv2) {
      const target = performanceSelectorData?.find(
        selector => selector.category === ReviewCycleCategory.PIP,
      )
      onSetSelectedPeriod(target)
      return
    }

    if (isProbationPeriod) {
      const target = performanceSelectorData?.find(
        selector => selector.category === ReviewCycleCategory.Probation,
      )
      onSetSelectedPeriod(target)
      return
    }
    if (shouldHideCurrentCycleForTerminatedUser) {
      onSetSelectedPeriod(currentCycle || performanceSelectorData?.[0])
    }

    if (data.status.id === IdStatuses.terminated) {
      const previousCycle = performanceSelectorData?.find(({ offset }) => offset === 1)
      onSetSelectedPeriod(previousCycle)
    }
  }, [currentCycle, isPIPv2, isProbationPeriod])

  const { isNewFlow } = useGetPeriodTypes(selectedPeriod)

  const requestsContext = useFetcherPerformanceRequests({
    category: initialCategory,
    isNewFlow,
    id: data.id,
    performanceCycle: selectedPeriod,
  })
  const {
    data: requestsData,
    isLoading: fetchingRequests,
    refetch: refetchRequests,
  } = requestsContext

  function isNewFlowRequestsResponse(
    response: unknown,
  ): response is NewFlowRequestsResponse {
    return (
      response !== undefined &&
      response !== null &&
      typeof response === 'object' &&
      'results' in response
    )
  }

  function isRequestFeedbackInterfaceArray(
    response: unknown,
  ): response is RequestFeedbackInterface[] {
    return Array.isArray(response)
  }

  const selectedCycleGrade = useMemo(() => {
    const grades = mappedChartData.grades.values

    // TODO: change this part after this ticket https://revolut.atlassian.net/browse/REVC-7117
    if (selectedPeriod?.category === ReviewCycleCategory.Probation) {
      const probationGrade = grades.find(
        item => item.progress_datetime_label === selectedPeriod?.name,
      )?.progress

      if (isCurrentUserProfile) {
        return isPast(new Date(data.end_of_probation_date_time))
          ? probationGrade
          : undefined
      }

      return probationGrade
    }

    return selectedCycle?.grade
  }, [selectedPeriod, mappedChartData])

  const canViewPerformanceHistoryNavigation =
    canViewPerformanceHistoryTab || isManager || canViewAllPerformanceStats

  const isProbationEnded = isPast(new Date(data.end_of_probation_date_time))
  const probationGrade = isProbationEnded
    ? mappedChartData.grades.values.find(
        item => item.progress_datetime_label === selectedPeriod?.name,
      )?.progress?.id
    : undefined

  const requests = isNewFlow
    ? isNewFlowRequestsResponse(requestsData)
      ? requestsData.results
      : undefined
    : isRequestFeedbackInterfaceArray(requestsData)
    ? requestsData
    : undefined
  const chartIcon = <BarChart cursor="pointer" size={16} color={Token.color.greyTone50} />
  return (
    <VStack space="s-16" width="100%">
      {canViewPerformanceHistoryTab && (isManager || canViewAllPerformanceStats) && (
        <Cell>
          <VStack space="s-16" width="100%">
            <Flex flexDirection="row">
              <PerformanceSummaryHeader
                isCurrentUserProfile={isCurrentUserProfile}
                probationGrade={probationGrade}
                employeeId={data.id}
                initialPeriod={initialPeriod}
                checkpoints={checkpoints}
                timelineStats={timelineStats}
                startDate={format(new Date(data.joining_date_time), 'MMM yyyy')}
                seniorityName={data.seniority?.name || ''}
                isExternalUserHeader={!!isCommercial && !canViewProgressionSummary}
                isLoading={!timelineStats}
              />
            </Flex>
          </VStack>
        </Cell>
      )}

      <Flex alignItems="center" justifyContent="space-between">
        {canViewPerformanceHistoryTab ? (
          <>
            <TabBar
              minWidth={260}
              onChange={selected => setNavigationTabValue(selected)}
              value={navigationTabValue}
            >
              <TabBar.Item to={Tabs.Cycles}>
                {isCurrentUserProfile ? 'Your review cycles' : 'Review cycles'}
              </TabBar.Item>
              <TabBar.Item to={Tabs.Analytics}>Analytics</TabBar.Item>
            </TabBar>
            {!isRecruitmentCycle && (
              <HeaderActions
                float="right"
                cycleTimelineEvents={eventsData}
                selectedPeriod={selectedPeriod}
                data={data}
                requests={requests}
                fetchingRequests={fetchingRequests}
                checkpoints={checkpoints}
                refetchRequests={refetchRequests}
              />
            )}
          </>
        ) : (
          !isRecruitmentCycle && (
            <HeaderActions
              cycleTimelineEvents={eventsData}
              selectedPeriod={selectedPeriod}
              data={data}
              requests={requests}
              fetchingRequests={fetchingRequests}
              checkpoints={checkpoints}
              refetchRequests={refetchRequests}
            />
          )
        )}
      </Flex>

      {navigationTabValue === Tabs.Cycles && (
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={270}
          left={
            canViewPerformanceHistoryNavigation ? (
              <PerformanceHistoryNavigation
                isRecruitmentCycle={isRecruitmentCycle}
                canViewPerformanceHistoryTab={canViewPerformanceHistoryNavigation}
                canViewRecruitmentPerformance={isManager}
                setRecruitmentCycle={setRecruitmentCycle}
                data={data}
                isFetching={isLoadingPerformanceSelector}
                chartDataGradesValues={mappedChartData.grades.values}
                setSelectedPeriod={onSetSelectedPeriod}
                performanceSelectorData={performanceSelectorData}
                selectedPeriod={selectedPeriod}
                setSelectedCycle={setSelectedCycle}
                isCurrentUserProfile={isCurrentUserProfile}
              />
            ) : (
              <PerformanceCyclesNavigation
                performanceSelector={performanceSelectorData}
                setSelectedPeriod={onSetSelectedPeriod}
                selectedPeriod={selectedPeriod}
              />
            )
          }
          right={
            <>
              <Group>
                {!selectedPeriod && !isRecruitmentCycle && (
                  <Flex gap="s-48" p="s-16" flexDirection="row">
                    <Flex gap="s-2" flexDirection="column">
                      <TextSkeleton size={20} variant="heading3" />
                      <TextSkeleton size={14} variant="body2" />
                    </Flex>
                    <Flex gap="s-2" flexDirection="column">
                      <TextSkeleton size={20} variant="heading3" />
                      <TextSkeleton size={14} variant="body2" />
                    </Flex>
                  </Flex>
                )}
                {selectedPeriod && (
                  <PerformanceStats
                    selectedCycleGrade={selectedCycleGrade}
                    selectedPeriod={selectedPeriod}
                    chartContent={
                      <PopupChart trigger={chartIcon}>
                        {() => (
                          <Chart
                            data={mappedChartData.grades}
                            id={data.id}
                            isRating={false}
                          />
                        )}
                      </PopupChart>
                    }
                  />
                )}

                {isRecruitmentCycle && (
                  <RecruitmentPerformanceStats empoyeeId={data.id} />
                )}

                {selectedPeriod && (
                  <Flex flexDirection="column">
                    <Text p="s-16" variant="emphasis1" color={Token.color.foreground}>
                      Timeline
                    </Text>
                    {!isNewFlow && !!selectedPeriod?.reviews.length && (
                      <Box p="s-16" pt={0}>
                        <PerformanceLayoutKeyDates reviews={selectedPeriod.reviews} />
                      </Box>
                    )}
                    {isNewFlow && selectedPeriod && (
                      <Box p="s-16" pt={0}>
                        {isProbationOrPip ? (
                          <ProbationPipTimeline
                            data={data}
                            finalGrade={selectedCycleGrade}
                            selectedPeriod={selectedPeriod}
                            performanceLink={performanceLink}
                          />
                        ) : (
                          <PerformanceProvider>
                            <PerformanceTimeline
                              isLoading={isLoading}
                              eventsData={eventsData || []}
                              data={data}
                              performanceSelectorData={performanceSelectorData}
                              finalGrade={selectedCycleGrade}
                              selectedPeriod={selectedPeriod}
                              performanceLink={performanceLink}
                            />
                          </PerformanceProvider>
                        )}
                      </Box>
                    )}
                  </Flex>
                )}
              </Group>

              {selectedPeriod && (
                <Group>
                  <Box p="s-16">
                    <VStack space="s-16">
                      <PerformanceSummary
                        selectedPeriod={selectedPeriod}
                        isNewFlow={isNewFlow}
                        performanceLink={performanceLink}
                        feedback={feedback}
                        data={data}
                        refetchFeedbackList={refetchFeedbackList}
                        stats={stats}
                        setPerformanceLink={setPerformanceLink}
                        periodBeforeOngoing={periodBeforeOngoing}
                      />
                    </VStack>
                  </Box>
                </Group>
              )}

              {isRecruitmentCycle && (
                <RecruitmentPerformanceSummary empoyeeId={data.id} />
              )}
            </>
          }
        />
      )}
      {navigationTabValue === Tabs.Analytics && canViewPerformanceHistoryTab && (
        <PerformanceHistorySection
          data={data}
          canViewProgression={!!canViewProgression}
          selectedPeriod={selectedPeriod}
          isSection={false}
        />
      )}
    </VStack>
  )
}
