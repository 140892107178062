import React, { useEffect } from 'react'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiGoals, KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { FormType } from '../../GoalMetricForm/types'

const CHECKBOX_OPTIONS = [
  {
    id: 'Checkbox',
    label: 'Checkbox',
    value: { id: 'Checkbox', name: 'Checkbox' },
  },
]

export const UnitOfMeasure = ({ variant = 'new' }: { variant?: FormType }) => {
  const { values, initialValues } = useLapeContext<KpiInterface>()

  const isToDo = values.means_of_measure?.id === 'todo'
  const isDisabled =
    (values.update_type === UpdateTypes.manual && !values.means_of_measure) || isToDo

  useEffect(() => {
    if (values.update_type === UpdateTypes.manual && variant === 'new') {
      if (isToDo) {
        const targetValue =
          values.todo_items?.length || initialValues?.targets?.[0]?.target || 0
        values.unit = 'Checkbox'
        if (values.targets[0]) {
          values.targets[0].initial_value = 0
          values.targets[0].target = targetValue
        } else {
          values.targets.push({
            initial_value: 0,
            target: targetValue,
            kpi_goal: KpiGoals.increase,
            parent_kpi: null,
          })
        }
      } else {
        // @ts-expect-error resetting value so that user needs to select unit of measure
        values.unit = initialValues.unit === 'Checkbox' ? undefined : initialValues.unit
        values.todo_items = []
      }
    }
  }, [isToDo])

  return (
    <LapeRadioSelectInput
      name="unit"
      label="Unit of measure"
      disabled={isDisabled}
      {...(isToDo
        ? {
            options: CHECKBOX_OPTIONS,
          }
        : { selector: selectorKeys.kpi_units, useQuery: true })}
      value={
        values.unit
          ? {
              id: values.unit,
              name: values.unit,
            }
          : null
      }
      onChange={option => {
        if (option?.name) {
          values.unit = option.name
        }
      }}
    />
  )
}
