import React, { useEffect, useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import {
  employeeNameColumn,
  employeeStatusColumn,
  employeeTypeColumn,
  lineManagerColumn,
  qualityControlRevolutersColumn,
} from '@src/constants/columns/employee'
import { teamNameColumn } from '@src/constants/columns/team'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { seniorityNameRevolutersColumn } from '@src/constants/columns/seniority'
import { locationNameColumn } from '@src/constants/columns/location'
import { startedAtColumn } from '@src/constants/columns/dates'
import { getRevolutersItems, getRevolutersStatistics } from '@src/api/revoluters'
import { RevolutersInterface, RevolutersStatisticsDTO } from '@src/interfaces/revoluters'
import { FilterButton, Flex, MoreBar, Widget } from '@revolut/ui-kit'
import { Pencil } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import pluralize from 'pluralize'
import { getFilteredEmployeesSelector } from '@src/api/employeesBulkEdit'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { useIsMount } from '@src/utils/isMount'
import { BulkEditData } from '@src/interfaces/bulkEdit'

const Row: RowInterface<RevolutersInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...employeeNameColumn,
      width: 200,
    },
    {
      ...employeeTypeColumn,
      width: 110,
    },
    {
      ...departmentNameRevolutersColumn,
      width: 160,
    },
    {
      ...teamNameColumn,
      width: 220,
    },
    {
      ...specialisationRoleNameColumn,
      width: 200,
    },
    {
      ...seniorityNameRevolutersColumn,
      width: 110,
    },
    {
      ...lineManagerColumn,
      width: 200,
    },
    {
      ...qualityControlRevolutersColumn,
      width: 200,
    },
    {
      ...locationNameColumn,
      width: 120,
    },
    {
      ...startedAtColumn,
      width: 120,
    },
    {
      ...employeeStatusColumn,
      width: 120,
    },
  ],
}

const SelectEmployeeTable = () => {
  const user = useSelector(selectUser)

  const [showMyReports, setShowMyReports] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_REPORTS,
    false,
  )

  const isMount = useIsMount()

  const getFilterByLineManager = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'line_manager__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters = []

    if (showMyReports) {
      filters.push(getFilterByLineManager(true))
    }

    return filters
  }

  const sortBy = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'employee_type',
      direction: SORT_DIRECTION.ASC,
      nonResettable: true,
    },
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const table = useTable<RevolutersInterface>(
    { getItems: getRevolutersItems },
    getInitialFilters(),
    sortBy,
  )

  const [bulkEditData, setBulkEditData] = useState<BulkEditData>({
    selectedIds: [],
    isAllSelected: false,
    filters: [],
  })

  const fetchEmployeeStatistics = async () => {
    const result = await getRevolutersStatistics()

    if (result.data) {
      setStats(result.data)
    }
  }

  useEffect(() => {
    setBulkEditData({
      selectedIds: [],
      isAllSelected: false,
      filters: [],
    })
    fetchEmployeeStatistics()
  }, [])

  const [stats, setStats] = useState<RevolutersStatisticsDTO>()

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<RevolutersInterface>>()

  useEffect(() => {
    const selectedIds = (
      selectedData?.selectedRowsIds ? Array.from(selectedData?.selectedRowsIds) : []
    ).map(id => Number(id))

    setBulkEditData({
      selectedIds,
      isAllSelected: selectedData?.isAllSelected || false,
      filters: table.filterBy,
    })
  }, [selectedData?.selectedRowsIds, selectedData?.isAllSelected, table.filterBy])

  const onToggleMyReports = () => {
    setShowMyReports(!showMyReports)
    table.onFilterChange(getFilterByLineManager(!showMyReports))
  }

  useEffect(() => {
    if (isMount) {
      return
    }

    if (
      !bulkEditData.isAllSelected &&
      Array.from(selectedData?.selectedRowsIds || []).length === 0
    ) {
      setBulkEditData({
        ...bulkEditData,
        selectedIds: [],
      })
    }

    const updateIds = async () => {
      const selectedEmployees = await getFilteredEmployeesSelector(bulkEditData.filters)
      const arrayOfIds = selectedEmployees.data.options.map(employee =>
        Number(employee.id),
      )
      setBulkEditData({
        ...bulkEditData,
        selectedIds: arrayOfIds,
      })
    }

    if (bulkEditData.isAllSelected) {
      updateIds()
    }
  }, [bulkEditData.isAllSelected])

  return (
    <Widget display="flex" bg="widget-background" p="s-16" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex mb="s-24">
            <Stat label="Active" val={stats?.active_employees} mr="s-32" />
            <Stat label="Pending" val={stats?.pending_employees} mr="s-32" />
            <Stat label="Onboarding" val={stats?.onboarding_employees} mr="s-32" />
            <Stat label="Hired" val={stats?.hired_employees} mr="s-32" />
            <Stat label="External" val={stats?.active_employees_external} mr="s-32" />
          </Flex>
          <SearchTable
            placeholder="Search by name or title"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>
        <Flex mb="s-16" justifyContent="space-between">
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              // @ts-expect-error object works fine here, but UI kit expects string
              to={getLocationDescriptor(
                pathToUrl(ROUTES.FORMS.BULK_EDIT_EMPLOYEES.EDIT),
                bulkEditData,
              )}
              style={bulkEditData.selectedIds.length ? {} : { pointerEvents: 'none' }}
              disabled={!bulkEditData.selectedIds.length}
              useIcon={Pencil}
              variant="accent"
            >
              Edit {pluralize('employee', bulkEditData.selectedIds.length, true)} in bulk
            </MoreBar.Action>
          </MoreBar>
          <MoreBar>
            <FilterButton onClick={onToggleMyReports} active={showMyReports}>
              My reports
            </FilterButton>
          </MoreBar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
          >
            <AdjustableTable<RevolutersInterface>
              name={TableNames.Employees}
              useWindowScroll
              dataType="Employee"
              row={Row}
              {...table}
              noDataMessage="Employees will appear here."
            />
          </SelectTableWrapper>
        </Flex>
      </Flex>
    </Widget>
  )
}

export default SelectEmployeeTable
