import React, { useEffect, useState } from 'react'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import {
  deleteEligibleGroup,
  reviewCyclesRequestsNew,
  useGetEligibleGroupsInfo,
  useGetReviewCycle,
} from '@src/api/reviewCycles'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router'
import { Box, Token, Text, Flex } from '@revolut/ui-kit'
import { EligibleGroupsFiltersSidebar } from '@src/pages/Forms/ReviewCycle/components/EligibleGroupsFiltersSidebar'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { EligibleGroupActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EditEligibleGroups/EligibleGroupActions'
import { EligibleGroups } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EditEligibleGroups/EligibleGroups'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'

export const EditEligibleGroups = () => {
  const params = useParams<{ id: string }>()
  const { data: cycle } = useGetReviewCycle(params.id)
  const { data: groups, refetch, isFetching } = useGetEligibleGroupsInfo(params.id)
  const [currentGroup, setCurrentGroup] = useState<EligibleGroupInterface>()
  const showStatusPopup = useShowStatusPopup()
  const [openSidebar, setOpenSidebar] = useState(false)
  const [isEligibleUpdates, setEligibleUpdates] = useState(false)

  useEffect(() => {
    setEligibleUpdates(!!cycle?.enabled_eligible_updates)
  }, [cycle?.enabled_eligible_updates])

  const openSideBar = () => {
    setOpenSidebar(true)
  }

  const closeSideBar = () => {
    setCurrentGroup(undefined)
    setOpenSidebar(false)
  }

  const makeDeleteHandler = (group: EligibleGroupInterface) => async () => {
    await deleteEligibleGroup(group.id)

    closeSideBar()
    showStatusPopup({ title: 'The group was successfully deleted' })

    await refetch()
  }

  const makeEditHandler = (group: EligibleGroupInterface) => () => {
    setCurrentGroup(group)
    openSideBar()
  }

  const backUrl = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)

  const handleEligibleUpdate = () => {
    const updatedEligibleUpdates = !isEligibleUpdates

    setEligibleUpdates(updatedEligibleUpdates)

    if (cycle) {
      const payload = { ...cycle, enabled_eligible_updates: updatedEligibleUpdates }

      reviewCyclesRequestsNew.update(payload, { id: cycle.id.toString() })
    }
  }

  return (
    <PageWrapper>
      <PageHeader title="Eligible employees" backUrl={backUrl} />
      <PageBody>
        {!!cycle && (
          <Box mb="s-16">
            <EligibleGroupActions />
          </Box>
        )}
        <EligibleGroups
          groups={groups?.results ?? []}
          isLoading={isFetching}
          handleAdd={openSideBar}
          handleEdit={makeEditHandler}
          handleDelete={makeDeleteHandler}
        />

        {cycle && (
          <Flex flexDirection="column" gap="s-3" mt="s-16">
            <Text mb="s-12" fontSize={18} color={Token.color.foreground}>
              Settings
            </Text>
            <ItemSwitch
              checked={isEligibleUpdates}
              title="Enable employee data sync"
              description="Keeps the employee data used in the performance review synchronised with their profile data until scorecards are created. Disabling this will freeze the data for the performance review only"
              onChange={handleEligibleUpdate}
            />
          </Flex>
        )}

        <EligibleGroupsFiltersSidebar
          isOpen={openSidebar}
          handleClose={closeSideBar}
          groupData={currentGroup}
          refetchGroups={refetch}
        />
      </PageBody>
    </PageWrapper>
  )
}
