import React from 'react'
import { Box, HStack, Icon, IconName, Text, Token, VStack, Widget } from '@revolut/ui-kit'

type Item = {
  icon: IconName | { url: string }
  title: React.ReactNode
  text: React.ReactNode
}

type Props = {
  title: React.ReactNode
  text?: React.ReactNode
  items: Item[]
}

export const OnboardingIntroTips = ({ title, text, items }: Props) => {
  return (
    <Widget p="s-16">
      <Text variant="h5" display="block" mb="s-16">
        {title}
      </Text>
      {text && (
        <Text variant="caption" display="block">
          {text}
        </Text>
      )}
      <VStack gap="s-16" mt="s-24">
        {items.map((item, i) => (
          <HStack gap="s-16" key={i}>
            <Icon
              size={23}
              src={typeof item.icon === 'object' ? item.icon.url : undefined}
              variant={typeof item.icon === 'object' ? 'image' : undefined}
              name={typeof item.icon === 'string' ? item.icon : undefined}
            />
            <Box>
              <Text variant="primary">{item.title}</Text>
              <Text color={Token.color.greyTone50} display="block" mt="s-8">
                {item.text}
              </Text>
            </Box>
          </HStack>
        ))}
      </VStack>
    </Widget>
  )
}
