import React from 'react'
import { MoreBar, VStack } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { useTable } from '@src/components/Table/hooks'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { getFaqCategories } from '@src/api/faq'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { FaqCategoryInterface } from '@src/interfaces/faq'
import {
  faqCategoryDescriptionColumn,
  faqCategoryIconColumn,
  faqCategoryLinkToSupportColumn,
  faqCategoryNameColumn,
} from '@src/constants/columns/faqCategory'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import Stat from '@src/components/Stat/Stat'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const row: RowInterface<FaqCategoryInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.FAQ_CATEGORY.GENERAL, { id: data.id })),
  cells: [
    {
      ...faqCategoryNameColumn,
      width: 200,
    },
    {
      ...faqCategoryDescriptionColumn,
      width: 300,
    },
    {
      ...faqCategoryLinkToSupportColumn,
      width: 100,
    },
    {
      ...faqCategoryIconColumn,
      width: 200,
    },
  ],
}

export const FAQCategories = () => {
  const table = useTable({ getItems: getFaqCategories })

  return (
    <TableWrapper mt="s-32">
      <VStack mb="s-16" space="s-16">
        <Stat label="Total" val={table.loading ? undefined : table.count} />
        <MoreBar>
          <MoreBar.Action
            to={pathToUrl(ROUTES.FORMS.FAQ_CATEGORY.GENERAL)}
            use={InternalLink}
            useIcon={Plus}
          >
            Add category
          </MoreBar.Action>
        </MoreBar>
      </VStack>
      <AdjustableTable name={TableNames.FaqCategories} row={row} {...table} />
    </TableWrapper>
  )
}
