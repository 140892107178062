import React from 'react'
import styled from 'styled-components'
import { Flex } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import {
  CommitteeResultInterface,
  ProbationCheckpoint,
} from '@src/interfaces/probationReview'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import ProbationResult from '@components/ProbationResult/ProbationResult'
import { formatDate } from '@src/utils/format'
import { PerformanceGradeRevamped } from '@components/PerformanceGrade/PerformanceGradeRevamped'

const ResultContent = styled(ProbationResult)`
  font-weight: 600;
`

type Props = {
  checkpoint: ProbationCheckpoint
  decision?: CommitteeResultInterface
}

const CommitteeReviewers = ({ checkpoint, decision }: Props) => {
  const finalGrade = decision?.final_grade
  return (
    <Flex justifyContent="space-between" mt="s-20" mb="s-20">
      <AvatarSnippet
        avatar={decision?.reviewer.avatar}
        status={checkpoint.decision ? Statuses.completed : Statuses.pending}
        fullName={
          checkpoint.decision?.reviewer
            ? checkpoint.decision.reviewer.full_name
            : 'Committee team'
        }
        icon={checkpoint.decision?.reviewer ? undefined : 'Profile'}
        text={formatDate(checkpoint.checkpoint_date_time)}
        noItemWrap
      />
      <Flex alignItems="flex-end" flexDirection="column" style={{ flexShrink: 0 }}>
        <ResultContent
          result={decision?.result}
          status={decision?.result ? Statuses.completed : Statuses.pending}
        />
        {finalGrade && (
          <PerformanceGradeRevamped
            grade={{ id: finalGrade.id, label: finalGrade.name }}
            inverse
          />
        )}
      </Flex>
    </Flex>
  )
}

export default CommitteeReviewers
