import React from 'react'
import { TableWidget } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import { getSubscriptionPlans } from '@src/api/plans'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { SubscriptionPlanInterface } from '@src/interfaces/plans'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import {
  subscriptionPlanNameColumn,
  subscriptionPlanPriceColumn,
  subscriptionPlanTrialPeriodColumn,
} from '@src/constants/columns/plans'

const row: RowInterface<SubscriptionPlanInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.SETTINGS.PLAN_MANAGEMENT.DETAILS, { id: data.id })),
  cells: [
    {
      ...subscriptionPlanNameColumn,
      width: 200,
    },
    {
      ...subscriptionPlanPriceColumn,
      width: 100,
    },
    {
      ...subscriptionPlanTrialPeriodColumn,
      width: 100,
    },
  ],
}

export const PlanList = () => {
  const table = useTable({ getItems: getSubscriptionPlans })

  return (
    <>
      <PageHeader title="Manage Plans" backUrl={ROUTES.SETTINGS.ALL} />

      <TableWidget>
        <TableWidget.Table>
          <AdjustableTable row={row} name={TableNames.SubscriptionPlans} {...table} />
        </TableWidget.Table>
      </TableWidget>
    </>
  )
}
