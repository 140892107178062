import React, { useState } from 'react'
import { MoreBar, VStack, Widget, Text, Box } from '@revolut/ui-kit'
import {
  onboardingTimeManagementImportSetBalanceColumn,
  onboardingTimeManagementImportAdjustBalanceColumn,
  onboardingTimeManagementImportBalanceEmployeeColumn,
  onboardingTimeManagementImportBalancePolicyColumn,
} from '@src/constants/columns/onboardingTimeManagement'
import { API, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportTimeOffBalancesInterface } from '@src/interfaces/importTimeOffBalances'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'
import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { IdAndName } from '@src/interfaces'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportTimeOffBalancesInterface>> => ({
  cells: [
    {
      ...onboardingTimeManagementImportBalanceEmployeeColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportBalancePolicyColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportSetBalanceColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportAdjustBalanceColumn(options.onChange),
      width: 200,
    },
  ],
})

export const BalanceImportFlow = () => {
  const adjustBalanceOption = { id: 'adjust_balance', name: 'Adjust balance' }
  const setBalanceOption = { id: 'set_balance', name: 'Set balance' }

  const [adjustmentType, setAdjustmentType] = useState<
    typeof adjustBalanceOption | typeof setBalanceOption
  >(adjustBalanceOption)

  return (
    <VStack space="s-24">
      <BulkDataUploadV2
        apiEndpoint={API.TIME_OFF_BALANCES_BULK_UPLOAD}
        anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.ANY}
        importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.IMPORT}
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.SESSION}
        category="time_off_balance_bulk_upload"
        entity="balance"
        name="balance"
        row={row}
        tableName={TableNames.UploadTimeOffProjectedBalances}
        hiddenColumns={
          adjustmentType.id === 'set_balance'
            ? { adjust_balance: true }
            : { set_balance: true }
        }
        uploadFileScreenTopNode={
          <Widget p="s-12">
            <VStack space="s-4">
              <Box p="s-12">
                <Text use="div" variant="h5">
                  Do you want to adjust balance or set balance?
                </Text>
              </Box>
              <RadioSwitch<IdAndName<string>>
                highlightSelected
                variant="horizontal"
                options={[
                  {
                    id: adjustBalanceOption.id,
                    label: adjustBalanceOption.name,
                    description:
                      'Enter a value to adjust the current balance. A positive number adds to the balance, while a negative number subtracts from it.',
                    value: adjustBalanceOption,
                  },
                  {
                    id: setBalanceOption.id,
                    label: setBalanceOption.name,
                    description:
                      "Enter the exact balance value for the employee's leave. This value will overwrite any existing balance.",
                    value: setBalanceOption,
                  },
                ]}
                value={adjustmentType}
                onChange={newType => setAdjustmentType(newType)}
              />
            </VStack>
          </Widget>
        }
        templateParams={{ version: '2', extra_columns: adjustmentType.id }}
        uploadParams={{ is_projected: true }}
        header={
          <OnboardingChecklistHeader
            backUrl={pathToUrl(
              ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ASSIGNMENTS,
            )}
            title="Simple balance adjustments"
          />
        }
        tableActions={props => (
          <MoreBar>
            <BulkEditAction
              {...props}
              apiEndpoint={API.TIME_OFF_BALANCES_BULK_UPLOAD}
              buttonIcon="ArrowRightLeft"
              field="policy"
              selector={selectorKeys.time_off_policies}
            />
            <BulkDeleteButton {...props} />
          </MoreBar>
        )}
      />
    </VStack>
  )
}
