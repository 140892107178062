import { Box } from '@revolut/ui-kit'
import React from 'react'

import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@src/components/TableV2/Cells/StatusCell'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { BenchmarkInterface } from '@src/interfaces/benchmarks'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { formatMoney } from '@src/utils/format'
import { selectorKeys } from '../api'

export const benchmarkSpecialisationColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation_id',
  filterKey: 'specialisation_id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Specialisation',
}

export const benchmarkSeniorityColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority_id',
  filterKey: 'seniority_id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => <Table.SeniorityCell seniority={data.seniority.name} />,
}

export const benchmarkSeniorityLevelColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation_seniority_sublevel.id',
  dataPoint: 'specialisation_seniority_sublevel.name',
  sortKey: 'specialisation_seniority_sublevel__name',
  filterKey: 'specialisation_seniority_sublevel__name',
  selectorsKey: selectorKeys.seniority_sublevels,
  title: 'Seniority Level',
}

export const benchmarkLocationColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.text,
  idPoint: 'location.id',
  dataPoint: 'location.name',
  sortKey: 'location_id',
  filterKey: 'location_id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const benchmarkTypeColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.text,
  idPoint: 'benchmark_type.id',
  dataPoint: 'benchmark_type.name',
  sortKey: 'benchmark_type',
  filterKey: 'benchmark_type',
  selectorsKey: selectorKeys.benchmark_types,
  title: 'Type',
}

export const benchmarkCreatedOnColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const benchmarkLowerBandColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.insert,
  idPoint: 'lower_band',
  dataPoint: 'lower_band',
  sortKey: 'lower_band',
  filterKey: 'lower_band',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Lower Band',
  insert: ({ data }) => {
    if (data.is_hidden) {
      return '-'
    }
    if (data.exchanged_lower_band != null && data.target_currency?.iso_code != null) {
      return (
        <Box mr="s-8">
          {formatMoney(data.exchanged_lower_band, data.target_currency.iso_code)}
        </Box>
      )
    }
    return <Box mr="s-8">{formatMoney(data.lower_band, data.currency.iso_code)}</Box>
  },
}

export const benchmarkUpperBandColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.insert,
  idPoint: 'upper_band',
  dataPoint: 'upper_band',
  sortKey: 'upper_band',
  filterKey: 'upper_band',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Upper Band',
  insert: ({ data }) => {
    if (data.is_hidden) {
      return '-'
    }
    if (data.exchanged_upper_band != null && data.target_currency?.iso_code != null) {
      return (
        <Box mr="s-8">
          {formatMoney(data.exchanged_upper_band, data.target_currency.iso_code)}
        </Box>
      )
    }
    return <Box mr="s-8">{formatMoney(data.upper_band, data.currency.iso_code)}</Box>
  },
}

export const benchmarkCreatedByColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.insert,
  idPoint: 'created_by.id',
  dataPoint: 'created_by.name',
  sortKey: 'created_by_id',
  filterKey: 'created_by_id',
  selectorsKey: selectorKeys.employee,
  title: 'Created by',
  insert: ({ data }) => <UserWithAvatar {...data.created_by} />,
}

export const benchmarkStatusColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.benchmark_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getDefaultStatusVariant(data.status.id)}
    />
  ),
}

export const benchmarkTagsColumn: ColumnInterface<BenchmarkInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: 'tags',
  filterKey: 'tags',
  selectorsKey: selectorKeys.benchmark_tags,
  title: 'Tags',
  insert: ({ data }) => data.tags?.map(tag => tag.name).join(', ') || '-',
}
