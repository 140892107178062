import React, { useMemo } from 'react'
import { Tag, Box, Flex, Text, Token } from '@revolut/ui-kit'
import { PerformanceSelector } from '@src/interfaces/performance'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownStatAction from '@components/Inputs/DropdownStatAction/DropdownStatAction'
import capitalize from 'lodash/capitalize'
import { getCycleOffsetTitleAndColor, useGetPeriodTypes } from '@src/utils/performance'
import { ReviewCycleCategory, ReviewCycleStatus } from '@src/interfaces/reviewCycles'

type Props = {
  selectedPeriod?: PerformanceSelector
  performanceSelector?: PerformanceSelector[]
  onChange: (selector?: PerformanceSelector) => void
}

const getProbationPipOffset = (status?: ReviewCycleStatus) => {
  switch (status) {
    case ReviewCycleStatus.ongoing:
      return 0
    case ReviewCycleStatus.planned:
      return -1
    case ReviewCycleStatus.closed:
      return 1
    default:
      return undefined
  }
}

export const PerformanceLayoutCycleSelect = ({
  selectedPeriod,
  performanceSelector,
  onChange,
}: Props) => {
  const { isNewProbation, isOldProbation, isPIPv2, isPIP } =
    useGetPeriodTypes(selectedPeriod)
  const isProbationOrPip = isNewProbation || isOldProbation || isPIPv2 || isPIP

  const options = useMemo(
    () =>
      performanceSelector?.map(item => {
        const offset =
          item.category === ReviewCycleCategory.Performance
            ? item.offset
            : getProbationPipOffset(item.status)
        return {
          label: item.name,
          value: {
            id: String(item.id),
            offset,
          },
        }
      }) || [],
    [performanceSelector, selectedPeriod],
  )

  const {
    title: tagTitle,
    color: tagColor,
    bgColor: tagBgColor,
  } = getCycleOffsetTitleAndColor(
    isProbationOrPip
      ? getProbationPipOffset(selectedPeriod?.status)
      : selectedPeriod?.offset,
  )

  return (
    <Box>
      <RadioSelectInput
        value={{
          id: String(selectedPeriod?.id),
          offset: selectedPeriod?.offset,
        }}
        options={options}
        onChange={val => {
          if (val) {
            onChange(
              performanceSelector?.find(item => String(item.id) === String(val.id)),
            )
          }
        }}
        renderInput={(open, setOpen, ref) => (
          <DropdownStatAction open={open} onClick={() => setOpen(!open)} ref={ref}>
            <Flex alignItems="center" gap="s-8">
              <Text>{selectedPeriod?.name || 'Select cycle'}</Text>
              {!!tagTitle.length && (
                <Tag variant="faded" bg={tagBgColor} color={tagColor}>
                  {capitalize(tagTitle)}
                </Tag>
              )}
            </Flex>
          </DropdownStatAction>
        )}
      >
        {option => {
          const { title, color } = getCycleOffsetTitleAndColor(option.value.offset)
          return (
            <>
              {option.label}{' '}
              <Text variant="caption" color={color}>
                {title}
              </Text>
            </>
          )
        }}
      </RadioSelectInput>
      <Text color={Token.color.greyTone50} use="div">
        Performance cycle
      </Text>
    </Box>
  )
}
