import React, { useState } from 'react'
import { TextSkeleton, Text } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { SidebarContent } from './SidebarContent'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { useBonusSidebar } from '@src/features/EditBonusSidebar/hooks'

type Props = {
  onAfterSubmit: () => void
}
export const EditBonusSidebar = ({ onAfterSubmit }: Props) => {
  const [employeeId, setEmployeeId] = useState<number>()

  const { isOpen, bonusId, closeBonusSidebar } = useBonusSidebar()

  const onClose = () => {
    setEmployeeId(undefined)
    closeBonusSidebar()
  }

  const getSubtitle = () => {
    if (!bonusId) {
      return undefined
    }
    return employeeId ? <EmployeeUserWithAvatar id={employeeId} /> : <TextSkeleton />
  }

  return (
    <SideBar
      variant="wide"
      title={<Text variant="h2">{bonusId ? 'Edit bonus' : 'Add bonus'}</Text>}
      subtitle={getSubtitle()}
      isOpen={isOpen}
      onClose={onClose}
    >
      <SidebarContent
        id={Number(bonusId)}
        setEmployeeId={setEmployeeId}
        onAfterSubmit={onAfterSubmit}
      />
    </SideBar>
  )
}
