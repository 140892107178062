import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Token } from '@revolut/ui-kit'
import { queryRunsTableRequests } from '@src/api/dataAnalytics'
import {
  queriesActionsHistoryTableColumn,
  queriesDateRunHistoryTableColumn,
  queriesDurationHistoryTableColumn,
  queriesParametersHistoryTableColumn,
  queriesRunByHistoryTableColumn,
  queriesRowsHistoryTableColumn,
  queriesStatusHistoryTableColumn,
} from '@src/constants/columns/dataAnalytics'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  RunQueryResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const ExportButtonComponent = React.lazy(() => import('./components/ExportButton'))

const getRow = (queryId: string): RowInterface<RunQueryResponseInterface> => ({
  cells: [
    {
      ...queriesDateRunHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesRunByHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesDurationHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesRowsHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesParametersHistoryTableColumn,
      width: 150,
    },
    {
      ...queriesStatusHistoryTableColumn,
      width: 50,
    },
    {
      ...queriesActionsHistoryTableColumn,
      width: 50,
      insert: ({ data }) =>
        data.status === QueryRunStatuses.Success && !data.result?.expired ? (
          <Suspense fallback={null}>
            <ExportButtonComponent iconOnly runId={data.id} queryId={queryId} />
          </Suspense>
        ) : null,
    },
  ],
})

export interface OptionInterface {
  id: string
  name: string
  fields: string[]
}

export const HistoryTable = connect(() => {
  const params = useParams<{ id: string }>()
  const table = useTable<RunQueryResponseInterface>({
    getItems: queryRunsTableRequests(params.id),
  })

  return (
    <PageBody maxWidth={Token.breakpoint.xxl}>
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable<RunQueryResponseInterface>
            dataType="Runs"
            name={TableNames.ReportingAppQueriesHistoricalRuns}
            emptyState={<EmptyTableRaw title="Runs will appear here" />}
            row={getRow(params.id)}
            useWindowScroll
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageBody>
  )
})
