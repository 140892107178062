import { Text, VStack, Token, InputGroup, Input } from '@revolut/ui-kit'
import React, { useState, useMemo, useEffect } from 'react'
import { VisualisationSidebarTypeSelect } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/VisualisationSidebarTypeSelect'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { ChartSettings, QueryRunResultInterface } from '@src/interfaces/dataAnalytics'
import SideBar from '@components/SideBar/SideBar'
import { NUMERIC_COLUMNS } from '@src/pages/Forms/QueryForm/constants'
import { VisualisationType } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/common'
import { ColorSelect } from '@src/pages/Forms/QueryForm/components/ColorSelect/ColorSelect'
import { getColorByIndex } from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { BaseColor } from '@src/pages/Forms/QueryForm/components/Charts/types'

interface VisualisationSidebarProps {
  open: boolean
  onClose: () => void
  columns: QueryRunResultInterface
  chartSettings: ChartSettings
  onChange: (settings: ChartSettings) => void
}

export type VisualisationVariant = 'one-bar' | 'two-bars' | 'three-bars'

const barOptions: { label: string; value: IdAndName<VisualisationVariant> }[] = [
  { label: '1 Bar', value: { id: 'one-bar', name: '1 Bar' } },
  { label: '2 Bars', value: { id: 'two-bars', name: '2 Bars' } },
  { label: '3 Bars', value: { id: 'three-bars', name: '3 Bars' } },
]

export const VisualisationSidebar = ({
  open,
  onClose,
  columns,
  chartSettings,
  onChange,
}: VisualisationSidebarProps) => {
  const [xValue, setXValue] = useState<IdAndName | undefined>(chartSettings?.xValue)
  const [yValue, setYValue] = useState<IdAndName[]>(chartSettings?.yValue || [])
  const [chartType, setChartType] = useState<VisualisationType | undefined>(
    chartSettings?.chartType,
  )
  const [chartVariant, setChartVariant] = useState<
    IdAndName<VisualisationVariant> | undefined
  >(chartSettings?.chartVariant)

  const [title, setTitle] = useState<string | undefined>(chartSettings?.title)
  const [subtitle, setSubtitle] = useState<string | undefined>(chartSettings?.subtitle)
  const [colors, setColors] = useState<BaseColor[]>(chartSettings?.colors || [])

  useEffect(() => {
    onChange({
      xValue,
      yValue,
      chartType,
      chartVariant,
      title,
      subtitle,
      colors,
    })
  }, [xValue, yValue, chartType, chartVariant, title, subtitle, colors])

  const axisOptions = useMemo(() => {
    return columns.map((value, index) => ({
      label: value.name,
      value: {
        id: index,
        name: value.name,
      },
    }))
  }, [columns])

  const numericOptions = useMemo(() => {
    return columns
      .filter(column => NUMERIC_COLUMNS.includes(column.type))
      .map((value, index) => ({
        label: value.name,
        value: {
          id: index,
          name: value.name,
        },
      }))
  }, [columns])

  const labelXAxis = useMemo(() => {
    if (chartType === 'pie') {
      return 'Label'
    }
    if (chartType === 'row') {
      return 'Y Axis'
    }
    if (chartType === 'counter') {
      return 'Value'
    }
    if (chartType === 'stack') {
      return 'Stack By'
    }
    return 'X axis'
  }, [chartType])

  const labelYAxis = useMemo(() => {
    if (chartType === 'pie') {
      return 'Value'
    }
    if (chartType === 'row') {
      return 'X Axis'
    }
    if (chartType === 'counter') {
      return 'Change %'
    }
    if (chartType === 'stack') {
      return 'Label'
    }
    return 'Y axis'
  }, [chartType])

  const showColorPicker = chartType && ['line', 'bar', 'combo', 'row'].includes(chartType)

  const upsertItemAt = (newItem: IdAndName, index: number) => {
    setYValue(prevItems => {
      const updatedItems = [...prevItems]
      while (updatedItems.length <= index) {
        updatedItems.push(undefined as any)
      }
      updatedItems[index] = newItem
      return updatedItems
    })
  }

  const handleTypeChange = (type: VisualisationType) => {
    setChartType(type)
    if (type !== 'bar' && type !== 'row') {
      setChartVariant(undefined)
    }
    if (type === 'bar' || type !== 'row') {
      handleVariantChange(barOptions[0].value)
    }
  }

  const handleVariantChange = (variant: IdAndName<VisualisationVariant> | null) => {
    if (variant) {
      setChartVariant(variant)
      if (variant.id === 'one-bar') {
        setYValue(prev => prev.slice(0, 1))
      }
      if (variant.id === 'two-bars') {
        setYValue(prev => prev.slice(0, 2))
      }
    }
  }

  const handleColorChange = (value: BaseColor, index: number) => {
    setColors(prevItems => {
      const updatedItems = [...prevItems]
      while (updatedItems.length <= index) {
        updatedItems.push(undefined as any)
      }
      updatedItems[index] = value
      return updatedItems
    })
  }

  return (
    <SideBar isOpen={open} onClose={onClose} title="Visualisation settings" useLayout>
      <VStack space="s-24">
        <VisualisationSidebarTypeSelect
          onChange={handleTypeChange}
          selectedType={chartType}
        />

        <Text variant="caption" color={Token.color.greyTone50} textAlign="center">
          Select one of the chart types to see their settings
        </Text>

        <InputGroup>
          {chartType === 'counter' ? (
            <>
              <Input
                label="Title"
                value={title}
                onChange={e => setTitle(e.currentTarget.value)}
              />
              <Input
                label="Subtitle"
                value={subtitle}
                onChange={e => setSubtitle(e.currentTarget.value)}
              />
            </>
          ) : null}
          {chartType === 'bar' || chartType === 'row' ? (
            <RadioSelectInput
              label="Type"
              onChange={handleVariantChange}
              options={barOptions}
              value={chartVariant}
              searchable={false}
            />
          ) : null}
          <RadioSelectInput
            label={labelXAxis}
            onChange={value => {
              if (value) {
                setXValue(value)
              }
            }}
            options={axisOptions}
            value={xValue}
          />
          <RadioSelectInput
            label={labelYAxis}
            onChange={value => {
              if (value) {
                upsertItemAt(value, 0)
              }
            }}
            options={chartType === 'stack' ? axisOptions : numericOptions}
            value={yValue[0]}
            inputProps={
              showColorPicker
                ? {
                    renderAction: () => (
                      <ColorSelect
                        color={colors[0] || getColorByIndex(0)}
                        onChange={val => handleColorChange(val, 0)}
                      />
                    ),
                  }
                : undefined
            }
          />
          {chartVariant?.id === 'two-bars' ||
          chartVariant?.id === 'three-bars' ||
          chartType === 'combo' ||
          chartType === 'stack' ? (
            <RadioSelectInput
              label={chartType === 'stack' ? 'Value' : labelYAxis}
              onChange={value => {
                if (value) {
                  upsertItemAt(value, 1)
                }
              }}
              options={numericOptions}
              value={yValue[1]}
              inputProps={
                showColorPicker
                  ? {
                      renderAction: () => (
                        <ColorSelect
                          color={colors[1] || getColorByIndex(1)}
                          onChange={val => handleColorChange(val, 1)}
                        />
                      ),
                    }
                  : undefined
              }
            />
          ) : null}
          {chartVariant?.id === 'three-bars' ? (
            <RadioSelectInput
              label={labelYAxis}
              onChange={value => {
                if (value) {
                  upsertItemAt(value, 2)
                }
              }}
              options={numericOptions}
              value={yValue[2]}
              inputProps={
                showColorPicker
                  ? {
                      renderAction: () => (
                        <ColorSelect
                          color={colors[2] || getColorByIndex(2)}
                          onChange={val => handleColorChange(val, 2)}
                        />
                      ),
                    }
                  : undefined
              }
            />
          ) : null}
        </InputGroup>
      </VStack>
    </SideBar>
  )
}
