import React, { useEffect } from 'react'
import {
  ThemeProvider,
  Grid,
  Box,
  Absolute,
  Flex,
  VStack,
  Token,
  Link,
  Image,
  Relative,
} from '@revolut/ui-kit'

import { Text } from './components/Text'
import { GlobalStyle } from './styles'
import { ROUTES } from '@src/constants/routes'
import { Hero } from '@src/pages/Landing/components/Hero'
import {
  FeatureBlock,
  FeatureBlockVariant,
} from '@src/pages/Landing/components/FeatureBlock'

import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'
import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { pathToUrl } from '@src/utils/router'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import {
  HR_IMG,
  RECRUITMENT_IMG,
  // CUSTOMER_1,
  CUSTOMER_2,
  CUSTOMER_3,
  CUSTOMER_4,
  CUSTOMER_5,
  GOALS_IMG,
  REVIEWS_IMG,
  LOGO_GOOGLE_IMG,
  LOGO_LINKEDIN_IMG,
  LOGO_MICROSOFT_IMG,
  LOGO_LOOKER_IMG,
  LOGO_JIRA_IMG,
  LOGO_SLACK_IMG,
  DEMO_GOALS_URL,
  DEMO_PERFORMANCE_URL,
  DEMO_RECRUITMENT_URL,
  DEMO_HR_URL,
  CUSTOMER_6,
} from '@src/pages/Landing/constants'
import styled from 'styled-components'
import css from '@styled-system/css'
import { FAQBlock } from '@src/pages/Landing/components/FAQBlock/FAQBlock'
import { ModuleItem } from '@src/pages/Landing/components/ModuleItem'
import { FeaturesCarousel } from '@src/pages/Landing/components/FeaturesCarousel'
import { CustomerStories } from '@src/pages/Landing/components/CustomerStories'
import { Footer } from '@src/pages/Landing/components/Footer'
import { useAnalytics, AnalyticsEvents } from '@src/utils/analytics'
import { BookDemoButton } from '@src/pages/Landing/components/BookDemoButton'

const More = styled(Flex)(
  css({
    gridColumn: ['span 2', null, 'span 3', 'span 1'],
  }),
)

export const LandingPage = () => {
  const { sendAnalyticsEvent } = useAnalytics()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box pb="s-16">
      <ThemeProvider mode="dark">
        <GlobalStyle />
        <Hero />

        <Absolute top={0} width="100%">
          <Flex
            maxWidth={{ all: '1000px', xxl: '1432px' }}
            width="100%"
            margin="auto"
            justifyContent="space-between"
            px="s-16"
            mt="s-16"
            alignItems="center"
          >
            <LogoAlt width="200" />

            <>
              <Flex gap="s-24" alignItems="center" display={{ all: 'none', md: 'flex' }}>
                <InternalLink
                  to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                  onClick={() => {
                    sendAnalyticsEvent(AnalyticsEvents.click_signup)
                  }}
                >
                  <Text variant="primary" fontWeight={500}>
                    Sign up
                  </Text>
                </InternalLink>

                <BookDemoButton />
              </Flex>

              <Button
                // @ts-ignore TODO: REVPI-28 Migrate LandingPage to standard UI Kit
                variant="secondary"
                use={InternalLink}
                to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                display={{ all: 'flex', md: 'none' }}
              >
                Sign up
              </Button>
            </>
          </Flex>
        </Absolute>

        <ContentBlockLayout>
          <VStack space="s-24">
            <Text
              use="h4"
              variant="h4"
              textAlign="center"
              pb="s-32"
              color={Token.color.greyTone50}
            >
              POWERING EUROPE’S GROWING TECH COMPANIES
            </Text>

            <Flex
              justifyContent={{ all: 'space-around', lg: 'space-between' }}
              flexWrap="wrap"
            >
              {/* <Image alt="" src={CUSTOMER_1} height={144} width={168} /> */}
              <Image alt="" src={CUSTOMER_2} height={144} width={168} />
              <Image alt="" src={CUSTOMER_3} height={144} width={168} />
              <Image alt="" src={CUSTOMER_4} height={144} width={168} />
              <Image alt="" src={CUSTOMER_5} height={144} width={168} />
              <Image alt="" src={CUSTOMER_6} height={144} width={168} />
            </Flex>
          </VStack>
        </ContentBlockLayout>

        <>
          <FeatureBlock
            title="Clear goals, better results"
            description="Drive accountability by setting top-to-bottom goals and motivate your team by linking those goals directly to company objectives - while real time progress updates keep you informed."
            caption="Performance"
            image={GOALS_IMG}
            url={DEMO_GOALS_URL}
            withPadding
          />
          <FeatureBlock
            title="Trusted reviews to raise the bar"
            description="Paint a picture of what success looks like in your organisation and provide employees with clear expectations. Take a complete approach to performance by assessing employee’s goals, skills, and behaviours - so you can make better decisions."
            caption="Performance"
            image={REVIEWS_IMG}
            url={DEMO_PERFORMANCE_URL}
            variant={FeatureBlockVariant.Right}
          />
          <ContentBlockLayout>
            <VStack space="s-24">
              <VStack
                align={{ all: 'start', md: 'center' }}
                space={{ all: 's-8', lg: 's-24' }}
                width="100%"
              >
                <Text
                  use="h2"
                  variant="h2"
                  textAlign={{ all: 'start', md: 'center' }}
                  pb="s-32"
                >
                  Why performance matters
                </Text>
              </VStack>

              <Grid
                use="ul"
                width="100%"
                columns={{ all: 1, md: 3 }}
                gap={{ all: 's-16', lg: 's-24' }}
              >
                <Box borderRadius={20} padding="s-24" bg="grouped-background">
                  <Text variant="h3" use="h3" textAlign="center">
                    800%
                  </Text>
                  <Text variant="h6" use="h6" textAlign="center" mt="s-8">
                    increase in productivity from top performers vs average performers in
                    the same role<sup>1</sup>
                  </Text>
                </Box>
                <Box borderRadius={20} padding="s-24" bg="grouped-background">
                  <Text variant="h3" use="h3" textAlign="center">
                    72%
                  </Text>
                  <Text variant="h6" use="h6" textAlign="center" mt="s-8">
                    Of managers can’t list their company’s strategic priorities
                    <sup>2</sup>
                  </Text>
                </Box>
                <Box borderRadius={20} padding="s-24" bg="grouped-background">
                  <Text variant="h3" use="h3" textAlign="center">
                    £12k
                  </Text>
                  <Text variant="h6" use="h6" textAlign="center" mt="s-8">
                    Is the average cost of a single bad hire<sup>3</sup>
                  </Text>
                </Box>
              </Grid>

              <Text textAlign="center">
                Sources: (1){' '}
                <Link
                  href="https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/attracting-and-retaining-the-right-talent"
                  target="_blank"
                >
                  McKinsey
                </Link>
                , (2){' '}
                <Link
                  href="https://sloanreview.mit.edu/article/no-one-knows-your-strategy-not-even-your-top-leaders/"
                  target="_blank"
                >
                  MIT
                </Link>
                , (3){' '}
                <Link
                  href="https://www.cipd.org/globalassets/media/knowledge/knowledge-hub/reports/resourcing-and-talent-planning-report-2022-1_tcm18-111500.pdf"
                  target="_blank"
                >
                  CIPD
                </Link>
              </Text>

              <Flex gap="s-24" justifyContent="center">
                <BookDemoButton />
              </Flex>
            </VStack>
          </ContentBlockLayout>
          <Box backgroundColor={Token.color.groupedBackground} py="s-48">
            <ContentBlockLayout>
              <CustomerStories />
            </ContentBlockLayout>
          </Box>
          <Box
            style={{
              background:
                'linear-gradient(132.49deg, #001B4D -14.49%, rgba(0, 27, 77, 0) 36.33%)',
            }}
          >
            <FeatureBlock
              title="Hire with precision at any scale"
              description="Introduce skills and values-based hiring to ensure the best fit for your team. With automated interview scheduling and a variety of integrations we take the heavy lifting out of finding the standout candidate."
              caption="Recruitment"
              image={RECRUITMENT_IMG}
              url={DEMO_RECRUITMENT_URL}
              variant={FeatureBlockVariant.Right}
            />
          </Box>
          <Box
            style={{
              background:
                'radial-gradient(24.24% 62.01% at 110.31% 45.53%, #001B4D 0%, rgba(0, 27, 77, 0) 100%)',
            }}
          >
            <FeatureBlock
              title="Engage a global workforce with core HR"
              description="Ensure your teams are happy and heard with surveys, compensation & benefits features. Effortlessly manage a global team with employee records, onboarding/off boarding, time management & attendance tracking."
              caption="HR"
              image={HR_IMG}
              url={DEMO_HR_URL}
              withPadding
            />
          </Box>

          <Box
            style={{
              background:
                'linear-gradient(52.85deg, #001B4D -13.62%, rgba(0, 27, 77, 0) 20%)',
            }}
          >
            <VStack gap="s-24" paddingY={{ all: 's-48', lg: '80px', xxl: '160px' }}>
              <Text
                use="h5"
                variant="h5"
                textAlign="center"
                style={{ textTransform: 'uppercase', fontWeight: 900 }}
              >
                Integrate with your favourite tools
              </Text>

              <Text
                use="h6"
                // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
                variant="subtitle1"
                textAlign="center"
              >
                Work with the tools you already use
              </Text>

              <Grid
                columns={{ all: 2, md: 3, lg: 7 }}
                gap={{ all: 's-16', lg: 's-24' }}
                style={{ justifyItems: 'center' }}
                maxWidth={1284}
                padding="s-24"
                margin="0 auto"
              >
                <Image alt="google" src={LOGO_LINKEDIN_IMG} maxWidth={168} />
                <Image alt="google" src={LOGO_GOOGLE_IMG} maxWidth={168} />
                <Image alt="google" src={LOGO_MICROSOFT_IMG} maxWidth={168} />
                <Image alt="google" src={LOGO_LOOKER_IMG} maxWidth={168} />
                <Image alt="google" src={LOGO_JIRA_IMG} maxWidth={168} />
                <Image alt="google" src={LOGO_SLACK_IMG} maxWidth={168} />
                <More alignItems="center">
                  <Text fontSize={18}>+ and more</Text>
                </More>
              </Grid>
            </VStack>
          </Box>
        </>

        <Relative width="100%">
          <Absolute
            bottom={0}
            height="20%"
            width="100%"
            backgroundColor={Token.color.foreground}
          >
            test
          </Absolute>
          <Box backgroundColor={Token.color.actionBlue} py="s-48">
            <ContentBlockLayout
              marginY={{ all: '0', lg: '0', xxl: '0' }}
              marginTop={{ all: 's-48', lg: '80px', xxl: '160px' }}
            >
              <Text
                use="h2"
                variant="h2"
                textAlign="center"
                color={Token.color.background}
              >
                A closer look at revolut People
              </Text>
              <Text
                pt="s-8"
                use="div"
                textAlign="center"
                color={Token.color.background}
                // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
                variant="subtitle1"
              >
                A glimpse into some of our favourite features
              </Text>
              <Flex justifyContent="center" py="s-24">
                <ThemeProvider mode="light">
                  <Button
                    variant="primary"
                    use={InternalLink}
                    to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                    onClick={() => {
                      sendAnalyticsEvent(AnalyticsEvents.click_get_started)
                    }}
                  >
                    Get started
                  </Button>
                </ThemeProvider>
              </Flex>
            </ContentBlockLayout>

            <Box width="100%" overflow="hidden">
              <FeaturesCarousel />
            </Box>
          </Box>
        </Relative>

        <ContentBlockLayout>
          <Text use="h2" variant="h2" textAlign="center">
            Pricing
          </Text>
          <VStack align="center" gap="s-8" pt="s-24">
            <Text variant="h6" style={{ fontWeight: 400 }} pb="s-4">
              £7.99 per active employee/month
            </Text>
            <Text variant="h6" color={Token.color.blue}>
              30 days free
            </Text>
            <Text variant="small" color={Token.color.greyTone50}>
              Save up to 40% on annual subscription
            </Text>
            <Flex gap="s-24" justifyContent={{ all: 'start', md: 'center' }} pt="s-16">
              <BookDemoButton />
              <Button
                use={InternalLink}
                to={pathToUrl(ROUTES.SIGNUP.MAIN)}
                // @ts-ignore TODO: REVPI-28 Migrate LandingPage to standard UI Kit
                variant="secondary"
              >
                Sign up
              </Button>
            </Flex>
          </VStack>
        </ContentBlockLayout>
        <ContentBlockLayout>
          <Grid
            use="ul"
            columns={{ all: 1, md: 2, lg: 4 }}
            gap={{ all: 's-16', lg: 's-24' }}
          >
            <ModuleItem
              title="Performance"
              description="Advanced performance features to achieve extraordinary results"
              image="3D332"
              items={[
                {
                  title: 'Goals',
                  description: 'Align and track goals across your organisation.',
                },
                {
                  title: 'Roadmaps',
                  description: 'Manage & track project progress with automatic updates.',
                },
                {
                  title: 'Organisational Structure',
                  description: 'Define and refine your company’s org structure.',
                },
                {
                  title: 'Probation & PIP',
                  description: 'Support for global probation & performance processes',
                },
                {
                  title: '360 Performance Cycles',
                  description: 'Holistic performance reviews for better growth.',
                },
                {
                  title: 'Skills & Values',
                  description:
                    'Define clear skill & value definitions across your organisation. ',
                },
                {
                  title: 'Roles & Competency Frameworks',
                  description: 'Provide clear progression frameworks for employees',
                },
                {
                  title: 'Performance Profiles',
                  description: 'Detailed insights into employee performance.',
                },
                {
                  title: 'Reviews & Anytime Feedback/1:1 Notes',
                  description: '360 reviews & feedback for continuous improvement.',
                },
                {
                  title: 'Engagement Surveys',
                  description: 'Gauge employee sentiment with ease.',
                },
              ]}
            />
            <ModuleItem
              title="Recruitment"
              description="End to end applicant tracking and candidate management"
              image="3D266"
              items={[
                {
                  title: 'Hiring planning',
                  description: 'Strategic planning for optimal hiring.',
                },
                {
                  title: 'Job posting',
                  description: 'Reach top talent with integrated job boards.',
                },
                {
                  title: 'Pipelines',
                  description: 'Streamline role based hiring from cv screen to hire.',
                },
                { title: 'ATS', description: 'Track and manage applicants seamlessly.' },
                {
                  title: 'Candidate profiles',
                  description: 'Detailed candidate information at your fingertips.',
                },
                {
                  title: 'Hiring processes',
                  description: 'Build custom hiring processes for individual roles.',
                },
                {
                  title: 'Interview Scheduling',
                  description: 'Efficient and hassle-free interview scheduling.',
                },
                {
                  title: 'eMail Integrations',
                  description:
                    'Seamless communication with Gmail & Microsoft integration.',
                },
                {
                  title: 'Job offers',
                  description:
                    'Simplified job offer management with e-signature support.',
                },
              ]}
            />
            <ModuleItem
              title="HR management"
              description="Scale globally & manage the complete employee lifecycle with all-in-one HR"
              image="3D211"
              items={[
                {
                  title: 'Employee Directory',
                  description: 'Instant access to everyone in your organisation.',
                },
                {
                  title: 'Org Chart',
                  description: 'Visualise your organisational structure effortlessly.',
                },
                {
                  title: 'Employee Profiles',
                  description: 'Comprehensive employee information all in one place.',
                },
                {
                  title: 'Employee Onboarding',
                  description: 'Seamless onboarding for a smooth start.',
                },
                {
                  title: 'Employee Offboarding',
                  description: 'Manage the employee lifecycle from start to finish.',
                },
                {
                  title: 'Document Management',
                  description: 'Centralised unlimited document storage and management.',
                },
                {
                  title: 'e-Signature support',
                  description: 'Secure and swift document approvals & signatures.',
                },
                {
                  title: 'Time Management',
                  description: 'Global time off policies for employees anywhere.',
                },
                {
                  title: 'Attendance Tracking',
                  description: 'Shift, break & overtime tracking for scheduled workers.',
                },
                {
                  title: 'Compensation',
                  description: 'Transparently view your total compensation with ease.',
                },
                {
                  title: 'Benefits',
                  description: 'Comprehensive benefits management made easy.',
                },
                {
                  title: 'Payroll',
                  description: 'Accurate, timely, and compliant payroll tracking.',
                },
              ]}
            />
            <ModuleItem
              title="Platform"
              description="Powerful settings & support to make the platform work for you"
              image="3D325"
              items={[
                {
                  title: 'GDPR',
                  description: 'Ensure compliance with GDPR regulations.',
                },
                {
                  title: 'Analytics',
                  description: 'Data-driven insights for better decisions.',
                },
                {
                  title: 'Integrations',
                  description: 'Seamlessly connect your favourite tools.',
                },
                {
                  title: 'Access Management',
                  description: 'Secure and efficient access control.',
                },
                {
                  title: 'Customer Success Manager',
                  description: 'Dedicated support for your success.',
                },
                {
                  title: 'No Implementation Fees',
                  description: 'Get started without upfront costs.',
                },
                {
                  title: 'Implementation Support',
                  description: 'Expert assistance every step of the way.',
                },
                {
                  title: 'Customer Support',
                  description: 'Reliable support whenever you need it.',
                },
              ]}
            />
          </Grid>
        </ContentBlockLayout>

        <Box backgroundColor={Token.color.groupedBackground} py={60}>
          <ContentBlockLayout>
            <Text use="h2" variant="h1" textAlign="center">
              FAQS
            </Text>
          </ContentBlockLayout>

          <ContentBlockLayout>
            <FAQBlock
              items={[
                {
                  title:
                    'Are there additional fees for implementation or change management?',
                  description:
                    'No, unlike many other systems Revolut People charges zero implementation fees. We also offer dedicated custom success & implementation support from day 1 at no extra cost!',
                },
                {
                  title: 'What integrations do you offer?',
                  description:
                    'Revolut People offers a wide range of integrations to seamlessly connect with your existing tools and systems. We support integrations with popular HR, payroll, and project management softwares, including but not limited to BI tools, job boards, payroll systems and more - with APIs to support selected custom integrations.',
                },
                // {
                //   title: 'Is my data safe with Revolut People?',
                //   description:
                //     'Absolutely. At Revolut People we have no access to customer data. As a SOC2 compliant company, your data remains secure and we implement advanced encryption, regular security audits, and strict access controls to ensure that your information is protected at all times. Our platform is fully compliant with GDPR and other relevant data protection regulations, giving you peace of mind that your data is secure and handled with the utmost care.',
                // },
                {
                  title: 'What should I expect during my demo call?',
                  description:
                    "In your demo call, our team will guide you through the key features of Revolut People, focus specifically on your organisation's needs. We'll address any questions you may have and explore how Revolut People can support you in achieving extraordinary results.",
                },
                {
                  title: 'Does the contract include dedicated human support?',
                  description:
                    "Yes, all plans include access to dedicated support. You'll have a dedicated Customer Success Manager who will assist you throughout your journey with Revolut People, ensuring you get the most out of the platform. Additionally, our support team is always available to provide personalised assistance whenever you need it.",
                },
                {
                  title: 'Who can onboard to Revolut People?',
                  description:
                    'Revolut People is available now for all companies that have a registered entity in UKI or EEA.',
                },
                {
                  title: 'In what currency will I be billed?',
                  description:
                    'Revolut People bills in Great British Pounds (GBP) only. This applies across all our products and services.',
                },
                {
                  title: 'What company size does Revolut People cater for?',
                  description:
                    "Revolut People is designed to be flexible and scalable, making it suitable for businesses of all sizes. Whether you're a small startup like our 2 co-founders back in the day, or a 5000 employee company, Revolut People can be tailored to meet & grow with your specific HR needs.",
                },
                {
                  title: 'Can employees from any location use Revolut People?',
                  description:
                    'Yes! Revolut People was built with international teams in mind. After all, as we grew as a company, we needed to hire and manage employees working for companies of any size, anywhere.',
                },
              ]}
            />
          </ContentBlockLayout>
        </Box>

        <ContentBlockLayout>
          <Text use="h4" variant="h4" style={{ textTransform: 'uppercase' }}>
            The engine behind your dream team
          </Text>

          <Flex gap="s-24" mt="s-32">
            <BookDemoButton />
          </Flex>
        </ContentBlockLayout>

        <Footer />
      </ThemeProvider>
    </Box>
  )
}
