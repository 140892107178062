import React from 'react'
import { Header, Flex, Text, Item, Avatar, Token } from '@revolut/ui-kit'
import { connect } from 'lape'
import startCase from 'lodash/startCase'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { onboardingSteps } from '@src/interfaces/onboarding'
import { navigateTo } from '@src/actions/RouterActions'
import {
  DocumentSources,
  DocumentStatuses,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { getOnboardingDocuments } from '@src/api/onboarding'
import { BackButton, NextButton, TabProps } from '../common'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { TablePreview } from '@src/components/TablePreview/TablePreview'
import { getDocumentStatusColor } from '@src/constants/columns/documents'
import {
  documentStatusToColor,
  documentStatusToIcon,
} from '@src/pages/Forms/OnboardingTimeline/common'

const getDocumentLink = (document: EmployeeDocumentListItemInterface) => {
  if (
    document.source === DocumentSources.Request ||
    document.source === DocumentSources.Upload
  ) {
    return navigateTo(
      pathToUrl(ROUTES.ONBOARDING.DOCUMENT_UPLOAD_REQUEST, {
        id: document.recipient_id,
        employeeId: document.employee.id,
      }),
    )
  }

  if (document.source === DocumentSources.Embedded) {
    return navigateTo(
      pathToUrl(ROUTES.ONBOARDING.DOCUMENT_EMBEDDED, {
        id: document.recipient_id,
        employeeId: document.employee.id,
      }),
    )
  }

  if (document.source === DocumentSources.Docusign) {
    return navigateTo(
      pathToUrl(ROUTES.ONBOARDING.DOCUMENT_DOCUSIGN, {
        id: document.recipient_id,
        employeeId: document.employee.id,
      }),
    )
  }

  return '/'
}

const DocumentsStep = ({ data, onComplete, prevRoute, isPreview }: TabProps) => {
  return (
    <>
      <PageBody>
        <Header variant="main" mb="-s-12">
          <Header.Title>Documents</Header.Title>
          <Header.Subtitle>
            Almost there! Please find below a list of documents that need to be uploaded
            or signed before we can start your employment.
          </Header.Subtitle>
        </Header>
        <Text
          textStyle="caption"
          color={Token.color.greyTone50}
          mb="s-24"
          display="inline-block"
        >
          Please note that the document status may take a few minutes to update after
          signing.
        </Text>

        <TablePreview<EmployeeDocumentListItemInterface>
          disable={isPreview}
          api={{
            getItems: requestData => getOnboardingDocuments(requestData, data.id),
          }}
          emptyState={{
            title: 'No documents',
            icon: 'DocumentsPair',
          }}
          row={document => {
            const pendingDocusign =
              document.source === DocumentSources.Docusign &&
              document.status === DocumentStatuses.pending
            return (
              <Item
                use="button"
                variant="disclosure"
                onClick={() => getDocumentLink(document)}
                key={document.id}
              >
                <Item.Avatar>
                  <Avatar useIcon="DocumentsPair">
                    <Avatar.Badge
                      useIcon={documentStatusToIcon[document.status]}
                      bg={documentStatusToColor[document.status]}
                    />
                  </Avatar>
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{document.document_name}</Item.Title>
                  <Item.Description>
                    {pendingDocusign ? (
                      <Text>Document was sent to your email address</Text>
                    ) : (
                      <Text
                        color={getDocumentStatusColor(document.status)}
                        fontWeight="normal"
                      >
                        {startCase(document.status)}
                      </Text>
                    )}
                  </Item.Description>
                </Item.Content>
              </Item>
            )
          }}
        />
      </PageBody>

      <PageActions>
        <Flex gap="s-16">
          <BackButton onClick={() => (prevRoute ? navigateTo(prevRoute) : undefined)} />
          <NextButton
            onClickInPreviewMode={() =>
              navigateTo(pathToUrl(ROUTES.ONBOARDING.SUMMARY, { id: data.id }))
            }
            afterSubmit={() => {
              navigateTo(pathToUrl(ROUTES.ONBOARDING.SUMMARY, { id: data.id }))
              onComplete(onboardingSteps.documents)
            }}
            elevated
          />
        </Flex>
      </PageActions>
    </>
  )
}

export const Documents = connect(DocumentsStep)
export const DocumentsPreview = connect((props: TabProps) => (
  <DocumentsStep isPreview {...props} />
))
