import { Box, Flex, Icon, Tag, Text, Token, VStack, chain } from '@revolut/ui-kit'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  DeliverableOptions,
  PerformanceRating,
  Ratings,
  ReviewerRelationToShortTitle,
} from '@src/interfaces/performance'
import React from 'react'
import { tooltipRatingToColor } from '@src/utils/performance'
import { getComparedRatingSettings } from '@src/constants/columns/performanceSummary'
import { GradesMapInterface } from '@src/utils/grades'
import { css } from 'styled-components'

const EMPTY_GRADE = '-'

const ratingTooltipShadowsStyle = css`
  box-shadow: 0px 3px 8px 0px rgba(25, 28, 31, 0.45);
`

export const RatingWithTooltip = ({
  getTitle,
  rating,
  ratings,
  showAsText,
  expectedLevel,
  gradesMap,
  mapperFunc,
}: {
  getTitle: (rating: PerformanceRating) => string
  rating?: PerformanceRating
  ratings?: Ratings[]
  showAsText?: boolean
  expectedLevel?: DeliverableOptions | null
  gradesMap?: GradesMapInterface
  mapperFunc?: (
    gradesMap: GradesMapInterface,
    rating?: PerformanceRating | null,
  ) => string | undefined
}) => {
  if (!rating) {
    return <Text variant="h6">-</Text>
  }
  const setting = getComparedRatingSettings(rating, expectedLevel)
  const isGradeMapperFunction = mapperFunc && gradesMap
  return (
    <Tooltip
      justifyContent="flex-end"
      backgroundColor={Token.color.popoverBackground}
      placement="top"
      body={
        <Box css={ratingTooltipShadowsStyle} width="max-content" maxWidth={400} p="s-8">
          <VStack>
            {ratings?.map(item => (
              <Flex flex="1" alignItems="center" key={item.review.id}>
                {chain(
                  isGradeMapperFunction ? (
                    <Text variant="h6">{mapperFunc(gradesMap, item.value)}</Text>
                  ) : (
                    <Text variant="h6" color={tooltipRatingToColor(item.value)}>
                      {getTitle(item.value)}
                    </Text>
                  ),
                  <Text color={Token.color.greyTone50}>
                    {item.review.reviewer.full_name}
                  </Text>,
                  <Text color={Token.color.greyTone50}>
                    {ReviewerRelationToShortTitle[item.review.reviewer_relation]}
                  </Text>,
                )}
              </Flex>
            ))}
          </VStack>
        </Box>
      }
    >
      <Flex
        gap="s-8"
        width="fit-content"
        alignItems="center"
        style={{ cursor: 'pointer' }}
      >
        {showAsText && (
          <Text variant="heading3" data-testid="card-result">
            {isGradeMapperFunction ? mapperFunc(gradesMap, rating) : getTitle(rating)}
          </Text>
        )}
        {!showAsText && (
          <Tag color={setting.color}>
            <Text color={Token.color.foreground}>{getTitle(rating)}</Text>
          </Tag>
        )}
        {getTitle(rating) !== EMPTY_GRADE && !showAsText && (
          <Icon size={16} name={setting.icon} color={setting.iconColor} />
        )}
      </Flex>
    </Tooltip>
  )
}
