import React, { useState } from 'react'
import {
  Action,
  ActionButton,
  Avatar,
  InputGroup,
  Item,
  Subheader,
  VStack,
} from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import JobPostingPopup from '@src/features/JobPostingFlow/components/JobPostingPopup'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { CareersPositionPreviewSidebar } from '@src/components/CareersPositionPreviewSidebar/CareersPositionPreviewSidebar'
import { LocationInterface } from '@src/interfaces/requisitions'

type DescriptionProps = {
  requisitionLocations?: LocationInterface[]
}

export const Description = ({ requisitionLocations }: DescriptionProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [openJobPostings, setOpenJobPostings] = useState(false)
  const [showPreview, setShowPreview] = useState(true)
  const isCommercial = useIsCommercial()
  return (
    <>
      {openJobPostings && (
        <JobPostingPopup
          confirmLabel="Reuse posting"
          specialisationId={values?.specialisation?.id!}
          onClose={() => {
            setOpenJobPostings(false)
          }}
          onConfirm={jobPosting => {
            values.name = jobPosting.name
            values.sections = jobPosting.sections
          }}
        />
      )}
      <Subheader>
        <Subheader.Title>Job description</Subheader.Title>
        <Subheader.Side>
          <Action
            onClick={() => {
              setShowPreview(!showPreview)
            }}
          >
            Preview
          </Action>
        </Subheader.Side>
      </Subheader>
      <VStack gap="s-16">
        <Item
          use="label"
          onClick={() => {
            setOpenJobPostings(true)
          }}
        >
          <Item.Avatar>
            <Avatar useIcon="WizardHat" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Reuse existing jobs</Item.Title>
            <Item.Description>
              Reuse content from previously posted job postings
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton useIcon="Search">Browse job postings</ActionButton>
          </Item.Side>
        </Item>
        <LapeNewInput
          name="name"
          label="Job posting title"
          message="This is job posting title candidates will see on the career website (Specialisation name by default)"
          required
        />
        {values.sections?.map((section, idx) => (
          <React.Fragment key={section.title}>
            <Subheader>
              <Subheader.Title>{section.title}</Subheader.Title>
            </Subheader>
            <InputGroup>
              <LapeHTMLEditor
                name={`sections.${idx}.content`}
                placeholder={section.title}
                height={350}
                required
                addMarginToParagraphs
              />
            </InputGroup>
          </React.Fragment>
        ))}
        <VStack>
          <Subheader>
            <Subheader.Title>Video</Subheader.Title>
          </Subheader>
          <Subheader variant="nested">
            <Subheader.Title>
              Add a video to the job posting to tell candidates more about your company
              and the role they’re applying to. The video will be shown below the main job
              posting description
            </Subheader.Title>
          </Subheader>
          <LapeNewInput
            name="presentation_video_url"
            label={
              isCommercial
                ? 'YouTube video URL'
                : 'YouTube or assets.revolut.com video URL'
            }
            message={
              isCommercial
                ? 'Only YouTube videos will be displayed'
                : 'Only videos from YouTube or assets.revolut.com will be displayed'
            }
            onAfterChange={val => {
              if (!val) {
                delete values.presentation_video_url
              }
            }}
          />
        </VStack>
      </VStack>
      <CareersPositionPreviewSidebar
        open={showPreview}
        onClose={() => setShowPreview(false)}
        text={values?.name || ''}
        locations={
          (values.location_source === LocationSource.requisition
            ? requisitionLocations
            : values.locations) || []
        }
        description={
          values.sections
            ?.filter(section => section.content)
            ?.map(section => `<b>${section.title}</b><br />${section.content}`)
            ?.join('<br />') || ''
        }
      />
    </>
  )
}
