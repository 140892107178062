import React from 'react'
import { Caption, Flex, Text, Token } from '@revolut/ui-kit'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props {
  title: string
  description: string
}

const OptionLabel = ({ title, description }: Props) => (
  <Flex flexDirection="column">
    <Text>{title}</Text>
    <Caption variant="caption" color={Token.color.greyTone50}>
      {description}
    </Caption>
  </Flex>
)

export enum CycleMode {
  Scheduled = 'Scheduled',
  Manual = 'Manual',
}

const scheduledOption: RadioOption<OptionInterface<CycleMode>> = {
  value: {
    name: CycleMode.Scheduled,
    id: CycleMode.Scheduled,
  },
  label: (
    <OptionLabel title="Schedule" description="Cycle stages change on fixed dates" />
  ),
}

const manualOption: RadioOption<OptionInterface<CycleMode>> = {
  value: {
    name: CycleMode.Manual,
    id: CycleMode.Manual,
  },
  label: <OptionLabel title="Manual" description="Move to the next stage manually" />,
}

export const makeCycleModeOptions = (
  cycle: ReviewCyclesInterface,
): RadioOption<OptionInterface<CycleMode>>[] => [
  {
    ...scheduledOption,
    disabled: cycleModel.isTest(cycle),
  },
  {
    ...manualOption,
    disabled: cycleModel.isClosed(cycle),
  },
]
