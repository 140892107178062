import React from 'react'
import { ROUTES } from '@src/constants/routes'
import DepartmentTalent from '@src/pages/Forms/CommonTalentTab/Talent'
import DepartmentTalentPerformance from '@src/pages/Forms/CommonTalentTab/TalentPerformance'
import TalentPromotionNominations from '@src/pages/Forms/CommonTalentTab/TalentPromotionNominations'
import { Route, Switch, useParams } from 'react-router-dom'
import { TalentType } from '@src/interfaces/talent/talent'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { PermissionTypes } from '@src/store/auth/types'
import Schedule from '@src/pages/Forms/DepartmentForm/Schedule/Schedule'
import { TimeOff } from '@src/pages/Forms/DepartmentForm/TimeOff/TimeOff'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { useGlobalSettings, useGetTimeOffSettings } from '@src/api/settings'
import Distribution from '@src/pages/Forms/DepartmentForm/Budget/Distribution'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { DropdownNavigation } from '@src/components/DropdownNavigation/DropdownNavigation'

const Talent = ({ data }: { data: DepartmentInterface }) => {
  const params = useParams<{ id: string }>()
  const { data: timeOffSettings } = useGetTimeOffSettings()
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()
  const isNewTable = useIsNewTable()

  const timeOffEnabled = !!timeOffSettings?.enabled

  // TODO: remove if removing compensation
  // const { data: organisationSettings } = useGetOrganisationSettings()
  // const budgetManagementEnabled = !!organisationSettings?.enable_budget_management
  // const canViewCompensationReviews = data?.field_options?.permissions?.includes(
  //   EntityPermissions.ViewCompensationReviews,
  // )
  // const canViewCompensation =
  //   compensation_enabled && budgetManagementEnabled && canViewCompensationReviews // compensation_enabled from useGlobalSettings

  const subTabs = [
    {
      path: ROUTES.FORMS.DEPARTMENT.TALENT.GENERAL,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.GENERAL, params),
      title: 'General',
      component: DepartmentTalent,
      canView: true,
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.TALENT.PERFORMANCE,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.PERFORMANCE, params),
      title: 'Performance',
      component: DepartmentTalentPerformance,
      canView: data.field_options.permissions?.includes(
        PermissionTypes.ViewEmployeePerformance,
      ),
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.TALENT.PROMOTION_NOMINATIONS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.PROMOTION_NOMINATIONS, params),
      title: 'Promotion nominations',
      component: TalentPromotionNominations,
      canView:
        promotions_enabled &&
        data.field_options.permissions?.includes(PermissionTypes.ViewPromotionNomination),
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.TALENT.SCHEDULE,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.SCHEDULE, params),
      title: 'Schedule',
      component: Schedule,
      canView: !!data?.id,
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.TALENT.TIME_OFF,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.TIME_OFF, params),
      title: 'Time Off',
      component: TimeOff,
      quickSummary:
        data.time_off_request_pending_count != null ? (
          <QuickSummaryCount count={data.time_off_request_pending_count} />
        ) : null,
      canView:
        data?.field_options.permissions?.includes(PermissionTypes.ViewTimeOff) &&
        timeOffEnabled,
    },
    {
      title: 'Compensation',
      path: ROUTES.FORMS.DEPARTMENT.TALENT.COMPENSATION,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.COMPENSATION, params),
      component: Distribution,
      // TODO: either remove compensation or keep once the decision is made, currently should be hidden
      canView: false, // !!data.id && canViewCompensation && isNewLayout,
    },
  ]

  const filteredTabs = subTabs.filter(tab => tab.canView)

  const navigation = (
    <Flex alignItems="flex-start" mb="s-24">
      <TabBarNavigation isSubtab tabs={filteredTabs} />
    </Flex>
  )

  return (
    <Flex flexDirection="column" minHeight={0}>
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component
              data={data}
              talentType={TalentType.Department}
              navigation={
                isNewTable ? <DropdownNavigation options={filteredTabs} /> : navigation
              }
            />
          </Route>
        ))}
        {filteredTabs?.[0]?.path && <InternalRedirect to={filteredTabs?.[0]?.path} />}
      </Switch>
    </Flex>
  )
}

export default Talent
