import React from 'react'
import { Box, InputGroup } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import { PageWrapper } from '@src/components/Page/Page'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useParams } from 'react-router'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ProbationCycleSettingsInterface,
  ReviewCycleCategory,
} from '@src/interfaces/reviewCycles'

export const Settings = ({ type }: { type: ReviewCycleCategory }) => {
  const { values } = useLapeContext<ProbationCycleSettingsInterface>()
  const params = useParams<{ employeeId?: any; cycleId?: any }>()
  const isPip = type === ReviewCycleCategory.PIP

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
    id: params.employeeId,
  })

  const performanceCheckpoints = values.performance_checkpoints?.filter(checkpoint =>
    ['review', 'decision'].includes(checkpoint.checkpoint_type),
  )

  return (
    <PageWrapper>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General info" />
          <InputGroup>
            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                name="start_date_time"
                label={`${isPip ? 'PIP' : 'Probation'} Start Date`}
                required
              />
              <LapeDatePickerInput
                name="end_date_time"
                label={`${isPip ? 'PIP' : 'Probation'} End Date`}
                required
              />
            </InputGroup>
            <LapeRadioSelectInput
              label={`${isPip ? 'PIP' : 'Probation'} Manager`}
              name="hr_manager"
              selector={
                isPip ? selectorKeys.pip_hr_managers : selectorKeys.probation_hr_managers
              }
            />
          </InputGroup>

          <NewStepperTitle title="Checkpoint Dates" />
          <Box maxWidth="50%">
            <InputGroup>
              {performanceCheckpoints?.map(checkpoint => {
                const index = values.performance_checkpoints.findIndex(
                  item => item.id === checkpoint.id,
                )
                return (
                  <LapeDatePickerInput
                    key={checkpoint.id}
                    name={`performance_checkpoints[${index}].checkpoint_date_time`}
                    label={
                      checkpoint.checkpoint_type === 'decision'
                        ? 'Committee decision'
                        : `Checkpoint ${checkpoint.number}`
                    }
                    required
                  />
                )
              })}
            </InputGroup>
          </Box>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings successfully updated"
          afterSubmitUrl={backUrl}
          useValidator
          isExistingData
        >
          Save
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}
