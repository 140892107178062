import React from 'react'
import { Button, Flex, Placeholder, Popup, Text, Token } from '@revolut/ui-kit'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { cycleStageToPropsMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/constants'
import { PopupBody } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/PopupBody'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
  isOpen: boolean
  isSwitchPending: boolean
  handleConfirm: VoidFunction
  handleClose: VoidFunction
}

export const SwitchStagePopup = ({
  cycle,
  stage,
  isOpen,
  isSwitchPending,
  handleConfirm,
  handleClose,
}: Props) => {
  const props = cycleStageToPropsMap[stage]

  if (props === null) {
    return null
  }

  const { title, description, confirmLabel, cancelLabel, image, note } = props

  return (
    <Popup size="sm" open={isOpen}>
      <Placeholder>
        <Placeholder.Image size={88} image={image} />
        <Placeholder.Title>{title}</Placeholder.Title>
        <Placeholder.Description>{description}</Placeholder.Description>
      </Placeholder>
      <PopupBody cycle={cycle} stage={stage} />
      {note && (
        <Flex mt="s-24">
          <Text variant="body3" textAlign="center" color={Token.color.greyTone50}>
            {note}
          </Text>
        </Flex>
      )}
      <Popup.Actions>
        <Button onClick={handleConfirm} pending={isSwitchPending}>
          {confirmLabel}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {cancelLabel}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
