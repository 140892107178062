import { Token } from '@revolut/ui-kit'

export const LargeWeeklyCalendarTheme = {
  common: {
    gridSelection: {
      backgroundColor: Token.color.actionBlue,
      border: `1px solid ${Token.color.white}`,
    },
    dayName: { color: Token.color.greyTone50 },
    holiday: { color: Token.color.greyTone50 },
    saturday: { color: Token.color.greyTone50 },
    today: { color: Token.color.greyTone50 },
    backgroundColor: Token.color.widgetBackground,
  },
  week: {
    dayName: {
      borderLeft: `1px solid ${Token.color.greyTone8}`,
      borderTop: 'none',
      borderBottom: 'none',
      backgroundColor: 'none',
    },
    dayGrid: {
      borderRight: 'none',
      backgroundColor: 'none',
    },
    dayGridLeft: {
      borderRight: 'none',
      backgroundColor: 'none',
      width: '56px',
    },
    timeGrid: { borderRight: `1px solid ${Token.color.greyTone8}` },
    timeGridLeft: {
      borderRight: `1px solid ${Token.color.greyTone8}`,
      backgroundColor: 'none',
      width: '56px',
    },
    timeGridLeftAdditionalTimezone: { backgroundColor: 'none' },
    timeGridHourLine: {
      borderBottom: `1px solid ${Token.color.greyTone8}`,
    },
    timeGridHalfHour: { borderBottom: 'none' },
    nowIndicatorLabel: { color: 'none' },
    nowIndicatorPast: { border: 'none' },
    nowIndicatorBullet: { backgroundColor: Token.color.red },
    nowIndicatorToday: { border: `1px solid ${Token.color.red}` },
    nowIndicatorFuture: { border: 'none' },
    pastTime: { color: Token.color.greyTone50 },
    futureTime: { color: Token.color.greyTone50 },
    weekend: { backgroundColor: 'none' },
    today: { color: 'none', backgroundColor: 'none' },
    pastDay: { color: 'none' },
    panelResizer: { border: `1px solid ${Token.color.greyTone8}` },
    gridSelection: { color: 'none' },
  },
}
