import React from 'react'
import { useTable } from '@components/Table/hooks'
import { getCompanyIssues, useCompanyIssuesCategoryCounts } from '@src/api/issues'
import { IssueInterface } from '@src/interfaces/issues'
import { IssuesTable } from '@src/features/IssuesTable/IssuesTable'
import { TableNames } from '@src/constants/table'
import { AuditStatuses } from '@src/interfaces/audit'
import { SORT_DIRECTION } from '@src/interfaces/data'
import Table from '@src/components/TableV2/Table'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'

const CompanyIssuesTable = () => {
  const initialFilterBy = [
    {
      filters: [
        { id: AuditStatuses.PENDING_FILING, name: AuditStatuses.PENDING_FILING },
        { id: AuditStatuses.QUALITY_REVIEW, name: AuditStatuses.QUALITY_REVIEW },
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
      ],
      columnName: 'status',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'severity_sorter',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'due_date',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<IssueInterface>(
    {
      getItems: getCompanyIssues(),
    },
    initialFilterBy,
    initialSortBy,
  )

  const { data: issuesCategoryCounts } = useCompanyIssuesCategoryCounts(table.filterBy)

  return (
    <IssuesTable
      table={table}
      name={TableNames.CompanyIssues}
      issuesCategoryCounts={issuesCategoryCounts}
      showIssueStatus
    />
  )
}

export const Issues = () => {
  return (
    <Table.Widget>
      <Table.Widget.Info>
        <CompanyNavigation />
      </Table.Widget.Info>
      <CompanyIssuesTable />
    </Table.Widget>
  )
}
