import React from 'react'
import { Box, Flex, Text, Token, Widget } from '@revolut/ui-kit'
import PredefinedLabel from '@src/pages/Careers/CareersApplication/PredefinedSectionLabel'
import { useIsNewTable } from '@components/TableV2/hooks'

export interface ApplicationFormWidgetSectionInterface {
  locked?: boolean
  onClickEdit?: (idx: number) => void
  idx?: number
  actions?: React.ReactNode
  title?: React.ReactNode
  description?: string | null
  children?: React.ReactNode
  titleIndexNumber: number
  disableEditing?: boolean
}

const ApplicationFormWidgetSection = ({
  locked,
  onClickEdit,
  idx,
  actions,
  title,
  description,
  children,
  titleIndexNumber,
  disableEditing = false,
}: ApplicationFormWidgetSectionInterface) => {
  const isNewTable = useIsNewTable()

  return (
    <Widget
      style={{
        cursor: locked || disableEditing ? 'auto' : 'pointer',
      }}
      onClick={() => {
        if (!locked && !disableEditing && idx !== undefined) {
          onClickEdit?.(idx)
        }
      }}
      width="100%"
      display="block"
      p="s-16"
      data-testid={`preview_section_${titleIndexNumber}`}
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="start">
          <Box mb="s-4">
            {isNewTable ? (
              <Text use="div" variant="emphasis1">
                {title || 'Section'} {locked && <PredefinedLabel />}
              </Text>
            ) : (
              <Text fontWeight={500} fontSize="h5" use="div">
                {titleIndexNumber}. {title || 'Section'} {locked && <PredefinedLabel />}
              </Text>
            )}
          </Box>
          {actions}
        </Flex>
        {description && (
          <Box mb="s-16">
            {isNewTable ? (
              <Text variant="body2" color={Token.color.greyTone50}>
                {description}
              </Text>
            ) : (
              <Text variant="secondary" color={Token.color.greyTone50}>
                {description}
              </Text>
            )}
          </Box>
        )}
      </Box>
      {children}
    </Widget>
  )
}

export default ApplicationFormWidgetSection
