import { LocalStorageKeys } from '@src/store/auth/types'
import { isWorkspacesEnabled } from '@src/utils'

const setItem = (key: string, value: string) => {
  const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)
  if (isWorkspacesEnabled() && activeWorkspace) {
    localStorage.setItem(`${activeWorkspace}:${key}`, value)
    return
  }
  localStorage.setItem(key, value)
}

const getItem = (key: string) => {
  const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)
  if (isWorkspacesEnabled() && activeWorkspace) {
    return localStorage.getItem(`${activeWorkspace}:${key}`)
  }
  return localStorage.getItem(key)
}

const removeItem = (key: string) => {
  const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)
  if (isWorkspacesEnabled() && activeWorkspace) {
    localStorage.removeItem(`${activeWorkspace}:${key}`)
    return
  }
  localStorage.removeItem(key)
}

export const workspaceLocalStorage = {
  getItem,
  setItem,
  removeItem,
}
