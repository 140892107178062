import {
  askClickupWorkspacePermissions,
  askJiraWorkspacePermissions,
  askLookerWorkspacePermissions,
  connectTableau,
} from '@src/api/officeSuiteProvider'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { integrationApiFormMap } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'
import { LocalStorageKeys } from '@src/store/auth/types'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { capitalize } from 'lodash'

export type ConnectionType = 'looker' | 'jira' | 'tableau' | 'clickup'

export const useMakeConnection = () => {
  const showStatusPopup = useShowStatusPopup()
  const makeConnection = async (type: ConnectionType) => {
    try {
      if (type === 'jira') {
        await askJiraWorkspacePermissions()
      }
      if (type === 'looker') {
        await askLookerWorkspacePermissions()
      }
      if (type === 'clickup') {
        await askClickupWorkspacePermissions()
      }
      if (type === 'tableau') {
        const resp = await connectTableau()
        if (resp.status === 200) {
          await integrationApiFormMap[type]?.update({ enabled: true }, { id: '1' })
          workspaceLocalStorage.setItem(LocalStorageKeys.CONNECTED_INTEGRATION, 'Tableau')
          window.location.reload()
        }
      }
    } catch (e) {
      showStatusPopup({
        status: 'error',
        title: `${capitalize(type)} integration failed`,
        description: getStringMessageFromError(e),
      })
    }
  }
  return makeConnection
}
