import React, { useMemo, useState } from 'react'
import {
  Button,
  MoreBar,
  StatusPopup,
  useStatusPopup,
  Text,
  Token,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'

import { PageActions } from '@src/components/Page/PageActions'
import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface, IdStatuses } from '@src/interfaces/employees'
import {
  employeesEmailColumn,
  employeesFirstNameColumn,
  employeesLastNameColumn,
  employeesAccessGroupColumn,
} from '@src/constants/columns/employeesV2'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { bulkActivationEmail } from '@src/api/platformOnboarding'
import { useQuery } from '@src/utils/queryParamsHooks'
import { getLocationDescriptor, goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import {
  completeOnboardingCheckpoint,
  useInvalidateOnboardingCheckpoints,
} from '@src/api/onboardingChecklistV2'
import { inviteEmployeesConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { ROUTES } from '@src/constants/routes'
import { DemoModeBanner } from './DemoModeBanner'
import { useEmployeesSimpleSelector } from '@src/api/employees'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<EmployeesSimpleInterface>> => ({
  cells: [
    {
      ...employeesEmailColumn(options.onChange),
      width: 200,
    },
    {
      ...employeesFirstNameColumn(options.onChange),
      width: 120,
    },
    {
      ...employeesLastNameColumn(options.onChange),
      width: 120,
    },
    {
      ...employeesAccessGroupColumn(options.onChange),
      width: 200,
    },
  ],
})

interface InviteEmployeesReviewTableProps {
  emailListRoute: string
  closeRoute: string
  isCompletingOnboarding: boolean
}

export const InviteEmployeesReviewTable = ({
  emailListRoute,
  closeRoute,
  isCompletingOnboarding,
}: InviteEmployeesReviewTableProps) => {
  const [pending, setPending] = useState(false)
  const [tableDataValid, setTableDataValid] = useState(false)

  const { query } = useQuery()

  const invalidateOnboardingCheckpoints = useInvalidateOnboardingCheckpoints()

  const preselectedIds = useMemo(
    () => query.id?.split(',')?.map(id => ({ id: Number(id) })),
    [query.id],
  )

  const statusPopup = useStatusPopup()

  const onSendInvitations = async (items: number[]) => {
    if (!items.length) {
      return
    }

    setPending(true)

    try {
      const response = await bulkActivationEmail(items.map(id => ({ id })))

      if (isCompletingOnboarding) {
        await completeOnboardingCheckpoint(inviteEmployeesConfig.category)
        navigateTo(ROUTES.ONBOARDING_CHECKLIST_V2.COMPLETE)
        return
      }

      if (response.data.success) {
        statusPopup.show(
          <StatusPopup
            variant="success"
            onClose={() => {
              statusPopup.hide()
              goBack(closeRoute)
            }}
          >
            <StatusPopup.Title>
              Invitation sent to{' '}
              <Text color={Token.color.blue}>
                {items.length} team {pluralize('member', items.length)}
              </Text>
            </StatusPopup.Title>
          </StatusPopup>,
        )
      } else {
        statusPopup.show(
          <StatusPopup variant="error" onClose={statusPopup.hide}>
            <StatusPopup.Title>Something went wrong</StatusPopup.Title>
            <StatusPopup.Description>Emails were not sent</StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide}>Close</Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      }
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Something went wrong</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Close</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } finally {
      setPending(false)
      if (isCompletingOnboarding) {
        invalidateOnboardingCheckpoints()
      }
    }
  }

  return (
    <>
      <DemoModeBanner />

      <GenericEditableTable
        apiEndpoint={API.EMPLOYEES_SIMPLE}
        tableName={TableNames.InviteEmployees}
        row={row}
        entity="employee"
        variant="existingEntities"
        allItemsSelector={useEmployeesSimpleSelector}
        tableActions={() => (
          <MoreBar>
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              // @ts-expect-error object works fine here, but UI kit expects string
              to={{
                ...getLocationDescriptor(pathToUrl(emailListRoute, { action: 'add' })),
                search: query.id ? `id=${query.id}` : undefined,
              }}
            >
              Add users
            </MoreBar.Action>
          </MoreBar>
        )}
        actions={props => {
          const selectedItems = props.getSelectedItems()

          return (
            <PageActions>
              <Button
                onClick={() => onSendInvitations(selectedItems)}
                disabled={!selectedItems?.length || !tableDataValid}
                pending={pending}
              >
                Send invitation
              </Button>
            </PageActions>
          )
        }}
        onChangeValidationState={state => {
          setTableDataValid(state === 'valid')
        }}
        preselectedItems={preselectedIds}
        selectAllItems={isCompletingOnboarding}
        filterByInitial={[
          {
            columnName: 'status',
            filters: [
              { name: IdStatuses.active, id: IdStatuses.active },
              { name: IdStatuses.hired, id: IdStatuses.hired },
              { name: IdStatuses.onboarding, id: IdStatuses.onboarding },
            ],
            nonResettable: true,
          },
        ]}
      />
    </>
  )
}
