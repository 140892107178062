import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ActionButton, Cell, FilterButton, Flex, Spacer } from '@revolut/ui-kit'
import {
  groupChannelsColumn,
  groupCreationDateColumn,
  groupMembersCountColumn,
  groupNameColumn,
  groupOwnerColumn,
  groupParentColumn,
  groupStatusColumn,
  groupTypeColumn,
} from '@src/constants/columns/communicationGroups'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import { getCommunicationGroupsList } from '@src/api/communicationGroups'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetCommunicationSettings } from '@src/api/settings'

const rows: RowInterface<CommunicationGroupInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.COMMUNICATION_GROUPS.VIEW.GROUP, { id })),
  cells: [
    {
      ...groupNameColumn,
      width: 150,
    },
    {
      ...groupParentColumn,
      width: 150,
    },
    {
      ...groupTypeColumn,
      width: 150,
    },
    {
      ...groupMembersCountColumn,
      width: 50,
    },
    {
      ...groupChannelsColumn,
      width: 50,
    },
    {
      ...groupCreationDateColumn,
      width: 80,
    },
    {
      ...groupOwnerColumn,
      width: 150,
    },
    {
      ...groupStatusColumn,
      width: 100,
    },
  ],
}

export const CommunicationGroupsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_COMMUNICATION_GROUPS_ASSIGNED_TO_ME,
    false,
  )

  const { data: communicationSettings } = useGetCommunicationSettings()

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const permissions = useSelector(selectPermissions)
  const table = useTable<CommunicationGroupInterface>(
    {
      getItems: getCommunicationGroupsList,
    },
    getInitialFilters(),
  )
  const canAdd = permissions?.includes(PermissionTypes.AddCommunicationGroup)

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Cell p="s-16">
      <Flex flexDirection="column" width="100%">
        <Flex flex="1 0" justifyContent="space-between" mb="s-12">
          {canAdd ? (
            <ActionButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.COMMUNICATION_GROUPS.EDIT, {})}
              mr="s-16"
              useIcon="Plus"
            >
              Create group
            </ActionButton>
          ) : (
            <Spacer />
          )}
          <SearchTable onFilter={table.onFilterChange} />
        </Flex>
        <Flex flexDirection="row-reverse">
          <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
            My Groups
          </FilterButton>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<CommunicationGroupInterface>
            name={TableNames.CommunicationGroups}
            useWindowScroll
            dataType="Group"
            row={rows}
            {...table}
            noDataMessage="Communication groups will appear here."
            hiddenCells={{
              [groupChannelsColumn.idPoint]:
                !communicationSettings?.enable_automatic_slack_channel_and_group_creation,
            }}
          />
        </Flex>
      </Flex>
    </Cell>
  )
}
