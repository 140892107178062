import React, { useState } from 'react'
import { Avatar, Group, Item, VStack } from '@revolut/ui-kit'
import { ScorecardSidebar } from '@src/pages/Forms/Candidate/Performance/Summary/ScorecardSidebar'
import {
  useGetPerformanceRecruitmentCycleData,
  useFetchInterviewStages,
} from '@src/api/performance'
import { PerformanceSections } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSections'
import { OverallImpressions } from '@src/pages/Forms/Candidate/Performance/Summary/OverallImpressions'
import { FilterBar } from '@src/pages/Forms/Candidate/Performance/Summary/FilterBar'

export const RecruitmentPerformanceSummary = ({ empoyeeId }: { empoyeeId: number }) => {
  const [interviewFeedbackIds, setInterviewFeedbackIds] = useState<number[]>()
  const [interviewersFilter, setInterviewersFilter] = useState<number[]>([])

  const { data: recruitmentData, isLoading } = useGetPerformanceRecruitmentCycleData(
    empoyeeId,
    interviewersFilter,
  )

  const { data: interviewStages } = useFetchInterviewStages(empoyeeId)

  const onCloseInterviewFeedbacks = () => {
    setInterviewFeedbackIds([])
  }

  const onViewScorecard = (feedbackIds: number[]) => {
    setInterviewFeedbackIds(feedbackIds)
  }

  return (
    <>
      <Group pb="s-12">
        <Item>
          <Item.Avatar>
            <Avatar useIcon="BarChart" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Performance summary</Item.Title>
          </Item.Content>
        </Item>
        <VStack>
          <FilterBar
            isPrefetchedDataLoading={isLoading}
            prefetchedData={recruitmentData?.summary_data}
            interviewersFilter={interviewersFilter}
            onChangeInterviewersFilter={filter => {
              setInterviewersFilter(filter)
            }}
          />
          <VStack gap="s-16">
            <PerformanceSections
              performanceRoundSummary={recruitmentData?.summary_data}
              loading={isLoading}
              onViewScorecard={onViewScorecard}
            />
            <OverallImpressions
              prefetchedData={interviewStages}
              onViewScorecard={onViewScorecard}
            />
          </VStack>
        </VStack>
      </Group>
      {!!interviewFeedbackIds?.length && (
        <ScorecardSidebar
          prefetchedData={interviewStages}
          interviewFeedbackIds={interviewFeedbackIds}
          onClose={onCloseInterviewFeedbacks}
        />
      )}
    </>
  )
}
