import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, Widget } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import AdjustableTable from '@components/Table/AdjustableTable'
import { PermissionManagementInterface } from '@src/interfaces/accessManagement'
import { useTable } from '@components/Table/hooks'
import {
  permissionsManagementRequests,
  revokePermission,
} from '@src/api/accessManagement'
import { RowInterface } from '@src/interfaces/data'
import {
  actionsColumn,
  assignedByColumn,
  notesColumn,
  userNameColumn,
  validFromColumn,
  validUntilColumn,
} from '@src/constants/columns/accessManagement/accessManagementAssignGroups'
import { Action, HStack } from '@revolut/ui-kit'
import {
  nameColumn,
  statusColumn,
} from '@src/constants/columns/accessManagement/accessManagementPermissions'
import { navigateTo } from '@src/actions/RouterActions'
import RevokeAction from '@src/features/TableRowRevokeAction/RevokeAction'
import { TableNames } from '@src/constants/table'
import { useGetAccessManagementSettings } from '@src/api/settings'

type Type = 'user' | 'permission'

const Row = (
  type: Type,
  onRevoke: (id: number) => void,
): RowInterface<PermissionManagementInterface> => {
  const linkToForm = (data: PermissionManagementInterface) => {
    switch (type) {
      case 'user':
        navigateTo(
          pathToUrl(ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION.GENERAL, {
            userId: data.user.id,
            id: data.id,
          }),
        )
        break
    }
  }

  return {
    linkToForm,
    cells: [
      type === 'permission'
        ? {
            ...userNameColumn,
            width: 300,
          }
        : {
            ...nameColumn,
            title: 'Permission',
            width: 300,
          },
      {
        ...assignedByColumn,
        title: 'Granted by',
        width: 300,
      },
      {
        ...validFromColumn,
        width: 150,
      },
      {
        ...validUntilColumn,
        width: 150,
      },
      {
        ...notesColumn,
        width: 300,
      },
      {
        ...statusColumn,
        width: 150,
      },
      {
        ...actionsColumn,
        width: 200,
        insert: ({ data }) => (
          <>
            <HStack gap="s-8">
              {type === 'user' && (
                <Action
                  onClick={e => {
                    e.stopPropagation()
                    linkToForm(data)
                  }}
                >
                  Edit
                </Action>
              )}
              {data.status !== 'expired' && (
                <RevokeAction onClick={() => onRevoke(data.id!)} />
              )}
            </HStack>
          </>
        ),
      },
    ],
  }
}

interface AssignedPermissionsUsersTableProps {
  type: Type
}

const AssignedPermissionsUsersTable = ({ type }: AssignedPermissionsUsersTableProps) => {
  const { id } = useParams<{ id: string }>()
  const { data: settings } = useGetAccessManagementSettings()
  const initialFilter = []

  if (type === 'permission') {
    initialFilter.push({
      filters: [{ name: String(id), id }],
      columnName: 'permission__id',
      nonResettable: true,
      nonInheritable: true,
    })
  }

  if (type === 'user') {
    initialFilter.push({
      filters: [{ name: String(id), id }],
      columnName: 'user__id',
      nonResettable: true,
      nonInheritable: true,
    })
  }

  const table = useTable<PermissionManagementInterface>(
    permissionsManagementRequests,
    initialFilter,
  )

  const onRevoke = async (itemId: number) => {
    await revokePermission(itemId)
    table.refresh()
  }

  return (
    <Widget p="s-16">
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<PermissionManagementInterface>
            name={TableNames.AssignedPermissionsUsers}
            useWindowScroll
            dataType={type === 'user' ? 'Permissions' : 'Users'}
            row={Row(type, onRevoke)}
            {...table}
            noDataMessage={`${
              type === 'user' ? 'Permissions' : 'Users'
            } will appear here.`}
            hiddenCells={{
              [validUntilColumn.idPoint]: !settings?.enable_limited_time_access,
            }}
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default AssignedPermissionsUsersTable
