import React, { useEffect, useRef } from 'react'
import { Box } from '@revolut/ui-kit'
import ScorecardSectionControls from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/ScorecardSectionControls'
import ApplicationFormPreviewQuestion from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormPreviewQuestion'
import { ApplicationFormSectionInterface } from '@src/interfaces/applicationForm'
import produce from 'immer'
import ApplicationFormWidgetSection from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormWidgetSection'

type Props = {
  idx: number
  titleIndexNumber: number
  onClickEdit: (sectionIdx: number) => void
  sections: ApplicationFormSectionInterface[]
  onChange?: (sections?: ApplicationFormSectionInterface[]) => void
  locked?: boolean
  disableEditing?: boolean
  scrollTo?: boolean
}

const ApplicationFormPreviewSection = ({
  idx,
  titleIndexNumber,
  onClickEdit,
  sections,
  onChange,
  locked,
  disableEditing = false,
  scrollTo,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const section = sections[idx]

  useEffect(() => {
    if (scrollTo) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleDelete = () => {
    onChange?.(sections.filter((_, i) => i !== idx))
  }

  const handleCopy = () => {
    onChange?.(
      produce(sections, draft => {
        draft.splice(idx + 1, 0, {
          ...draft[idx],
          questions: draft[idx]?.questions?.map(question => ({
            ...question,
            id: undefined,
            options: question.options?.map(option => ({
              ...option,
              id: undefined,
            })),
          })),
          id: undefined,
        })
      }),
    )
  }

  return (
    <Box ref={scrollRef}>
      <ApplicationFormWidgetSection
        idx={idx}
        titleIndexNumber={titleIndexNumber}
        title={section.title}
        description={section.description}
        onClickEdit={onClickEdit}
        locked={locked}
        disableEditing={disableEditing}
        actions={
          !locked && !disableEditing ? (
            <Box
              style={{
                flexShrink: 0,
              }}
            >
              <ScorecardSectionControls
                id={idx}
                onDelete={handleDelete}
                onCopy={handleCopy}
                onEdit={() => onClickEdit(idx)}
                sections={sections}
                isView
              />
            </Box>
          ) : undefined
        }
      >
        {section.questions.map((_, questionIdx) => (
          <ApplicationFormPreviewQuestion
            key={questionIdx}
            sectionIdx={titleIndexNumber}
            questionIdx={questionIdx}
            question={sections[idx]?.questions[questionIdx]}
          />
        ))}
      </ApplicationFormWidgetSection>
    </Box>
  )
}

export default ApplicationFormPreviewSection
