import React from 'react'
import isEmpty from 'lodash/isEmpty'
import toLower from 'lodash/toLower'
import { HStack, Icon, Text, Token, VStack } from '@revolut/ui-kit'

import {
  PayrollTimelineChange,
  PayrollTimelineChangeInterface,
  PayrollTimelineChangeSalary,
} from '@src/interfaces/payrollV2'
import { formatMoneyWithCode } from '@src/utils/format'
import {
  MultipleChangesCellWrapper,
  SingleChangeCellWrapper,
} from './DetailsSidebarContent/DomainChangesWidget'

const assertFields = (data: object | null | undefined, fields: string[]): boolean => {
  if (!data) {
    return false
  }
  for (const field of fields) {
    if (!(field in data)) {
      return false
    }
  }
  return true
}

const isSalaryChange = (
  data: PayrollTimelineChange | null | undefined,
): data is PayrollTimelineChangeSalary => {
  return assertFields(data, [
    'salary_amount',
    'salary_currency',
    'salary_time_unit',
    'salary_payment_frequency',
  ])
}

const getSalaryString = (data?: PayrollTimelineChangeSalary) => {
  return data?.salary_amount &&
    data?.salary_currency &&
    data?.salary_time_unit &&
    data?.salary_payment_frequency
    ? `${formatMoneyWithCode(data.salary_amount, data.salary_currency)} a ${toLower(
        data.salary_time_unit,
      )} paid ${toLower(data.salary_payment_frequency)}`
    : null
}

export const formatSalaryAmount = (change: PayrollTimelineChangeInterface) => {
  const dataFrom = change.from_value
  const dataTo = change.to_value

  if (!isSalaryChange(dataTo)) {
    return undefined
  }
  const salaryStringTo = getSalaryString(dataTo)
  const isAdded = isEmpty(change.from_value) && isSalaryChange(change.to_value)
  const isUpdated = isSalaryChange(dataFrom) && isSalaryChange(dataTo)

  if (isAdded) {
    return (
      <SingleChangeCellWrapper change={change}>
        <HStack align="center" space="s-8">
          <Icon name="PlusCircle" color={Token.color.blue} size={15} />
          <Text>{salaryStringTo}</Text>
        </HStack>
      </SingleChangeCellWrapper>
    )
  }
  if (isUpdated) {
    const salaryStringFrom = getSalaryString(dataFrom)

    if (salaryStringFrom) {
      return (
        <MultipleChangesCellWrapper change={change}>
          <VStack space="s-16" py="s-8">
            <HStack align="center" space="s-8">
              <Text color={Token.color.greyTone50} fontWeight={500}>
                Updated to:
              </Text>
              <Text>{salaryStringTo}</Text>
            </HStack>
            <HStack align="center" space="s-8">
              <Text color={Token.color.greyTone50} fontWeight={500}>
                Previous:
              </Text>
              <Text>{salaryStringFrom}</Text>
            </HStack>
          </VStack>
        </MultipleChangesCellWrapper>
      )
    }
  }
  return undefined
}
