import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import {
  chain,
  ExpandableItem,
  HStack,
  ItemSkeleton,
  Link,
  ProgressCircle,
  StatusWidget,
  Subheader,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { BetaBadge } from '@components/BetaBadge/BetaBadge'
import { ARCHETYPES_DOCS } from '@src/constants/externalLinks'
import sortBy from 'lodash/sortBy'

type Props = {
  candidateName: string
  candidateId: number
  onClose: VoidFunction
  score: number | null
}

export const CandidateMatchSidebar = ({
  onClose,
  candidateId,
  candidateName,
  score,
}: Props) => {
  const { data: opportunities, isLoading } = useGetFullInterviewRounds(candidateId)
  const sortedOpportunities = sortBy(opportunities, [o => !o.active])

  return (
    <SideBar
      isOpen
      onClose={onClose}
      title={candidateName}
      subtitle={opportunities?.find(item => item.active)?.specialisation.name}
    >
      <Subheader>
        <HStack gap="s-8" align="center">
          Matching opportunities <BetaBadge />
        </HStack>
      </Subheader>

      {isLoading ? (
        <VStack gap="s-16">
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </VStack>
      ) : (
        <VStack gap="s-16">
          {sortedOpportunities?.length ? (
            sortedOpportunities.map((opportunity, i) => {
              const showMatch = opportunity.active && score !== null
              const archived = opportunity.state === 'archived'

              return (
                <ExpandableItem defaultExpanded={i === 0} key={opportunity.id}>
                  <ExpandableItem.Avatar>
                    <ProgressCircle
                      strokeWidth={3}
                      size={44}
                      value={showMatch ? score / 100 : 0.7}
                      color={showMatch ? Token.color.blue : Token.color.deepGrey}
                    >
                      <ProgressCircle.Text style={{ fontSize: 12 }}>
                        {showMatch ? `${score}%` : '?'}
                      </ProgressCircle.Text>
                    </ProgressCircle>
                  </ExpandableItem.Avatar>
                  <ExpandableItem.Content>
                    <ExpandableItem.Title
                      color={archived ? Token.color.greyTone50 : undefined}
                    >
                      {chain(opportunity.specialisation.name, archived && 'Archived')}
                    </ExpandableItem.Title>
                    {showMatch && score >= 80 && (
                      <ExpandableItem.Description>
                        <Text variant="emphasis3" color={Token.color.blue}>
                          Brilliant hire 🔥
                        </Text>
                      </ExpandableItem.Description>
                    )}
                  </ExpandableItem.Content>
                  <ExpandableItem.NestedContent p="s-16">
                    <VStack gap="s-16" mt="s-16">
                      {showMatch ? (
                        <>
                          <Text variant="emphasis2" color={Token.color.greyTone50}>
                            About this score
                          </Text>
                          <Text color={Token.color.greyTone50}>
                            This score measures how well a candidate’s education and work
                            experience matches the ideal archetype for this role. <br />
                            <br />
                            You can read more about the archetype{' '}
                            <Link
                              href={ARCHETYPES_DOCS}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              here
                            </Link>
                          </Text>
                        </>
                      ) : (
                        <Text color={Token.color.greyTone50}>
                          No archetype available yet
                        </Text>
                      )}
                    </VStack>
                  </ExpandableItem.NestedContent>
                </ExpandableItem>
              )
            })
          ) : (
            <StatusWidget>
              <StatusWidget.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images/3D086.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images/3D086@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images/3D086@3x.png',
                }}
              />
              <StatusWidget.Title>
                No opportunities found for this candidate
              </StatusWidget.Title>
            </StatusWidget>
          )}
        </VStack>
      )}
    </SideBar>
  )
}
