import React from 'react'
import { Box, Skeleton, Text, Color } from '@revolut/ui-kit'

import { useGetProbationRecommendations } from '@src/api/probationReview'
import ProbationRecommendationsTable from './ProbationRecommendationsTable'

interface Props {
  employeeId?: number
  cycleId?: string
  wide?: boolean
}

const ManagerRecommendations = ({ employeeId, cycleId, wide = true }: Props) => {
  const { data: recommendations, isLoading } = useGetProbationRecommendations(
    employeeId !== undefined ? employeeId : null,
    cycleId !== undefined ? cycleId : null,
  )

  if (!isLoading && !recommendations?.results?.length) {
    return null
  }

  return (
    <Box mt="s-24" mb="s-16" data-testid="manager-recommendations">
      {isLoading ? (
        <>
          <Skeleton height={69} borderRadius={4} mb="s-8" />
          <Skeleton height={69} borderRadius={4} mb="s-8" />
          <Skeleton height={69} borderRadius={4} mb="s-8" />
          <Skeleton height={69} borderRadius={4} />
        </>
      ) : (
        <>
          <Text
            color={Color.GREY_TONE_50}
            fontWeight={500}
            fontSize="caption"
            use="div"
            pb="s-16"
          >
            Recommendations
          </Text>
          <ProbationRecommendationsTable
            recommendations={recommendations?.results || []}
            wide={wide}
          />
        </>
      )}
    </Box>
  )
}

export default ManagerRecommendations
