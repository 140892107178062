import React from 'react'
import pluralize from 'pluralize'
import { format } from 'date-fns'
import { Tag, Text } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { PayGroupListItemInterface } from '@src/interfaces/payrollV2'
import { payrollIssuesLevelToColor } from '@src/apps/People/PayrollV2/common'

export const payGroupNameColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Paygroup',
}

export const payGroupHeadcountColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'headcount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
}

export const payGroupPayRunColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'current_pay_cycle.start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Current payrun',
  insert: ({ data }) => {
    if (!data.current_pay_cycle.start_date || !data.current_pay_cycle.end_date) {
      return '-'
    }
    const formattedFrom = format(new Date(data.current_pay_cycle.start_date), 'd MMM')
    const formattedTo = format(new Date(data.current_pay_cycle.end_date), 'd MMM')

    return (
      <Tag>
        {formattedFrom} - {formattedTo}
      </Tag>
    )
  },
}

export const payGroupPayrollProviderColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'payroll_provider.id',
  dataPoint: 'payroll_provider.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Payroll provider',
}

export const payGroupChangesNumColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'total_changes',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Changes',
  insert: ({ data }) => <Text>{pluralize('change', data.total_changes || 0, true)}</Text>,
}

export const payGroupStatusColumn: ColumnInterface<PayGroupListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => {
    if (data.status?.id === 'approved') {
      return <Tag>{data.status.name}</Tag>
    }
    if (data.issues_level) {
      return (
        <Tag color={payrollIssuesLevelToColor[data.issues_level.id]}>
          {pluralize('issue', data.total_issues, true)}
        </Tag>
      )
    }
    return '-'
  },
}
