import React, { useEffect, useMemo } from 'react'
import { FilterInputSelectorType } from '../FilterSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { Box, Ellipsis, Flex, Tag, Text, Token } from '@revolut/ui-kit'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownStatAction from '@components/Inputs/DropdownStatAction/DropdownStatAction'
import capitalize from 'lodash/capitalize'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'

interface Props {
  onFilterChange: (filters: FilterByInterface, reset?: boolean) => void
  columnName: string
  filter: FilterByInterface[]
  selector?: FilterInputSelectorType<OptionInterface>
  label?: string
  onChangeCallback?: (val: OptionInterface) => void
}

export const PerformanceLayoutCycleFilter = ({
  onFilterChange,
  filter,
  columnName,
  selector = selectorKeys.cycle_offsets,
  label = 'Performance cycle',
  onChangeCallback,
}: Props) => {
  const { options } = useFetchOptions<OptionInterface>(selector)

  const filteredFilter = useMemo(
    () =>
      filter
        ?.find((filterBy: FilterByInterface) => filterBy.columnName === columnName)
        ?.filters.filter(option => option.id !== '' && option.name !== '')?.[0],
    [filter],
  )

  const selectedPeriod = options.find(
    option => String(option.value.id) === String(filteredFilter?.id),
  )

  useEffect(() => {
    if (selectedPeriod?.value) {
      onChangeCallback && onChangeCallback(selectedPeriod.value)
    }
  }, [selectedPeriod?.value.id])

  const selectedOffset =
    selectedPeriod?.value.offset === undefined
      ? selectedPeriod?.value.id
      : selectedPeriod?.value.offset

  const {
    title: tagTitle,
    color: tagColor,
    bgColor: tagBgColor,
  } = getCycleOffsetTitleAndColor(Number(selectedOffset))

  return (
    <Box>
      <RadioSelectInput
        value={selectedPeriod?.value}
        options={options}
        onChange={val => {
          if (val) {
            onFilterChange(
              {
                columnName,
                filters: [val],
              },
              false,
            )
          }
        }}
        renderInput={(open, setOpen, ref) => (
          <DropdownStatAction open={open} onClick={() => setOpen(!open)} ref={ref}>
            <Flex alignItems="center" gap="s-8">
              <Ellipsis>
                <Text>{selectedPeriod?.label || 'Select cycle'}</Text>
              </Ellipsis>
              {!!tagTitle.length && (
                <Tag variant="faded" bg={tagBgColor} color={tagColor}>
                  {capitalize(tagTitle)}
                </Tag>
              )}
            </Flex>
          </DropdownStatAction>
        )}
      >
        {option => {
          const offset =
            option.value.offset === undefined ? option.value.id : option.value.offset
          const { title, color } = getCycleOffsetTitleAndColor(Number(offset))
          return (
            <>
              {option.label}{' '}
              <Text variant="caption" color={color}>
                {title}
              </Text>
            </>
          )
        }}
      </RadioSelectInput>
      <Text color={Token.color.greyTone50} use="div">
        {label}
      </Text>
    </Box>
  )
}
