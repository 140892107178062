import React, { useState } from 'react'
import { EntityTypes } from '@src/constants/api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Spinner } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { deleteGoal, goalsRequests } from '@src/api/goals'
import { GoalsInterface } from '@src/interfaces/goals'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useLocation } from 'react-router-dom'
import {
  getDefaultBackRoute,
  useSubmitFlowHelpers,
} from '@src/pages/Forms/GoalForm/common/utils'
import { useGoalFormSubmit } from '@src/pages/Forms/GoalForm/Form/useGoalFormSubmit'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { captureException } from '@sentry/core'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { FormObserverProvider } from '@src/pages/Forms/GoalForm/Form/Widgets/FormObserverProvider'
import { CascadeGoalFormPageBody } from '@src/pages/Forms/GoalForm/CascadeGoal/CascadeGoalFormPageBody'
import { cleanGoalCache } from '../useGoalFormCache'

const General = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const [autoDeletePending, setAutoDeletePending] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const location = useLocation<{
    reviewCycleId?: string
    history: string[]
    isNew?: boolean
  }>()
  const { confirm, prompt, confirmationDialog } = useSubmitFlowHelpers()
  const { submit } = useGoalFormSubmit()

  const isDraft = values.approval_status.id === ApprovalStatuses.Draft
  const isNew = !!location.state?.isNew

  const shouldAutoDelete = isDraft && isNew
  const shouldConfirmBack = isDraft && isNew && isDirty

  const backUrl = getDefaultBackRoute(values, false)

  const contentLabelByType = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : ''

  const title = isNew
    ? `Cascade ${entityTitle} goal`
    : isDraft
    ? `Edit cascaded ${entityTitle} draft goal`
    : `Edit cascaded ${entityTitle} goal`

  const deleteAndGoBack = async () => {
    setAutoDeletePending(true)
    await deleteGoal(values.id).catch(captureException)
    setAutoDeletePending(false)
    cleanGoalCache(values.id)
    goBack(backUrl)
  }

  const confirmBack = async () => {
    const confirmVariant = values.name ? confirm : prompt

    const confirmed = await confirmVariant({
      yesMessage: 'Save as draft',
      noMessage: 'Delete goal',
      noBtnVariant: 'negative',
      variant: 'compact',
      label: 'You have unsaved changes',
      body: 'Do you want to save them or delete before proceeding?',
      promptLabel: 'Please set goal name before saving',
      commentRequired: true,
    })

    if (confirmed.status === 'canceled') {
      return
    }

    if (confirmed.status === 'confirmed') {
      if (confirmed.comment) {
        values.name = confirmed.comment
      }
      await submit('draft')
      goBack(backUrl)
    }

    if (confirmed.status === 'rejected') {
      deleteAndGoBack()
    }
  }

  const handleBack = () => {
    if (shouldConfirmBack) {
      confirmBack()
    } else if (shouldAutoDelete) {
      deleteAndGoBack()
    } else {
      goBack(backUrl)
    }
  }

  const onSubmitted = () => {
    if (values.approval_status.id === ApprovalStatuses.Draft) {
      goBack(backUrl)
    } else {
      navigateReplace(pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: values.id }))
    }
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        backButton={autoDeletePending ? <Spinner /> : undefined}
        withVerticalSpacing
        onClickBack={handleBack}
        hideGlobalSearch={false}
      />
      <FormObserverProvider>
        <CascadeGoalFormPageBody onDirtyChanged={setIsDirty} onSubmitted={onSubmitted} />
      </FormObserverProvider>
      {confirmationDialog}
    </PageWrapper>
  ) : null
}

export const CascadeGoalForm = () => {
  return (
    <PageWrapper>
      <Form
        api={goalsRequests}
        fieldsToExclude={['kpis', 'roadmaps', 'dashboards']}
        refetchOnLocationChange
      >
        <General />
      </Form>
    </PageWrapper>
  )
}
