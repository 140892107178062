import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  Box,
  Flex,
  useTooltip,
  VStack,
  Token,
  ActionButton,
  IconName,
  Text,
  chain,
} from '@revolut/ui-kit'
import React, { ReactNode } from 'react'
import AvatarSnippet from '@components/AvatarSnippet/AvatarSnippet'
import { relationToString } from '@src/features/Scorecard/constants'
import {
  ReviewerRelation,
  ReviewSummaryInterface,
  SingleTimelineEventReviewInterface,
} from '@src/interfaces/performance'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'
import styled from 'styled-components'
import { Statuses } from '@src/interfaces'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import { isObject } from 'lodash'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import StyledLink from '@src/components/Atoms/StyledLink'
import { isPast } from 'date-fns'
import { getColorsAndGrade } from '@src/components/PerformanceGrade/PerformanceGrade'

const FlexWrap = styled(Flex)<{ isLink?: boolean }>`
  border-radius: ${Token.radius.r12};
  transition: all 0.3s;
  &:hover {
    background-color: ${props => (props.isLink ? Token.color.greyTone2 : 'inherit')};
  }
`

export const StepReviewersList = ({
  reviews,
  onClickAddReview,
  buttonDisabled,
  icon,
  title,
  performanceLink,
  subText,
  secondaryActionButton,
  isProfileLink,
  isNewDesign = false,
  reviewsPublishingDay,
  isCalibrationStep = false,
  summaryData,
}: {
  reviews: SingleTimelineEventReviewInterface[] | GradedRequestInterface[]
  onClickAddReview: (e: React.MouseEvent) => void
  buttonDisabled: boolean
  icon: IconName
  title: string
  performanceLink?: string
  subText?: string
  secondaryActionButton?: ReactNode
  isProfileLink?: boolean
  isNewDesign?: boolean
  reviewsPublishingDay?: string
  isCalibrationStep?: boolean
  summaryData?: ReviewSummaryInterface
}) => {
  const user = useSelector(selectUser)
  const tooltip = useTooltip()

  const isSkipedStatus = reviewsPublishingDay
    ? isCalibrationStep && isPast(new Date(reviewsPublishingDay))
    : false

  if (!reviews?.length) {
    return null
  }

  const getStatusSettings = (status?: Statuses) => {
    switch (status) {
      case Statuses.completed:
      case Statuses.completed_late:
      case Statuses.approved:
        return {
          color: Token.color.foreground,
          title: 'Completed',
        }
      case Statuses.rejected:
        return { color: Token.color.red, title: 'Rejected' }
      case Statuses.expired:
        return { color: Token.color.greyTone50, title: 'Expired' }
      default:
        return { color: Token.color.greyTone50, title: 'Pending' }
    }
  }

  const renderStatusField = (
    item: SingleTimelineEventReviewInterface | GradedRequestInterface,
    userIsReviewer: boolean,
  ) => {
    if (isSkipedStatus) {
      return null
    }

    if (userIsReviewer && item.status === Statuses.completed) {
      const grade = [
        ReviewerRelation.FunctionalManager,
        ReviewerRelation.LineManager,
      ].includes(item.reviewer_relation)
        ? item.reviewer_relation === ReviewerRelation.FunctionalManager
          ? summaryData?.summary?.functional_manager_extra_section
              ?.employee_project_performance?.value
          : summaryData?.summary?.line_manager_extra_section?.employee_project_performance
              ?.value
        : null
      return grade && summaryData?.grade_label_mapping ? (
        <Text variant="body1" color={getColorsAndGrade(grade)?.gradeColor}>
          {summaryData.grade_label_mapping[grade]}
        </Text>
      ) : (
        <Text variant="body1">Completed</Text>
      )
    }
    return (
      <Text variant="body1" color={getStatusSettings(item.status)?.color}>
        {getStatusSettings(item.status)?.title}
      </Text>
    )
  }

  return (
    <Box>
      <VStack>
        {reviews.map(item => {
          const userIsReviewer = item.reviewer?.id === user.id
          return isNewDesign ? (
            <FlexWrap
              key={`${item.reviewer?.id}${item.reviewer_relation}`}
              alignItems="center"
              justifyContent="space-between"
              p="s-8"
              style={{
                cursor: performanceLink && item.reviewer?.id ? 'pointer' : 'default',
              }}
              isLink={!!performanceLink}
              onClick={() => {
                if (performanceLink) {
                  navigateTo(
                    pathToUrl(performanceLink, undefined, {
                      reviewer_id: item.reviewer.id.toString(),
                    }),
                  )
                }
              }}
            >
              <AvatarSnippet
                avatar={item.reviewer?.avatar}
                status={item.status}
                fullName={relationToString(item.reviewer_relation, false)}
                text={
                  isProfileLink && item.reviewer?.id ? (
                    <StyledLink
                      onClick={e => e.stopPropagation()}
                      to={getLocationDescriptor(
                        pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                          id: item.reviewer?.id,
                        }),
                      )}
                    >
                      {chain(item.reviewer?.full_name, `${subText || ''}`)}
                    </StyledLink>
                  ) : (
                    chain(item.reviewer?.full_name, `${subText || ''}`)
                  )
                }
                noItemWrap
              />
              {userIsReviewer && !buttonDisabled && !secondaryActionButton && (
                <ActionButton
                  useIcon={icon}
                  onClick={e => {
                    e.stopPropagation()
                    onClickAddReview(e)
                  }}
                  {...tooltip.getAnchorProps()}
                >
                  {title}
                </ActionButton>
              )}
              {userIsReviewer && !buttonDisabled && secondaryActionButton}
              {(buttonDisabled || !userIsReviewer) && (
                <Flex flexDirection="column">
                  {isObject(item.grade) ? (
                    <Text variant="body1">{item.grade.label}</Text>
                  ) : (
                    renderStatusField(item, userIsReviewer)
                  )}
                </Flex>
              )}
            </FlexWrap>
          ) : (
            <FlexWrap
              key={item.id}
              alignItems="center"
              justifyContent="space-between"
              style={{ cursor: performanceLink ? 'pointer' : 'default' }}
              onClick={() => {
                if (performanceLink) {
                  navigateTo(
                    pathToUrl(performanceLink, {
                      reviewer_id: item.reviewer.id.toString(),
                    }),
                  )
                }
              }}
              p="s-8"
              isLink={!!performanceLink}
            >
              <AvatarSnippet
                avatar={item.reviewer.avatar}
                status={item.status}
                fullName={`${item.reviewer.full_name} (${relationToString(
                  item.reviewer_relation,
                  true,
                )})`}
                noItemWrap
              />
              {userIsReviewer && !buttonDisabled && (
                <ActionButton
                  useIcon={icon}
                  onClick={e => {
                    e.stopPropagation()
                    onClickAddReview(e)
                  }}
                  {...tooltip.getAnchorProps()}
                >
                  {title}
                </ActionButton>
              )}
            </FlexWrap>
          )
        })}
      </VStack>
    </Box>
  )
}
