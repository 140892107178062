import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { formatStagePeriod } from '@src/pages/Forms/ReviewCycle/ReviewCycle/formatters/formatStagePeriod'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EditLink } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/EditLink'
import { Box, Flex } from '@revolut/ui-kit'
import { TimelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { stageToEditLinkLabelMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/constants'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const StagePeriods = ({ cycle, stage }: Props) => {
  const { id } = cycle
  const editPath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.TIMELINE, { id })
  const subStages = TimelineModel.getSubStages(stage)

  return (
    <Flex flexDirection={{ sm: 'column', md: 'row' }}>
      {(subStages.length ? subStages : [stage]).map(period => (
        <Box key={period} pr="s-16" pb="s-16">
          <EditLink
            to={editPath}
            label={stageToEditLinkLabelMap[period]}
            linkText={formatStagePeriod(cycle, period, false) ?? 'No period defined'}
          />
        </Box>
      ))}
    </Flex>
  )
}
