import React from 'react'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import {
  Action,
  DetailsCell,
  Text,
  Group,
  Toggle,
  TransitionCollapse,
  VStack,
} from '@revolut/ui-kit'
import { useEngagementSurveyRounds } from '@src/api/engagement'
import Loader from '@src/components/CommonSC/Loader'
import { SingleRoundItem } from './SurveyRoundItem'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const DEFALT_SURVEYS_DISPLAY_NUM = 4

export const SurveyOverview = ({
  survey,
  refetch,
}: {
  survey: EngagementSurveyInterface
  refetch: VoidFunction
}) => {
  const { data: surveyRoundsResponse, isLoading } = useEngagementSurveyRounds(survey.id)

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Group px="s-8">
        <Toggle>
          {({ state, toggle }) => (
            <>
              <DetailsCell>
                <DetailsCell.Title>
                  <Text variant="h4" color="foreground">
                    {state ? 'All survey runs' : 'Latest survey run'}
                  </Text>
                </DetailsCell.Title>
                <DetailsCell.Content>
                  {surveyRoundsResponse &&
                    surveyRoundsResponse?.count > DEFALT_SURVEYS_DISPLAY_NUM && (
                      <Action onClick={() => toggle()}>
                        {state ? 'Collapse' : 'See all'}
                      </Action>
                    )}
                </DetailsCell.Content>
              </DetailsCell>
              {surveyRoundsResponse?.results.length ? (
                <VStack gap="s-8" px="s-16" pb="s-16">
                  {surveyRoundsResponse.results
                    .slice(0, DEFALT_SURVEYS_DISPLAY_NUM)
                    .map(singleRound => (
                      <SingleRoundItem
                        permissions={survey.field_options.permissions}
                        key={singleRound.id}
                        refetch={refetch}
                        round={singleRound}
                        surveyId={survey.id}
                      />
                    ))}

                  <TransitionCollapse in={state}>
                    <VStack gap="s-8">
                      {surveyRoundsResponse?.results
                        .slice(DEFALT_SURVEYS_DISPLAY_NUM)
                        .map(singleResult => (
                          <SingleRoundItem
                            permissions={survey.field_options.permissions}
                            refetch={refetch}
                            round={singleResult}
                            key={singleResult.id}
                            surveyId={survey.id}
                          />
                        ))}
                    </VStack>
                  </TransitionCollapse>
                </VStack>
              ) : (
                <EmptyTableRaw title="This survey has no results yet" />
              )}
            </>
          )}
        </Toggle>
      </Group>
    </>
  )
}
