import React, { useContext } from 'react'
import { Dropdown, IconName, MoreBar } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useCanViewNominateForPromotion } from '../common'
import { EmployeeButtonProps } from '../types'
import {
  PerformanceContext,
  PerformanceContextValue,
} from '@src/pages/Forms/PromotionNominationForm/common/EligibilityAndPerformance/PerformanceContext'

export const NominateForPromotion = ({
  data,
  isDropdownItem,
  cycleId,
  icon,
  text,
}: EmployeeButtonProps & { icon?: IconName; text?: string }) => {
  const { lastPublishedCycle } = useContext<PerformanceContextValue>(PerformanceContext)
  const canView = useCanViewNominateForPromotion({ data, cycleId })

  const onClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM, {
        employeeId: data.id,
      }),
    )
  }

  if (lastPublishedCycle && canView) {
    if (isDropdownItem) {
      return (
        <Dropdown.Item use="button" useIcon="Rocket" onClick={onClick}>
          Nominate for promotion
        </Dropdown.Item>
      )
    }
    return (
      <MoreBar.Action useIcon={icon || undefined} onClick={onClick}>
        {text || `Nominate for promotion`}
      </MoreBar.Action>
    )
  }
  return null
}
