import { EntityTypes } from '@src/constants/api'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { FilterByInterface } from '@src/interfaces/data'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { TeamInterface } from '@src/interfaces/teams'

export const getCalibrationFilters = (
  entity: OrgEntityInterface | null,
): FilterByInterface[] => {
  if (!entity) {
    return []
  }

  switch (entity.type) {
    case EntityTypes.department:
      return [
        {
          columnName: 'department__id',
          filters: [{ id: entity.data.id, name: entity.data.name! }],
          nonResettable: true,
        },
      ]
    case EntityTypes.team:
    case EntityTypes.teams: {
      const data = entity.data as TeamInterface
      const id = data.department_id || data.department?.id
      return id
        ? [
            {
              columnName: 'deparetment__id',
              filters: [{ id, name: String(id) }],
              nonResettable: true,
            },
          ]
        : []
    }
    case EntityTypes.function:
      return [
        {
          columnName: 'function__id',
          filters: [{ id: entity.data.id, name: entity.data.name! }],
          nonResettable: true,
        },
      ]
    case EntityTypes.role: {
      const data = entity.data as RoleInterface
      return [
        {
          columnName: 'function__id',
          filters: [{ id: data.function.id, name: data.function.name }],
          nonResettable: true,
        },
      ]
    }
    case EntityTypes.specialisation: {
      const data = entity.data as SpecialisationInterface
      const functionId = data.role.function_id

      return functionId
        ? [
            {
              columnName: 'function__id',
              filters: [{ id: functionId, name: String(functionId) }],
              nonResettable: true,
            },
          ]
        : []
    }
    case EntityTypes.employees:
    case EntityTypes.employee:
      return [
        {
          columnName: 'employee__id',
          filters: [{ id: entity.data.id, name: entity.data.name! }],
          nonResettable: true,
        },
      ]
    default:
      return []
  }
}
