import { useState } from 'react'
import { isArray } from 'lodash'

import { ImportDataCategory } from '@src/interfaces/bulkDataImport'
import { getImportFileTemplate, importFile } from '@src/api/bulkDataImport'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { handleError } from '@src/api'

import { saveFile } from '.'
import { useQuery } from './queryParamsHooks'
import { IdAndName } from '@src/interfaces'

type UseTemplateFileDownloadParams = {
  endpoint: string
  name: string
  params?: Record<string, string>
}
export const useTemplateFileDownload = ({
  endpoint,
  name,
  params,
}: UseTemplateFileDownloadParams) => {
  const [pending, setPending] = useState(false)

  return {
    isDownloadPending: pending,
    onDownloadTemplate: async () => {
      setPending(true)
      try {
        const { data, fileName } = await getImportFileTemplate(endpoint, params, name)
        saveFile(data, fileName || name)
      } finally {
        setPending(false)
      }
    },
  }
}

type UseTemplateFileUploadParams = {
  endpoint: string
  category: ImportDataCategory
  navigateOnSuccessPath: string
  params?: Record<string, string | boolean | IdAndName<string>[]>
}
export const useTemplateFileUpload = ({
  endpoint,
  category,
  navigateOnSuccessPath,
  params,
}: UseTemplateFileUploadParams) => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<string>()

  const { query } = useQuery()

  return {
    isUploadPending: pending,
    uploadTemplateError: error,
    onUploadTemplate: async (file: File | File[] | null) => {
      if (file) {
        setPending(true)
        setError(undefined)

        try {
          const response = await importFile(
            isArray(file) ? file[0] : file,
            endpoint,
            category,
            params,
          )
          navigateReplace(
            pathToUrl(navigateOnSuccessPath, { id: response.data.id }, query),
          )
        } catch (e) {
          if (e?.response?.data?.file) {
            setError(e?.response?.data?.file as string)
          } else {
            handleError(e)
          }
          setPending(false)
        }
      }
    },
  }
}
