import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { FormattedMessage } from 'react-intl'
import { isHTMLFieldEmpty } from '@src/utils/string'
import { formattedHTMLContentCSS } from '@src/utils/styles'
import styled from 'styled-components'

const StyledText = styled(Text)`
  p {
    margin: 0 !important;
  }
`

type Props = {
  data?: string
}

const HeadCard = ({ data }: Props) => {
  if (isHTMLFieldEmpty(data)) {
    return null
  }

  return (
    <ActionWidget
      title={
        <FormattedMessage
          id="recruitment.interviewTemplate.purpose"
          defaultMessage="Purpose"
        />
      }
      avatarColor={Token.color.blue}
    >
      <StyledText
        css={formattedHTMLContentCSS}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data!) }}
      />
    </ActionWidget>
  )
}

export default HeadCard
