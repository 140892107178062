import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect, useLape } from 'lape'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import * as RoleRole from '@src/pages/Forms/RoleForm/General'
import * as Preview from '@src/pages/Forms/RoleForm/Preview/Preview'
import * as RoleCompetencyMatrix from '@src/pages/Forms/RoleForm/CompetencyMatrix/CompetencyMatrix'
import * as RoleHiringProcess from '@src/pages/Forms/RoleForm/HiringProcess/HiringProcess'
import KpiTab from '@src/pages/Forms/RoleForm/Kpi/KPI'
import Talent from '@src/pages/Forms/RoleForm/Talent/Talent'
import { RoleInterface } from '@src/interfaces/roles'
import * as Requisitions from '@src/pages/Forms/RoleForm/Requisitions/Requisitions'
import { TalentStatsInterface } from '@src/interfaces/functions'
import {
  rolesRequestsNew,
  roleTalentTabStats,
  silentDeleteRole,
  useUpdateRole,
} from '@src/api/roles'
import { validator as generalValidator } from '@src/pages/Forms/RoleForm/General/General'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, chain, Flex, Icon } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import ValidationPre from '@src/features/TabBarNavigation/ValidationPre'
import Specialisations from '@src/pages/Forms/RoleForm/Specialisations/Specialisations'
import { PageWrapper } from '@components/Page/Page'
import { Statuses } from '@src/interfaces'
import upperFirst from 'lodash/upperFirst'
import { Status } from '@components/CommonSC/General'
import { useGetMatrixValidatorAndSenioritiesForRole } from '@src/features/CompetencyMatrixTable/utils'
import { getTalentStatsQuickSummary } from '@src/pages/Forms/CommonTalentTab/TalentStats'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { Engagement } from './Engagement/Engagement'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  useGetHiringProcessSettings,
  useGetOrganisationSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { EntityTypes, API } from '@src/constants/api'
import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import * as yup from 'yup'
import { AnalyticsDashboards } from './AnalyticsDashboards/AnalyticsDashboards'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import SettingsButtons, {
  ApproveButton,
  ArchiveButton,
  CopyButton,
  RejectButton,
} from '@src/features/SettingsButtons'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import { goBack } from '@src/actions/RouterActions'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'

const competencyMatrixValidator = {
  name: yup.string().required(),
  role_goals: yup.array(),
  role_description: yup.string().nullable(),
}

const Roles = () => {
  const params = useParams<{ id?: string }>()
  const permissions = useSelector(selectPermissions)
  const { values, valid, dirty, isSubmitting, initialValues } =
    useLapeContext<RoleInterface>()
  const entity = useMemo<OrgEntityInterface | undefined>(
    () => (values.id ? { type: EntityTypes.role, data: values } : undefined),
    [values.id],
  )

  const { data: settings } = useGetPerformanceSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()

  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const {
    settings: { engagement_enabled, requisitions_enabled, candidates_enabled },
  } = useGlobalSettings()

  const kpiEnabled = !!settings?.enable_function_role_specialisation_kpis

  const state = useLape<{ loading: boolean; stats?: TalentStatsInterface }>({
    loading: !!params.id,
    stats: undefined,
  })
  const backUrl = ROUTES.ORGANISATION.ROLES.ROLES

  const { matrixValidated, seniorities } =
    useGetMatrixValidatorAndSenioritiesForRole(values)

  useEffect(() => {
    if (params.id) {
      roleTalentTabStats(params.id).then(res => {
        state.stats = res.data
        state.loading = false
      })
    }
  }, [])

  if (state.loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const isNewInstance = !values?.id || values.status === Statuses.draft
  const isHiringProcessFilled = matrixValidated && !!values?.hiring_process_rounds?.length

  const tabs = [
    {
      title: 'General',
      path: ROUTES.FORMS.ROLE.GENERAL,
      to: pathToUrl(ROUTES.FORMS.ROLE.GENERAL, params),
      preTitle: <ValidationPre isVisible={isNewInstance} isValid={valid} />,
      component: RoleRole.default,
      canView: isNewInstance,
    },
    {
      title: 'Preview',
      path: ROUTES.FORMS.ROLE.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, params),
      component: Preview.default,
      canView: !isNewInstance,
    },
    {
      title: 'KPI',
      path: ROUTES.FORMS.ROLE.KPI,
      to: pathToUrl(ROUTES.FORMS.ROLE.KPI, params),
      component: KpiTab,
      canView: kpiEnabled && !isNewInstance && !goalsEnabled,
    },
    {
      title: 'Competency matrix',
      path: ROUTES.FORMS.ROLE.COMPETENCY_MATRIX,
      to: pathToUrl(ROUTES.FORMS.ROLE.COMPETENCY_MATRIX, params),
      disabled: isNewInstance && !valid,
      preTitle: (
        <ValidationPre isVisible={isNewInstance && valid} isValid={matrixValidated} />
      ),
      component: RoleCompetencyMatrix.default,
    },
    {
      title: 'Hiring process',
      path: ROUTES.FORMS.ROLE.HIRING_PROCESS,
      disabled: isNewInstance && !matrixValidated,
      to: pathToUrl(ROUTES.FORMS.ROLE.HIRING_PROCESS, params),
      preTitle: (
        <ValidationPre
          isVisible={isNewInstance && matrixValidated}
          isValid={isHiringProcessFilled}
        />
      ),
      component: RoleHiringProcess.default,
      canView:
        candidates_enabled && hiringProcessSettings?.enable_role_level_process_definition,
    },
    {
      title: 'Preview',
      path: ROUTES.FORMS.ROLE.PREVIEW,
      disabled: isNewInstance && !isHiringProcessFilled,
      to: pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, params),
      component: Preview.default,
      canView: isNewInstance,
    },
    {
      title: 'Specialisations',
      path: ROUTES.FORMS.ROLE.SPECIALISATIONS,
      to: pathToUrl(ROUTES.FORMS.ROLE.SPECIALISATIONS, params),
      component: Specialisations,
      canView: !isNewInstance,
    },
    {
      key: 'analytics',
      title: 'Analytics',
      icon: <Icon name="BarChart" size={15} />,
      path: ROUTES.FORMS.ROLE.ANALYTICS_DASHBOARDS,
      to: pathToUrl(ROUTES.FORMS.ROLE.ANALYTICS_DASHBOARDS, params),
      component: AnalyticsDashboards,
      canView: !isNewInstance,
      quickSummary: <QuickSummaryCount count={values.dashboard_count} />,
    },
    {
      title: 'Talent',
      path: ROUTES.FORMS.ROLE.TALENT.ANY,
      to: pathToUrl(ROUTES.FORMS.ROLE.TALENT.ANY, params),
      quickSummary: getTalentStatsQuickSummary({
        headcount: values?.headcount,
        nips: state.stats?.nips,
      }),
      component: Talent,
      canView: !isNewInstance,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.ROLE.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.ROLE.REQUISITIONS, params),
      quickSummary: <QuickSummaryCount count={values.requisition_headcount} />,
      component: Requisitions.default,
      canView: !isNewInstance && requisitions_enabled,
    },
    {
      title: 'Engagement',
      path: ROUTES.FORMS.ROLE.ENGAGEMENT.ANY,
      to: pathToUrl(ROUTES.FORMS.ROLE.ENGAGEMENT.CATEGORIES, params),
      component: Engagement,
      canView:
        engagement_enabled &&
        !isNewInstance &&
        permissions.includes(PermissionTypes.ViewEngagementTabs),
    },
  ]

  const filteredTabs = tabs.filter(organisationSubtab => {
    if (organisationSubtab.canView === undefined) {
      return true
    }

    return organisationSubtab.canView
  })

  const showStatus =
    !!values.status &&
    (!!organisationSettings?.enable_roles_approvals ||
      values.status === Statuses.archived)

  const approvalsEnabled = !!organisationSettings?.enable_roles_approvals

  const isNew = !values.id || values.status === Statuses.draft

  const rolePermissions = values.field_options.permissions || []
  const allowEdit = rolePermissions?.includes(PermissionTypes.ChangeRoles)
  const allowDelete = rolePermissions?.includes(PermissionTypes.DeleteRoles)
  const allowArchive = rolePermissions.includes(PermissionTypes.ArchiveRoles)
  const allowApprove = rolePermissions.includes(PermissionTypes.ApproveRoles)
  const allowCopy = rolePermissions.includes(PermissionTypes.AddRoles)

  const showActionsBar =
    !isNew &&
    [allowEdit, allowDelete, allowArchive, allowCopy, allowApprove].some(Boolean)

  return (
    <OrgEntityProvider entity={entity}>
      <PageWrapper>
        <PageHeader
          title={
            <PageHeader.Title
              title={chain(
                values.name || 'New Role',
                showStatus && (
                  <Status status={values.status}>{upperFirst(values.status)}</Status>
                ),
              )}
              avatar={
                <EntityAvatar
                  data={values}
                  defaultIcon={FUNCTION_DEFAULT_ICON}
                  api={useUpdateRole}
                  apiUrl={API.ROLES}
                  canEdit={allowEdit}
                  onSuccess={data => {
                    values.icon = data?.icon || null
                    values.avatar = data?.avatar || null
                  }}
                />
              }
              labels={
                <PageHeader.LabelsBar>
                  <PageHeader.Label useTag icon="RepairTool">
                    Role
                  </PageHeader.Label>
                  {values.role_manager && (
                    <PageHeader.Label
                      icon="Profile"
                      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, {
                        id: values.role_manager.id,
                      })}
                    >
                      {values.role_manager.display_name}
                    </PageHeader.Label>
                  )}
                </PageHeader.LabelsBar>
              }
              actions={
                showActionsBar && (
                  <SettingsButtons>
                    {approvalsEnabled && allowApprove ? (
                      <>
                        <ApproveButton
                          isVisible={initialValues.status === Statuses.pending}
                          notification={{
                            updateMsg: 'Role successfully approved.',
                          }}
                        />
                        <RejectButton
                          isVisible={initialValues.status === Statuses.pending}
                          notification={{
                            updateMsg: 'Role successfully rejected.',
                          }}
                          dialog={{
                            title: 'Reasons for rejection',
                            placeholder: 'Please provide reasons for rejection',
                            fieldName: 'description',
                          }}
                        />
                      </>
                    ) : null}
                    <CopyButton
                      globalPermissions={[PermissionTypes.AddRoles]}
                      isVisible={allowCopy && !!values.id}
                      cleanFields={['cv_criterias', 'hiring_process_rounds']}
                      afterSubmitUrl={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
                    />
                    <LapeDeleteOrgUnitButton
                      onAfterDelete={() => goBack(ROUTES.ORGANISATION.ROLES.ROLES)}
                      deleteApi={silentDeleteRole}
                      prefix="role"
                      displayName="role"
                    />
                    <ArchiveButton isVisible={allowArchive && !!values.id} showDialog />
                  </SettingsButtons>
                )
              }
            />
          }
          backUrl={backUrl}
        >
          <Box pb="s-16" maxWidth="100vw">
            <TabBarNavigation
              isDisabled={isNewInstance && dirty}
              disabledTooltip="Please save your changes."
              key={`${isSubmitting}`}
              tabs={filteredTabs}
            />
          </Box>
        </PageHeader>
        <Flex flexDirection="column" minHeight={0}>
          <Switch>
            {filteredTabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component
                  data={values}
                  matrixValidated={matrixValidated}
                  seniorities={seniorities}
                />
              </Route>
            ))}
            {filteredTabs[0]?.path ? (
              <InternalRedirect to={filteredTabs[0].path} />
            ) : null}
          </Switch>
        </Flex>
      </PageWrapper>
    </OrgEntityProvider>
  )
}

const PageRole = () => {
  const matrixRouteMatch = useRouteMatch(ROUTES.FORMS.ROLE.COMPETENCY_MATRIX)
  return (
    <Form
      api={rolesRequestsNew}
      validator={matrixRouteMatch ? competencyMatrixValidator : generalValidator}
    >
      <Roles />
    </Form>
  )
}
export default connect(PageRole)
