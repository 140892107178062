import React from 'react'

import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import { useQuery } from '@src/utils/queryParamsHooks'

export const IAPRedirect = () => {
  const { query } = useQuery<{ redirect_url?: string }>()

  return (
    <InternalRedirect
      to={query.redirect_url ? decodeURIComponent(query.redirect_url) : ROUTES.MAIN}
    />
  )
}
