import React, { useState } from 'react'
import { MoreBar, MoreBarSkeleton } from '@revolut/ui-kit'
import { ReviewResult } from '@src/interfaces/CRM'
import {
  createReviewResult,
  invalidateGetReviewResult,
  updateReviewResult,
  useGetReviewResult,
} from '@src/api/CRM'
import {
  useReviewLocationState,
  useReviewNavigation,
} from '@src/pages/Forms/Candidate/CRMReview/utils'
import { useQueryClient } from 'react-query'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const useReviewResult = () => {
  const { reviewId, interviewRoundId } = useReviewLocationState()
  const { data, ...ctx } = useGetReviewResult(reviewId, interviewRoundId)
  const queryClent = useQueryClient()
  const invalidateReviewResult = () => {
    invalidateGetReviewResult(queryClent, reviewId)
  }
  return {
    ...ctx,
    data: data?.results?.[0],
    invalidateReviewResult,
  }
}

type CRMReviewActionsProps = {
  interviewRounds: number[]
}

export const CRMReviewActions = ({ interviewRounds }: CRMReviewActionsProps) => {
  const { reviewId, interviewRoundId } = useReviewLocationState()
  const { handleNavigateToNext } = useReviewNavigation(interviewRounds)
  const { data, isLoading: loadingReview, invalidateReviewResult } = useReviewResult()
  const [loading, setLoading] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const showErrorPopup = (title: string, description: string) => {
    showStatusPopup({
      status: 'error',
      title,
      description,
    })
  }
  const handleReview = async (reviewResult: ReviewResult) => {
    setLoading(true)
    try {
      if (data) {
        await updateReviewResult(reviewId, interviewRoundId, data.id, reviewResult)
      } else {
        await createReviewResult(reviewId, interviewRoundId, reviewResult)
      }
      invalidateReviewResult()
      await handleNavigateToNext()
    } catch (e) {
      showErrorPopup(
        `There was a error ${
          reviewResult === 'skipped' ? 'skipping' : 'qualifying'
        } candidate`,
        getStringMessageFromError(e),
      )
    } finally {
      setLoading(false)
    }
  }
  if (loadingReview) {
    return <MoreBarSkeleton />
  }
  const skipped = data?.review_result?.id === 'skipped'
  const qualified = data?.review_result?.id === 'qualified'
  return (
    <MoreBar>
      <MoreBar.Action
        disabled={skipped || loading}
        pending={loading}
        onClick={() => {
          handleReview('skipped')
        }}
      >
        Skip
      </MoreBar.Action>
      <MoreBar.Action
        disabled={qualified || loading}
        pending={loading}
        onClick={() => {
          handleReview('qualified')
        }}
      >
        Qualify
      </MoreBar.Action>
    </MoreBar>
  )
}
