import { useEffect } from 'react'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'

export const useGlobalSearchShortcut = () => {
  const handleOpenLinkInterface = (e: KeyboardEvent) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    if (globalSearchState.open && e.code === 'Escape') {
      globalSearchState.open = false
    }
    if (
      (e.composed && !isUsingWindows && e.metaKey && e.code === 'KeyK') ||
      (isUsingWindows && e.ctrlKey && e.code === 'KeyK')
    ) {
      globalSearchState.open = !globalSearchState.open
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleOpenLinkInterface)

    return () => {
      document.removeEventListener('keydown', handleOpenLinkInterface)
    }
  }, [])
}
