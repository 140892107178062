import React from 'react'
import { StatusWidget } from '@revolut/ui-kit'
import { AxiosError } from 'axios'

type Props = {
  error: AxiosError
  onReset?: () => void
}

export const TableError = ({ error, onReset }: Props) => {
  const errorCode = error.response?.status
  return (
    <StatusWidget>
      <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images-v2/3D018.png" />
      <StatusWidget.Title>Sorry, the table failed to load</StatusWidget.Title>
      <StatusWidget.Description>
        {errorCode === 403
          ? 'You do not have access to this table'
          : 'The filters you have used are incorrect or too long. Please try changing them.'}
      </StatusWidget.Description>
      {onReset && (
        <StatusWidget.Action onClick={onReset}>Reset filters</StatusWidget.Action>
      )}
    </StatusWidget>
  )
}
