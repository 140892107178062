import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'
import {
  ReviewCycleSubStage,
  TimelineModel,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { isSameDay } from 'date-fns'
import { formatDate, formatPeriod } from '@src/utils/format'

export const formatStagePeriod = (
  cycle: ReviewCyclesInterface,
  stage: ReviewCycleStage | ReviewCycleSubStage,
  hideYear: boolean = true,
): string | undefined => {
  if (cycleModel.isManual(cycle)) {
    return undefined
  }

  const startDay = TimelineModel.getPeriodStartDay(cycle, stage)
  const endDay = TimelineModel.getPeriodEndDay(cycle, stage)

  if (!startDay || !endDay) {
    return undefined
  }

  if (isSameDay(startDay, endDay)) {
    return formatDate(endDay)
  }

  return formatPeriod(startDay, endDay, { hideYear })
}
