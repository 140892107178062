import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { GoalsSettingsAll } from './All'
import { GoalsSettingsTargetTypes } from './TargetTypes'
import { GoalsSettingsApprovalProcesses } from './ApprovalProcesses'
import { GoalsSettingsRoadmaps } from './Roadmaps'
import { GoalsSettingsRules } from './Rules'
import { GoalsSettingsTemplates } from './Templates'
import { ROUTES } from '@src/constants/routes'
import { KpiTemplateForm } from '@src/pages/Forms/KpiTemplates/KpiTemplate/General'
import RuleForm from '@src/pages/Forms/KpiTemplates/KpiRule/General'
import JiraIntegration from '@src/pages/Hub/Integrations/Jira'
import { LookerIntegration } from '@src/pages/Hub/Integrations/Looker'
import { TableauIntegration } from '@src/pages/Hub/Integrations/Tableau'
import { ClickupIntegration } from '@src/pages/Hub/Integrations/Clickup'

export interface GoalsSettingsProps {
  allRoute: string
  targetTypesRoute: string
  approvalProcessRoute: string
  roadmapsRoute: string
  templatesRoute: string
  rulesRoute: string
  jiraIntegrationRoute: string
  tableauIntegrationRoute: string
  lookerIntegrationRoute: string
  clickUpIntegrationRoute: string
  backUrl: string
  isOnboarding: boolean
}

export const GoalsSettings = (props: GoalsSettingsProps) => {
  return (
    <Switch>
      <Route path={props.allRoute}>
        <GoalsSettingsAll {...props} />
      </Route>
      <Route path={props.targetTypesRoute}>
        <GoalsSettingsTargetTypes {...props} />
      </Route>
      <Route path={props.approvalProcessRoute}>
        <GoalsSettingsApprovalProcesses {...props} />
      </Route>
      <Route path={props.roadmapsRoute}>
        <GoalsSettingsRoadmaps {...props} />
      </Route>
      <Route exact path={props.rulesRoute}>
        <GoalsSettingsRules {...props} />
      </Route>
      <Route exact path={props.templatesRoute}>
        <GoalsSettingsTemplates {...props} />
      </Route>
      <Route
        exact
        path={[
          ROUTES.SETTINGS.GOALS.TEMPLATES.NEW,
          ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.TEMPLATES.NEW,
          ROUTES.SETTINGS.GOALS.TEMPLATES.EDIT,
          ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.TEMPLATES.EDIT,
        ]}
      >
        <KpiTemplateForm variant="goal" />
      </Route>
      <Route
        exact
        path={[
          ROUTES.SETTINGS.GOALS.RULES.NEW,
          ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.RULES.NEW,
          ROUTES.SETTINGS.GOALS.RULES.EDIT,
          ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.RULES.EDIT,
        ]}
      >
        <RuleForm variant="goal" />
      </Route>
      <Route exact path={props.jiraIntegrationRoute}>
        <JiraIntegration />
      </Route>
      <Route exact path={props.tableauIntegrationRoute}>
        <TableauIntegration />
      </Route>
      <Route exact path={props.lookerIntegrationRoute}>
        <LookerIntegration />
      </Route>
      <Route exact path={props.clickUpIntegrationRoute}>
        <ClickupIntegration />
      </Route>
    </Switch>
  )
}
