import React, { useState } from 'react'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackStatus,
} from '@src/interfaces/interviewTool'
import { Dropdown, DropdownItemProps } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import StagesTableActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StagesTableActions'
import RejectFeedbackButton from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/RejectFeedbackButton'

type Props = {
  feedback: InterviewFeedbackInterface
  canCancel: boolean
  canAddFeedback: boolean
  onRefresh?: (hard?: boolean) => void
}

const FeedbackActions = ({ canCancel, feedback, onRefresh, canAddFeedback }: Props) => {
  const user = useSelector(selectUser)
  const [isOpen, setOpen] = useState(false)

  const actions = []

  const itemProps: DropdownItemProps = {
    use: 'button',
  }

  if (
    canCancel &&
    (feedback.status === InterviewFeedbackStatus.pending ||
      feedback.status === InterviewFeedbackStatus.draft)
  ) {
    actions.push(
      <RejectFeedbackButton
        key="reject"
        itemProps={itemProps}
        id={feedback.id}
        onRefresh={onRefresh}
        menuType="dropdown"
      />,
    )
  }

  if (
    canAddFeedback &&
    user.id === feedback.interviewer?.id &&
    (feedback.status === InterviewFeedbackStatus.completed ||
      feedback.status === InterviewFeedbackStatus.pending ||
      feedback.status === InterviewFeedbackStatus.draft)
  ) {
    actions.push(
      <Dropdown.Item
        key="add"
        {...itemProps}
        onClick={() => {
          setOpen(false)
          window.open(
            pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
              id: feedback.id,
            }),
            '_blank',
          )
        }}
      >
        {feedback.status === InterviewFeedbackStatus.pending ||
        feedback.status === InterviewFeedbackStatus.draft
          ? 'Add feedback'
          : 'Edit feedback'}
      </Dropdown.Item>,
    )
  }

  if (!actions.length) {
    return null
  }

  return (
    <>
      <StagesTableActions isOpen={isOpen} setOpen={setOpen}>
        {actions}
      </StagesTableActions>
    </>
  )
}

export default FeedbackActions
