import React, { forwardRef } from 'react'
import { Flex, H4, Icon, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

const StyledIcon = styled(Icon)`
  &:hover {
    color: ${Token.color.greyTone50};
  }
`

type Props = {
  open: boolean
  onClick: () => void
  children: React.ReactNode
}
const DropdownStatAction = forwardRef<HTMLButtonElement, Props>(
  ({ children, open, onClick, ...props }, ref) => (
    <button type="button" onClick={onClick} aria-label="open menu" ref={ref} {...props}>
      <Flex alignItems="center" color="foreground" width={240}>
        <H4 use="span" fontWeight="bold">
          {children}
        </H4>
        <StyledIcon
          color={Token.color.foreground}
          name={open ? 'ChevronUp' : 'ChevronDown'}
          size={24}
        />
      </Flex>
    </button>
  ),
)

export default DropdownStatAction
