import React, { useContext } from 'react'
import { TaggedUsersMap } from '@src/interfaces/chat'
import ChatTextEditor, {
  ChatTextEditorVariant,
} from '@components/Chat/ChatTextEditor/ChatTextEditor'
import { NotificationsContext } from '@src/apps/General/Todo/common'

interface Props {
  text: string
  taggedEmployees?: TaggedUsersMap
}

const ChatMessagePreview = ({ text, ...props }: Props) => {
  const context = useContext(NotificationsContext)
  const taggedEmployees = props.taggedEmployees || context.taggedEmployees

  return (
    <ChatTextEditor
      variant={ChatTextEditorVariant.InTable}
      onSubmit={() => Promise.resolve()}
      defaultText={text}
      taggedEmployees={taggedEmployees}
      readonly
    />
  )
}

export default ChatMessagePreview
