import { Header, Popup, Button, StatusPopup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { setEmployeeContractEndDate } from '@src/api/employees'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'

type Props = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  id: string
  employeeId: string
}

export const Terminate = ({ open, setOpen, id, employeeId }: Props) => {
  const [terminationDate, setTerminationDate] = useState<Date | null>(null)
  const [successOpen, setSuccessOpen] = useState(false)

  const onTerminate = async () => {
    if (!terminationDate) {
      return
    }
    await setEmployeeContractEndDate({
      id,
      employeeId,
      endDate: terminationDate,
    })
    setOpen(false)
    setSuccessOpen(true)
  }

  return (
    <>
      <Popup open={open} onClose={() => setOpen(false)} variant="bottom-sheet">
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Terminate Contract</Header.Title>
        </Header>
        <DatePickerInput
          disabledDays={{ before: new Date() }}
          value={terminationDate}
          onChange={setTerminationDate}
          label="End date"
        />
        <Popup.Actions>
          <Button variant="negative" onClick={onTerminate} disabled={!terminationDate}>
            Terminate
          </Button>
        </Popup.Actions>
      </Popup>
      <StatusPopup
        variant="success"
        open={successOpen}
        onClose={async () => {
          setSuccessOpen(false)
          navigateTo(
            `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, {
              id: employeeId,
            })}#contracts`,
          )
        }}
        // @ts-ignore prop exists
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Contract was scheduled for termination</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
