import type { FC } from 'react'
import React, { ReactNode, useState } from 'react'

import {
  BarProps,
  Line,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Text,
  LabelList,
  Rectangle,
  Tooltip,
  Legend,
  Cell,
} from 'recharts'
import truncate from 'lodash/truncate'
import { Absolute, Text as UIKitText, Token, Relative } from '@revolut/ui-kit'

import { ChartEmptyResults } from './ChartEmptyResults'

import { ChartSkeleton } from './ChartSkeleton'
import { ChartTooltip } from './components/ChartTooltip'
import {
  getActiveShapeColor,
  getMainShapeColor,
  getProjectedShapeColor,
  getColorByIndex,
  formatIfNumber,
} from './helpers'
import { ResponsiveContainer } from './ResponsiveContainer'
import type {
  DataKey,
  HorizontalBarDataItem,
  TAxisTooltip,
  XAxis as XAxisProps,
  YAxis as YAxisProps,
} from './types'
import { DefaultTooltip } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultTooltip'
import {
  LINE_STROKE_WIDTH,
  DOT_RADIUS,
  LINE_ANIMATION_DURATION,
} from '@src/pages/Forms/QueryForm/components/Charts/constants'
import { DefaultLegend } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultLegend'

const DEFAULT_HEIGHT = 300
const Y_AXIS_WIDTH = 120
const BAR_RADIUS = 3
const X_OFFSET = 10
const Y_OFFSET = 4

type YAxisLeftTickProps<T> = {
  y: number
  payload: { index: number }
  data: T[]
  width: number
}

type XAxisTickProps = {
  x: number
  y: number
  width: number
  payload: DataKey<ReactNode>
}

const YAxisLeftTick = <T extends HorizontalBarDataItem>({
  y,
  payload: { index },
  data,
  width,
}: YAxisLeftTickProps<T>) => {
  const parsedValue = truncate(data[index]?.label || 'No name', { length: 26 })

  return (
    <Text
      x={0}
      y={y}
      textAnchor="start"
      verticalAnchor="middle"
      fill={Token.color.greyTone50}
      fontSize={12}
      width={width}
    >
      {parsedValue}
    </Text>
  )
}

const XAxisTick = ({ x, y, width, payload }: XAxisTickProps) => {
  if (payload?.value) {
    return (
      <Text
        x={x}
        y={y}
        fill={Token.color.greyTone50}
        width={width}
        fontSize={10}
        textAnchor="middle"
        verticalAnchor="start"
      >
        {payload.value}
      </Text>
    )
  }

  return null
}

interface CustomizedLabelProps {
  x?: number | string
  y?: number | string
  width?: number | string
  value?: number | string
}

interface CustomizedVerticalLabelProps extends CustomizedLabelProps {
  height?: number | string
}

const CustomizedLabelVertical = ({
  x,
  y,
  width,
  height,
  value,
}: CustomizedVerticalLabelProps) => {
  if (
    typeof x !== 'number' ||
    typeof width !== 'number' ||
    typeof height !== 'number' ||
    typeof y !== 'number'
  ) {
    return null
  }
  const xPos = x + width + X_OFFSET
  const YPos = Y_OFFSET + y + height / 2

  return (
    <Text x={xPos} y={YPos} fill={Token.color.greyTone50} fontSize={12}>
      {value}
    </Text>
  )
}

const CustomizedLabelHorizontal = ({
  x,
  y,
  width,
  value,
}: CustomizedLabelProps): ReactNode => {
  if (typeof x !== 'number' || typeof width !== 'number' || typeof y !== 'number') {
    return null
  }
  const xPos = x + width / 2
  const YPos = y - Y_OFFSET

  return (
    <Text
      x={xPos}
      y={YPos}
      fill={Token.color.greyTone50}
      fontSize={12}
      textAnchor="middle"
    >
      {value}
    </Text>
  )
}

const AxisTooltip = <T extends HorizontalBarDataItem>({
  tooltip,
  data,
}: {
  tooltip: TAxisTooltip | null
  data: T[]
}) => {
  const item = typeof tooltip?.index === 'number' ? data[tooltip.index] : undefined

  if (tooltip?.index === undefined || !item || !item.label.includes('...')) {
    return null
  }

  return (
    <Absolute
      left={0}
      top={tooltip.coordinate + 10}
      backgroundColor={Token.color.foreground}
      borderRadius={10}
      px="s-8"
      py="s-4"
      mr="s-8"
      zIndex={1}
    >
      <UIKitText variant="caption" color={Token.color.background}>
        {item.tooltip}
      </UIKitText>
    </Absolute>
  )
}

export type HorizontalBarChartProps<T, K> = {
  dataKeys: DataKey<K>[]
  data: T[]
  height?: number
  yAxisWidth?: number
  isLoading: boolean
  barOptions?: Omit<BarProps, 'dataKey' | 'ref'>
  tooltip?: FC<T>
  layout?: 'horizontal' | 'vertical'
  lineKey?: string
  isStacked?: boolean
  hideLabelList?: boolean
  projectedDataIdx?: number[]
  xAxis?: XAxisProps
  yAxis?: YAxisProps
  customValueAccessor?: (
    entry: T,
    dataKey: string,
    dataKeys: { value: unknown }[],
  ) => void
}

const valueAccessor = (
  entry: object,
  dataKey: string,
  dataKeys: { value: unknown }[],
) => {
  let sum = 0
  let lastKey

  dataKeys.forEach(key => {
    const keyValue = String(key.value)
    if (keyValue in entry) {
      sum += Number(entry[keyValue as keyof typeof entry]) // we validate that keyValue exists in entry above
      lastKey = String(key.value)
    }
  })

  if (dataKey !== lastKey) {
    return null
  }

  return formatIfNumber(sum)
}

export const BarChart = <T extends HorizontalBarDataItem, K>({
  height = DEFAULT_HEIGHT,
  yAxisWidth = Y_AXIS_WIDTH,
  data,
  dataKeys,
  isLoading,
  barOptions,
  tooltip,
  layout = 'horizontal',
  lineKey,
  isStacked = false,
  hideLabelList = false,
  projectedDataIdx = [],
  xAxis,
  yAxis,
  customValueAccessor,
}: HorizontalBarChartProps<T, K>) => {
  const [axisTooltip, setAxisTooltip] = useState<TAxisTooltip | null>(null)

  if (isLoading) {
    return <ChartSkeleton height={height} />
  }

  if (!data.length) {
    return <ChartEmptyResults height={height} />
  }

  const lineDataKey = dataKeys.filter(dataKey => dataKey.value === lineKey)[0]

  return (
    <Relative width="100%" height="100%">
      <AxisTooltip tooltip={axisTooltip} data={data} />

      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          layout={layout}
          margin={{ top: 16, right: 40, bottom: 5, left: 5 }}
        >
          {layout === 'vertical' ? (
            <>
              <XAxis hide axisLine={false} type="number" {...xAxis} />
              <YAxis
                yAxisId={0}
                dataKey="label"
                type="category"
                axisLine={false}
                tickLine={false}
                width={yAxisWidth}
                tick={props => <YAxisLeftTick {...props} data={data} />}
                onMouseEnter={params => setAxisTooltip(params as unknown as TAxisTooltip)}
                onMouseLeave={() => setAxisTooltip(null)}
                {...yAxis}
              />
            </>
          ) : (
            <>
              <XAxis
                dataKey="label"
                axisLine={{ color: Token.color.greyTone20, strokeDasharray: 3 }}
                tick={props => <XAxisTick {...props} />}
                tickLine={false}
                tickMargin={8}
                {...xAxis}
              />
              <YAxis
                type="number"
                axisLine={false}
                tickLine={false}
                allowDecimals={false}
                tick={{ color: Token.color.greyTone50, fontSize: 12 }}
                {...yAxis}
              />
            </>
          )}

          {dataKeys
            .filter(dataKey => dataKey.value !== lineKey)
            .map((dataKey, index) => (
              <Bar
                key={dataKey.id}
                dataKey={String(dataKey.value)}
                fill={getMainShapeColor(
                  dataKey.color || Token.colorChannel[getColorByIndex(index)],
                )}
                activeBar={
                  <Rectangle
                    fill={getActiveShapeColor(
                      dataKey.color || Token.colorChannel[getColorByIndex(index)],
                    )}
                  />
                }
                onClick={dataKey.onClick}
                radius={!isStacked ? BAR_RADIUS : undefined}
                stackId={isStacked ? 'stack' : undefined}
                {...barOptions}
              >
                {hideLabelList ? null : isStacked ? (
                  <LabelList
                    content={
                      layout === 'horizontal'
                        ? CustomizedLabelHorizontal
                        : CustomizedLabelVertical
                    }
                    position={layout === 'horizontal' ? 'top' : 'right'}
                    valueAccessor={(entry: T) =>
                      (customValueAccessor || valueAccessor)(
                        entry,
                        String(dataKey.value),
                        dataKeys,
                      )
                    }
                  />
                ) : (
                  <LabelList
                    dataKey={String(dataKey.value)}
                    content={
                      layout === 'horizontal'
                        ? CustomizedLabelHorizontal
                        : CustomizedLabelVertical
                    }
                    position={layout === 'horizontal' ? 'top' : 'right'}
                  />
                )}

                {projectedDataIdx.length &&
                  data.map((_, i) => {
                    const isProjected = projectedDataIdx.includes(i)
                    const fill = isProjected ? getProjectedShapeColor : getMainShapeColor
                    const strokeDasharray = isProjected ? 5 : 0

                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={fill(
                          dataKey.color || Token.colorChannel[getColorByIndex(index)],
                        )}
                        strokeDasharray={strokeDasharray}
                      />
                    )
                  })}
              </Bar>
            ))}

          {lineDataKey ? (
            <Line
              type="linear"
              activeDot={{
                r: DOT_RADIUS,
                stroke: Token.color.white,
              }}
              animationDuration={LINE_ANIMATION_DURATION}
              dataKey={String(lineDataKey.value)}
              strokeWidth={LINE_STROKE_WIDTH}
              stroke={
                lineDataKey.color
                  ? getActiveShapeColor(lineDataKey.color)
                  : Token.color.orange
              }
            />
          ) : null}

          <Tooltip
            cursor={false}
            content={
              <ChartTooltip<T> tooltip={tooltip || DefaultTooltip} dataKeys={dataKeys} />
            }
          />

          <Legend
            content={props => (
              <DefaultLegend
                props={props}
                dataKeys={dataKeys}
                withProjection={!!projectedDataIdx.length}
              />
            )}
            wrapperStyle={{
              paddingTop: '10px',
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Relative>
  )
}
