import React from 'react'
import { ErrorWidget, Flex } from '@revolut/ui-kit'

const PerformanceErrorWidget = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <ErrorWidget>
        <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
        <ErrorWidget.Title />
        <ErrorWidget.Description>
          You don't have permissions to see this page
        </ErrorWidget.Description>
      </ErrorWidget>
    </Flex>
  )
}

export default PerformanceErrorWidget
