import React, { useEffect, useMemo, useRef } from 'react'
import { Banner, Link, MoreBar, VStack } from '@revolut/ui-kit'
import { ExclamationTriangle, LinkExternal } from '@revolut/icons'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import {
  bonusDateColumn,
  bonusDetailsColumn,
  bonusGrantTypeColumn,
  bonusPeriodColumn,
  bonusReasonColumn,
  bonusStatusColumn,
  bonusTaxabilityColumn,
  bonusValueColumn,
} from '@src/constants/columns/rewards'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { useTable } from '@components/TableV2/hooks'
import {
  getEmployeeRewardsBonuses,
  getEmployeeRewardsBonusesStats,
} from '@src/api/rewards'
import { BonusesInterface, BonusesStats, BonusTypes } from '@src/interfaces/rewards'
import { formatMoney } from '@src/utils/format'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import { SHAREWORKS_HOW_TO, SHAREWORKS_LINK } from '@src/constants/externalLinks'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<BonusesInterface> = {
  cells: [
    {
      ...bonusDateColumn,
      width: 80,
    },
    {
      ...bonusReasonColumn,
      width: 240,
    },
    {
      ...bonusPeriodColumn,
      width: 80,
    },
    {
      ...bonusValueColumn,
      width: 80,
    },
    {
      ...bonusStatusColumn,
      width: 100,
    },
    {
      ...bonusGrantTypeColumn,
      width: 80,
    },
    {
      ...bonusTaxabilityColumn,
      width: 80,
    },
    {
      ...bonusDetailsColumn,
      width: 240,
    },
  ],
}

interface Props {
  bonusType?: BonusTypes
  data: EmployeeInterface
  currency?: string
  setCurrency: (currency: string) => void
  hideBanner?: boolean
  hideBonusesStats?: boolean
}

export const Bonuses = ({
  bonusType,
  data,
  currency,
  setCurrency,
  hideBanner,
  hideBonusesStats,
}: Props) => {
  const mounted = useRef(false)

  const filterBy = bonusType
    ? [
        {
          filters: [{ name: 'cash', id: 'cash' }],
          columnName: 'bonus_type',
          nonResettable: true,
        },
      ]
    : []

  const table = useTable<BonusesInterface, BonusesStats>(
    {
      getItems: getEmployeeRewardsBonuses(data.id, currency),
      getStats: !hideBonusesStats
        ? getEmployeeRewardsBonusesStats(data.id, currency)
        : undefined,
    },
    filterBy,
  )

  useEffect(() => {
    if (mounted.current) {
      table.refresh()
    } else {
      mounted.current = true
    }
  }, [currency])

  const current = useMemo(() => {
    if (table.loading || !table.stats) {
      return undefined
    }

    if (!table.stats.result_currency) {
      return 'N/A'
    }

    return formatMoney(table.stats.total_bonuses, table.stats.result_currency)
  }, [table.stats, table.loading])

  return (
    <VStack gap="s-16">
      {!hideBanner && (
        <Banner>
          <Banner.Avatar>
            <ExclamationTriangle size={56} color="blue" />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>This data is for indicative purposes only</Banner.Title>
            <Banner.Description>
              Bonuses will appear here only once the effective date has been reached.
              Please refer to your{' '}
              <Link href={SHAREWORKS_LINK} target="_blank">
                Shareworks profile
              </Link>{' '}
              for final equity grant amounts, or to your payslips for final cash payments.{' '}
              <Link href={SHAREWORKS_HOW_TO} target="_blank">
                More information here.
              </Link>
            </Banner.Description>
          </Banner.Content>
        </Banner>
      )}
      <Table.Widget>
        {!hideBonusesStats && (
          <Table.Widget.Info>
            <CurrencySelect
              value={current}
              label="Total bonuses earned"
              onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
            />
          </Table.Widget.Info>
        )}
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              useIcon={LinkExternal}
              use="a"
              href={SHAREWORKS_LINK}
              target="_blank"
            >
              Go to Shareworks
            </MoreBar.Action>
            <MoreInfoButton href={SHAREWORKS_HOW_TO} target="_blank" />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.Bonuses}
            useWindowScroll
            dataType="Bonuses"
            row={ROW}
            {...table}
            noDataMessage="Bonuses will appear here"
            hiddenCells={{
              [bonusGrantTypeColumn.idPoint]: !!bonusType,
            }}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </VStack>
  )
}
