import React from 'react'
import SuccessWidget from '@components/SuccessWidget/SuccessWidget'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Flex } from '@revolut/ui-kit'

type Props = {
  date: string
  timeZone: string
}

const ConfirmedAppointment = ({ date, timeZone }: Props) => {
  return (
    <Flex alignItems="center" height="100vh">
      <SuccessWidget
        type="planned"
        title={`Availability confirmed for ${format(
          utcToZonedTime(new Date(date), timeZone),
          'ccc, d LLLL, hh:mmaaa',
        )} (${timeZone})`}
        text="Thank you for confirming your availability. We will reach out to you soon to share further details about the interview"
      />
    </Flex>
  )
}

export default ConfirmedAppointment
